import { Box, Button, Typography } from '@mui/material'
import { DocumentationIcon } from '@sitoo/mui-components'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Report } from '../../generated/graphql'
import { ReportsContext, ReportsContextType } from './reports-context'

type Props = {
  report: Report
}
export const NoReportGenerated = (props: Props) => {
  const { t } = useTranslation(['reports'])
  const { onAddReportJob, isAddingReportJob } = useContext(
    ReportsContext,
  ) as ReportsContextType

  return (
    <Box
      sx={{
        width: '100%',
        height: '70vh',
        bgcolor: (theme) => theme.palette.gray10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        mt: 2,
      }}
    >
      <Box
        sx={{
          height: (theme) => theme.spacing(4),
          mb: 2,
        }}
      >
        <DocumentationIcon
          sx={{
            height: '100%',
            width: '100%',
          }}
        />
      </Box>

      <Typography variant="body02">
        {t('reports:no_report_job_data')}
      </Typography>
      <Button
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={() => onAddReportJob(props.report)}
        disabled={isAddingReportJob}
        loading={isAddingReportJob}
        sx={{ mt: 2 }}
      >
        {t('reports:generate_report')}
      </Button>
    </Box>
  )
}
