import { Box, SxProps, Theme, Typography } from '@mui/material'

export type CustomOverlayProps = {
  icon?: React.ReactElement
  title?: string
  description?: string
  action?: React.ReactElement
  sx?: SxProps<Theme>
}

export const CustomOverlay = (props: CustomOverlayProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: (theme) => theme.spacing(8.5, 1),
        backgroundColor: (theme) => theme.palette.background.paper,

        ...props.sx,
      }}
    >
      {props.icon && (
        <Box
          sx={{
            height: (theme) => theme.spacing(8),
            '.MuiSvgIcon-root': { width: '100%', height: '100%' },
          }}
        >
          {props.icon}
        </Box>
      )}
      {props.title && (
        <Typography
          variant="heading"
          sx={{
            marginTop: (theme) => theme.spacing(3),
            maxWidth: '90%',
            textAlign: 'center',
          }}
        >
          {props.title}
        </Typography>
      )}
      {props.description && (
        <Box
          sx={{
            marginTop: (theme) => theme.spacing(1),
            maxWidth: (theme) => `min(${theme.spacing(37.5)}, 90%)`,
            textAlign: 'center',
          }}
        >
          {props.description}
        </Box>
      )}
      {props.action && (
        <Box
          sx={{
            marginTop: (theme) => theme.spacing(3),
            textAlign: 'center',
          }}
        >
          {props.action}
        </Box>
      )}
    </Box>
  )
}
