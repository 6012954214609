import {
  MenuItem,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
} from '@mui/material'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../hooks/tracking'

export type Option<T extends string | number = string | number> = {
  name: string
  value: T
}

type Props = {
  options: Option[]
  label?: string
  helperText?: string
  dataTestid?: string
  slotProps?: TextFieldProps['slotProps']
  sx?: SxProps<Theme>
  required?: boolean
}

export const SelectInput = <TFieldValues extends FieldValues>(
  props: Props & UseControllerProps<TFieldValues>,
) => {
  const { t } = useTranslation(['shared'])
  const { trackInputChange } = useTracking()
  const { control, name, options, label, helperText, dataTestid, sx } = props
  const { disabled, slotProps, rules, required } = props

  const validationRules = {
    required: required
      ? t('shared:validation.field_required', { field: label ?? name })
      : undefined,
    ...rules,
  }

  const { formState, fieldState, field } = useController({
    name,
    control,
    rules: validationRules,
  })

  const { isSubmitting } = formState
  const formError = fieldState.error
  const isRequired = !!validationRules?.required

  return (
    <TextField
      {...field}
      data-testid={dataTestid}
      disabled={disabled || isSubmitting}
      select
      label={label}
      error={!!formError}
      helperText={formError?.message ?? helperText}
      required={isRequired}
      onChange={(event) => {
        field.onChange(event)
        trackInputChange({ name, value: event.target.value })(event)
      }}
      slotProps={{
        ...slotProps,
        htmlInput: {
          ['data-testid']: `select-input-${name}`,
          ...slotProps?.htmlInput,
        },
      }}
      sx={{ mb: 2, ...sx }}
    >
      {options.map(({ name, value }) => (
        <MenuItem value={value} key={value}>
          {name}
        </MenuItem>
      ))}
    </TextField>
  )
}
