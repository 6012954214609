import { useCallback, useState, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PackageItems } from './package-items'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { usePrevious } from 'react-use'
import { CampaignFormContext } from '../../..'
import { ArrayElement } from '../../../../../utils/types'
import { useTracking } from '../../../../../hooks/tracking'
import { SearchProductField } from '../../../../../components/search-product-field'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

type Products = ArrayElement<
  CampaignFormContext['campaign']['productoptions']
>['sitooProducts']

type Props = {
  open: boolean
  productOptionIndex?: number
  productOptionProducts: Products
  onClose(): void
  onSubmit?: (products: Products, index?: number) => void | Promise<void>
  onSuccess?: () => void
  dataTestid?: string
}

export const SetProductsDialog = (props: Props) => {
  const { t } = useTranslation(['campaigns', 'shared'])
  const dialogName = 'set-product-option-items'

  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const [loading, setLoading] = useState(false)

  const { getValues, control } = useForm<{ items: Products }>({
    defaultValues: { items: [] },
  })

  const prevOpen = usePrevious(props.open)

  const apiRef = useGridApiRef()

  const { prepend, replace, remove } = useFieldArray({
    control,
    name: 'items',
    keyName: 'rowId',
  })

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true)
      await props.onSubmit?.(getValues('items'), props.productOptionIndex)
      trackFormSuccess({ name: `${dialogName}-dialog` })
      props.onSuccess?.()
    } catch (error) {
      trackFormError({ name: `${dialogName}-dialog` })
    } finally {
      setLoading(false)
    }
  }, [getValues, props, trackFormError, trackFormSuccess])

  useEffect(() => {
    if (props.open === prevOpen) return

    if (props.open) {
      replace(props.productOptionProducts || [])
      trackDialogOpen({ name: dialogName })
    } else {
      replace([])
      trackDialogClose({ name: dialogName })
    }
  }, [
    prevOpen,
    props.open,
    props.productOptionProducts,
    replace,
    trackDialogClose,
    trackDialogOpen,
  ])

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="sm"
      fullWidth
      data-testid={props.dataTestid}
    >
      <DialogTitle
        type="extended"
        sx={{ pb: 2, justifyContent: 'space-between' }}
      >
        {t('campaigns:campaign_form.product_options_edit_products')}
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <SearchProductField
          sx={{ mb: 2, px: 3 }}
          data-testid="search-product-input"
          onChange={(product) => {
            const index = getValues('items')?.findIndex(
              (item) => item.sku === product.sku,
            )

            if (index !== -1) {
              apiRef.current.scrollToIndexes({ rowIndex: index })
              return
            }

            prepend(product)

            apiRef.current.scrollToIndexes({ rowIndex: 0 })
          }}
        />

        <PackageItems
          items={getValues('items')}
          removeItem={remove}
          apiRef={apiRef}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={trackButtonClickEvent(
            { name: `${dialogName}-dialog-cancel` },
            props.onClose,
          )}
          color="secondary"
          size="small"
          type="button"
          disabled={loading}
        >
          {t('shared:action.cancel')}
        </Button>{' '}
        <Button
          type="submit"
          size="small"
          onClick={trackButtonClickEvent(
            { name: `${dialogName}-dialog-save` },
            onSubmit,
          )}
          data-testid="dialog-submit"
          loading={loading}
        >
          {t('shared:action.set')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
