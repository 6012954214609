import { useMutation, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '../..'
import { CurrenciesInput } from '../../../inputs/currencies-input'
import { SettingsDetailDialog } from '../../../components/settings-detail-dialog'
import { TextFieldInput } from '../../../inputs/text-field-input'
import {
  AddPurchasePriceListDocument,
  DeletePurchasePriceListDocument,
  GetPurchasePriceListDocument,
  GetPurchasePriceListsDocument,
  PurchasePriceList,
  UpdatePurchasePriceListDocument,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useAuthorization } from '../../../hooks/authorization'

export const PurchasePriceDialog = () => {
  const { t } = useTranslation(['shared', 'settings'])

  const { id } = useParams()
  const priceListId = id
  const isNewPriceList = !priceListId

  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const { writeSettingsPurchasePriceList } = useAuthorization().modules

  const { reset, control, getValues, handleSubmit, formState } =
    useForm<PurchasePriceList>({
      defaultValues: {
        id: '',
        name: '',
        currency: 'SEK',
      },
    })

  const [addPriceList, { loading: addLoading }] = useMutation(
    AddPurchasePriceListDocument,
    { refetchQueries: [GetPurchasePriceListsDocument] },
  )

  const [updatePriceList, { loading: updateLoading }] = useMutation(
    UpdatePurchasePriceListDocument,
    { refetchQueries: [GetPurchasePriceListsDocument] },
  )

  const { data, loading: fetchLoading } = useQuery(
    GetPurchasePriceListDocument,
    {
      variables: { id: String(priceListId) },
      fetchPolicy: 'cache-and-network',
      skip: isNewPriceList,
    },
  )

  const [deletePriceList, { loading: deleteLoading }] = useMutation(
    DeletePurchasePriceListDocument,
    { refetchQueries: [GetPurchasePriceListsDocument] },
  )

  const isLoading = fetchLoading || addLoading || updateLoading || deleteLoading

  const onSubmit = async (data: PurchasePriceList) => {
    const { id, name, currency } = data
    const priceList = { id, name, currency }

    if (isNewPriceList) {
      await addPriceList({ variables: { priceList } })
    } else {
      await updatePriceList({ variables: { priceList } })
    }
  }

  const deleteItem = async () => {
    if (priceListId) {
      await deletePriceList({ variables: { id: priceListId } })
    }
  }

  useEffect(() => {
    if (!priceListId) reset()

    if (data?.purchasePriceList) {
      reset(data.purchasePriceList)
    }
  }, [data, reset, priceListId])

  const onClose = () => {
    void navigate(generatePath(RootRoute.SettingsPurchasePriceLists))
  }

  return (
    <SettingsDetailDialog
      type={isNewPriceList ? 'add' : 'edit'}
      dialogName="price-list"
      typeLabel={t('settings:purchase_price_lists.item_type')}
      getItemName={() => getValues('name')}
      onSave={onSubmit}
      onDelete={deleteItem}
      onClose={onClose}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      formState={formState}
    >
      <TextFieldInput
        name="id"
        control={control}
        label={t('settings:purchase_price_lists.id')}
        disabled={!isNewPriceList || !writeSettingsPurchasePriceList}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: { 'data-testid': 'purchase-price-list-id' },
        }}
      />

      <TextFieldInput
        name="name"
        control={control}
        label={t('settings:purchase_price_lists.name')}
        required={true}
        disabled={!writeSettingsPurchasePriceList}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: { 'data-testid': 'purchase-price-list-name' },
        }}
      />

      <CurrenciesInput
        name="currency"
        control={control}
        isRequired
        disabled={!isNewPriceList || !writeSettingsPurchasePriceList}
        sx={{ '.MuiFormControl-root': { mb: 2 } }}
      />
    </SettingsDetailDialog>
  )
}
