/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { CountriesInput } from '../../../inputs/countries-input'
import { useServerValidation } from '../../../hooks/server-validation'
import { useTracking } from '../../../hooks/tracking'
import { GenericShipmentAddressFields } from '../shared'

export type AddressDialogProps = {
  open: boolean
  action: 'add' | 'edit'
  address?: GenericShipmentAddressFields | null
  onClose(): void
  onSubmit?(address: GenericShipmentAddressFields): Promise<void> | void
  onSuccess?(): void
  onError?(error: unknown): void
  isNewShipment: boolean
}

type Form = GenericShipmentAddressFields

export const SetAddressDialog = (props: AddressDialogProps) => {
  const { t } = useTranslation(['shipments_v2', 'shared'])
  const dialogName = 'set-address-v2'
  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)
  const [loading, setLoading] = useState(false)
  const { formState, reset, handleSubmit, register, control, setError } =
    useForm<Form>({
      mode: 'onChange',
    })
  const setFormError = useServerValidation<Form>('shipments', setError, {
    resolveFieldFromProperty: (property) =>
      property.split('.').pop() || property,
  })

  const onSubmit = async (formData: Form) => {
    try {
      setLoading(true)
      await props.onSubmit?.(formData)
      trackFormSuccess({ name: `${dialogName}-dialog` })
      props.onSuccess?.()
    } catch (error) {
      trackFormError({ name: `${dialogName}-dialog` })

      setFormError(error)
      props.onError?.(error)
    } finally {
      setLoading(false)
    }
  }

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
      reset({ ...props.address })
    }
  }, [props.open, prevOpen, trackDialogOpen, props.address, reset])

  return (
    <Dialog open={props.open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          type="extended"
          sx={{
            paddingBottom: (theme) => theme.spacing(2),
          }}
        >
          {t(
            props.action === 'add'
              ? 'shipments_v2:set_address_dialog.add_title'
              : 'shipments_v2:set_address_dialog.edit_title',
          )}
        </DialogTitle>
        <DialogContent>
          <TextField
            error={!!formState?.errors?.name}
            fullWidth
            helperText={formState?.errors?.name?.message}
            label={t('shipments_v2:shipment_form.name')}
            {...register('name')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.address}
            fullWidth
            helperText={formState?.errors?.address?.message}
            label={t('shipments_v2:shipment_form.address')}
            {...register('address')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.address2}
            fullWidth
            helperText={formState?.errors?.address2?.message}
            label={t('shipments_v2:shipment_form.address2')}
            {...register('address2')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.city}
            fullWidth
            helperText={formState?.errors?.city?.message}
            label={t('shipments_v2:shipment_form.city')}
            {...register('city')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.zip}
            fullWidth
            helperText={formState?.errors?.zip?.message}
            label={t('shipments_v2:shipment_form.zip')}
            {...register('zip')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.state}
            fullWidth
            helperText={formState?.errors?.state?.message}
            label={t('shipments_v2:shipment_form.state')}
            {...register('state')}
            sx={{ mb: 2 }}
          />

          <CountriesInput control={control} name="countryid" />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={trackButtonClickEvent(
              {
                name: `${dialogName}-dialog-cancel`,
              },
              onClose,
            )}
            color="secondary"
            size="small"
            type="button"
          >
            {t('shared:action.cancel')}
          </Button>
          <Button
            type="submit"
            size="small"
            onClick={trackButtonClickEvent({
              name: `${dialogName}-dialog-set`,
            })}
            disabled={!formState.isValid}
            loading={loading}
          >
            {props.isNewShipment
              ? t('shared:action.ok')
              : t('shared:action.save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
