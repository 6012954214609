import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import {
  GetProductGroupsQuery,
  GetProductGroupsDocument,
} from '../../../../generated/graphql'
import { usePageTitle } from '../../../../hooks/title'
import { SettingsList } from '../../../../components/settings-list'
import { useTracking } from '../../../../hooks/tracking'
import { useNavigate } from 'react-router-dom'
import { useAbsolutePath } from '../../../../hooks/absolute-path'
import { RootRoute } from '../../..'
import { useCallback, useMemo } from 'react'
import { GridColDef, GridRowId, useGridApiRef } from '@mui/x-data-grid-pro'
import { ArrayElement } from '../../../../utils/types'
import { OrderIcon } from '@sitoo/mui-components'
import { Button } from '@mui/material'
import { PageHeader } from '../../../../components/page-header'
import { CursorPagination } from '../../../../components/data-grid/cursor-pagination'

type Row = ArrayElement<GetProductGroupsQuery['productGroups']['items']>

const PAGE_SIZE = 50

export const SettingsProductGroupsPage = () => {
  const name = 'product-group-lists'
  const { t } = useTranslation(['shared', 'settings'])
  const apiRef = useGridApiRef()
  const { trackButtonClick } = useTracking()
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  usePageTitle(t('shared:menu.product_groups'))

  const {
    loading: fetchLoading,
    data,
    fetchMore,
  } = useQuery(GetProductGroupsDocument, {
    variables: { start: 0, num: PAGE_SIZE },
    fetchPolicy: 'cache-and-network',
  })

  const isLoading = fetchLoading

  const onEdit = (id: GridRowId) => {
    trackButtonClick({ name: `${name}-edit` })
    void navigate(generatePath(RootRoute.SettingsProductsGroup, { id }))
  }

  const onAdd = () => {
    trackButtonClick({ name: `${name}-add` })
    void navigate(generatePath(RootRoute.SettingsProductsGroupsNew))
  }

  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'name',
        headerName: t('settings:product_groups.name'),
      },
      {
        field: 'type',
        headerName: t('settings:product_groups.type'),
        valueGetter: (_, row) => t(`settings:product_group_type.${row.type}`),
      },
      {
        field: 'value',
        headerName: t('settings:product_groups.vat'),
        valueGetter: (_, row) => `${row.value}%`,
      },
      {
        field: 'comment',
        headerName: t('settings:product_groups.internal_comment'),
      },
    ],
    [t],
  )

  const fetchMoreItems = useCallback(async () => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.productGroups.totalcount) {
      return fetchMore({
        variables: {
          start: (data.productGroups?.start ?? 0) + pageSize,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  return (
    <SettingsList
      name={name}
      apiRef={apiRef}
      rows={data?.productGroups.items ?? []}
      rowCount={data?.productGroups.totalcount ?? 0}
      showMore={
        Number(data?.productGroups.items?.length) <
        Number(data?.productGroups.totalcount)
      }
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      fetchMore={fetchMoreItems}
      columns={columns}
      loading={isLoading}
      onRowClick={({ id }) => onEdit(id)}
      noRowsOverlay={{
        icon: <OrderIcon />,
        title: t('settings:product_groups.empty_title'),
        action: (
          <Button
            onClick={() =>
              void navigate(generatePath(RootRoute.SettingsProductsGroupsNew))
            }
          >
            {t('settings:product_groups.add')}
          </Button>
        ),
      }}
      slots={{ PageHeader, pagination: CursorPagination }}
      slotProps={{
        pageHeader: {
          title: t('shared:menu.product_groups'),
          isFlexible: true,
          rightColumn: (
            <Button data-testid="add-product-group" onClick={onAdd}>
              {t('settings:product_groups.add')}
            </Button>
          ),
        },
      }}
    />
  )
}
