/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { ConfirmationDialog } from '@sitoo/mui-components'
import { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useTracking } from '../../hooks/tracking'
import { getErrorMessages } from '../../utils/error-mapping'
import { useSnackbar } from 'notistack'
import { FieldValues, FormState, UseFormHandleSubmit } from 'react-hook-form'
import { DialogRouteLeavingGuard } from '../dialog-route-leaving-guard'

type Props = PropsWithChildren & {
  type: 'add' | 'edit'
  dialogName: string
  typeLabel: string
  getItemName(): string
  readOnly?: boolean
  onClose?(): void
  onSave?(formData: FieldValues): Promise<void>
  handleSubmit: UseFormHandleSubmit<FieldValues, undefined>
  onDelete(): Promise<void>
  isLoading?: boolean
  formState: FormState<FieldValues>
}

export const SettingsDetailDialog = (props: Props) => {
  const { t } = useTranslation(['shared', 'settings'])

  const { type, readOnly = false, isLoading, handleSubmit } = props
  const isNew = type === 'add'
  const dialogName = isNew
    ? `add-${props.dialogName}`
    : `edit-${props.dialogName}`
  const formId = `${dialogName}-form`

  const [showDetailDialog, setDetailDialog] = useState(true)
  const [showDeleteDialog, setDeleteDialog] = useState(false)

  const {
    trackDialogOpen,
    trackDialogClose,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const { enqueueSnackbar } = useSnackbar()

  const onClose = useCallback(() => {
    trackDialogClose({ name: dialogName })
    props.onClose?.()
  }, [dialogName, props, trackDialogClose])

  const onSubmit = useCallback(
    async (data: FieldValues) => {
      try {
        await props.onSave?.(data)

        enqueueSnackbar(
          <Typography>
            <Trans
              i18nKey="settings:detail_dialog.saved_message"
              values={{ type: props.typeLabel, name: props.getItemName() }}
              components={{ b: <b /> }}
            />
          </Typography>,
        )
        trackFormSuccess({ name: dialogName })
        onClose()
      } catch (error) {
        const errorMessages = getErrorMessages(error)
        enqueueSnackbar({ message: errorMessages[0], variant: 'error' })
        trackFormError({ name: dialogName, message: errorMessages[0] })
      }
    },
    [
      props,
      enqueueSnackbar,
      trackFormSuccess,
      dialogName,
      onClose,
      trackFormError,
    ],
  )

  const onDelete = async () => {
    try {
      await props.onDelete()

      enqueueSnackbar(
        <Typography>
          <Trans
            i18nKey="settings:detail_dialog.deleted_message"
            values={{ type: props.typeLabel, name: props.getItemName() }}
            components={{ b: <b /> }}
          />
        </Typography>,
      )
    } catch (error) {
      const errorMessages = getErrorMessages(error)

      enqueueSnackbar({ message: errorMessages[0], variant: 'error' })
      trackFormError({ name: dialogName, message: errorMessages[0] })
    }
    onDeleteDialogClose()
    onClose()
  }

  const onDeleteDialogOpen = useCallback(() => {
    setDeleteDialog(true)
    setDetailDialog(false)
  }, [])

  const onDeleteDialogClose = useCallback(() => {
    setDeleteDialog(false)
    setDetailDialog(true)
  }, [])

  useEffect(() => {
    trackDialogOpen({ name: dialogName })
  }, [trackDialogOpen, dialogName])

  return (
    <>
      <DialogRouteLeavingGuard
        formState={props.formState}
        setOriginalDialogOpen={
          showDeleteDialog ? setDeleteDialog : setDetailDialog
        }
      />
      <ConfirmationDialog
        confirmAction={onDelete}
        title={t('settings:detail_dialog.delete_title', {
          type: props.typeLabel,
        })}
        text={t('settings:detail_dialog.delete_description', {
          name: props.getItemName(),
        })}
        variant="destructive"
        open={showDeleteDialog}
        onClose={onDeleteDialogClose}
      />

      <Dialog
        data-testid={`${dialogName}-dialog`}
        open={showDetailDialog}
        maxWidth="xs"
        fullWidth
        onClose={onClose}
      >
        <DialogTitle>
          {isNew
            ? t('settings:detail_dialog.add_title', { type: props.typeLabel })
            : t('settings:detail_dialog.update_title', {
                type: props.typeLabel,
              })}
        </DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            {props.children}
          </form>

          {!isNew && (
            <Button
              color="error"
              data-testid={`${dialogName}-delete-button`}
              fullWidth
              loading={isLoading}
              disabled={readOnly}
              sx={{ mt: 2 }}
              onClick={onDeleteDialogOpen}
            >
              {t('shared:action.delete')}
            </Button>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            color="secondary"
            onClick={onClose}
            data-testid={`${dialogName}-close-button`}
          >
            {t(!readOnly ? 'shared:action.cancel' : 'shared:action.close')}
          </Button>

          {!readOnly && (
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              form={formId}
              loading={isLoading}
              color="primary"
              data-testid={`${dialogName}-save-button`}
            >
              {t('shared:action.save')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}
