import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { FilterViewPanel } from '../../../components/filter-view-panel'
import { ReportTypesFilter } from './report-types-filter'

type ReportListFilterPanelProps = {
  isOpen: boolean
  onClose(): void
}

export const ReportListFilterPanel = (props: ReportListFilterPanelProps) => {
  const { t } = useTranslation(['reports', 'shared'])

  return (
    <FilterViewPanel open={props.isOpen} onClose={props.onClose}>
      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="filter">
          <SectionHeader sx={{ p: 0 }}>
            {t('shared:menu.general')}
          </SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 1.5 }}>
          <List sx={{ px: 2 }}>
            <ReportTypesFilter />
          </List>
        </AccordionDetails>
      </Accordion>
    </FilterViewPanel>
  )
}
