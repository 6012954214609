import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AutocompleteInput } from '../../../inputs/autocomplete-input'

import { FilterContext } from '../../../components/data-grid/context'
import { ReportType } from '../../../generated/graphql'
import { useFormFilter } from '../../../hooks/form-filter'

export type ReportTypesFilter = {
  reportTypes?: ReportType[]
}

export const ReportTypesFilter = () => {
  const { t } = useTranslation(['shared', 'filter'])
  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)

  const filterKey = 'reportTypes'

  const { queryParams, formContext, resetForm } =
    useFormFilter<ReportTypesFilter>({
      formProps: {
        defaultValues: { reportTypes: [] },
      },
      parseOptions: {
        types: { reportTypes: (value) => value.split(',').map(String) },
      },
    })

  const { reportTypes } = queryParams

  useEffect(() => {
    registerFilter({ key: filterKey })
  }, [registerFilter])

  useEffect(() => {
    if (reportTypes?.length) {
      setFilter(filterKey, {
        label: t('reports:columns.type'),
        labelValues: reportTypes.map((value) =>
          t(`reports:report_types.${value}`),
        ),
        value: reportTypes,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [removeFilter, reportTypes, setFilter, t])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        resetForm()
      }
    })

    return () => unsubscribe()
  }, [resetForm, subscribeOnResetFilter])

  return (
    <AutocompleteInput
      multiple
      name="reportTypes"
      control={formContext.control}
      options={Object.keys(ReportType)}
      label={t('reports:columns.type')}
      textFieldProps={{
        slotProps: {
          htmlInput: { ['data-testid']: 'report-types-input' },
        },
      }}
      autocompleteProps={{
        limitTags: 5,
        getOptionKey: (option) => option,
        getOptionLabel: (option) => t(`reports:report_types.${option}`),
      }}
    />
  )
}
