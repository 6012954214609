import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '../..'
import { FormDeleteField } from '../../../components/form-delete-field'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { InfluencerCodesDeleteDialog } from '../../influencer-codes/influencer-codes-delete-dialog'

export const Delete = () => {
  const { t } = useTranslation(['influencer_codes'])
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const onClose = () => {
    setIsDialogOpen(false)
  }
  const { id } = useParams()

  const onSuccess = (result: boolean, codes: string[]) => {
    if (result) {
      enqueueSnackbar(
        t('influencer_codes:success_delete_snackbar', {
          count: codes.length,
        }),
      )
    } else {
      enqueueSnackbar(
        t('influencer_codes:failure_delete_snackbar', {
          count: codes.length,
        }),
        { variant: 'error' },
      )
    }

    void navigate(generatePath(RootRoute.InfluencerCodes))
  }

  if (!id) return null

  return (
    <>
      <FormDeleteField
        onClick={() => setIsDialogOpen(true)}
        label={t('influencer_codes:influencer_code_delete_title')}
        testId="influencer-code-delete-button"
      >
        {t('influencer_codes:influencer_code_delete_title')}
      </FormDeleteField>

      <InfluencerCodesDeleteDialog
        open={isDialogOpen}
        onClose={onClose}
        ids={[id]}
        onSuccess={onSuccess}
        dataTestid="influencer-code-delete-dialog"
      />
    </>
  )
}
