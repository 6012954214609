import { useSnackbar } from 'notistack'
import { SubmitErrorHandler, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { UploadedFile } from '@sitoo/mui-components'
import { RootRoute } from '..'
import { getErrorMessage } from '../../utils/error-mapping'
import {
  GetProductDocument,
  GetProductQuery,
  ProductInput,
  AddProductDocument,
  UpdateProductImagesDocument,
  UpdateProductDocument,
  UpdateProductVariantsDocument,
  UploadProductImageDocument,
} from '../../generated/graphql'
import { useServerValidation } from '../../hooks/server-validation'
import { reactiveProductViewPanelVar } from '../products'
import { ProductFormContext } from '.'
import { useTracking } from '../../hooks/tracking'
import { useApolloClient, useMutation } from '@apollo/client'
import { useMoney } from '../../hooks/money'
import { useAbsolutePath } from '../../hooks/absolute-path'

type Props = {
  productId: number
  isNewProduct: boolean
  formContext: UseFormReturn<ProductFormContext>
}

export const useProductFormSubmit = (props: Props) => {
  const { t } = useTranslation(['products', 'shared'])
  const { trackButtonClick, trackFormError, trackFormSuccess } = useTracking()
  const { formContext, productId, isNewProduct } = props
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const client = useApolloClient()
  const [addProductMutation, { loading: addLoading }] =
    useMutation(AddProductDocument)
  const [updateProductMutation, { loading: updateLoading }] = useMutation(
    UpdateProductDocument,
  )
  const [uploadProductImageMutation, { loading: uploadImageLoading }] =
    useMutation(UploadProductImageDocument, {
      context: { headers: { 'apollo-require-preflight': true } },
    })
  const [updateProductImagesMutation, { loading: updateImagesLoading }] =
    useMutation(UpdateProductImagesDocument)
  const [updateProductVariantMutation, { loading: updateVariantLoading }] =
    useMutation(UpdateProductVariantsDocument)
  const { enqueueSnackbar } = useSnackbar()
  const setFormError = useServerValidation<ProductFormContext>(
    'product',
    formContext.setError,
  )
  const { formatStringCurrencyToNumber } = useMoney()

  const isSubmitting =
    addLoading ||
    updateLoading ||
    uploadImageLoading ||
    updateImagesLoading ||
    updateVariantLoading

  const formatProductData = (
    data: GetProductQuery['product'],
  ): ProductInput => {
    return {
      title: data.title,
      sku: data.sku,
      description: data.description,
      descriptionshort: data.descriptionshort,
      defaultcategoryid: data.defaultcategoryid || null,
      vatid: data.productgroup?.id,
      manufacturerid: data.manufacturer?.id || 0,
      skumanufacturer: data.skumanufacturer,
      manufacturerurl: data.manufacturerurl,
      categories: data.categories || [],
      stockcountenable: data.stockcountenable,
      barcode: data.barcode || '',

      allowdecimals: data.allowdecimals,
      unitlabel: data.unitlabel,

      custom1: data.custom1,
      custom2: data.custom2,
      custom3: data.custom3,
      custom4: data.custom4,
      custom5: data.custom5,

      customattributes: data.customattributes,

      related: data.related,
      similar: data.similar,
      accessories: data.accessories,
      moneypriceorg: formatStringCurrencyToNumber(
        data.moneypriceorg || '0',
      ).toFixed(2),
      moneyprice: formatStringCurrencyToNumber(data.moneyprice || '0').toFixed(
        2,
      ),
    }
  }

  const formatVariantsData = (
    data: GetProductQuery['product'],
    mainProductId: number,
  ) => {
    if (!data.childVariants || !data.variantGroups) {
      return
    }

    const variants = data.childVariants.map((v, index) => {
      const attributes = v.variant?.map(({ value }) => value) as string[]
      return {
        productid: v.isMainVariant
          ? mainProductId
          : v['__typename'] || index === 0
            ? v.id
            : 0,
        sku: v.sku,
        barcode: v.barcode || '',
        attributes: attributes?.length ? attributes : [''],
        customattributes: v.customattributes,
        title: v.title,
        moneypriceorg: formatStringCurrencyToNumber(
          v.moneypriceorg || '0',
        ).toFixed(2),
        moneyprice: formatStringCurrencyToNumber(v.moneyprice || '0').toFixed(
          2,
        ),
      }
    })

    //  Groups define the product order, so we get the options from the variants it self
    const groups = data.variantGroups.map(({ name }, index) => ({
      name,
      options: [
        ...new Set(
          variants
            .map((x) => x.attributes[index])
            .filter((x): x is string => !!x),
        ),
      ],
    }))

    return { variants, groups }
  }

  const uploadImages = async (productId: number, images: UploadedFile[]) => {
    const existingFiles = images.filter((f) => f.id)
    const newFiles = images.filter((f) => f.file)

    await updateProductImagesMutation({
      variables: {
        productId,
        imageIds: existingFiles.map((f) => f.id) as string[],
      },
    })

    for (const file of newFiles) {
      if (!file.file) continue

      await uploadProductImageMutation({
        variables: {
          productId,
          file: file.file,
        },
      })
    }
  }

  const onSubmit = async (data: ProductFormContext) => {
    try {
      trackButtonClick({ name: 'product-save' })
      let mainProductId = productId

      //  Save the main variant
      if (isNewProduct) {
        const { data: response } = await addProductMutation({
          variables: { data: formatProductData(data.product) },
        })
        if (response?.addProduct) {
          mainProductId = response?.addProduct
        }
      } else {
        await updateProductMutation({
          variables: {
            productId: mainProductId,
            data: formatProductData(data.product),
          },
        })
      }

      //  Update all the child variants (including main variant)
      if (data.product.childVariants && data.product.childVariants.length > 0) {
        const variantsData = formatVariantsData(data.product, mainProductId)
        if (variantsData) {
          const { data: variantsResponse } = await updateProductVariantMutation(
            {
              variables: {
                productId: mainProductId,
                deleteMainIfFails: isNewProduct,
                ...variantsData,
              },
            },
          )

          for (const childVariant of data.product.childVariants) {
            const foundVariant = variantsResponse?.updateProductVariants.find(
              (v) => v.sku === childVariant.sku,
            )

            //  Update child variant id (its most for new child variants)
            if (foundVariant) {
              childVariant.id = foundVariant.id
            }
          }
        }
      }

      //  Set product images
      if (data.product.childVariants) {
        for (const variant of data.product.childVariants) {
          if (variant.productImages) {
            await uploadImages(variant.id, variant.productImages)
          }
        }
      } else if (data.product.productImages) {
        await uploadImages(mainProductId, data.product.productImages)
      }

      if (!isNewProduct) {
        await client.refetchQueries({
          include: [GetProductDocument],
        })
      }

      trackFormSuccess({
        name: 'product',
        isNewProduct,
      })
      reactiveProductViewPanelVar({
        isOpen: true,
        productId: mainProductId,
      })
      void navigate(generatePath(RootRoute.Products))

      enqueueSnackbar(
        isNewProduct
          ? t('products:product_message.success_add')
          : t('products:product_message.success_update'),
      )
    } catch (error) {
      setFormError(error)

      const errorMessage = getErrorMessage(
        error,
        'products',
        isNewProduct
          ? t('products:product_message.failure_add')
          : t('products:product_message.failure_update'),
      )

      trackFormError({
        name: 'product',

        errorMessage,
        isNewProduct,
      })

      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  const onError: SubmitErrorHandler<ProductFormContext> = (_errors) => {
    enqueueSnackbar(t('products:error.generic'), {
      variant: 'error',
      autoHideDuration: 2000,
    })
  }

  return { onSubmit, isSubmitting, onError }
}
