import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { ArrowLeftIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../../pages'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { useSessionStorage } from 'react-use'
import { SESSION_BACK_PATH } from '../../hooks/navigation-back-path'

export const SidebarListBackItem = () => {
  const navigate = useNavigate()
  const [backPath] = useSessionStorage<string | null>(SESSION_BACK_PATH)
  const { t } = useTranslation()
  const generatePath = useAbsolutePath()

  const handleNavigation = () => {
    if (!backPath || !backPath.startsWith('/')) {
      void navigate(generatePath(RootRoute.Home))
      return
    }

    void navigate(backPath)
  }

  return (
    <List>
      <ListItem
        sx={{
          bgcolor: 'darkMode60',
          marginTop: (theme) => theme.spacing(2),
          cursor: 'pointer',
          ':hover': {
            bgcolor: 'darkMode40',
          },
          color: 'white',
          py: 2.25,
        }}
        onClick={handleNavigation}
      >
        <ListItemIcon>
          <ArrowLeftIcon sx={{ color: (theme) => theme.palette.white }} />
        </ListItemIcon>

        <ListItemText>{t('shared:action.back')}</ListItemText>
      </ListItem>
    </List>
  )
}
