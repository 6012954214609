import { useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../../..'
import {
  ParsePricesCsvDocument,
  ImportPricesDocument,
} from '../../../../generated/graphql'
import { useAbsolutePath } from '../../../../hooks/absolute-path'
import { ImportDialog } from '../import-dialog'
import { stripNullValues } from '../../../../utils/strip-null-values'

export type Props = {
  open: boolean
  onClose(): void
}

const CSV_EXAMPLE = `
pricelistid;productid;sku;moneyprice
1;21681;product-sku-1;9000.00
1;21682;product-sku-2;7000.00
`

export const ImportPricesDialog = (props: Props) => {
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const [parseCsvMutation, { data: parsedData, loading: parseLoading }] =
    useMutation(ParsePricesCsvDocument)

  const [
    importMutation,
    { data: importData, loading: importLoading, error: importError },
  ] = useMutation(ImportPricesDocument)

  const isLoading = parseLoading || importLoading

  const onParse = useCallback(
    (csv: string) => {
      return parseCsvMutation({ variables: { csv } })
    },
    [parseCsvMutation],
  )

  const onImport = useCallback(() => {
    const parseItems = parsedData?.parsePricesCsv.items.map((o) =>
      stripNullValues(o),
    )

    if (parseItems) {
      return importMutation({ variables: { data: parseItems } })
    }
  }, [importMutation, parsedData?.parsePricesCsv.items])

  const onSuccess = useCallback(() => {
    void navigate(generatePath(RootRoute.Prices))
  }, [generatePath, navigate])

  return (
    <ImportDialog
      {...props}
      isLoading={isLoading}
      ns="prices"
      onImport={onImport}
      onParse={onParse}
      onSuccess={onSuccess}
      importData={importData?.importPrices}
      parsedItems={parsedData?.parsePricesCsv}
      importError={importError}
      example={CSV_EXAMPLE}
    />
  )
}
