import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { AuthenticateSiteDocument } from '../../generated/graphql'
import { RootRoute } from '../../pages'
import { getErrorMessages } from '../../utils/error-mapping'
import { extractGraphqlErrors } from '../../utils/extract-graphql-errors'
import { useMe } from '../me'

export const useSiteSelector = () => {
  const params = useParams<{ siteId: string }>()
  const siteId = Number(params.siteId)
  const { me, loading } = useMe()
  const navigate = useNavigate()
  const [authenticateSite] = useMutation(AuthenticateSiteDocument, {
    refetchQueries: 'active',
  })
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (!siteId || !me?.siteId || loading) return

    if (siteId !== me.siteId) {
      void authenticateSite({ variables: { siteId } }).catch((error) => {
        const errorMessage = getErrorMessages(error)[0]
        enqueueSnackbar(errorMessage, { variant: 'error' })

        const allErrors = extractGraphqlErrors(error)

        const isInvalidSite = allErrors.some(
          (x) => x.extensions?.['code'] === 'INVALID_SITE_ID',
        )

        void navigate(
          { pathname: RootRoute.Logout },
          { state: { clean: isInvalidSite } },
        )
      })
    }
  }, [siteId, me, authenticateSite, navigate, loading, enqueueSnackbar])
}
