/* eslint-disable @typescript-eslint/no-misused-promises */
import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  GetProductGroupsDocument,
  UpdateProductsDocument,
} from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { MAX_NUM_REQUEST } from '../../../utils/constants'

export type SetProductGroupDialogProps = {
  open: boolean
  productIds: number[]
  dataTestid?: string
  onClose(): void
  onSuccess?(successfulProductIds: number[], failedProductIds: number[]): void
  onError?(successfulProductIds: number[], failedProductIds: number[]): void
}

type Form = { vatId: number }

export const SetProductGroupDialog = (props: SetProductGroupDialogProps) => {
  const { t } = useTranslation(['products', 'shared'])
  const dialogName = 'set-product-group'
  const {
    trackButtonClickEvent,
    trackDialogOpen,
    trackDialogClose,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)
  const [updateProductsMutation, { loading }] = useMutation(
    UpdateProductsDocument,
  )
  const formContext = useForm<Form>({
    defaultValues: {
      vatId: 0,
    },
    mode: 'onChange',
  })

  const { data: productGroupsResponse } = useQuery(GetProductGroupsDocument, {
    variables: {
      num: MAX_NUM_REQUEST,
    },
  })
  const productGroups = productGroupsResponse?.productGroups.items || []

  const onSubmit = async (data: Form) => {
    const successfulProductIds: number[] = []
    const failedProductIds: number[] = []
    try {
      const mutationResponse = await updateProductsMutation({
        variables: {
          data: props.productIds.map((id) => ({
            productid: id,
            vatid: data.vatId,
          })),
        },
      })

      for (const updateProductResponse of mutationResponse.data
        ?.updateProducts || []) {
        if (updateProductResponse.success) {
          successfulProductIds.push(updateProductResponse.product.id)
        } else {
          failedProductIds.push(updateProductResponse.product.id)
        }
      }

      trackFormSuccess({
        name: `${dialogName}-dialog`,
      })
      if (props.onSuccess) {
        props.onSuccess(successfulProductIds, failedProductIds)
      }
    } catch {
      trackFormError({
        name: `${dialogName}-dialog`,
      })
      if (props.onError) {
        props.onError(successfulProductIds, failedProductIds)
      }
    } finally {
      formContext.reset()
    }
  }

  const onClose = () => {
    if (!loading) {
      trackDialogClose({ name: dialogName })
      formContext.reset()
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen])

  return (
    <Dialog
      onClose={onClose}
      open={props.open}
      maxWidth="sm"
      fullWidth
      data-testid={props.dataTestid}
    >
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <DialogTitle
          type="extended"
          sx={{
            paddingBottom: (theme) => theme.spacing(2),
          }}
        >
          {t('products:set_product_group')}
        </DialogTitle>
        <DialogContent>
          <Controller
            control={formContext.control}
            name="vatId"
            defaultValue={0}
            rules={{
              min: 1,
            }}
            render={({ field }) => (
              <Select
                data-testid="select-product-group"
                value={field.value}
                onChange={(event) => {
                  field.onChange(event.target.value)
                }}
              >
                <MenuItem disabled value={0} hidden>
                  <em>{t('shared:label.select')}</em>
                </MenuItem>
                {productGroups.map((pg) => (
                  <MenuItem
                    data-testid="product-group-option"
                    value={pg.id}
                    key={pg.id}
                  >
                    {pg.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={trackButtonClickEvent(
              {
                name: `${dialogName}-dialog-cancel`,
              },
              onClose,
            )}
            color="secondary"
            size="small"
            disabled={loading}
            type="button"
          >
            {t('shared:action.cancel')}
          </Button>
          <Button
            data-testid="set-product-group-button"
            type="submit"
            size="small"
            loading={loading}
            onClick={trackButtonClickEvent({
              name: `${dialogName}-dialog-cancel-set`,
            })}
            disabled={!formContext.formState.isValid}
          >
            {t('shared:action.set')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
