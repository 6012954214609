import { Box, Divider } from '@mui/material'
import { PropsWithChildren } from 'react'

type Props = PropsWithChildren

export const FilterViewPanelFooter = (props: Props) => {
  return (
    <Box
      sx={{
        position: 'sticky',
        bottom: 0,
      }}
    >
      <Divider />
      <Box
        sx={{
          p: 2,
          bgcolor: (theme) => theme.palette.background.paper,
        }}
      >
        {props.children}
      </Box>
    </Box>
  )
}
