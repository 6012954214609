/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** Converts a decimal to 00[0].000000 format */
  Coordinate: { input: string; output: string }
  /** Decimal value must have exactly three decimal numbers. Ex: 0.000 */
  Decimal: { input: string; output: string }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: {
    input: Record<string, unknown>
    output: Record<string, unknown>
  }
  /** Money value must have exactly two decimal numbers. Ex: 123.00 */
  Money: { input: string; output: string }
  /** Convert integer money values to decimal Ex. 10000 -> 1000.00 */
  MoneyInt: { input: number; output: number }
  /** Sitoo stores data without milisseconds */
  UnixDate: { input: string; output: string }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: unknown; output: unknown }
  /** Prepends "https://" to a string before sending to the server */
  Url: { input: string; output: string }
}

export enum ActionType {
  AddArrivedPackages = 'AddArrivedPackages',
  AddPackage = 'AddPackage',
  AddReceivedItems = 'AddReceivedItems',
  AddReceivedItemsUndeclared = 'AddReceivedItemsUndeclared',
  Create = 'Create',
  DeletePackage = 'DeletePackage',
  RemoveStock = 'RemoveStock',
  SetInfo = 'SetInfo',
  SetState = 'SetState',
  UpdatePackage = 'UpdatePackage',
}

export type ActivationCode = {
  __typename?: 'ActivationCode'
  account?: Maybe<Scalars['String']['output']>
  activationCode?: Maybe<Scalars['String']['output']>
}

export type AddOrderDeliveryInput = {
  datecancelled?: InputMaybe<Scalars['UnixDate']['input']>
  orderdeliveryitems: Array<OrderDeliveryItemInput>
  orderdeliveryref?: InputMaybe<Scalars['String']['input']>
  pluginname?: InputMaybe<Scalars['String']['input']>
  warehouseid: Scalars['Int']['input']
}

export type AddPaymentTypeInput = {
  externalid?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  paymentmethodid: PosPaymentMethod
  sortorder?: InputMaybe<Scalars['Int']['input']>
}

export type AddServiceOrderInput = {
  author: ServiceOrderUserInput
  currency: Scalars['String']['input']
  customer: ServiceOrderCustomerInput
  note?: InputMaybe<Scalars['String']['input']>
  owner: ServiceOrderUserInput
  product: ServiceOrderProductInput
  revision?: InputMaybe<Scalars['Int']['input']>
  state?: InputMaybe<ServiceOrderState>
  store: ServiceOrderStoreInput
}

export type AddUpdateLoginOptionInput = {
  id?: InputMaybe<Scalars['String']['input']>
  oidc_private?: InputMaybe<LoginOptionOidcPrivateInput>
  type: LoginOptionTypeEnum
}

export type AddUpdatePurchasePriceListInput = {
  currency: Scalars['String']['input']
  id?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
}

export type AddUserInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  address?: InputMaybe<Scalars['String']['input']>
  address2?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  company?: InputMaybe<Scalars['String']['input']>
  companyid?: InputMaybe<Scalars['String']['input']>
  countryid?: InputMaybe<Scalars['String']['input']>
  customernumber?: InputMaybe<Scalars['String']['input']>
  department?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  externalid?: InputMaybe<Scalars['String']['input']>
  mobile?: InputMaybe<Scalars['String']['input']>
  namefirst?: InputMaybe<Scalars['String']['input']>
  namelast?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  personalid?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  pin?: InputMaybe<Scalars['String']['input']>
  posallstores?: InputMaybe<Scalars['Boolean']['input']>
  posrefundcard?: InputMaybe<Scalars['Boolean']['input']>
  role?: InputMaybe<UserRole>
  staff_pos_activation?: InputMaybe<Scalars['Boolean']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  tags?: InputMaybe<Array<Scalars['String']['input']>>
  timezone?: InputMaybe<Scalars['String']['input']>
  voucherpasswords?: InputMaybe<Array<Scalars['String']['input']>>
  warehouses?: InputMaybe<Array<Scalars['Int']['input']>>
  zip?: InputMaybe<Scalars['String']['input']>
}

export type AddWarehouseBatchInput = {
  comment?: InputMaybe<Scalars['String']['input']>
  externalid?: InputMaybe<Scalars['String']['input']>
  reasoncode?: InputMaybe<Scalars['String']['input']>
  shipmentid?: InputMaybe<Scalars['Int']['input']>
  transactiontype: WarehouseBatchTransaction
  warehousebatchstate?: InputMaybe<WarehouseBatchState>
  warehousetransactionid?: InputMaybe<Scalars['Int']['input']>
}

export enum ArchivedFilterTypeV2 {
  NUMBER_10 = 'NUMBER_10',
  NUMBER_20 = 'NUMBER_20',
  NUMBER_30 = 'NUMBER_30',
}

export type Authenticate = {
  accountId: Scalars['Float']['input']
  email: Scalars['String']['input']
  mfa?: InputMaybe<Scalars['String']['input']>
  password: Scalars['String']['input']
}

export type Campaign = {
  __typename?: 'Campaign'
  /** @deprecated  */
  active?: Maybe<Scalars['Boolean']['output']>
  activepos?: Maybe<Scalars['Boolean']['output']>
  datecreated?: Maybe<Scalars['UnixDate']['output']>
  dateend?: Maybe<Scalars['UnixDate']['output']>
  datestart?: Maybe<Scalars['UnixDate']['output']>
  discountoptions?: Maybe<Array<CampaignDiscountOption>>
  id: Scalars['Int']['output']
  maxuses?: Maybe<Scalars['Int']['output']>
  money_m?: Maybe<Scalars['Money']['output']>
  money_n?: Maybe<Scalars['Money']['output']>
  pricelisttags?: Maybe<Array<Scalars['String']['output']>>
  priority?: Maybe<Scalars['Int']['output']>
  productattributes?: Maybe<CampaignProductAttributes>
  productoptions?: Maybe<Array<CampaignProductOption>>
  products?: Maybe<Array<Scalars['String']['output']>>
  tags?: Maybe<Array<Scalars['String']['output']>>
  use_discounted_price?: Maybe<Scalars['Boolean']['output']>
  value_x?: Maybe<Scalars['Float']['output']>
  value_y?: Maybe<Scalars['Float']['output']>
  value_z?: Maybe<Scalars['Float']['output']>
  vouchercode?: Maybe<Scalars['String']['output']>
  vouchercode1?: Maybe<Scalars['String']['output']>
  vouchercode2?: Maybe<Scalars['String']['output']>
  vouchercomment?: Maybe<Scalars['String']['output']>
  vouchergrouptype?: Maybe<CampaignGroupType>
  vouchername: Scalars['String']['output']
  vouchername1?: Maybe<Scalars['String']['output']>
  vouchername2?: Maybe<Scalars['String']['output']>
  voucherpassword?: Maybe<Scalars['String']['output']>
  voucherstate?: Maybe<CampaignState>
  /** @deprecated  */
  voucherterms?: Maybe<Scalars['String']['output']>
  vouchertype: CampaignType
}

export type CampaignDiscountOption = {
  __typename?: 'CampaignDiscountOption'
  decimaldiscount?: Maybe<Scalars['String']['output']>
  moneydiscount?: Maybe<Scalars['String']['output']>
  moneydiscounttotal?: Maybe<Scalars['String']['output']>
  moneyprice?: Maybe<Scalars['String']['output']>
  num?: Maybe<Scalars['Float']['output']>
  productattributes?: Maybe<CampaignProductAttributes>
  products?: Maybe<Array<Scalars['String']['output']>>
}

export type CampaignDiscountOptionInput = {
  decimaldiscount?: InputMaybe<Scalars['String']['input']>
  moneydiscount?: InputMaybe<Scalars['Money']['input']>
  moneydiscounttotal?: InputMaybe<Scalars['Money']['input']>
  moneyprice?: InputMaybe<Scalars['Money']['input']>
  num?: InputMaybe<Scalars['Float']['input']>
  productattributes?: InputMaybe<CampaignProductAttributesInput>
  products?: InputMaybe<Array<Scalars['String']['input']>>
}

export enum CampaignGroupType {
  Order = 'Order',
  Package = 'Package',
  PriceList = 'PriceList',
  Product = 'Product',
}

export type CampaignInput = {
  activepos?: InputMaybe<Scalars['Boolean']['input']>
  datecreated?: InputMaybe<Scalars['UnixDate']['input']>
  dateend?: InputMaybe<Scalars['UnixDate']['input']>
  datestart?: InputMaybe<Scalars['UnixDate']['input']>
  discountoptions?: InputMaybe<Array<CampaignDiscountOptionInput>>
  maxuses?: InputMaybe<Scalars['Float']['input']>
  money_m?: InputMaybe<Scalars['Money']['input']>
  money_n?: InputMaybe<Scalars['Money']['input']>
  pricelisttags?: InputMaybe<Array<Scalars['String']['input']>>
  priority?: InputMaybe<Scalars['Float']['input']>
  productattributes?: InputMaybe<CampaignProductAttributesInput>
  productoptions?: InputMaybe<Array<CampaignProductOptionInput>>
  products?: InputMaybe<Array<Scalars['String']['input']>>
  tags?: InputMaybe<Array<Scalars['String']['input']>>
  use_discounted_price?: InputMaybe<Scalars['Boolean']['input']>
  value_x?: InputMaybe<Scalars['Float']['input']>
  value_y?: InputMaybe<Scalars['Float']['input']>
  value_z?: InputMaybe<Scalars['Float']['input']>
  vouchercode?: InputMaybe<Scalars['String']['input']>
  vouchercode1?: InputMaybe<Scalars['String']['input']>
  vouchercode2?: InputMaybe<Scalars['String']['input']>
  vouchercomment?: InputMaybe<Scalars['String']['input']>
  vouchergrouptype?: InputMaybe<CampaignGroupType>
  vouchername: Scalars['String']['input']
  vouchername1?: InputMaybe<Scalars['String']['input']>
  vouchername2?: InputMaybe<Scalars['String']['input']>
  voucherpassword?: InputMaybe<Scalars['String']['input']>
  voucherstate?: InputMaybe<CampaignState>
  vouchertype: CampaignType
}

export type CampaignProductAttributes = {
  __typename?: 'CampaignProductAttributes'
  exclude?: Maybe<Scalars['JSONObject']['output']>
  include?: Maybe<Scalars['JSONObject']['output']>
}

export type CampaignProductAttributesInput = {
  exclude?: InputMaybe<Scalars['JSONObject']['input']>
  include?: InputMaybe<Scalars['JSONObject']['input']>
}

export type CampaignProductOption = {
  __typename?: 'CampaignProductOption'
  num?: Maybe<Scalars['Int']['output']>
  products?: Maybe<Array<Scalars['String']['output']>>
  sitooProducts?: Maybe<Array<Product>>
}

export type CampaignProductOptionInput = {
  num?: InputMaybe<Scalars['Float']['input']>
  products?: InputMaybe<Array<Scalars['String']['input']>>
}

export enum CampaignState {
  Active = 'Active',
  Inactive = 'Inactive',
  Planned = 'Planned',
}

export enum CampaignType {
  OrderBuyForMGetDiscountN = 'OrderBuyForMGetDiscountN',
  OrderDiscountM = 'OrderDiscountM',
  OrderDiscountX = 'OrderDiscountX',
  PackageBuyForPriceM = 'PackageBuyForPriceM',
  PackageDiscountM = 'PackageDiscountM',
  PackageDiscountX = 'PackageDiscountX',
  PricelistActivate = 'PricelistActivate',
  PricelistActivateFinal = 'PricelistActivateFinal',
  ProductBuy3For2Or2For1AndAHalf = 'ProductBuy3For2Or2For1AndAHalf',
  ProductBuyForMGetDiscountList = 'ProductBuyForMGetDiscountList',
  ProductBuyMinXDiscountY = 'ProductBuyMinXDiscountY',
  ProductBuyXForPriceM = 'ProductBuyXForPriceM',
  ProductBuyXGetDiscountList = 'ProductBuyXGetDiscountList',
  ProductBuyXPayForY = 'ProductBuyXPayForY',
  ProductBuyXPayForYDiscountZRest = 'ProductBuyXPayForYDiscountZRest',
  ProductDiscountM = 'ProductDiscountM',
  ProductDiscountX = 'ProductDiscountX',
}

export type CashRegister = {
  __typename?: 'CashRegister'
  additionaldata?: Maybe<Scalars['JSONObject']['output']>
  address?: Maybe<Scalars['String']['output']>
  address2?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  clientdescription?: Maybe<Scalars['String']['output']>
  company?: Maybe<Scalars['String']['output']>
  companyid?: Maybe<Scalars['String']['output']>
  countryid?: Maybe<Scalars['String']['output']>
  ctu?: Maybe<Scalars['String']['output']>
  currencycode?: Maybe<Scalars['String']['output']>
  datelastping?: Maybe<Scalars['UnixDate']['output']>
  loglevel?: Maybe<CashRegisterLogLevelEnum>
  manufacturerid?: Maybe<Scalars['String']['output']>
  receiptlanguagetag?: Maybe<Scalars['String']['output']>
  registerid: Scalars['String']['output']
  registerkey?: Maybe<Scalars['String']['output']>
  registernumber?: Maybe<Scalars['Int']['output']>
  salestaxgroupid?: Maybe<Scalars['Int']['output']>
  state?: Maybe<Scalars['String']['output']>
  storesettingid?: Maybe<Scalars['Int']['output']>
  warehouse?: Maybe<Warehouse>
  warehouseid?: Maybe<Scalars['Int']['output']>
  zip?: Maybe<Scalars['String']['output']>
}

export enum CashRegisterLogLevelEnum {
  Critical = 'Critical',
  Debug = 'Debug',
  Error = 'Error',
  Info = 'Info',
  Warning = 'Warning',
}

export type Category = {
  __typename?: 'Category'
  categoryparentid?: Maybe<Scalars['Int']['output']>
  custom1?: Maybe<Scalars['String']['output']>
  custom2?: Maybe<Scalars['String']['output']>
  datecreated?: Maybe<Scalars['UnixDate']['output']>
  datemodified?: Maybe<Scalars['UnixDate']['output']>
  friendly?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  seo_description?: Maybe<Scalars['String']['output']>
  seo_keywords?: Maybe<Scalars['String']['output']>
  seo_title?: Maybe<Scalars['String']['output']>
  sortorder?: Maybe<Scalars['Int']['output']>
  title: Scalars['String']['output']
  visible?: Maybe<Scalars['Boolean']['output']>
}

export enum CheckoutMethod {
  NUMBER_0 = 'NUMBER_0',
  NUMBER_100 = 'NUMBER_100',
  NUMBER_101 = 'NUMBER_101',
  NUMBER_200 = 'NUMBER_200',
  NUMBER_300 = 'NUMBER_300',
  NUMBER_301 = 'NUMBER_301',
  NUMBER_305 = 'NUMBER_305',
  NUMBER_306 = 'NUMBER_306',
  NUMBER_400 = 'NUMBER_400',
  NUMBER_401 = 'NUMBER_401',
  NUMBER_500 = 'NUMBER_500',
  NUMBER_600 = 'NUMBER_600',
  NUMBER_601 = 'NUMBER_601',
  NUMBER_700 = 'NUMBER_700',
}

export type ComputedPurchasePriceValue = {
  __typename?: 'ComputedPurchasePriceValue'
  price?: Maybe<Scalars['Float']['output']>
  price_list?: Maybe<PurchasePriceList>
  price_list_id?: Maybe<Scalars['String']['output']>
}

export type ConfigVars = {
  __typename?: 'ConfigVars'
  /** @deprecated Adyen Classic SDK payment method for POS (Only used by iOS app. Replaced with ADYENAPI) */
  adyen: Scalars['Boolean']['output']
  adyenTapToPay: Scalars['Boolean']['output']
  adyenTerminalApi: Scalars['Boolean']['output']
  apps: Scalars['Boolean']['output']
  campaignPriceListDiscount: Scalars['Boolean']['output']
  campaigns: Scalars['Boolean']['output']
  cashRegisters: Scalars['Boolean']['output']
  coiney: Scalars['Boolean']['output']
  ctuNorway: Scalars['Boolean']['output']
  customGiftCard: Scalars['Boolean']['output']
  customPayment: Scalars['Boolean']['output']
  deliveryIn: Scalars['Boolean']['output']
  deliveryOut: Scalars['Boolean']['output']
  directFeedback: Scalars['Boolean']['output']
  foreignCurrency: Scalars['Boolean']['output']
  franchise: Scalars['Boolean']['output']
  giftCard: Scalars['Boolean']['output']
  iZettle: Scalars['Boolean']['output']
  inStoreReplenishment: Scalars['Boolean']['output']
  influencerCodes: Scalars['Boolean']['output']
  intercom: Scalars['Boolean']['output']
  invoice: Scalars['Boolean']['output']
  journals: Scalars['Boolean']['output']
  /** @deprecated Webshop and POS payment method (replaced by KLARNAV3) */
  klarna: Scalars['Boolean']['output']
  klarnav3: Scalars['Boolean']['output']
  limitUserManagement: Scalars['Boolean']['output']
  /** @deprecated MobilePay payment method for POS (replaced by VIPPSMOBILEPAY) */
  mobilePay: Scalars['Boolean']['output']
  orderLookup: Scalars['Boolean']['output']
  orders: Scalars['Boolean']['output']
  paymentSettings: Scalars['Boolean']['output']
  posSettings: Scalars['Boolean']['output']
  posUsingAuthV2: Scalars['Boolean']['output']
  prices: Scalars['Boolean']['output']
  products: Scalars['Boolean']['output']
  purchasePrices: Scalars['Boolean']['output']
  reports: Scalars['Boolean']['output']
  rewards: Scalars['Boolean']['output']
  salesTax: Scalars['Boolean']['output']
  serviceOrders: Scalars['Boolean']['output']
  shipments: Scalars['Boolean']['output']
  shipmentsv3: Scalars['Boolean']['output']
  sitooPayments: Scalars['Boolean']['output']
  statistics: Scalars['Boolean']['output']
  stock: Scalars['Boolean']['output']
  stockValueWarning: Scalars['Boolean']['output']
  stocktaking: Scalars['Boolean']['output']
  stocktakingShowCustom: Scalars['Boolean']['output']
  storeDashboard: Scalars['Boolean']['output']
  swish: Scalars['Boolean']['output']
  users: Scalars['Boolean']['output']
  /** @deprecated Vipps payment method for POS (replaced by VIPPSMOBILEPAY) */
  vipps: Scalars['Boolean']['output']
  vippsMobilePay: Scalars['Boolean']['output']
}

export type ConvertInfluencerCodesResponse = {
  __typename?: 'ConvertInfluencerCodesResponse'
  errors: Array<Error>
  items: Array<ImportInfluencerCode>
}

export type ConvertPricesResponse = {
  __typename?: 'ConvertPricesResponse'
  errors: Array<Error>
  items: Array<ImportPriceItem>
}

export type ConvertPurchasePricesResponse = {
  __typename?: 'ConvertPurchasePricesResponse'
  errors: Array<Error>
  items: Array<ImportPurchasePriceItem>
}

export type ConvertShipmentPackageItemsResponse = {
  __typename?: 'ConvertShipmentPackageItemsResponse'
  errors: Array<Error>
  items: Array<ImportShipmentPackageItem>
}

export type ConvertStoresResponse = {
  __typename?: 'ConvertStoresResponse'
  errors: Array<Error>
  items: Array<ImportStore>
}

export type ConvertUsersResponse = {
  __typename?: 'ConvertUsersResponse'
  errors: Array<Error>
  items: Array<ImportUser>
}

export type ConvertWarehouseBatchItemsResponse = {
  __typename?: 'ConvertWarehouseBatchItemsResponse'
  errors: Array<Error>
  items: Array<ImportWarehouseBatchItem>
}

export type ConvertWarehousesResponse = {
  __typename?: 'ConvertWarehousesResponse'
  errors: Array<Error>
  items: Array<ImportWarehouse>
}

export type CurrencyRate = {
  __typename?: 'CurrencyRate'
  code: Scalars['String']['output']
  rate: Scalars['String']['output']
}

export type CurrencyRateInput = {
  code: Scalars['String']['input']
  rate: Scalars['String']['input']
}

export type CustomAttribute = {
  __typename?: 'CustomAttribute'
  enums?: Maybe<Array<Scalars['String']['output']>>
  id: Scalars['String']['output']
  searchable?: Maybe<Scalars['Boolean']['output']>
  title: Scalars['String']['output']
  type: CustomAttributeTypeEnum
}

export type CustomAttributeMapping = {
  __typename?: 'CustomAttributeMapping'
  additionaldata_name?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  show_title: Scalars['Boolean']['output']
}

export type CustomAttributeMappingInput = {
  additionaldata_name?: InputMaybe<Scalars['String']['input']>
  id: Scalars['String']['input']
  show_title: Scalars['Boolean']['input']
}

export enum CustomAttributeTypeEnum {
  Integer = 'Integer',
  String = 'String',
}

export type Dashboard = {
  __typename?: 'Dashboard'
  animate?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  theme?: Maybe<DashboardThemeEnum>
  widgets: Array<Widget>
}

export enum DashboardColor {
  Blue = 'Blue',
  Green = 'Green',
  Orange = 'Orange',
  Purple = 'Purple',
  Red = 'Red',
  Yellow = 'Yellow',
}

export type DashboardConfig = {
  __typename?: 'DashboardConfig'
  currency_append: Scalars['String']['output']
  currency_prepend: Scalars['String']['output']
  format_date: Scalars['String']['output']
  format_date_time: Scalars['String']['output']
  format_date_time_full: Scalars['String']['output']
  format_time: Scalars['String']['output']
  num_decimals: Scalars['Int']['output']
  separator_comma: Scalars['String']['output']
  separator_thousands: Scalars['String']['output']
}

export type DashboardData = {
  __typename?: 'DashboardData'
  dashboard: Scalars['JSONObject']['output']
  templateUrl: Scalars['String']['output']
}

export enum DashboardThemeEnum {
  Dark = 'Dark',
  Light = 'Light',
}

export enum DashboardWidgetsInnerLineTypeEnum {
  Curved = 'Curved',
  Linear = 'Linear',
}

export enum DashboardWidgetsInnerTypeEnum {
  Compare = 'Compare',
  Donut = 'Donut',
  DonutChart = 'DonutChart',
  LineChart = 'LineChart',
  SingleValue = 'SingleValue',
  Time = 'Time',
  ValueAndTrend = 'ValueAndTrend',
}

export enum DateRange {
  Today = 'Today',
  Yesterday = 'Yesterday',
}

export type DeleteCampaignInput = {
  voucherid: Scalars['Int']['input']
}

export type DeleteCategoriesInput = {
  ids: Array<Scalars['Int']['input']>
}

export type DeleteCategoryResponse = {
  __typename?: 'DeleteCategoryResponse'
  categoryId: Scalars['Float']['output']
  error?: Maybe<Error>
  success: Scalars['Boolean']['output']
}

export type DeletePricelistItemInput = {
  productid: Scalars['Int']['input']
  sku: Scalars['String']['input']
}

export type DeleteProductsInput = {
  productids: Array<Scalars['Int']['input']>
}

export type Error = {
  __typename?: 'Error'
  code: Scalars['String']['output']
  property?: Maybe<Scalars['String']['output']>
  sizeLimit?: Maybe<Scalars['Float']['output']>
}

export enum FileType {
  CSV = 'CSV',
  JSON = 'JSON',
  XLSX = 'XLSX',
}

export type FilterValue = {
  __typename?: 'FilterValue'
  id: Scalars['String']['output']
  key: Scalars['String']['output']
  name: Scalars['String']['output']
  value: Scalars['String']['output']
}

export enum FormatLabel {
  Day = 'Day',
  Hour = 'Hour',
  Label = 'Label',
  Month = 'Month',
  Year = 'Year',
}

export enum FormatValue {
  Date = 'Date',
  HourMinute = 'HourMinute',
  Integer = 'Integer',
  Money = 'Money',
  Number = 'Number',
  Percent = 'Percent',
}

export enum GetInfluencerCodesSort {
  code_asc = 'code_asc',
  code_desc = 'code_desc',
  dateend_asc = 'dateend_asc',
  dateend_desc = 'dateend_desc',
  datestart_asc = 'datestart_asc',
  datestart_desc = 'datestart_desc',
  name_asc = 'name_asc',
  name_desc = 'name_desc',
  vouchername_asc = 'vouchername_asc',
  vouchername_desc = 'vouchername_desc',
  voucherpassword_asc = 'voucherpassword_asc',
  voucherpassword_desc = 'voucherpassword_desc',
}

export enum GetInventoryBatchesSort {
  Binlocation = 'Binlocation',
  Binlocation2 = 'Binlocation2',
  Comment = 'Comment',
  Comment2 = 'Comment2',
  Custom1 = 'Custom1',
  Custom2 = 'Custom2',
  Custom12 = 'Custom12',
  Custom22 = 'Custom22',
  Datelastmodified = 'Datelastmodified',
  Datelastmodified2 = 'Datelastmodified2',
  Datelaststocktaking = 'Datelaststocktaking',
  Datelaststocktaking2 = 'Datelaststocktaking2',
  Datemodified = 'Datemodified',
  Datemodified2 = 'Datemodified2',
  Decimalavailable = 'Decimalavailable',
  Decimalavailable2 = 'Decimalavailable2',
  Decimalcounteddiff = 'Decimalcounteddiff',
  Decimalcounteddiff2 = 'Decimalcounteddiff2',
  Decimalquantity = 'Decimalquantity',
  Decimalquantity2 = 'Decimalquantity2',
  Decimalreserved = 'Decimalreserved',
  Decimalreserved2 = 'Decimalreserved2',
  Decimalthreshold = 'Decimalthreshold',
  Decimalthreshold2 = 'Decimalthreshold2',
  Decimalthresholddiff = 'Decimalthresholddiff',
  Decimalthresholddiff2 = 'Decimalthresholddiff2',
  Decimaltotal = 'Decimaltotal',
  Decimaltotal2 = 'Decimaltotal2',
  Emailowner = 'Emailowner',
  Emailowner2 = 'Emailowner2',
  Moneytotal = 'Moneytotal',
  Moneytotal2 = 'Moneytotal2',
  Productid = 'Productid',
  Productid2 = 'Productid2',
  Sku = 'Sku',
  Sku2 = 'Sku2',
  Title = 'Title',
  Title2 = 'Title2',
  Vatid = 'Vatid',
  Vatid2 = 'Vatid2',
  Warehousebatchitemid = 'Warehousebatchitemid',
  Warehousebatchitemid2 = 'Warehousebatchitemid2',
  Warehousebatchitemstate = 'Warehousebatchitemstate',
  Warehousebatchitemstate2 = 'Warehousebatchitemstate2',
  Warehouseitemid = 'Warehouseitemid',
  Warehouseitemid2 = 'Warehouseitemid2',
}

export enum GetInventoryProductsSort {
  Binlocation = 'Binlocation',
  Binlocation2 = 'Binlocation2',
  Custom1 = 'Custom1',
  Custom2 = 'Custom2',
  Custom12 = 'Custom12',
  Custom22 = 'Custom22',
  Datelastmodified = 'Datelastmodified',
  Datelastmodified2 = 'Datelastmodified2',
  Datelaststocktaking = 'Datelaststocktaking',
  Datelaststocktaking2 = 'Datelaststocktaking2',
  Decimalavailable = 'Decimalavailable',
  Decimalavailable2 = 'Decimalavailable2',
  Decimalreserved = 'Decimalreserved',
  Decimalreserved2 = 'Decimalreserved2',
  Decimalthreshold = 'Decimalthreshold',
  Decimalthreshold2 = 'Decimalthreshold2',
  Decimalthresholddiff = 'Decimalthresholddiff',
  Decimalthresholddiff2 = 'Decimalthresholddiff2',
  Decimaltotal = 'Decimaltotal',
  Decimaltotal2 = 'Decimaltotal2',
  Moneytotal = 'Moneytotal',
  Moneytotal2 = 'Moneytotal2',
  Productid = 'Productid',
  Productid2 = 'Productid2',
  Sku = 'Sku',
  Sku2 = 'Sku2',
  Title = 'Title',
  Title2 = 'Title2',
  Vatid = 'Vatid',
  Vatid2 = 'Vatid2',
  Warehouseitemid = 'Warehouseitemid',
  Warehouseitemid2 = 'Warehouseitemid2',
}

export enum GetOrdersSort {
  orderid_asc = 'orderid_asc',
  orderid_desc = 'orderid_desc',
}

export enum GetProductsSort {
  productid_asc = 'productid_asc',
  productid_desc = 'productid_desc',
  sku_asc = 'sku_asc',
  sku_desc = 'sku_desc',
  title_asc = 'title_asc',
  title_desc = 'title_desc',
}

export enum GetRegistersSort {
  DateLastPingAsc = 'DateLastPingAsc',
  DateLastPingDesc = 'DateLastPingDesc',
  RegisterIdAsc = 'RegisterIdAsc',
  RegisterIdDesc = 'RegisterIdDesc',
  RegisterKeyAsc = 'RegisterKeyAsc',
  RegisterKeyDesc = 'RegisterKeyDesc',
}

export enum GetReplenishmentItemsSort {
  binlocation_asc = 'binlocation_asc',
  binlocation_desc = 'binlocation_desc',
  sku_asc = 'sku_asc',
  sku_desc = 'sku_desc',
}

export enum GetReportListSort {
  ReportIdAsc = 'ReportIdAsc',
  ReportIdDesc = 'ReportIdDesc',
  ReportNameAsc = 'ReportNameAsc',
  ReportNameDesc = 'ReportNameDesc',
}

export enum GetServiceOrdersSort {
  CreatedAsc = 'CreatedAsc',
  CreatedDesc = 'CreatedDesc',
}

export enum GetStoresSort {
  ExternalGroupIdAsc = 'ExternalGroupIdAsc',
  ExternalGroupIdDesc = 'ExternalGroupIdDesc',
  ExternalIdAsc = 'ExternalIdAsc',
  ExternalIdDesc = 'ExternalIdDesc',
  NameAsc = 'NameAsc',
  NameDesc = 'NameDesc',
}

export enum GetUsersSort {
  EmailAsc = 'EmailAsc',
  EmailDesc = 'EmailDesc',
  NameFirstAsc = 'NameFirstAsc',
  NameFirstDesc = 'NameFirstDesc',
  NameLastAsc = 'NameLastAsc',
  NameLastDesc = 'NameLastDesc',
  UserIdAsc = 'UserIdAsc',
  UserIdDesc = 'UserIdDesc',
}

export enum GetWarehousesSort {
  AddressAsc = 'AddressAsc',
  AddressDesc = 'AddressDesc',
  CityAsc = 'CityAsc',
  CityDesc = 'CityDesc',
  CountryIdAsc = 'CountryIdAsc',
  CountryIdDesc = 'CountryIdDesc',
  CurrencyCodeAsc = 'CurrencyCodeAsc',
  CurrencyCodeDesc = 'CurrencyCodeDesc',
  NameAsc = 'NameAsc',
  NameDesc = 'NameDesc',
  StateAsc = 'StateAsc',
  StateDesc = 'StateDesc',
  WarehouseTypeAsc = 'WarehouseTypeAsc',
  WarehouseTypeDesc = 'WarehouseTypeDesc',
  ZipAsc = 'ZipAsc',
  ZipDesc = 'ZipDesc',
}

export enum GiftCardType {
  Creditnote = 'Creditnote',
  Giftcard = 'Giftcard',
}

export type ImportInfluencerCode = {
  __typename?: 'ImportInfluencerCode'
  active?: Maybe<Scalars['Boolean']['output']>
  code: Scalars['String']['output']
  dateend?: Maybe<Scalars['UnixDate']['output']>
  datelastused?: Maybe<Scalars['Int']['output']>
  datestart?: Maybe<Scalars['UnixDate']['output']>
  maxuses?: Maybe<Scalars['Int']['output']>
  moneyinfluencerdiscount?: Maybe<Scalars['String']['output']>
  moneyinfluencertotal?: Maybe<Scalars['String']['output']>
  moneyordertotal?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  numused?: Maybe<Scalars['Int']['output']>
  vouchercode?: Maybe<Scalars['String']['output']>
  vouchercomment?: Maybe<Scalars['String']['output']>
  vouchername?: Maybe<Scalars['String']['output']>
  voucherpassword: Scalars['String']['output']
}

export type ImportInfluencerCodeInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  code: Scalars['String']['input']
  dateend?: InputMaybe<Scalars['UnixDate']['input']>
  datelastused?: InputMaybe<Scalars['Int']['input']>
  datestart?: InputMaybe<Scalars['UnixDate']['input']>
  maxuses?: InputMaybe<Scalars['Int']['input']>
  moneyinfluencerdiscount?: InputMaybe<Scalars['String']['input']>
  moneyinfluencertotal?: InputMaybe<Scalars['String']['input']>
  moneyordertotal?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  numused?: InputMaybe<Scalars['Int']['input']>
  vouchercode?: InputMaybe<Scalars['String']['input']>
  vouchercomment?: InputMaybe<Scalars['String']['input']>
  vouchername?: InputMaybe<Scalars['String']['input']>
  voucherpassword: Scalars['String']['input']
}

export type ImportPriceItem = {
  __typename?: 'ImportPriceItem'
  moneyprice?: Maybe<Scalars['String']['output']>
  pricelistid?: Maybe<Scalars['String']['output']>
  productid?: Maybe<Scalars['String']['output']>
  sku?: Maybe<Scalars['String']['output']>
}

export type ImportPriceItemInput = {
  moneyprice?: InputMaybe<Scalars['String']['input']>
  pricelistid?: InputMaybe<Scalars['String']['input']>
  productid?: InputMaybe<Scalars['String']['input']>
  sku?: InputMaybe<Scalars['String']['input']>
}

export type ImportPurchasePriceItem = {
  __typename?: 'ImportPurchasePriceItem'
  price_list_id?: Maybe<Scalars['String']['output']>
  purchase_price?: Maybe<Scalars['Float']['output']>
  sku?: Maybe<Scalars['String']['output']>
}

export type ImportPurchasePriceItemInput = {
  price_list_id?: InputMaybe<Scalars['String']['input']>
  purchase_price?: InputMaybe<Scalars['Float']['input']>
  sku?: InputMaybe<Scalars['String']['input']>
}

export type ImportResponse = {
  __typename?: 'ImportResponse'
  deleted: Scalars['Int']['output']
  failed: Scalars['Int']['output']
  imported: Scalars['Int']['output']
}

export type ImportShipmentPackageItem = {
  __typename?: 'ImportShipmentPackageItem'
  barcode?: Maybe<Scalars['String']['output']>
  batch?: Maybe<Scalars['String']['output']>
  batch_expires?: Maybe<Scalars['UnixDate']['output']>
  product?: Maybe<Product>
  product_name?: Maybe<Scalars['String']['output']>
  quantity?: Maybe<Scalars['Int']['output']>
  sku?: Maybe<Scalars['String']['output']>
  unit_price?: Maybe<Scalars['Int']['output']>
}

export type ImportStore = {
  __typename?: 'ImportStore'
  address?: Maybe<Scalars['String']['output']>
  address2?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  countryid?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  eshopid?: Maybe<Scalars['Int']['output']>
  externalgroupid?: Maybe<Scalars['String']['output']>
  externalid?: Maybe<Scalars['String']['output']>
  latitude?: Maybe<Scalars['Coordinate']['output']>
  longitude?: Maybe<Scalars['Coordinate']['output']>
  name: Scalars['String']['output']
  nearby_stores?: Maybe<Array<Scalars['Int']['output']>>
  phone?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  storeid: Scalars['Int']['output']
  storetype: Scalars['Int']['output']
  tags?: Maybe<Array<Scalars['String']['output']>>
  usetype_manualin?: Maybe<Scalars['Int']['output']>
  usetype_manualout?: Maybe<Scalars['Int']['output']>
  usetype_movein?: Maybe<Scalars['Int']['output']>
  usetype_moveout?: Maybe<Scalars['Int']['output']>
  usetype_shipmentin?: Maybe<Scalars['Int']['output']>
  usetype_shipmentout?: Maybe<Scalars['Int']['output']>
  zip?: Maybe<Scalars['String']['output']>
}

export type ImportStoreItemInput = {
  address?: InputMaybe<Scalars['String']['input']>
  address2?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  countryid?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  eshopid?: InputMaybe<Scalars['Int']['input']>
  externalgroupid?: InputMaybe<Scalars['String']['input']>
  externalid?: InputMaybe<Scalars['String']['input']>
  latitude?: InputMaybe<Scalars['Coordinate']['input']>
  longitude?: InputMaybe<Scalars['Coordinate']['input']>
  name: Scalars['String']['input']
  nearby_stores?: InputMaybe<Array<Scalars['Int']['input']>>
  phone?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  storeid: Scalars['Int']['input']
  storetype: Scalars['Int']['input']
  tags?: InputMaybe<Array<Scalars['String']['input']>>
  usetype_manualin?: InputMaybe<Scalars['Int']['input']>
  usetype_manualout?: InputMaybe<Scalars['Int']['input']>
  usetype_movein?: InputMaybe<Scalars['Int']['input']>
  usetype_moveout?: InputMaybe<Scalars['Int']['input']>
  usetype_shipmentin?: InputMaybe<Scalars['Int']['input']>
  usetype_shipmentout?: InputMaybe<Scalars['Int']['input']>
  zip?: InputMaybe<Scalars['String']['input']>
}

export type ImportUser = {
  __typename?: 'ImportUser'
  active?: Maybe<Scalars['Boolean']['output']>
  address?: Maybe<Scalars['String']['output']>
  address2?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  company?: Maybe<Scalars['String']['output']>
  companyid?: Maybe<Scalars['String']['output']>
  countryid?: Maybe<Scalars['String']['output']>
  customernumber?: Maybe<Scalars['String']['output']>
  department?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  externalid?: Maybe<Scalars['String']['output']>
  mobile?: Maybe<Scalars['String']['output']>
  namefirst?: Maybe<Scalars['String']['output']>
  namelast?: Maybe<Scalars['String']['output']>
  notes?: Maybe<Scalars['String']['output']>
  personalid?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  pin?: Maybe<Scalars['String']['output']>
  posallstores?: Maybe<Scalars['Boolean']['output']>
  posrefundcard?: Maybe<Scalars['Boolean']['output']>
  pricelistid?: Maybe<Scalars['Int']['output']>
  role?: Maybe<UserRole>
  state?: Maybe<Scalars['String']['output']>
  userid?: Maybe<Scalars['String']['output']>
  voucherpasswords?: Maybe<Array<Scalars['String']['output']>>
  warehouses?: Maybe<Array<Scalars['Int']['output']>>
  zip?: Maybe<Scalars['String']['output']>
}

export type ImportUserInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  address?: InputMaybe<Scalars['String']['input']>
  address2?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  company?: InputMaybe<Scalars['String']['input']>
  companyid?: InputMaybe<Scalars['String']['input']>
  countryid?: InputMaybe<Scalars['String']['input']>
  customernumber?: InputMaybe<Scalars['String']['input']>
  department?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  externalid?: InputMaybe<Scalars['String']['input']>
  mobile?: InputMaybe<Scalars['String']['input']>
  namefirst?: InputMaybe<Scalars['String']['input']>
  namelast?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  personalid?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  pin?: InputMaybe<Scalars['String']['input']>
  posallstores?: InputMaybe<Scalars['Boolean']['input']>
  posrefundcard?: InputMaybe<Scalars['Boolean']['input']>
  pricelistid?: InputMaybe<Scalars['Int']['input']>
  role?: InputMaybe<UserRole>
  state?: InputMaybe<Scalars['String']['input']>
  userid?: InputMaybe<Scalars['String']['input']>
  voucherpasswords?: InputMaybe<Array<Scalars['String']['input']>>
  warehouses?: InputMaybe<Array<Scalars['Int']['input']>>
  zip?: InputMaybe<Scalars['String']['input']>
}

export type ImportWarehouse = {
  __typename?: 'ImportWarehouse'
  address?: Maybe<Scalars['String']['output']>
  address2?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  countryid?: Maybe<Scalars['String']['output']>
  currencycode: Scalars['String']['output']
  externalid?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  sellable?: Maybe<Scalars['Boolean']['output']>
  state?: Maybe<Scalars['String']['output']>
  storeid?: Maybe<Scalars['Int']['output']>
  usetype?: Maybe<Scalars['Int']['output']>
  warehouseid?: Maybe<Scalars['Int']['output']>
  warehousetype?: Maybe<Scalars['Int']['output']>
  zip?: Maybe<Scalars['String']['output']>
}

export type ImportWarehouseBatchItem = {
  __typename?: 'ImportWarehouseBatchItem'
  decimalquantity?: Maybe<Scalars['Float']['output']>
  note?: Maybe<Scalars['String']['output']>
  purchaseprice?: Maybe<Scalars['String']['output']>
  sku?: Maybe<Scalars['String']['output']>
}

export type ImportWarehouseBatchItemInput = {
  decimalquantity?: InputMaybe<Scalars['Float']['input']>
  note?: InputMaybe<Scalars['String']['input']>
  purchaseprice?: InputMaybe<Scalars['String']['input']>
  sku?: InputMaybe<Scalars['String']['input']>
}

export type ImportWarehouseInput = {
  address?: InputMaybe<Scalars['String']['input']>
  address2?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  countryid?: InputMaybe<Scalars['String']['input']>
  currencycode: Scalars['String']['input']
  externalid?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  sellable?: InputMaybe<Scalars['Boolean']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  storeid?: InputMaybe<Scalars['Int']['input']>
  usetype?: InputMaybe<Scalars['Int']['input']>
  warehouseid?: InputMaybe<Scalars['Int']['input']>
  warehousetype?: InputMaybe<Scalars['Int']['input']>
  zip?: InputMaybe<Scalars['String']['input']>
}

export type InfluencerCode = {
  __typename?: 'InfluencerCode'
  active?: Maybe<Scalars['Boolean']['output']>
  campaigns?: Maybe<Array<Campaign>>
  code: Scalars['String']['output']
  dateend?: Maybe<Scalars['UnixDate']['output']>
  datelastused?: Maybe<Scalars['UnixDate']['output']>
  datestart?: Maybe<Scalars['UnixDate']['output']>
  maxuses?: Maybe<Scalars['Int']['output']>
  moneyinfluencerdiscount?: Maybe<Scalars['Money']['output']>
  moneyinfluencertotal?: Maybe<Scalars['Money']['output']>
  moneyordertotal?: Maybe<Scalars['Money']['output']>
  name: Scalars['String']['output']
  numused?: Maybe<Scalars['Int']['output']>
  vouchercode?: Maybe<Scalars['String']['output']>
  vouchercomment?: Maybe<Scalars['String']['output']>
  vouchername?: Maybe<Scalars['String']['output']>
  voucherpassword: Scalars['String']['output']
}

export type InfluencerCodeInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  code: Scalars['String']['input']
  dateend?: InputMaybe<Scalars['UnixDate']['input']>
  datelastused?: InputMaybe<Scalars['UnixDate']['input']>
  datestart?: InputMaybe<Scalars['UnixDate']['input']>
  maxuses?: InputMaybe<Scalars['Int']['input']>
  moneyinfluencerdiscount?: InputMaybe<Scalars['Money']['input']>
  moneyinfluencertotal?: InputMaybe<Scalars['Money']['input']>
  moneyordertotal?: InputMaybe<Scalars['Money']['input']>
  name: Scalars['String']['input']
  numused?: InputMaybe<Scalars['Int']['input']>
  vouchercode?: InputMaybe<Scalars['String']['input']>
  vouchercomment?: InputMaybe<Scalars['String']['input']>
  vouchername?: InputMaybe<Scalars['String']['input']>
  voucherpassword: Scalars['String']['input']
}

export type InventoryItem = {
  __typename?: 'InventoryItem'
  activestate?: Maybe<InventoryItemState>
  barcode?: Maybe<Scalars['String']['output']>
  binlocation?: Maybe<Scalars['String']['output']>
  custom1?: Maybe<Scalars['String']['output']>
  custom2?: Maybe<Scalars['String']['output']>
  datelastmodified?: Maybe<Scalars['UnixDate']['output']>
  datelaststocktaking?: Maybe<Scalars['UnixDate']['output']>
  decimalavailable: Scalars['Money']['output']
  decimalreserved: Scalars['Money']['output']
  decimalthreshold: Scalars['Money']['output']
  decimaltotal: Scalars['Money']['output']
  defaultcategoryid?: Maybe<Scalars['Int']['output']>
  defaultcategoryname?: Maybe<Scalars['String']['output']>
  defaultcategorypath?: Maybe<Array<Scalars['String']['output']>>
  eshopid: Scalars['Int']['output']
  images?: Maybe<Array<Scalars['String']['output']>>
  manufacturerid?: Maybe<Scalars['Int']['output']>
  moneytotal: Scalars['Money']['output']
  productid?: Maybe<Scalars['Int']['output']>
  sku: Scalars['String']['output']
  skumanufacturer?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  variant?: Maybe<Array<ProductVariantItem>>
  vatid?: Maybe<Scalars['Int']['output']>
  warehouseid: Scalars['Int']['output']
  warehouseitemid?: Maybe<Scalars['Int']['output']>
}

export type InventoryItemBatch = {
  __typename?: 'InventoryItemBatch'
  activestate?: Maybe<InventoryItemState>
  barcode?: Maybe<Scalars['String']['output']>
  binlocation?: Maybe<Scalars['String']['output']>
  comment?: Maybe<Scalars['String']['output']>
  custom1?: Maybe<Scalars['String']['output']>
  custom2?: Maybe<Scalars['String']['output']>
  datelastmodified?: Maybe<Scalars['UnixDate']['output']>
  datelaststocktaking?: Maybe<Scalars['UnixDate']['output']>
  datemodified?: Maybe<Scalars['UnixDate']['output']>
  decimalavailable: Scalars['Money']['output']
  decimalcounteddiff?: Maybe<Scalars['Decimal']['output']>
  decimalnostockvalue?: Maybe<Scalars['Decimal']['output']>
  decimalquantity?: Maybe<Scalars['Decimal']['output']>
  decimalreserved: Scalars['Money']['output']
  decimalthreshold: Scalars['Money']['output']
  decimaltotal: Scalars['Money']['output']
  decimaltranschanges?: Maybe<Scalars['Decimal']['output']>
  defaultcategoryid?: Maybe<Scalars['Int']['output']>
  defaultcategoryname?: Maybe<Scalars['String']['output']>
  defaultcategorypath?: Maybe<Array<Scalars['String']['output']>>
  emailowner?: Maybe<Scalars['String']['output']>
  eshopid: Scalars['Int']['output']
  images?: Maybe<Array<Scalars['String']['output']>>
  manufacturer?: Maybe<Manufacturer>
  manufacturerid?: Maybe<Scalars['Int']['output']>
  moneypricein?: Maybe<Scalars['Money']['output']>
  moneytotal: Scalars['Money']['output']
  productid?: Maybe<Scalars['Int']['output']>
  sku: Scalars['String']['output']
  skumanufacturer?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  variant?: Maybe<Array<ProductVariantItem>>
  vatid?: Maybe<Scalars['Int']['output']>
  warehousebatchid: Scalars['Int']['output']
  warehousebatchitemid?: Maybe<Scalars['Int']['output']>
  warehousebatchitemstate?: Maybe<InventoryItemBatchItemState>
  warehouseid: Scalars['Int']['output']
  warehouseitemid?: Maybe<Scalars['Int']['output']>
}

export enum InventoryItemBatchItemState {
  CountedDiff = 'CountedDiff',
  CountedModified = 'CountedModified',
  CountedOk = 'CountedOk',
  NotCounted = 'NotCounted',
  QuantityAdded = 'QuantityAdded',
  QuantityNotAdded = 'QuantityNotAdded',
}

export enum InventoryItemBatchState {
  AllCounted = 'AllCounted',
  AllStatus = 'AllStatus',
  CountedDiff = 'CountedDiff',
  CountedModified = 'CountedModified',
  CountedOk = 'CountedOk',
  DiffAndModified = 'DiffAndModified',
  NotCounted = 'NotCounted',
  QuantityAdded = 'QuantityAdded',
  QuantityNotAdded = 'QuantityNotAdded',
}

export enum InventoryItemState {
  Active = 'Active',
  Inactive = 'Inactive',
  NoProduct = 'NoProduct',
}

export enum InventoryProductState {
  Active = 'Active',
  All = 'All',
  Inactive = 'Inactive',
  NoProduct = 'NoProduct',
}

export type Journal = {
  __typename?: 'Journal'
  data: JournalData
  dateadded?: Maybe<Scalars['UnixDate']['output']>
  datecreated: Scalars['UnixDate']['output']
  id: Scalars['Int']['output']
  ispractice: Scalars['Boolean']['output']
  registerid: Scalars['String']['output']
  registerkey: Scalars['String']['output']
  type: JournalEntryType
}

export type JournalCart = {
  __typename?: 'JournalCart'
  additionaldata?: Maybe<Scalars['JSONObject']['output']>
  id: Scalars['String']['output']
  moneydiscount: Scalars['Money']['output']
  moneytotal: Scalars['Money']['output']
  quantitytotal: Scalars['Int']['output']
  staff: Scalars['String']['output']
  staffuserid: Scalars['String']['output']
}

export type JournalCartItem = {
  __typename?: 'JournalCartItem'
  additionaldata?: Maybe<Scalars['JSONObject']['output']>
  cart_id: Scalars['String']['output']
  cart_moneydiscount: Scalars['Money']['output']
  cart_moneytotal: Scalars['Money']['output']
  cart_quantitytotal: Scalars['Int']['output']
  decimalunitquantity?: Maybe<Scalars['String']['output']>
  item_index: Scalars['Int']['output']
  moneydiscount?: Maybe<Scalars['Money']['output']>
  moneytotal?: Maybe<Scalars['Money']['output']>
  note?: Maybe<Scalars['String']['output']>
  quantity?: Maybe<Scalars['Int']['output']>
  sku: Scalars['String']['output']
  staff: Scalars['String']['output']
  staffuserid: Scalars['String']['output']
}

export type JournalCartPayment = {
  __typename?: 'JournalCartPayment'
  additionaldata?: Maybe<Scalars['JSONObject']['output']>
  cart_id: Scalars['String']['output']
  moneycaptured: Scalars['Money']['output']
  moneytotal: Scalars['Money']['output']
  payment_index: Scalars['Int']['output']
  paymentmethod: Scalars['Int']['output']
  paymentname: Scalars['String']['output']
  staff: Scalars['String']['output']
  staffuserid: Scalars['String']['output']
}

export type JournalCash = {
  __typename?: 'JournalCash'
  additionaldata?: Maybe<Scalars['JSONObject']['output']>
  comment?: Maybe<Scalars['String']['output']>
  currencycode: Scalars['String']['output']
  moneycash: Scalars['Money']['output']
  staff: Scalars['String']['output']
  staffuserid: Scalars['String']['output']
}

export type JournalCtuError = {
  __typename?: 'JournalCtuError'
  errortext: Scalars['String']['output']
}

export type JournalData =
  | JournalCart
  | JournalCartItem
  | JournalCartPayment
  | JournalCash
  | JournalCtuError
  | JournalPrintout
  | JournalReceipt
  | JournalReport
  | JournalStaff

export enum JournalEntryType {
  CartClear = 'CartClear',
  CartItemAdd = 'CartItemAdd',
  CartItemChange = 'CartItemChange',
  CartItemRemove = 'CartItemRemove',
  CartPaymentAdd = 'CartPaymentAdd',
  CartPaymentCancel = 'CartPaymentCancel',
  CartPaymentRemove = 'CartPaymentRemove',
  CartPaymentRetry = 'CartPaymentRetry',
  CartPaymentSuccess = 'CartPaymentSuccess',
  CartVoucherAdd = 'CartVoucherAdd',
  CartVoucherRemove = 'CartVoucherRemove',
  CashBank = 'CashBank',
  CashBankFinal = 'CashBankFinal',
  CashCounted = 'CashCounted',
  CashPetty = 'CashPetty',
  CashStart = 'CashStart',
  CtuError = 'CtuError',
  Login = 'Login',
  Logout = 'Logout',
  OpenCashDrawer = 'OpenCashDrawer',
  Printout = 'Printout',
  Receipt = 'Receipt',
  ReportX = 'ReportX',
  ReportZ = 'ReportZ',
}

export enum JournalFilterDateType {
  Added = 'Added',
  Created = 'Created',
}

export type JournalPrintout = {
  __typename?: 'JournalPrintout'
  additionaldata?: Maybe<Scalars['JSONObject']['output']>
  orderid?: Maybe<Scalars['Int']['output']>
  receiptid?: Maybe<Scalars['String']['output']>
  staff: Scalars['String']['output']
  staffuserid: Scalars['String']['output']
  type: JournalPrintoutTypeEnum
  zreportid?: Maybe<Scalars['Int']['output']>
}

export enum JournalPrintoutTypeEnum {
  Delivery = 'Delivery',
  GiftReceipt = 'GiftReceipt',
  Order = 'Order',
  ReceiptRefund = 'ReceiptRefund',
  ReceiptRefundElectronic = 'ReceiptRefundElectronic',
  ReceiptRefundStoreCopy = 'ReceiptRefundStoreCopy',
  ReceiptSale = 'ReceiptSale',
  ReceiptSaleElectronic = 'ReceiptSaleElectronic',
  ReceiptSaleStoreCopy = 'ReceiptSaleStoreCopy',
  ReportX = 'ReportX',
  ReportZ = 'ReportZ',
}

export type JournalReceipt = {
  __typename?: 'JournalReceipt'
  additionaldata?: Maybe<Scalars['JSONObject']['output']>
  comment?: Maybe<Scalars['String']['output']>
  creditorderid?: Maybe<Scalars['Int']['output']>
  currencycode: Scalars['String']['output']
  customer?: Maybe<JournalReceiptCustomer>
  isrefund: Scalars['Boolean']['output']
  items: Array<JournalReceiptItem>
  moneyroundoff: Scalars['Money']['output']
  moneysalestaxinadvance: Scalars['Money']['output']
  moneysubtotal?: Maybe<Scalars['Money']['output']>
  moneytotal: Scalars['Money']['output']
  payments: Array<JournalReceiptPayment>
  posname: Scalars['String']['output']
  posversion: Scalars['String']['output']
  printsection: Array<PrintLine>
  quantitytotal: Scalars['Int']['output']
  receiptnumber: Scalars['Int']['output']
  salestaxsummary: Array<JournalReceiptSalesTax>
  staff: Scalars['String']['output']
  staffuserid: Scalars['String']['output']
  vatsummary: Array<JournalReceiptVat>
}

export type JournalReceiptCustomer = {
  __typename?: 'JournalReceiptCustomer'
  company?: Maybe<Scalars['String']['output']>
  companyid?: Maybe<Scalars['String']['output']>
  customerref?: Maybe<Scalars['String']['output']>
  delivery_address?: Maybe<Scalars['String']['output']>
  delivery_address2?: Maybe<Scalars['String']['output']>
  delivery_city?: Maybe<Scalars['String']['output']>
  delivery_countryid?: Maybe<Scalars['String']['output']>
  delivery_state?: Maybe<Scalars['String']['output']>
  delivery_zip?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['String']['output']>
  invoice_address?: Maybe<Scalars['String']['output']>
  invoice_address2?: Maybe<Scalars['String']['output']>
  invoice_city?: Maybe<Scalars['String']['output']>
  invoice_countryid?: Maybe<Scalars['String']['output']>
  invoice_state?: Maybe<Scalars['String']['output']>
  invoice_zip?: Maybe<Scalars['String']['output']>
  mobile?: Maybe<Scalars['String']['output']>
  namefirst?: Maybe<Scalars['String']['output']>
  namelast?: Maybe<Scalars['String']['output']>
  personalid?: Maybe<Scalars['String']['output']>
}

export type JournalReceiptItem = {
  __typename?: 'JournalReceiptItem'
  additionaldata?: Maybe<Scalars['JSONObject']['output']>
  decimalunitquantity?: Maybe<Scalars['String']['output']>
  discountname?: Maybe<Scalars['String']['output']>
  discounts: Array<JournalReceiptItemDiscount>
  moneydiscount: Scalars['Money']['output']
  moneytotal: Scalars['Money']['output']
  moneyunitprice: Scalars['Money']['output']
  moneyvat: Scalars['Money']['output']
  note?: Maybe<Scalars['String']['output']>
  printrow: Array<PrintLine>
  printsection: Array<PrintLine>
  productgroupname: Scalars['String']['output']
  productgrouptype: Scalars['Int']['output']
  productname: Scalars['String']['output']
  quantity: Scalars['Int']['output']
  salestaxes: Array<JournalReceiptSalesTax>
  sku: Scalars['String']['output']
  unitlabel?: Maybe<Scalars['String']['output']>
  variant?: Maybe<Scalars['String']['output']>
  vatid: Scalars['Int']['output']
  vatvalue: Scalars['Float']['output']
}

export type JournalReceiptItemDiscount = {
  __typename?: 'JournalReceiptItemDiscount'
  additionaldata?: Maybe<Scalars['JSONObject']['output']>
  discountgroup?: Maybe<Scalars['String']['output']>
  discountname: Scalars['String']['output']
  id: Scalars['Int']['output']
  moneytotal: Scalars['Money']['output']
  moneyvat: Scalars['Money']['output']
}

export type JournalReceiptPayment = {
  __typename?: 'JournalReceiptPayment'
  additionaldata?: Maybe<Scalars['JSONObject']['output']>
  moneycaptured: Scalars['String']['output']
  moneyinadvance: Scalars['String']['output']
  moneytotal: Scalars['String']['output']
  paymentexternalid?: Maybe<Scalars['String']['output']>
  paymentmethod: Scalars['Int']['output']
  paymentname: Scalars['String']['output']
  printrow: Array<PrintLine>
  printsection: Array<PrintLine>
}

export type JournalReceiptSalesTax = {
  __typename?: 'JournalReceiptSalesTax'
  code?: Maybe<Scalars['String']['output']>
  decimalvalue: Scalars['String']['output']
  externalid?: Maybe<Scalars['String']['output']>
  groupregion?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  moneytax: Scalars['Money']['output']
  moneytotal: Scalars['Money']['output']
  name: Scalars['String']['output']
}

export type JournalReceiptVat = {
  __typename?: 'JournalReceiptVat'
  moneynet: Scalars['Money']['output']
  moneyvat: Scalars['Money']['output']
  vatvalue: Scalars['Float']['output']
}

export type JournalReport = {
  __typename?: 'JournalReport'
  address: Scalars['String']['output']
  address2: Scalars['String']['output']
  address3: Scalars['String']['output']
  cash_bankfinal_bag_id?: Maybe<Scalars['String']['output']>
  cashdiff_reasoncode?: Maybe<Scalars['String']['output']>
  cashdiff_reasoncomment?: Maybe<Scalars['String']['output']>
  comment?: Maybe<Scalars['String']['output']>
  company: Scalars['String']['output']
  companyid: Scalars['String']['output']
  currencycode: Scalars['String']['output']
  currencyconversions?: Maybe<Array<JournalReportCurrencyConversion>>
  dateopened: Scalars['UnixDate']['output']
  discountsrefund: Array<JournalReportDiscount>
  discountssales: Array<JournalReportDiscount>
  discountssalesnotdelivered: Array<JournalReportDiscount>
  eshopid: Scalars['Int']['output']
  manufacturerid: Scalars['String']['output']
  moneycash_bank: Scalars['Money']['output']
  moneycash_bankfinal: Scalars['Money']['output']
  moneycash_counted: Scalars['Money']['output']
  moneycash_diff: Scalars['Money']['output']
  moneycash_expected: Scalars['Money']['output']
  moneycash_in: Scalars['Money']['output']
  moneycash_out: Scalars['Money']['output']
  moneycash_petty: Scalars['Money']['output']
  moneycash_salesrefunds: Scalars['Money']['output']
  moneydiscount: Scalars['Money']['output']
  moneygrandtotalnet: Scalars['Money']['output']
  moneygrandtotalrefund: Scalars['Money']['output']
  moneygrandtotalsales: Scalars['Money']['output']
  moneypractice: Scalars['Money']['output']
  moneyrefundtotal: Scalars['Money']['output']
  moneyrefundtotalnet: Scalars['Money']['output']
  moneyroundoff: Scalars['Money']['output']
  moneysalesnotdeliveredtotal: Scalars['Money']['output']
  moneysalesnotdeliveredtotalnet: Scalars['Money']['output']
  moneysalestaxinadvance: Scalars['Money']['output']
  moneysalestotal: Scalars['Money']['output']
  moneysalestotalnet: Scalars['Money']['output']
  moneysummaryrefund: Scalars['Money']['output']
  moneysummaryroundoff: Scalars['Money']['output']
  moneysummarysales: Scalars['Money']['output']
  moneysummarysalestax: Scalars['Money']['output']
  moneysummarysubtotal: Scalars['Money']['output']
  moneysummarytotal: Scalars['Money']['output']
  numopendrawer: Scalars['Int']['output']
  numpractice: Scalars['Int']['output']
  numreceipts: Scalars['Int']['output']
  numrefund: Scalars['Int']['output']
  numrefunditems: Scalars['Int']['output']
  numsales: Scalars['Int']['output']
  numsalesitems: Scalars['Int']['output']
  numsalesnotdelivered: Scalars['Int']['output']
  numsalesnotdelivereditems: Scalars['Int']['output']
  numsalestypegiftcard: Scalars['Int']['output']
  numsalestypeproduct: Scalars['Int']['output']
  numsalestypeservice: Scalars['Int']['output']
  paymentsrefund: Array<JournalReportPayment>
  paymentssales: Array<JournalReportPayment>
  productgroupsrefund: Array<JournalReportProductGroup>
  productgroupssales: Array<JournalReportProductGroup>
  productgroupssalesnotdelivered: Array<JournalReportProductGroup>
  salestaxesrefund: Array<JournalReportSalesTax>
  salestaxessales: Array<JournalReportSalesTax>
  staff: Scalars['String']['output']
  staffuserid: Scalars['String']['output']
  store_id?: Maybe<Scalars['String']['output']>
  store_name?: Maybe<Scalars['String']['output']>
  vatgroupsrefund: Array<JournalReportVatGroup>
  vatgroupssales: Array<JournalReportVatGroup>
  vatgroupssalesnotdelivered: Array<JournalReportVatGroup>
  zreportid?: Maybe<Scalars['Int']['output']>
}

export type JournalReportCurrencyConversion = {
  __typename?: 'JournalReportCurrencyConversion'
  bag_id?: Maybe<Scalars['String']['output']>
  currency_code: Scalars['String']['output']
  money_counted: Scalars['Money']['output']
  money_counted_base: Scalars['Money']['output']
  money_expected: Scalars['Money']['output']
  money_expected_base: Scalars['Money']['output']
}

export type JournalReportDiscount = {
  __typename?: 'JournalReportDiscount'
  moneytotal: Scalars['Money']['output']
  moneytotalnet: Scalars['Money']['output']
  moneytotalvat: Scalars['Money']['output']
  vouchercode: Scalars['String']['output']
}

export type JournalReportPayment = {
  __typename?: 'JournalReportPayment'
  moneycaptured?: Maybe<Scalars['Money']['output']>
  moneyinadvance?: Maybe<Scalars['Money']['output']>
  moneyreserved?: Maybe<Scalars['Money']['output']>
  moneytotal?: Maybe<Scalars['Money']['output']>
  name: Scalars['String']['output']
  subpayments: Array<JournalReportSubPayment>
}

export type JournalReportProductGroup = {
  __typename?: 'JournalReportProductGroup'
  moneytotal: Scalars['Money']['output']
  moneytotalnet: Scalars['Money']['output']
  moneytotalvat: Scalars['Money']['output']
  name: Scalars['String']['output']
  numtotal: Scalars['Int']['output']
  vatvalue: Scalars['Float']['output']
}

export type JournalReportSalesTax = {
  __typename?: 'JournalReportSalesTax'
  code?: Maybe<Scalars['String']['output']>
  decimaltaxvalue: Scalars['String']['output']
  externalid?: Maybe<Scalars['String']['output']>
  groupregion?: Maybe<Scalars['String']['output']>
  moneytax?: Maybe<Scalars['Money']['output']>
  moneytotal?: Maybe<Scalars['Money']['output']>
  name: Scalars['String']['output']
}

export type JournalReportSubPayment = {
  __typename?: 'JournalReportSubPayment'
  moneycaptured?: Maybe<Scalars['Money']['output']>
  moneyinadvance?: Maybe<Scalars['Money']['output']>
  moneyreserved?: Maybe<Scalars['Money']['output']>
  moneysubtotal: Scalars['Money']['output']
  name: Scalars['String']['output']
}

export type JournalReportVatGroup = {
  __typename?: 'JournalReportVatGroup'
  moneytotal: Scalars['Money']['output']
  moneytotalnet: Scalars['Money']['output']
  moneytotalvat: Scalars['Money']['output']
  vatvalue: Scalars['Float']['output']
}

export type JournalStaff = {
  __typename?: 'JournalStaff'
  staff: Scalars['String']['output']
  staffuserid: Scalars['String']['output']
}

export type LogEvent = {
  __typename?: 'LogEvent'
  actor: Scalars['String']['output']
  data: Scalars['JSONObject']['output']
  eshop_id?: Maybe<Scalars['Int']['output']>
  id: Scalars['String']['output']
  source: Scalars['String']['output']
  timestamp: Scalars['UnixDate']['output']
  type: Scalars['String']['output']
}

export type LoginOption = {
  __typename?: 'LoginOption'
  id: Scalars['String']['output']
  oidc_auth_request_url?: Maybe<Scalars['String']['output']>
  oidc_private?: Maybe<LoginOptionOidcPrivate>
  type: LoginOptionTypeEnum
}

export type LoginOptionOidcPrivate = {
  __typename?: 'LoginOptionOidcPrivate'
  auth_url: Scalars['String']['output']
  client_id: Scalars['String']['output']
  client_secret: Scalars['String']['output']
  tenant_id?: Maybe<Scalars['String']['output']>
  token_url: Scalars['String']['output']
}

export type LoginOptionOidcPrivateInput = {
  auth_url: Scalars['String']['input']
  client_id: Scalars['String']['input']
  client_secret: Scalars['String']['input']
  tenant_id?: InputMaybe<Scalars['String']['input']>
  token_url: Scalars['String']['input']
}

export enum LoginOptionTypeEnum {
  OidcPrivateAzureAd = 'OidcPrivateAzureAd',
  OidcPrivateGoogle = 'OidcPrivateGoogle',
  SitooLogin = 'SitooLogin',
}

export type Manufacturer = {
  __typename?: 'Manufacturer'
  address?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  countryid?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  phone?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  url1?: Maybe<Scalars['String']['output']>
  url2?: Maybe<Scalars['String']['output']>
  zip?: Maybe<Scalars['String']['output']>
}

export type ManufacturerInput = {
  address?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  countryid?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  name: Scalars['String']['input']
  phone?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  url1?: InputMaybe<Scalars['String']['input']>
  url2?: InputMaybe<Scalars['String']['input']>
  zip?: InputMaybe<Scalars['String']['input']>
}

export type Me = {
  __typename?: 'Me'
  accountId: Scalars['Int']['output']
  currentSite: Site
  intercomHash?: Maybe<Scalars['String']['output']>
  resourceUrl: Scalars['String']['output']
  role: PermissionRole
  siteId: Scalars['Int']['output']
  sites: Array<Site>
  storeIds: Array<Scalars['Int']['output']>
  timeZone: Scalars['String']['output']
  user?: Maybe<User>
  warehouseIds: Array<Scalars['Int']['output']>
  warehouses: Array<Warehouse>
}

export type MetricScalar = {
  __typename?: 'MetricScalar'
  default_value?: Maybe<Scalars['Int']['output']>
  metric?: Maybe<MetricScalarMetric>
  value?: Maybe<Scalars['Int']['output']>
}

export type MetricScalarMetric = {
  __typename?: 'MetricScalarMetric'
  filter_date_range?: Maybe<DateRange>
  filter_manufacturer_ids?: Maybe<MetricScalarMetricFilterManufacturerIdsEnum>
  filter_store_id?: Maybe<MetricScalarMetricFilterStoreIdEnum>
  filter_user_id_staff?: Maybe<MetricScalarMetricFilterUserIdStaffEnum>
  key: MetricScalarType
  params?: Maybe<Scalars['JSONObject']['output']>
}

export enum MetricScalarMetricFilterManufacturerIdsEnum {
  ManufacturerIds = 'ManufacturerIds',
  None = 'None',
}

export enum MetricScalarMetricFilterStoreIdEnum {
  StoreId = 'StoreId',
}

export enum MetricScalarMetricFilterUserIdStaffEnum {
  None = 'None',
  UserIdStaff = 'UserIdStaff',
}

export enum MetricScalarType {
  CostSalesDeliveredTotal = 'CostSalesDeliveredTotal',
  CurrentTime = 'CurrentTime',
  RefundsAvg = 'RefundsAvg',
  RefundsAvgItems = 'RefundsAvgItems',
  RefundsDiscount = 'RefundsDiscount',
  RefundsNum = 'RefundsNum',
  RefundsNumItems = 'RefundsNumItems',
  RefundsTotal = 'RefundsTotal',
  RevenueAvg = 'RevenueAvg',
  RevenueAvgItems = 'RevenueAvgItems',
  RevenueNum = 'RevenueNum',
  RevenueNumItems = 'RevenueNumItems',
  RevenueTotal = 'RevenueTotal',
  SalesAvg = 'SalesAvg',
  SalesAvgContributionMargin = 'SalesAvgContributionMargin',
  SalesAvgItems = 'SalesAvgItems',
  SalesContributionMargin = 'SalesContributionMargin',
  SalesContributionMarginDelivered = 'SalesContributionMarginDelivered',
  SalesContributionMarginRatioDelivered = 'SalesContributionMarginRatioDelivered',
  SalesContributionRatio = 'SalesContributionRatio',
  SalesDeliveredTotal = 'SalesDeliveredTotal',
  SalesDiscount = 'SalesDiscount',
  SalesNotDeliveredTotal = 'SalesNotDeliveredTotal',
  SalesNum = 'SalesNum',
  SalesNumItems = 'SalesNumItems',
  SalesRefundNum = 'SalesRefundNum',
  SalesTotal = 'SalesTotal',
}

export type MetricSeries = {
  __typename?: 'MetricSeries'
  default_series?: Maybe<MetricSeriesDefaultSeries>
  metric?: Maybe<MetricSeriesMetric>
  value?: Maybe<MetricSeriesValue>
}

export type MetricSeriesDefaultSeries = {
  __typename?: 'MetricSeriesDefaultSeries'
  series: Array<MetricSeriesDefaultSeriesSeries>
  total_count: Scalars['Int']['output']
  total_value: Scalars['Int']['output']
}

export type MetricSeriesDefaultSeriesSeries = {
  __typename?: 'MetricSeriesDefaultSeriesSeries'
  label: Scalars['String']['output']
  value: Scalars['Int']['output']
  value2?: Maybe<Scalars['Int']['output']>
}

export type MetricSeriesMetric = {
  __typename?: 'MetricSeriesMetric'
  filter_date_range?: Maybe<DateRange>
  filter_manufacturer_ids?: Maybe<MetricSeriesMetricFilterManufacturerIdsEnum>
  filter_store_id?: Maybe<MetricSeriesMetricFilterStoreIdEnum>
  filter_user_id_staff?: Maybe<MetricSeriesMetricFilterUserIdStaffEnum>
  key?: Maybe<MetricScalarType>
  limit?: Maybe<Scalars['Int']['output']>
  params?: Maybe<Scalars['JSONObject']['output']>
}

export enum MetricSeriesMetricFilterManufacturerIdsEnum {
  ManufacturerIds = 'ManufacturerIds',
  None = 'None',
}

export enum MetricSeriesMetricFilterStoreIdEnum {
  StoreId = 'StoreId',
}

export enum MetricSeriesMetricFilterUserIdStaffEnum {
  None = 'None',
  UserIdStaff = 'UserIdStaff',
}

export type MetricSeriesValue = {
  __typename?: 'MetricSeriesValue'
  series: Array<MetricSeriesDefaultSeriesSeries>
  total_count: Scalars['Int']['output']
  total_value: Scalars['Int']['output']
}

export type Mfa = {
  __typename?: 'Mfa'
  image: Scalars['String']['output']
  mfaId: Scalars['String']['output']
  secret: Scalars['String']['output']
}

export type Mutation = {
  __typename?: 'Mutation'
  addCampaign: Scalars['Int']['output']
  addCashRegisterActivationCode: ActivationCode
  addCustomAttribute: CustomAttribute
  addInfluencerCode: InfluencerCode
  addOrderDelivery: Array<OrderDelivery>
  addPaymentType: PaymentType
  addProduct: Scalars['Int']['output']
  addPurchasePriceList: PurchasePriceList
  addReasonCode: Scalars['Int']['output']
  addReport: Report
  addReportJob: ReportJob
  addSalesTax: SalesTax
  addSalesTaxGroup: SalesTaxGroup
  addServiceOrder: ServiceOrder
  addShipment: Shipment
  addShipmentAction: ShipmentAction
  addShipmentV2: ShipmentV2
  addUpdateLoginOption: LoginOption
  addUpdateManufacturer: Manufacturer
  addUpdateProductGroup: ProductGroup
  addUpdateStore: Store
  addUpdateWarehouse: Warehouse
  addUser: User
  addUserTag: Scalars['String']['output']
  addWarehouseBatch: WarehouseBatch
  authenticate: Scalars['Boolean']['output']
  authenticateSite: Scalars['Boolean']['output']
  deauthenticate: Scalars['Boolean']['output']
  deleteCampaign: Scalars['Boolean']['output']
  deleteCategories: Array<DeleteCategoryResponse>
  deleteCustomAttribute: Scalars['Boolean']['output']
  deleteInfluencerCodes: Scalars['Boolean']['output']
  deleteLoginOption: Scalars['Boolean']['output']
  deleteManufacturer: Scalars['Boolean']['output']
  deletePaymentType: Scalars['Boolean']['output']
  deletePriceList: Scalars['Boolean']['output']
  deletePricelistItems: Array<Product>
  deleteProductGroup: Scalars['Boolean']['output']
  deleteProducts: Array<Scalars['Boolean']['output']>
  deletePurchasePriceList: Scalars['Boolean']['output']
  deletePurchasePriceListItems: Scalars['Boolean']['output']
  deleteReasonCode: Scalars['Boolean']['output']
  deleteReport: Scalars['Boolean']['output']
  deleteReportJob: Scalars['Boolean']['output']
  deleteSalesTax: Scalars['Boolean']['output']
  deleteSalesTaxGroup: Scalars['Boolean']['output']
  deleteShipments: Array<Scalars['String']['output']>
  deleteStore: Scalars['Boolean']['output']
  deleteUser: Scalars['Boolean']['output']
  deleteUserTag: Scalars['Boolean']['output']
  deleteWarehouse: Scalars['Boolean']['output']
  deleteWarehouseBatchItems: Scalars['Boolean']['output']
  exportAllReplenishmentItems?: Maybe<Scalars['String']['output']>
  exportInfluencerCodes?: Maybe<Scalars['String']['output']>
  exportOrders?: Maybe<Scalars['String']['output']>
  exportProductWarehouseBatchItems?: Maybe<Scalars['String']['output']>
  exportStores?: Maybe<Scalars['String']['output']>
  exportUsers?: Maybe<Scalars['String']['output']>
  exportWarehouses?: Maybe<Scalars['String']['output']>
  importInfluencerCodes: ImportResponse
  importPrices: ImportResponse
  importPurchasePrices: ImportResponse
  importStores: ImportResponse
  importUsers: ImportResponse
  importWarehouseBatchItems: ImportResponse
  importWarehouses: ImportResponse
  parseInfluencerCodesCsv: ConvertInfluencerCodesResponse
  parsePricesCsv: ConvertPricesResponse
  parsePurchasePricesCsv: ConvertPurchasePricesResponse
  parseStoresCsv: ConvertStoresResponse
  parseUsersCsv: ConvertUsersResponse
  parseWarehousesCsv: ConvertWarehousesResponse
  removeMfa: Scalars['Boolean']['output']
  sendFeedback: Scalars['Boolean']['output']
  sendResetPasswordLink: Scalars['Boolean']['output']
  setMfa: Scalars['Boolean']['output']
  updateAdyenApi: PluginAdyenApi
  updateAdyenTapToPay: PluginAdyenTapToPay
  updateBatchItemsRelative: Array<InventoryItemBatch>
  updateCampaign: Campaign
  updateCategories: Array<UpdateCategoryResponse>
  updateCtuNorway: PluginCtuNorway
  updateCustomAttribute: CustomAttribute
  updateInfluencerCode: InfluencerCode
  updateOrder: Order
  updateOrderDelivery: Array<OrderDelivery>
  updatePassword: Scalars['Boolean']['output']
  updatePaymentType: PaymentType
  updatePriceList: PriceList
  updatePricelistItems: Array<Product>
  updateProduct: Product
  updateProductImages: Scalars['Boolean']['output']
  updateProductVariants: Array<Product>
  updateProducts: Array<SingleUpdateProductsResponse>
  updatePurchasePriceList: PurchasePriceList
  updatePurchasePriceListItems: Array<Product>
  updatePurchasePriceListMapping: PurchasePriceListMapping
  updateReasonCode: ReasonCode
  updateReport: Report
  updateRewardsSettings: Scalars['Boolean']['output']
  updateSalesTax: SalesTax
  updateSalesTaxGroup: SalesTaxGroup
  updateServiceOrder: ServiceOrder
  updateSettings: Settings
  updateShipmentV2: ShipmentV2
  updateShipmentsV2: Array<ShipmentV2>
  updateUser: User
  updateUserTag: Scalars['Boolean']['output']
  updateUsers: Array<User>
  updateWarehouseBatch: WarehouseBatch
  /** @deprecated Use updateWarehouseBatchItems2 */
  updateWarehouseBatchItems: Array<WarehouseBatchItem>
  updateWarehouseBatchItems2: Array<InventoryItemBatch>
  updateWarehouseItems: Array<WarehouseItem>
  uploadProductImage: Scalars['Boolean']['output']
}

export type MutationAddCampaignArgs = {
  data: CampaignInput
}

export type MutationAddCashRegisterActivationCodeArgs = {
  registerId: Scalars['String']['input']
}

export type MutationAddCustomAttributeArgs = {
  customAttribute: SingleCustomAttributeInput
}

export type MutationAddInfluencerCodeArgs = {
  data: InfluencerCodeInput
}

export type MutationAddOrderDeliveryArgs = {
  data: AddOrderDeliveryInput
  orderId: Scalars['Int']['input']
}

export type MutationAddPaymentTypeArgs = {
  paymentType: AddPaymentTypeInput
}

export type MutationAddProductArgs = {
  data: ProductInput
}

export type MutationAddPurchasePriceListArgs = {
  priceList: AddUpdatePurchasePriceListInput
}

export type MutationAddReasonCodeArgs = {
  reasonCode: ReasonCodeInput
}

export type MutationAddReportArgs = {
  report: ReportInput
}

export type MutationAddReportJobArgs = {
  reportJob: ReportJobInput
}

export type MutationAddSalesTaxArgs = {
  data: SalesTaxInput
}

export type MutationAddSalesTaxGroupArgs = {
  data: SalesTaxGroupInput
}

export type MutationAddServiceOrderArgs = {
  serviceOrder: AddServiceOrderInput
}

export type MutationAddShipmentArgs = {
  data: ShipmentInput
}

export type MutationAddShipmentActionArgs = {
  data: ShipmentActionInput
  shipmentId: Scalars['String']['input']
}

export type MutationAddShipmentV2Args = {
  shipment: ShipmentV2Input
}

export type MutationAddUpdateLoginOptionArgs = {
  data: AddUpdateLoginOptionInput
}

export type MutationAddUpdateManufacturerArgs = {
  manufacturer: ManufacturerInput
}

export type MutationAddUpdateProductGroupArgs = {
  ProductGroup: SingleProductGroupInput
}

export type MutationAddUpdateStoreArgs = {
  store: StoreInput
}

export type MutationAddUpdateWarehouseArgs = {
  warehouse: WarehouseInput
}

export type MutationAddUserArgs = {
  data: AddUserInput
}

export type MutationAddUserTagArgs = {
  label: Scalars['String']['input']
}

export type MutationAddWarehouseBatchArgs = {
  data: AddWarehouseBatchInput
  warehouseId: Scalars['Int']['input']
}

export type MutationAuthenticateArgs = {
  data: Authenticate
}

export type MutationAuthenticateSiteArgs = {
  siteId: Scalars['Int']['input']
}

export type MutationDeleteCampaignArgs = {
  data: DeleteCampaignInput
}

export type MutationDeleteCategoriesArgs = {
  data: DeleteCategoriesInput
}

export type MutationDeleteCustomAttributeArgs = {
  id: Scalars['String']['input']
}

export type MutationDeleteInfluencerCodesArgs = {
  codes: Array<Scalars['String']['input']>
}

export type MutationDeleteLoginOptionArgs = {
  loginOptionId: Scalars['String']['input']
}

export type MutationDeleteManufacturerArgs = {
  id: Scalars['Float']['input']
}

export type MutationDeletePaymentTypeArgs = {
  id: Scalars['Float']['input']
}

export type MutationDeletePriceListArgs = {
  id: Scalars['Int']['input']
}

export type MutationDeletePricelistItemsArgs = {
  items: Array<DeletePricelistItemInput>
  priceListId: Scalars['Int']['input']
  productSiteId?: InputMaybe<Scalars['Int']['input']>
}

export type MutationDeleteProductGroupArgs = {
  id: Scalars['Int']['input']
}

export type MutationDeleteProductsArgs = {
  data: DeleteProductsInput
}

export type MutationDeletePurchasePriceListArgs = {
  id: Scalars['String']['input']
}

export type MutationDeletePurchasePriceListItemsArgs = {
  priceListId: Scalars['String']['input']
  skus: Array<Scalars['String']['input']>
}

export type MutationDeleteReasonCodeArgs = {
  id: Scalars['Int']['input']
}

export type MutationDeleteReportArgs = {
  reportId: Scalars['Float']['input']
}

export type MutationDeleteReportJobArgs = {
  jobId: Scalars['Float']['input']
}

export type MutationDeleteSalesTaxArgs = {
  id: Scalars['Int']['input']
}

export type MutationDeleteSalesTaxGroupArgs = {
  id: Scalars['Int']['input']
}

export type MutationDeleteShipmentsArgs = {
  shipmentIds: Array<Scalars['String']['input']>
}

export type MutationDeleteStoreArgs = {
  storeId: Scalars['Int']['input']
}

export type MutationDeleteUserArgs = {
  userId: Scalars['String']['input']
}

export type MutationDeleteUserTagArgs = {
  id: Scalars['String']['input']
}

export type MutationDeleteWarehouseArgs = {
  warehouseId: Scalars['Int']['input']
}

export type MutationDeleteWarehouseBatchItemsArgs = {
  skus: Array<Scalars['String']['input']>
  warehouseBatchId: Scalars['Int']['input']
  warehouseId: Scalars['Int']['input']
}

export type MutationExportAllReplenishmentItemsArgs = {
  fileType: FileType
  skus?: InputMaybe<Array<Scalars['String']['input']>>
  warehouseFromId: Scalars['Int']['input']
  warehouseToId: Scalars['Int']['input']
}

export type MutationExportInfluencerCodesArgs = {
  fileType: FileType
  timeZone?: InputMaybe<Scalars['String']['input']>
}

export type MutationExportOrdersArgs = {
  fileType: FileType
  orderIds: Array<Scalars['Int']['input']>
}

export type MutationExportProductWarehouseBatchItemsArgs = {
  category?: InputMaybe<Array<Scalars['Int']['input']>>
  fileType: FileType
  inventoryState?: InputMaybe<InventoryItemBatchState>
  manufacturer?: InputMaybe<Array<Scalars['Int']['input']>>
  num?: InputMaybe<Scalars['Int']['input']>
  productState?: InputMaybe<InventoryProductState>
  productgroup?: InputMaybe<Array<Scalars['Int']['input']>>
  searchtext?: InputMaybe<Scalars['String']['input']>
  siteId?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<GetInventoryBatchesSort>
  start?: InputMaybe<Scalars['Int']['input']>
  warehousebatchid: Scalars['Int']['input']
  warehouseid: Scalars['Int']['input']
}

export type MutationExportStoresArgs = {
  fileType: FileType
}

export type MutationExportUsersArgs = {
  fileType: FileType
  userIds?: InputMaybe<Array<Scalars['String']['input']>>
}

export type MutationExportWarehousesArgs = {
  fileType: FileType
}

export type MutationImportInfluencerCodesArgs = {
  data: Array<ImportInfluencerCodeInput>
}

export type MutationImportPricesArgs = {
  data: Array<ImportPriceItemInput>
}

export type MutationImportPurchasePricesArgs = {
  data: Array<ImportPurchasePriceItemInput>
}

export type MutationImportStoresArgs = {
  data: Array<ImportStoreItemInput>
}

export type MutationImportUsersArgs = {
  data: Array<ImportUserInput>
}

export type MutationImportWarehouseBatchItemsArgs = {
  data: Array<ImportWarehouseBatchItemInput>
  warehouseBatchId: Scalars['Int']['input']
  warehouseId: Scalars['Int']['input']
}

export type MutationImportWarehousesArgs = {
  data: Array<ImportWarehouseInput>
}

export type MutationParseInfluencerCodesCsvArgs = {
  csv: Scalars['String']['input']
}

export type MutationParsePricesCsvArgs = {
  csv: Scalars['String']['input']
}

export type MutationParsePurchasePricesCsvArgs = {
  csv: Scalars['String']['input']
}

export type MutationParseStoresCsvArgs = {
  csv: Scalars['String']['input']
}

export type MutationParseUsersCsvArgs = {
  csv: Scalars['String']['input']
}

export type MutationParseWarehousesCsvArgs = {
  csv: Scalars['String']['input']
}

export type MutationRemoveMfaArgs = {
  userId: Scalars['String']['input']
}

export type MutationSendFeedbackArgs = {
  files: Array<Scalars['Upload']['input']>
  message: Scalars['String']['input']
  path: Scalars['String']['input']
}

export type MutationSendResetPasswordLinkArgs = {
  accountId: Scalars['Int']['input']
  email: Scalars['String']['input']
}

export type MutationSetMfaArgs = {
  accountId: Scalars['Int']['input']
  code: Scalars['String']['input']
  mfaId: Scalars['String']['input']
}

export type MutationUpdateAdyenApiArgs = {
  data: PluginAdyenApiInput
}

export type MutationUpdateAdyenTapToPayArgs = {
  data: PluginAdyenTapToPayInput
}

export type MutationUpdateBatchItemsRelativeArgs = {
  warehouseBatchId: Scalars['Int']['input']
  warehouseBatchItems: Array<WarehouseBatchItemInput>
  warehouseId: Scalars['Int']['input']
}

export type MutationUpdateCampaignArgs = {
  campaignId: Scalars['Int']['input']
  data: CampaignInput
}

export type MutationUpdateCategoriesArgs = {
  data: Array<UpdateCategoryInput>
}

export type MutationUpdateCtuNorwayArgs = {
  data: PluginCtuNorwayInput
}

export type MutationUpdateCustomAttributeArgs = {
  customAttribute: SingleCustomAttributeInput
}

export type MutationUpdateInfluencerCodeArgs = {
  data: InfluencerCodeInput
}

export type MutationUpdateOrderArgs = {
  data: UpdateOrderInput
  orderId: Scalars['Int']['input']
}

export type MutationUpdateOrderDeliveryArgs = {
  data: UpdateOrderDeliveryInput
  orderDeliveryId: Scalars['Int']['input']
  orderId: Scalars['Int']['input']
}

export type MutationUpdatePasswordArgs = {
  accountId: Scalars['Int']['input']
  password: Scalars['String']['input']
  token: Scalars['String']['input']
}

export type MutationUpdatePaymentTypeArgs = {
  paymentType: UpdatePaymentTypeInput
}

export type MutationUpdatePriceListArgs = {
  priceList: UpdatePriceListInput
}

export type MutationUpdatePricelistItemsArgs = {
  items: Array<UpdatePricelistItemInput>
  priceListId: Scalars['Int']['input']
  productSiteId?: InputMaybe<Scalars['Int']['input']>
}

export type MutationUpdateProductArgs = {
  data: ProductInput
  productId: Scalars['Int']['input']
}

export type MutationUpdateProductImagesArgs = {
  imageIds: Array<Scalars['String']['input']>
  productId: Scalars['Int']['input']
}

export type MutationUpdateProductVariantsArgs = {
  deleteMainIfFails?: Scalars['Boolean']['input']
  groups: Array<VariantGroupInput>
  productId: Scalars['Int']['input']
  variants: Array<VariantInput>
}

export type MutationUpdateProductsArgs = {
  data: Array<SingleProductsInput>
}

export type MutationUpdatePurchasePriceListArgs = {
  priceList: AddUpdatePurchasePriceListInput
}

export type MutationUpdatePurchasePriceListItemsArgs = {
  items: Array<PurchasePriceListItemInput>
  priceListId: Scalars['String']['input']
}

export type MutationUpdatePurchasePriceListMappingArgs = {
  mapping: PurchasePriceListMappingInput
}

export type MutationUpdateReasonCodeArgs = {
  id: Scalars['Int']['input']
  reasonCode: ReasonCodeInput
}

export type MutationUpdateReportArgs = {
  report: ReportInput
}

export type MutationUpdateRewardsSettingsArgs = {
  api_token: Scalars['String']['input']
  api_url: Scalars['Url']['input']
}

export type MutationUpdateSalesTaxArgs = {
  data: SalesTaxInput
  id: Scalars['Int']['input']
}

export type MutationUpdateSalesTaxGroupArgs = {
  data: SalesTaxGroupInput
  id: Scalars['Int']['input']
}

export type MutationUpdateServiceOrderArgs = {
  serviceOrder: UpdateServiceOrderInput
}

export type MutationUpdateSettingsArgs = {
  settings: UpdateSettingsInput
}

export type MutationUpdateShipmentV2Args = {
  shipment: ShipmentV2Input
}

export type MutationUpdateShipmentsV2Args = {
  shipment: Array<ShipmentV2Input>
}

export type MutationUpdateUserArgs = {
  data: UpdateUserInput
}

export type MutationUpdateUserTagArgs = {
  userTag: UserTagInput
}

export type MutationUpdateUsersArgs = {
  users: Array<UpdateUserInput>
}

export type MutationUpdateWarehouseBatchArgs = {
  data: UpdateWarehouseBatchInput
  warehouseBatchId: Scalars['Int']['input']
  warehouseId: Scalars['Int']['input']
}

export type MutationUpdateWarehouseBatchItemsArgs = {
  warehouseBatchId: Scalars['Int']['input']
  warehouseBatchItems: Array<WarehouseBatchItemInput>
  warehouseId: Scalars['Int']['input']
}

export type MutationUpdateWarehouseBatchItems2Args = {
  siteId?: InputMaybe<Scalars['Int']['input']>
  warehouseBatchId: Scalars['Int']['input']
  warehouseBatchItems: Array<WarehouseBatchItemInput>
  warehouseId: Scalars['Int']['input']
}

export type MutationUpdateWarehouseItemsArgs = {
  items: Array<WarehouseItemInput>
  warehouseId: Scalars['Int']['input']
}

export type MutationUploadProductImageArgs = {
  file: Scalars['Upload']['input']
  productId: Scalars['Int']['input']
}

export type Order = {
  __typename?: 'Order'
  additionaldata: Scalars['JSONObject']['output']
  cashRegister?: Maybe<CashRegister>
  /** @deprecated  */
  checkoutmethodid: CheckoutMethod
  /** @deprecated  */
  checkoutmethodsubid: Scalars['Int']['output']
  checkoutref?: Maybe<Scalars['String']['output']>
  /** @deprecated  */
  checkouttypeid: Scalars['Int']['output']
  /** @deprecated  */
  checkouttypename: Scalars['String']['output']
  comment?: Maybe<Scalars['String']['output']>
  commentinternal?: Maybe<Scalars['String']['output']>
  company?: Maybe<Scalars['String']['output']>
  creditorderid?: Maybe<Scalars['Int']['output']>
  currencycode?: Maybe<Scalars['String']['output']>
  customerref?: Maybe<Scalars['String']['output']>
  datereserved?: Maybe<Scalars['UnixDate']['output']>
  delivery_address?: Maybe<Scalars['String']['output']>
  delivery_address2?: Maybe<Scalars['String']['output']>
  delivery_city?: Maybe<Scalars['String']['output']>
  delivery_countryid?: Maybe<Scalars['String']['output']>
  delivery_state?: Maybe<Scalars['String']['output']>
  delivery_zip?: Maybe<Scalars['String']['output']>
  /** @deprecated  */
  deliverytypeid: Scalars['Int']['output']
  /** @deprecated  */
  deliverytypename: Scalars['String']['output']
  email?: Maybe<Scalars['String']['output']>
  eshopid: Scalars['Int']['output']
  exchangeOrder?: Maybe<Order>
  externalid?: Maybe<Scalars['String']['output']>
  invoice_address?: Maybe<Scalars['String']['output']>
  invoice_address2?: Maybe<Scalars['String']['output']>
  invoice_city?: Maybe<Scalars['String']['output']>
  invoice_countryid?: Maybe<Scalars['String']['output']>
  invoice_state?: Maybe<Scalars['String']['output']>
  invoice_zip?: Maybe<Scalars['String']['output']>
  moneycheckout_net?: Maybe<Scalars['String']['output']>
  moneycheckout_vat?: Maybe<Scalars['String']['output']>
  moneydelivery_net?: Maybe<Scalars['String']['output']>
  moneydelivery_vat?: Maybe<Scalars['String']['output']>
  moneyfinal_net?: Maybe<Scalars['String']['output']>
  moneyfinal_salestax?: Maybe<Scalars['String']['output']>
  moneyfinal_vat?: Maybe<Scalars['String']['output']>
  moneytotal_gross_all?: Maybe<Scalars['String']['output']>
  moneytotal_gross_checkout?: Maybe<Scalars['String']['output']>
  moneytotal_gross_delivery?: Maybe<Scalars['String']['output']>
  moneytotal_gross_roundoff?: Maybe<Scalars['String']['output']>
  moneytotal_net?: Maybe<Scalars['String']['output']>
  moneytotal_vat?: Maybe<Scalars['String']['output']>
  namefirst?: Maybe<Scalars['String']['output']>
  namelast?: Maybe<Scalars['String']['output']>
  orderDelivery: Array<OrderDelivery>
  orderDeliveryState?: Maybe<OrderDeliveryState>
  orderdate: Scalars['UnixDate']['output']
  orderid: Scalars['Int']['output']
  orderitems: Array<OrderItem>
  orderlogitems: Array<OrderLogItem>
  orderstateid: OrderState
  ordertypeid: OrderType
  originalOrder?: Maybe<Order>
  payments: Array<OrderPayment>
  paymentstateid: PaymentState
  personalid?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  purchaseJournal?: Maybe<Array<Journal>>
  refundOrder?: Maybe<Order>
  registerid?: Maybe<Scalars['String']['output']>
  reservedpayments: Array<OrderReservedPayment>
  staff?: Maybe<User>
  staffHelpers?: Maybe<Array<User>>
  userid_staff?: Maybe<Scalars['String']['output']>
  warehouse?: Maybe<Warehouse>
  warehouseid?: Maybe<Scalars['Int']['output']>
}

export type OrderDelivery = {
  __typename?: 'OrderDelivery'
  binlocation?: Maybe<Scalars['String']['output']>
  datecancelled?: Maybe<Scalars['UnixDate']['output']>
  datedelivery?: Maybe<Scalars['UnixDate']['output']>
  ignorestock?: Maybe<Scalars['Boolean']['output']>
  orderdeliveryid?: Maybe<Scalars['Int']['output']>
  orderdeliveryitems: Array<OrderDeliveryItem>
  orderdeliveryref?: Maybe<Scalars['String']['output']>
  orderid?: Maybe<Scalars['Int']['output']>
  pluginname?: Maybe<Scalars['String']['output']>
  state?: Maybe<OrderDeliveryItemState>
  warehouse?: Maybe<Warehouse>
  warehouseid: Scalars['Int']['output']
}

export type OrderDeliveryItem = {
  __typename?: 'OrderDeliveryItem'
  orderdeliveryid?: Maybe<Scalars['Int']['output']>
  orderdeliveryitemid?: Maybe<Scalars['Int']['output']>
  orderitemid: Scalars['Int']['output']
  quantity: Scalars['Int']['output']
}

export type OrderDeliveryItemInput = {
  orderitemid: Scalars['Int']['input']
  quantity: Scalars['Int']['input']
}

export enum OrderDeliveryItemState {
  Arrived = 'Arrived',
  Cancelled = 'Cancelled',
  Delivered = 'Delivered',
  InTransit = 'InTransit',
  New = 'New',
  ReadyForPickup = 'ReadyForPickup',
  ReadyToShip = 'ReadyToShip',
}

export enum OrderDeliveryState {
  Delivered = 'Delivered',
  NotDelivered = 'NotDelivered',
  PartialDelivery = 'PartialDelivery',
}

export type OrderItem = {
  __typename?: 'OrderItem'
  additionaldata?: Maybe<Scalars['JSONObject']['output']>
  decimalquantitytotal?: Maybe<Scalars['String']['output']>
  decimalunitquantity?: Maybe<Scalars['String']['output']>
  deliveryinfo?: Maybe<Scalars['String']['output']>
  externalinput?: Maybe<Scalars['String']['output']>
  externalinputtitle?: Maybe<Scalars['String']['output']>
  ispercentage?: Maybe<Scalars['Boolean']['output']>
  moneydiscount?: Maybe<Scalars['String']['output']>
  moneydiscountedprice?: Maybe<Scalars['String']['output']>
  moneyitemtotal_net?: Maybe<Scalars['String']['output']>
  moneyitemtotal_vat?: Maybe<Scalars['String']['output']>
  moneynetpriceperquantity?: Maybe<Scalars['String']['output']>
  moneynetpriceperunit?: Maybe<Scalars['String']['output']>
  moneyoriginalprice?: Maybe<Scalars['String']['output']>
  moneypriceorg?: Maybe<Scalars['String']['output']>
  moneyrowdiscount?: Maybe<Scalars['String']['output']>
  moneyrowprice?: Maybe<Scalars['String']['output']>
  orderitemid?: Maybe<Scalars['Int']['output']>
  orderitemtype?: Maybe<Orderitemtype>
  product?: Maybe<Product>
  productattributes?: Maybe<Scalars['String']['output']>
  productid?: Maybe<Scalars['Int']['output']>
  productname: Scalars['String']['output']
  quantity: Scalars['Int']['output']
  salestaxes?: Maybe<Array<OrderItemSalesTax>>
  sku?: Maybe<Scalars['String']['output']>
  unitlabel?: Maybe<Scalars['String']['output']>
  vatvalue?: Maybe<Scalars['Float']['output']>
  vouchercode?: Maybe<Scalars['String']['output']>
  vouchercomment?: Maybe<Scalars['String']['output']>
  vouchername?: Maybe<Scalars['String']['output']>
  vouchervalue?: Maybe<Scalars['Float']['output']>
}

export type OrderItemSalesTax = {
  __typename?: 'OrderItemSalesTax'
  code?: Maybe<Scalars['String']['output']>
  decimaltaxvalue: Scalars['String']['output']
  externalid?: Maybe<Scalars['String']['output']>
  groupregion?: Maybe<Scalars['String']['output']>
  moneytax: Scalars['String']['output']
  moneytotal: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type OrderLogItem = {
  __typename?: 'OrderLogItem'
  datelog?: Maybe<Scalars['UnixDate']['output']>
  fvalue?: Maybe<Scalars['Int']['output']>
  orderid?: Maybe<Scalars['Int']['output']>
  orderlogtypeid: OrderLogType
  strvalue?: Maybe<Scalars['String']['output']>
}

export enum OrderLogType {
  AppEvent = 'AppEvent',
  ConfirmationSent = 'ConfirmationSent',
  Created = 'Created',
  DeliveryAdded = 'DeliveryAdded',
  DeliveryCancelled = 'DeliveryCancelled',
  DeliveryConfirmationSent = 'DeliveryConfirmationSent',
  DeliveryStateNone = 'DeliveryStateNone',
  DeliveryToTASystem = 'DeliveryToTASystem',
  DeliveryUpdated = 'DeliveryUpdated',
  EmailReceipt = 'EmailReceipt',
  GenericEvent = 'GenericEvent',
  IpForOrder = 'IpForOrder',
  LogDataCleared = 'LogDataCleared',
  OrderStateAbandoned = 'OrderStateAbandoned',
  OrderStateCancelled = 'OrderStateCancelled',
  OrderStateClosed = 'OrderStateClosed',
  OrderStateOpen = 'OrderStateOpen',
  PaymentReceived = 'PaymentReceived',
  PaymentStateCancelled = 'PaymentStateCancelled',
  PaymentStateFailed = 'PaymentStateFailed',
  PaymentStateNone = 'PaymentStateNone',
  PaymentStatePending = 'PaymentStatePending',
  PaymentStateReserved = 'PaymentStateReserved',
  PaymentStateSuccessful = 'PaymentStateSuccessful',
  PickupRejected = 'PickupRejected',
  ReadyForPickup = 'ReadyForPickup',
}

export enum OrderLookupFilterOrderTransactionTypeEnum {
  Returns = 'Returns',
  ReturnsUnvalidated = 'ReturnsUnvalidated',
  ReturnsValidated = 'ReturnsValidated',
  Sales = 'Sales',
}

export enum OrderLookupFilterOrderTypeEnum {
  Booking = 'Booking',
  Order = 'Order',
  Parked = 'Parked',
}

export enum OrderLookupFilterPaymentMethodEnum {
  Adyen = 'Adyen',
  AdyenApi = 'AdyenApi',
  AdyenTapToPay = 'AdyenTapToPay',
  Cash = 'Cash',
  CashOnDelivery = 'CashOnDelivery',
  Coiney = 'Coiney',
  CreditNote = 'CreditNote',
  Custom1 = 'Custom1',
  Custom2 = 'Custom2',
  Custom3 = 'Custom3',
  Custom4 = 'Custom4',
  Custom5 = 'Custom5',
  CustomInvoice = 'CustomInvoice',
  CustomPayment = 'CustomPayment',
  ExternalCard = 'ExternalCard',
  ForeignCurrency = 'ForeignCurrency',
  GiftCard = 'GiftCard',
  GiftCardCreditNote = 'GiftCardCreditNote',
  Host2t = 'Host2t',
  IZettle = 'IZettle',
  Invoice = 'Invoice',
  Klarna = 'Klarna',
  KlarnaV3 = 'KlarnaV3',
  MobilePay = 'MobilePay',
  Nets = 'Nets',
  PointAirpay = 'PointAirpay',
  Swish = 'Swish',
  SwishQr = 'SwishQr',
  VerifoneLpp = 'VerifoneLpp',
  Vipps = 'Vipps',
  VippsMobilePay = 'VippsMobilePay',
}

export enum OrderLookupFilterReturnLookupMethodEnum {
  CRMTransactionList = 'CRMTransactionList',
  LatestPurchase = 'LatestPurchase',
  Manual = 'Manual',
  OrderList = 'OrderList',
  ScannerReceiptId = 'ScannerReceiptId',
  SearchExternalId = 'SearchExternalId',
  SearchOrderId = 'SearchOrderId',
  SearchReceiptId = 'SearchReceiptId',
}

export enum OrderLookupFilterSortEnum {
  Asc = 'Asc',
  Desc = 'Desc',
}

export type OrderPayment = {
  __typename?: 'OrderPayment'
  additionaldata?: Maybe<Scalars['JSONObject']['output']>
  cardissuer?: Maybe<Scalars['String']['output']>
  externalid?: Maybe<Scalars['String']['output']>
  moneyamount: Scalars['String']['output']
  name: Scalars['String']['output']
  refid?: Maybe<Scalars['String']['output']>
  reftype?: Maybe<Scalars['String']['output']>
  reservedpaymentid?: Maybe<Scalars['String']['output']>
}

export enum OrderPaymentState {
  Cancelled = 'Cancelled',
  Failed = 'Failed',
  None = 'None',
  Pending = 'Pending',
  Reserved = 'Reserved',
  Successful = 'Successful',
}

export type OrderReservedPayment = {
  __typename?: 'OrderReservedPayment'
  additionaldata?: Maybe<Scalars['JSONObject']['output']>
  cardissuer?: Maybe<Scalars['String']['output']>
  externalid?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  moneyreserved: Scalars['String']['output']
  name: Scalars['String']['output']
  refid?: Maybe<Scalars['String']['output']>
  reftype?: Maybe<Scalars['String']['output']>
}

export enum OrderState {
  Abandoned = 'Abandoned',
  Cancelled = 'Cancelled',
  Closed = 'Closed',
  Open = 'Open',
}

export enum OrderType {
  Booking = 'Booking',
  Order = 'Order',
  PosParked = 'PosParked',
}

export enum Orderitemtype {
  NUMBER_10 = 'NUMBER_10',
  NUMBER_20 = 'NUMBER_20',
}

export enum OtherDeliveryOption {
  Delivery = 'Delivery',
  Disable = 'Disable',
  EnableAll = 'EnableAll',
  Pickup = 'Pickup',
}

export type PaginatedBatchItemsResponse = {
  __typename?: 'PaginatedBatchItemsResponse'
  items?: Maybe<Array<InventoryItemBatch>>
  start?: Maybe<Scalars['Int']['output']>
  totalcount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedCampaignsResponse = {
  __typename?: 'PaginatedCampaignsResponse'
  items?: Maybe<Array<Campaign>>
  start?: Maybe<Scalars['Int']['output']>
  totalcount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedCashRegistersResponse = {
  __typename?: 'PaginatedCashRegistersResponse'
  items?: Maybe<Array<CashRegister>>
  start?: Maybe<Scalars['Int']['output']>
  totalcount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedCategoriesResponse = {
  __typename?: 'PaginatedCategoriesResponse'
  items?: Maybe<Array<Category>>
  start?: Maybe<Scalars['Int']['output']>
  totalcount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedCustomAttributesResponse = {
  __typename?: 'PaginatedCustomAttributesResponse'
  items?: Maybe<Array<CustomAttribute>>
  start?: Maybe<Scalars['Int']['output']>
  totalcount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedInfluencerCodesResponse = {
  __typename?: 'PaginatedInfluencerCodesResponse'
  items?: Maybe<Array<InfluencerCode>>
  start?: Maybe<Scalars['Int']['output']>
  totalcount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedInventoryItemsResponse = {
  __typename?: 'PaginatedInventoryItemsResponse'
  items?: Maybe<Array<InventoryItem>>
  start?: Maybe<Scalars['Int']['output']>
  totalcount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedJournalsResponse = {
  __typename?: 'PaginatedJournalsResponse'
  items?: Maybe<Array<Journal>>
  next?: Maybe<Scalars['String']['output']>
  start?: Maybe<Scalars['Int']['output']>
}

export type PaginatedLogsResponse = {
  __typename?: 'PaginatedLogsResponse'
  items?: Maybe<Array<LogEvent>>
  next?: Maybe<Scalars['String']['output']>
  start?: Maybe<Scalars['Int']['output']>
}

export type PaginatedManufacturerResponse = {
  __typename?: 'PaginatedManufacturerResponse'
  items?: Maybe<Array<Manufacturer>>
  start?: Maybe<Scalars['Int']['output']>
  totalcount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedOrdersResponse = {
  __typename?: 'PaginatedOrdersResponse'
  items?: Maybe<Array<Order>>
  start?: Maybe<Scalars['Int']['output']>
  totalcount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedOrdersSearchResponse = {
  __typename?: 'PaginatedOrdersSearchResponse'
  items?: Maybe<Array<Order>>
  next?: Maybe<Scalars['String']['output']>
  start?: Maybe<Scalars['Int']['output']>
}

export type PaginatedPriceListsResponse = {
  __typename?: 'PaginatedPriceListsResponse'
  items?: Maybe<Array<PriceList>>
  start?: Maybe<Scalars['Int']['output']>
  totalcount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedProductGroupsResponse = {
  __typename?: 'PaginatedProductGroupsResponse'
  items?: Maybe<Array<ProductGroup>>
  start?: Maybe<Scalars['Int']['output']>
  totalcount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedProductsResponse = {
  __typename?: 'PaginatedProductsResponse'
  items?: Maybe<Array<Product>>
  start?: Maybe<Scalars['Int']['output']>
  totalcount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedPurchasePriceListsResponse = {
  __typename?: 'PaginatedPurchasePriceListsResponse'
  items?: Maybe<Array<PurchasePriceList>>
  next?: Maybe<Scalars['String']['output']>
  start?: Maybe<Scalars['Int']['output']>
}

export type PaginatedReplenishmentResponse = {
  __typename?: 'PaginatedReplenishmentResponse'
  items?: Maybe<Array<StoreReplenishmentItem>>
  next?: Maybe<Scalars['String']['output']>
  start?: Maybe<Scalars['Int']['output']>
}

export type PaginatedReportsResponse = {
  __typename?: 'PaginatedReportsResponse'
  items?: Maybe<Array<Report>>
  start?: Maybe<Scalars['Int']['output']>
  totalcount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedSalesTaxGroupsResponse = {
  __typename?: 'PaginatedSalesTaxGroupsResponse'
  items?: Maybe<Array<SalesTaxGroup>>
  start?: Maybe<Scalars['Int']['output']>
  totalcount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedSalesTaxesResponse = {
  __typename?: 'PaginatedSalesTaxesResponse'
  items?: Maybe<Array<SalesTax>>
  start?: Maybe<Scalars['Int']['output']>
  totalcount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedServiceOrdersResponse = {
  __typename?: 'PaginatedServiceOrdersResponse'
  items?: Maybe<Array<ServiceOrder>>
  next?: Maybe<Scalars['String']['output']>
  start?: Maybe<Scalars['Int']['output']>
}

export type PaginatedShipmentsResponse = {
  __typename?: 'PaginatedShipmentsResponse'
  items?: Maybe<Array<Shipment>>
  next?: Maybe<Scalars['String']['output']>
  start?: Maybe<Scalars['Int']['output']>
}

export type PaginatedUsersResponse = {
  __typename?: 'PaginatedUsersResponse'
  items?: Maybe<Array<User>>
  start?: Maybe<Scalars['Int']['output']>
  totalcount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedWarehouseBatchItemsResponse = {
  __typename?: 'PaginatedWarehouseBatchItemsResponse'
  items?: Maybe<Array<WarehouseBatchItem>>
  start?: Maybe<Scalars['Int']['output']>
  totalcount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedWarehouseBatchesResponse = {
  __typename?: 'PaginatedWarehouseBatchesResponse'
  items?: Maybe<Array<WarehouseBatch>>
  start?: Maybe<Scalars['Int']['output']>
  totalcount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedWarehouseItemsResponse = {
  __typename?: 'PaginatedWarehouseItemsResponse'
  items?: Maybe<Array<WarehouseItem>>
  start?: Maybe<Scalars['Int']['output']>
  totalcount?: Maybe<Scalars['Int']['output']>
}

export enum PaymentState {
  Cancelled = 'Cancelled',
  Failed = 'Failed',
  None = 'None',
  Pending = 'Pending',
  Reserved = 'Reserved',
  Successful = 'Successful',
}

export type PaymentType = {
  __typename?: 'PaymentType'
  externalid?: Maybe<Scalars['String']['output']>
  iconUrl?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  paymentmethodid: PosPaymentMethod
  paymenttypeid?: Maybe<Scalars['Int']['output']>
  sortorder?: Maybe<Scalars['Int']['output']>
}

export enum PermissionRole {
  Admin = 'Admin',
  SaGlobal = 'SaGlobal',
  SaLocal = 'SaLocal',
  Staff = 'Staff',
  StoreManager = 'StoreManager',
  StoreStaff = 'StoreStaff',
}

export type PluginAdyenApi = {
  __typename?: 'PluginAdyenApi'
  id?: Maybe<PluginId>
  istestmode?: Maybe<Scalars['Boolean']['output']>
  keyidentifier?: Maybe<Scalars['String']['output']>
  keypassphrase?: Maybe<Scalars['String']['output']>
  keyversion?: Maybe<Scalars['Int']['output']>
}

export type PluginAdyenApiInput = {
  id?: InputMaybe<PluginId>
  istestmode?: InputMaybe<Scalars['Boolean']['input']>
  keyidentifier?: InputMaybe<Scalars['String']['input']>
  keypassphrase?: InputMaybe<Scalars['String']['input']>
  keyversion?: InputMaybe<Scalars['Int']['input']>
}

export type PluginAdyenTapToPay = {
  __typename?: 'PluginAdyenTapToPay'
  api_key?: Maybe<Scalars['String']['output']>
  id?: Maybe<PluginId>
  is_test_mode?: Maybe<Scalars['Boolean']['output']>
  merchant_account?: Maybe<Scalars['String']['output']>
}

export type PluginAdyenTapToPayInput = {
  api_key?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<PluginId>
  is_test_mode?: InputMaybe<Scalars['Boolean']['input']>
  merchant_account?: InputMaybe<Scalars['String']['input']>
}

export type PluginCtuNorway = {
  __typename?: 'PluginCtuNorway'
  companyId?: Maybe<Scalars['String']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  currencyCode?: Maybe<Scalars['String']['output']>
  dateFiscalFirst?: Maybe<Scalars['UnixDate']['output']>
  dateFiscalSecond?: Maybe<Scalars['UnixDate']['output']>
  id?: Maybe<PluginId>
  taxRegistrationCountry?: Maybe<Scalars['String']['output']>
  taxRegistrationNumber?: Maybe<Scalars['String']['output']>
}

export type PluginCtuNorwayInput = {
  companyId?: InputMaybe<Scalars['String']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  currencyCode?: InputMaybe<Scalars['String']['input']>
  dateFiscalFirst?: InputMaybe<Scalars['UnixDate']['input']>
  dateFiscalSecond?: InputMaybe<Scalars['UnixDate']['input']>
  id?: InputMaybe<PluginId>
  taxRegistrationCountry?: InputMaybe<Scalars['String']['input']>
  taxRegistrationNumber?: InputMaybe<Scalars['String']['input']>
}

export type PluginCtuPortugal = {
  __typename?: 'PluginCtuPortugal'
  companyAddress?: Maybe<Scalars['String']['output']>
  companyCity?: Maybe<Scalars['String']['output']>
  companyId?: Maybe<Scalars['String']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  companyZip?: Maybe<Scalars['String']['output']>
  currencyCode?: Maybe<Scalars['String']['output']>
  dateFiscalFirst?: Maybe<Scalars['UnixDate']['output']>
  dateFiscalSecond?: Maybe<Scalars['UnixDate']['output']>
  id?: Maybe<PluginId>
  taxCountryRegion?: Maybe<PluginCtuPortugalTaxCountryRegionEnum>
  taxEntity?: Maybe<Scalars['String']['output']>
  taxRegistrationNumberPortugal?: Maybe<Scalars['Int']['output']>
}

export enum PluginCtuPortugalTaxCountryRegionEnum {
  Pt = 'Pt',
  PtAc = 'PtAc',
  PtMa = 'PtMa',
}

export type PluginCustomGiftCard = {
  __typename?: 'PluginCustomGiftCard'
  api_key?: Maybe<Scalars['String']['output']>
  api_url?: Maybe<Scalars['String']['output']>
  id?: Maybe<PluginId>
}

export type PluginCustomPayment = {
  __typename?: 'PluginCustomPayment'
  api_key?: Maybe<Scalars['String']['output']>
  api_url?: Maybe<Scalars['String']['output']>
  bg_payment?: Maybe<Scalars['String']['output']>
  bg_payment_small?: Maybe<Scalars['String']['output']>
  icon_payment?: Maybe<Scalars['String']['output']>
  icon_payment_small?: Maybe<Scalars['String']['output']>
  id?: Maybe<PluginId>
  required_customer_info?: Maybe<
    Array<PluginCustomPaymentRequiredCustomerInfoEnum>
  >
}

export enum PluginCustomPaymentRequiredCustomerInfoEnum {
  Mobile = 'Mobile',
}

export type PluginCustomProductSearch = {
  __typename?: 'PluginCustomProductSearch'
  api_key?: Maybe<Scalars['String']['output']>
  api_url?: Maybe<Scalars['String']['output']>
  id?: Maybe<PluginId>
}

export type PluginGivex = {
  __typename?: 'PluginGivex'
  extendedLogging?: Maybe<Scalars['Boolean']['output']>
  hostPrimary?: Maybe<Scalars['String']['output']>
  hostSecondary?: Maybe<Scalars['String']['output']>
  id?: Maybe<PluginId>
  userId?: Maybe<Scalars['String']['output']>
  userPassword?: Maybe<Scalars['String']['output']>
}

export enum PluginId {
  Adyenapi = 'Adyenapi',
  Adyentaptopay = 'Adyentaptopay',
  Ctunorway = 'Ctunorway',
  Ctuportugal = 'Ctuportugal',
  Customgiftcard = 'Customgiftcard',
  Custompayment = 'Custompayment',
  Customproductsearch = 'Customproductsearch',
  Givex = 'Givex',
  Klarnav3 = 'Klarnav3',
  Netsbaxilogs = 'Netsbaxilogs',
  Netscurrencyconversion = 'Netscurrencyconversion',
  Nosto = 'Nosto',
  Productsearch = 'Productsearch',
  Retain24 = 'Retain24',
  Selfcheckout = 'Selfcheckout',
  Swish = 'Swish',
  Switchboard = 'Switchboard',
  Taxfree = 'Taxfree',
  Zebra = 'Zebra',
}

export type PluginKlarnaV3 = {
  __typename?: 'PluginKlarnaV3'
  id?: Maybe<PluginId>
  klarna_region?: Maybe<PluginKlarnaV3RegionEnum>
  password?: Maybe<Scalars['String']['output']>
  profileid?: Maybe<PluginKlarnaV3ProfileidEnum>
  profileid_selfcheckout?: Maybe<PluginKlarnaV3ProfileidSelfcheckoutEnum>
  username?: Maybe<Scalars['String']['output']>
}

export enum PluginKlarnaV3ProfileidEnum {
  Ac0ef585B1b14951Ab0fF250f44f6b08 = 'Ac0ef585B1b14951Ab0fF250f44f6b08',
  E205530c37b54843A228871b5fd80c97 = 'E205530c37b54843A228871b5fd80c97',
  _4baf659226754ed793cc783b8f73de5e = '_4baf659226754ed793cc783b8f73de5e',
  _784174313bf2456e9f1895c3313db874 = '_784174313bf2456e9f1895c3313db874',
}

export enum PluginKlarnaV3ProfileidSelfcheckoutEnum {
  Ac0ef585B1b14951Ab0fF250f44f6b08 = 'Ac0ef585B1b14951Ab0fF250f44f6b08',
  _4baf659226754ed793cc783b8f73de5e = '_4baf659226754ed793cc783b8f73de5e',
}

export enum PluginKlarnaV3RegionEnum {
  Europe = 'Europe',
  NorthAmerica = 'NorthAmerica',
  Oceania = 'Oceania',
}

export type PluginNetsBaxiLogs = {
  __typename?: 'PluginNetsBaxiLogs'
  access_key_id?: Maybe<Scalars['String']['output']>
  access_key_secret?: Maybe<Scalars['String']['output']>
  bucket?: Maybe<Scalars['String']['output']>
  id?: Maybe<PluginId>
  path?: Maybe<PluginNetsBaxiLogsPathEnum>
  region?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export enum PluginNetsBaxiLogsPathEnum {
  Register = 'Register',
  StoreRegister = 'StoreRegister',
}

export type PluginNetsCurrencyConversion = {
  __typename?: 'PluginNetsCurrencyConversion'
  id?: Maybe<PluginId>
  payment_types: Array<PluginNetsCurrencyConversionPaymentType>
}

export type PluginNetsCurrencyConversionPaymentType = {
  __typename?: 'PluginNetsCurrencyConversionPaymentType'
  bax_number?: Maybe<Scalars['String']['output']>
  currency_code?: Maybe<Scalars['String']['output']>
  payment_type_id?: Maybe<Scalars['Int']['output']>
}

export type PluginNosto = {
  __typename?: 'PluginNosto'
  graphQLRecordRequests: Scalars['Boolean']['output']
  graphQLToken?: Maybe<Scalars['String']['output']>
  id?: Maybe<PluginId>
}

export type PluginProductSearch = {
  __typename?: 'PluginProductSearch'
  external_scan_to_cart: Scalars['Boolean']['output']
  external_search_active: Scalars['Boolean']['output']
  external_search_title: Scalars['String']['output']
  filters: Array<PluginProductSearchFilter>
  id?: Maybe<PluginId>
}

export type PluginProductSearchFilter = {
  __typename?: 'PluginProductSearchFilter'
  id: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
  type: PluginProductSearchFiltersInnerTypeEnum
}

export enum PluginProductSearchFiltersInnerTypeEnum {
  Bool = 'Bool',
  MultiEnum = 'MultiEnum',
  SingleEnum = 'SingleEnum',
}

export type PluginRetain24 = {
  __typename?: 'PluginRetain24'
  certificate_private: Scalars['String']['output']
  certificate_public: Scalars['String']['output']
  id?: Maybe<PluginId>
  template_creditnote_printout: Scalars['String']['output']
  template_giftcard_email: Scalars['String']['output']
  template_giftcard_printout: Scalars['String']['output']
  template_giftcard_sms: Scalars['String']['output']
}

export type PluginSelfCheckout = {
  __typename?: 'PluginSelfCheckout'
  active: Scalars['Boolean']['output']
  checkout_user?: Maybe<Scalars['String']['output']>
  color_background?: Maybe<Scalars['String']['output']>
  color_text?: Maybe<Scalars['String']['output']>
  featured_products: Array<Scalars['Int']['output']>
  id?: Maybe<PluginId>
  logotype?: Maybe<Scalars['String']['output']>
}

export type PluginSwish = {
  __typename?: 'PluginSwish'
  certificate_priavte: Scalars['String']['output']
  certificate_public: Scalars['String']['output']
  id?: Maybe<PluginId>
  message_payment: Scalars['String']['output']
  message_refund: Scalars['String']['output']
  moneylimitmin: Scalars['Money']['output']
  payee_alias: Scalars['String']['output']
  selfcheckoutenabled: Scalars['Boolean']['output']
}

export type PluginSwitchboard = {
  __typename?: 'PluginSwitchboard'
  handler_giftcards?: Maybe<PluginSwitchboardHandlerGiftcardsEnum>
  handler_recommendations?: Maybe<PluginSwitchboardHandlerRecommendationsEnum>
  id?: Maybe<PluginId>
}

export enum PluginSwitchboardHandlerGiftcardsEnum {
  Customgiftcard = 'Customgiftcard',
  Giftcardsitoo = 'Giftcardsitoo',
  Givex = 'Givex',
  Retain24 = 'Retain24',
}

export enum PluginSwitchboardHandlerRecommendationsEnum {
  Nosto = 'Nosto',
  Recommendationssitoo = 'Recommendationssitoo',
}

export type PluginTaxFree = {
  __typename?: 'PluginTaxFree'
  active: Scalars['Boolean']['output']
  id?: Maybe<PluginId>
}

export type PluginZebra = {
  __typename?: 'PluginZebra'
  id?: Maybe<PluginId>
  templates?: Maybe<Array<PluginZebraTemplate>>
}

export type PluginZebraTemplate = {
  __typename?: 'PluginZebraTemplate'
  data?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  name?: Maybe<Scalars['String']['output']>
  type?: Maybe<PluginZebraTemplatesInnerTypeEnum>
}

export enum PluginZebraTemplatesInnerTypeEnum {
  NUMBER_10 = 'NUMBER_10',
  NUMBER_20 = 'NUMBER_20',
}

export enum PosOrderField {
  Email = 'Email',
  NameFirst = 'NameFirst',
  NameLast = 'NameLast',
  Phone = 'Phone',
  PhoneOrEmail = 'PhoneOrEmail',
}

export enum PosPaymentMethod {
  CARD_ADYEN_SDK = 'CARD_ADYEN_SDK',
  CARD_ADYEN_TAP_TO_PAY = 'CARD_ADYEN_TAP_TO_PAY',
  CARD_ADYEN_TERMINAL_API = 'CARD_ADYEN_TERMINAL_API',
  CARD_COINEY = 'CARD_COINEY',
  CARD_EXTERNAL = 'CARD_EXTERNAL',
  CARD_HOST2T = 'CARD_HOST2T',
  CARD_IZETTLE = 'CARD_IZETTLE',
  CARD_NETS = 'CARD_NETS',
  CARD_VERIFONE_AIRPAY = 'CARD_VERIFONE_AIRPAY',
  CARD_VERIFONE_LPP = 'CARD_VERIFONE_LPP',
  CASH = 'CASH',
  CASH_ON_DELIVERY = 'CASH_ON_DELIVERY',
  CREDIT_NOTE = 'CREDIT_NOTE',
  CREDIT_NOTE_AS_GIFT_CARD = 'CREDIT_NOTE_AS_GIFT_CARD',
  CUSTOM_INVOICE = 'CUSTOM_INVOICE',
  CUSTOM_METHOD_1 = 'CUSTOM_METHOD_1',
  CUSTOM_METHOD_2 = 'CUSTOM_METHOD_2',
  CUSTOM_METHOD_3 = 'CUSTOM_METHOD_3',
  CUSTOM_METHOD_4 = 'CUSTOM_METHOD_4',
  CUSTOM_METHOD_5 = 'CUSTOM_METHOD_5',
  CUSTOM_PAYMENT_API = 'CUSTOM_PAYMENT_API',
  FOREIGN_CURRENCY_CASH = 'FOREIGN_CURRENCY_CASH',
  GIFT_CARD = 'GIFT_CARD',
  INVOICE = 'INVOICE',
  KLARNA = 'KLARNA',
  KLARNA_LEGACY = 'KLARNA_LEGACY',
  MOBILEPAY = 'MOBILEPAY',
  SITOO_PAYMENTS = 'SITOO_PAYMENTS',
  SWISH = 'SWISH',
  SWISH_WITH_QR = 'SWISH_WITH_QR',
  VIPPS = 'VIPPS',
  VIPPS_MOBILEPAY = 'VIPPS_MOBILEPAY',
}

export enum PosPermissionOptionLevel {
  Off = 'Off',
  On = 'On',
  OnDualAuth = 'OnDualAuth',
  OnSelfAuth = 'OnSelfAuth',
}

export type PosPermissionOptions = {
  __typename?: 'PosPermissionOptions'
  cash_management?: Maybe<PosPermissionOptionLevel>
  explicit_open_cash_drawer?: Maybe<PosPermissionOptionLevel>
  inventory_management?: Maybe<PosPermissionOptionLevel>
  manual_price?: Maybe<PosPermissionOptionLevel>
  manualreturn?: Maybe<PosPermissionOptionLevel>
  salestax_exemption?: Maybe<PosPermissionOptionLevel>
  validatedreturn?: Maybe<PosPermissionOptionLevel>
  validatedreturn_any_payment?: Maybe<PosPermissionOptionLevel>
}

export type PosPermissionOptionsInput = {
  cash_management?: InputMaybe<PosPermissionOptionLevel>
  explicit_open_cash_drawer?: InputMaybe<PosPermissionOptionLevel>
  inventory_management?: InputMaybe<PosPermissionOptionLevel>
  manual_price?: InputMaybe<PosPermissionOptionLevel>
  manualreturn?: InputMaybe<PosPermissionOptionLevel>
  salestax_exemption?: InputMaybe<PosPermissionOptionLevel>
  validatedreturn?: InputMaybe<PosPermissionOptionLevel>
  validatedreturn_any_payment?: InputMaybe<PosPermissionOptionLevel>
}

export type PosPermissions = {
  __typename?: 'PosPermissions'
  store_manager: PosPermissionOptions
  store_staff: PosPermissionOptions
}

export type PosPermissionsInput = {
  store_manager: PosPermissionOptionsInput
  store_staff: PosPermissionOptionsInput
}

export type PriceList = {
  __typename?: 'PriceList'
  active?: Maybe<Scalars['Boolean']['output']>
  currencycode?: Maybe<Scalars['String']['output']>
  externalid?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  pricelistdescription?: Maybe<Scalars['String']['output']>
  pricelistname: Scalars['String']['output']
  tags?: Maybe<Array<Scalars['String']['output']>>
}

export type PricelistItem = {
  __typename?: 'PricelistItem'
  moneyprice: Scalars['String']['output']
  moneypricevolume?: Maybe<VolumePriceLevel>
  pricelist?: Maybe<PriceList>
  pricelistid: Scalars['Int']['output']
  product?: Maybe<Product>
  productid?: Maybe<Scalars['Int']['output']>
  sku?: Maybe<Scalars['String']['output']>
}

export type PrintLine = {
  __typename?: 'PrintLine'
  scope?: Maybe<Scalars['Float']['output']>
  type: Scalars['Float']['output']
  value1?: Maybe<Scalars['String']['output']>
  value2?: Maybe<Scalars['String']['output']>
}

export type Product = {
  __typename?: 'Product'
  accessories?: Maybe<Array<Scalars['Int']['output']>>
  accessoriesProducts?: Maybe<Array<Product>>
  /** @deprecated  */
  active?: Maybe<Scalars['Boolean']['output']>
  activepos?: Maybe<Scalars['Boolean']['output']>
  allcategories?: Maybe<Array<Category>>
  allowdecimals?: Maybe<Scalars['Boolean']['output']>
  barcode?: Maybe<Scalars['String']['output']>
  barcodealiases?: Maybe<Array<Scalars['String']['output']>>
  categories?: Maybe<Array<Scalars['Int']['output']>>
  childVariants?: Maybe<Array<Product>>
  computedPurchasePrice?: Maybe<ComputedPurchasePriceValue>
  custom1?: Maybe<Scalars['String']['output']>
  custom2?: Maybe<Scalars['String']['output']>
  custom3?: Maybe<Scalars['String']['output']>
  custom4?: Maybe<Scalars['String']['output']>
  custom5?: Maybe<Scalars['String']['output']>
  customattributes?: Maybe<Scalars['JSONObject']['output']>
  datecreated?: Maybe<Scalars['UnixDate']['output']>
  datemodified?: Maybe<Scalars['UnixDate']['output']>
  defaultcategory?: Maybe<Category>
  defaultcategoryid?: Maybe<Scalars['Int']['output']>
  /** @deprecated  */
  deliveryclassid?: Maybe<Scalars['Int']['output']>
  deliveryinfo?: Maybe<Scalars['String']['output']>
  /** @deprecated  */
  deliverystatus?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  /** @deprecated  */
  descriptionhtml?: Maybe<Scalars['String']['output']>
  descriptionshort?: Maybe<Scalars['String']['output']>
  /** @deprecated  */
  externalinputtitle?: Maybe<Scalars['String']['output']>
  /** @deprecated  */
  friendly?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  images?: Maybe<Array<Scalars['String']['output']>>
  isMainVariant?: Maybe<Scalars['Boolean']['output']>
  isSingleProduct?: Maybe<Scalars['Boolean']['output']>
  manufacturer?: Maybe<Manufacturer>
  manufacturerid?: Maybe<Scalars['Int']['output']>
  manufacturerurl?: Maybe<Scalars['String']['output']>
  moneyfinalprice?: Maybe<Scalars['String']['output']>
  moneyofferprice?: Maybe<Scalars['String']['output']>
  /** @deprecated  */
  moneyprice?: Maybe<Scalars['String']['output']>
  /** @deprecated We should use purchase price instead */
  moneypricein?: Maybe<Scalars['String']['output']>
  /** SRP */
  moneypriceorg?: Maybe<Scalars['String']['output']>
  offerdateend?: Maybe<Scalars['UnixDate']['output']>
  offerdatestart?: Maybe<Scalars['UnixDate']['output']>
  /** @deprecated  */
  offerisactive?: Maybe<Scalars['Boolean']['output']>
  /** @deprecated  */
  offerisenabled?: Maybe<Scalars['Boolean']['output']>
  /** @deprecated  */
  offertitle?: Maybe<Scalars['String']['output']>
  pricelisthasvolume?: Maybe<Scalars['Boolean']['output']>
  pricelistitems?: Maybe<Array<PricelistItem>>
  productImages?: Maybe<Array<ProductImage>>
  productgroup?: Maybe<ProductGroup>
  productgrouptype?: Maybe<ProductGroupType>
  purchasePrice?: Maybe<PurchasePriceListItem>
  related?: Maybe<Array<Scalars['Int']['output']>>
  relatedProducts?: Maybe<Array<Product>>
  /** @deprecated  */
  seo_description?: Maybe<Scalars['String']['output']>
  /** @deprecated  */
  seo_keywords?: Maybe<Scalars['String']['output']>
  /** @deprecated  */
  seo_title?: Maybe<Scalars['String']['output']>
  similar?: Maybe<Array<Scalars['Int']['output']>>
  similarProducts?: Maybe<Array<Product>>
  siteId?: Maybe<Scalars['Int']['output']>
  sku: Scalars['String']['output']
  skumanufacturer?: Maybe<Scalars['String']['output']>
  /** @deprecated  */
  stockallowbackorder?: Maybe<Scalars['Boolean']['output']>
  stockcountenable?: Maybe<Scalars['Boolean']['output']>
  title?: Maybe<Scalars['String']['output']>
  unitlabel?: Maybe<Scalars['String']['output']>
  variant?: Maybe<Array<ProductVariantItem>>
  variantGroups?: Maybe<Array<VariantGroup>>
  variantparentid?: Maybe<Scalars['Int']['output']>
  vatid?: Maybe<Scalars['Int']['output']>
  vatvalue?: Maybe<Scalars['Float']['output']>
  warehouseBatchItem?: Maybe<WarehouseBatchItem>
  warehouseId?: Maybe<Array<Scalars['Int']['output']>>
  warehouseItems?: Maybe<Array<WarehouseItem>>
}

export type ProductComputedPurchasePriceArgs = {
  currency?: InputMaybe<Scalars['String']['input']>
}

export type ProductPricelistitemsArgs = {
  fallbackPriceToCommon?: InputMaybe<Scalars['Boolean']['input']>
}

export type ProductPurchasePriceArgs = {
  priceListId: Scalars['String']['input']
}

export type ProductWarehouseBatchItemArgs = {
  num?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
  warehouseBatchId: Scalars['Int']['input']
  warehouseId: Scalars['Int']['input']
}

export type ProductWarehouseItemsArgs = {
  warehouseid?: InputMaybe<Scalars['Int']['input']>
}

export type ProductGroup = {
  __typename?: 'ProductGroup'
  comment?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  type?: Maybe<ProductGroupType>
  value?: Maybe<Scalars['Float']['output']>
}

export enum ProductGroupType {
  Deposit = 'Deposit',
  GiftCard = 'GiftCard',
  Product = 'Product',
  Service = 'Service',
}

export type ProductImage = {
  __typename?: 'ProductImage'
  fileUrl: Scalars['String']['output']
  id: Scalars['String']['output']
}

export type ProductImageFile = {
  __typename?: 'ProductImageFile'
  datecreated?: Maybe<Scalars['UnixDate']['output']>
  fileUrl: Scalars['String']['output']
  filesize?: Maybe<Scalars['Int']['output']>
  height?: Maybe<Scalars['Int']['output']>
  id: Scalars['String']['output']
  mimetype?: Maybe<Scalars['String']['output']>
  width?: Maybe<Scalars['Int']['output']>
}

export type ProductInput = {
  accessories?: InputMaybe<Array<Scalars['Int']['input']>>
  active?: InputMaybe<Scalars['Boolean']['input']>
  activepos?: InputMaybe<Scalars['Boolean']['input']>
  allowdecimals?: InputMaybe<Scalars['Boolean']['input']>
  barcode?: InputMaybe<Scalars['String']['input']>
  barcodealiases?: InputMaybe<Array<Scalars['String']['input']>>
  categories?: InputMaybe<Array<Scalars['Int']['input']>>
  custom1?: InputMaybe<Scalars['String']['input']>
  custom2?: InputMaybe<Scalars['String']['input']>
  custom3?: InputMaybe<Scalars['String']['input']>
  custom4?: InputMaybe<Scalars['String']['input']>
  custom5?: InputMaybe<Scalars['String']['input']>
  customattributes?: InputMaybe<Scalars['JSONObject']['input']>
  datecreated?: InputMaybe<Scalars['UnixDate']['input']>
  datemodified?: InputMaybe<Scalars['UnixDate']['input']>
  defaultcategoryid?: InputMaybe<Scalars['Int']['input']>
  deliveryclassid?: InputMaybe<Scalars['Int']['input']>
  deliveryinfo?: InputMaybe<Scalars['String']['input']>
  deliverystatus?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  descriptionhtml?: InputMaybe<Scalars['String']['input']>
  descriptionshort?: InputMaybe<Scalars['String']['input']>
  externalinputtitle?: InputMaybe<Scalars['String']['input']>
  friendly?: InputMaybe<Scalars['String']['input']>
  images?: InputMaybe<Array<Scalars['String']['input']>>
  manufacturerid?: InputMaybe<Scalars['Int']['input']>
  manufacturerurl?: InputMaybe<Scalars['String']['input']>
  moneyfinalprice?: InputMaybe<Scalars['String']['input']>
  moneyofferprice?: InputMaybe<Scalars['String']['input']>
  moneyprice?: InputMaybe<Scalars['String']['input']>
  moneypricein?: InputMaybe<Scalars['String']['input']>
  moneypriceorg?: InputMaybe<Scalars['String']['input']>
  offerdateend?: InputMaybe<Scalars['UnixDate']['input']>
  offerdatestart?: InputMaybe<Scalars['UnixDate']['input']>
  offerisactive?: InputMaybe<Scalars['Boolean']['input']>
  offerisenabled?: InputMaybe<Scalars['Boolean']['input']>
  offertitle?: InputMaybe<Scalars['String']['input']>
  pricelisthasvolume?: InputMaybe<Scalars['Boolean']['input']>
  productgrouptype?: InputMaybe<ProductGroupType>
  related?: InputMaybe<Array<Scalars['Int']['input']>>
  seo_description?: InputMaybe<Scalars['String']['input']>
  seo_keywords?: InputMaybe<Scalars['String']['input']>
  seo_title?: InputMaybe<Scalars['String']['input']>
  similar?: InputMaybe<Array<Scalars['Int']['input']>>
  sku?: InputMaybe<Scalars['String']['input']>
  skumanufacturer?: InputMaybe<Scalars['String']['input']>
  stockallowbackorder?: InputMaybe<Scalars['Boolean']['input']>
  stockcountenable?: InputMaybe<Scalars['Boolean']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  unitlabel?: InputMaybe<Scalars['String']['input']>
  variantparentid?: InputMaybe<Scalars['Int']['input']>
  vatid?: InputMaybe<Scalars['Int']['input']>
  vatvalue?: InputMaybe<Scalars['Float']['input']>
}

export type ProductVariant = {
  __typename?: 'ProductVariant'
  active: Scalars['Boolean']['output']
  activepos: Scalars['Boolean']['output']
  attributes: Array<Scalars['String']['output']>
  barcode: Scalars['String']['output']
  barcodealiases?: Maybe<Array<Scalars['String']['output']>>
  deliverystatus: Scalars['String']['output']
  friendly: Scalars['String']['output']
  id: Scalars['Int']['output']
  images?: Maybe<Array<ProductImageFile>>
  moneyofferprice: Scalars['String']['output']
  moneyprice: Scalars['String']['output']
  moneypricein: Scalars['String']['output']
  moneypriceorg: Scalars['String']['output']
  pricelisthasvolume?: Maybe<Scalars['Boolean']['output']>
  sku: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type ProductVariantGroup = {
  __typename?: 'ProductVariantGroup'
  name: Scalars['String']['output']
  options: Array<Scalars['String']['output']>
}

export type ProductVariantItem = {
  __typename?: 'ProductVariantItem'
  name?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type ProductVariantsResponse = {
  __typename?: 'ProductVariantsResponse'
  groups: Array<ProductVariantGroup>
  variants: Array<ProductVariant>
}

export type PublicLoginOption = {
  __typename?: 'PublicLoginOption'
  id: Scalars['String']['output']
  oidc_auth_request_url?: Maybe<Scalars['String']['output']>
  type: LoginOptionTypeEnum
}

export type PurchasePriceList = {
  __typename?: 'PurchasePriceList'
  created_at: Scalars['UnixDate']['output']
  currency: Scalars['String']['output']
  id: Scalars['String']['output']
  modified_at: Scalars['UnixDate']['output']
  name: Scalars['String']['output']
  ownerSite?: Maybe<Site>
  owner_eshop_id: Scalars['Int']['output']
  sharedStores?: Maybe<Array<Store>>
  /** Store External Id */
  shared_store_ids?: Maybe<Array<Scalars['String']['output']>>
}

export type PurchasePriceListItem = {
  __typename?: 'PurchasePriceListItem'
  price: Scalars['Float']['output']
  priceListId: Scalars['String']['output']
  sku: Scalars['String']['output']
}

export type PurchasePriceListItemInput = {
  price: Scalars['Float']['input']
  productId: Scalars['Int']['input']
  sku: Scalars['String']['input']
}

export type PurchasePriceListMapping = {
  __typename?: 'PurchasePriceListMapping'
  assigned?: Maybe<PurchasePriceListMappingItem>
  id: Scalars['Int']['output']
  shared?: Maybe<Scalars['JSONObject']['output']>
}

export type PurchasePriceListMappingInput = {
  assigned?: InputMaybe<PurchasePriceListMappingItemInput>
  shared?: InputMaybe<Scalars['JSONObject']['input']>
}

export type PurchasePriceListMappingItem = {
  __typename?: 'PurchasePriceListMappingItem'
  price_list_id: Scalars['String']['output']
  stores?: Maybe<Scalars['JSONObject']['output']>
}

export type PurchasePriceListMappingItemInput = {
  price_list_id: Scalars['String']['input']
  stores?: InputMaybe<Scalars['JSONObject']['input']>
}

export type Query = {
  __typename?: 'Query'
  accessLogs: PaginatedLogsResponse
  allCashRegisters: Array<CashRegister>
  allCustomAttributes: Array<CustomAttribute>
  allFranchiseSites: Array<Site>
  /** @deprecated Use allWarehouseBatches */
  allOpenWarehouseBatches: Array<WarehouseBatch>
  allPaymentTypes: Array<PaymentType>
  allPriceListTags: Array<Scalars['String']['output']>
  allProductGroups: Array<ProductGroup>
  allProductWarehouseItems: Array<WarehouseItem>
  allReasonCodes: Array<ReasonCode>
  allReplenishmentItems: Array<StoreReplenishmentItem>
  allSalesTaxes: Array<SalesTax>
  allShipmentActions: Array<ShipmentAction>
  allSites: Array<Site>
  allStoreTags: Array<Scalars['String']['output']>
  /** Get batches for all the warehouses */
  allWarehouseBatches: PaginatedWarehouseBatchesResponse
  allWarehouses: Array<Warehouse>
  authenticateOidc: Scalars['Boolean']['output']
  availablePaymentMethods: Array<PosPaymentMethod>
  campaign: Campaign
  campaignGroupTypes: Array<Scalars['String']['output']>
  campaignStates: Array<Scalars['String']['output']>
  campaignTypes: Array<Scalars['String']['output']>
  campaigns: PaginatedCampaignsResponse
  cashRegister: CashRegister
  cashRegisters: PaginatedCashRegistersResponse
  categories: PaginatedCategoriesResponse
  configVars: ConfigVars
  convertShipmentPackageItemsCsv: ConvertShipmentPackageItemsResponse
  convertWarehouseBatchItemsCsv: ConvertWarehouseBatchItemsResponse
  customAttribute: CustomAttribute
  customAttributes: PaginatedCustomAttributesResponse
  dashboard: Scalars['JSONObject']['output']
  dashboardConfiguration: DashboardConfig
  dashboardData: DashboardData
  dashboards: Array<Dashboard>
  filter: Array<FilterValue>
  getPaymentType: PaymentType
  getShipmentV2: ShipmentV2
  getShipmentsV2: ShipmentsV2
  getUserTags: Array<UserTag>
  influencerCode: InfluencerCode
  influencerCodes: PaginatedInfluencerCodesResponse
  journalEntries: PaginatedJournalsResponse
  loginOption: LoginOption
  loginOptions: Array<PublicLoginOption>
  manufacturer: Manufacturer
  manufacturers: PaginatedManufacturerResponse
  me: Me
  mfaCode: Mfa
  mfaStatus: Scalars['Boolean']['output']
  numActiveCashRegisters: Scalars['Int']['output']
  numNegativeStockLevels: Array<WarehouseStockTotal>
  numTransitShipments: Scalars['Int']['output']
  oidcAccountId: Scalars['Int']['output']
  order: Order
  orders: PaginatedOrdersResponse
  plugin: SettingsPlugin
  priceList: PriceList
  priceLists: PaginatedPriceListsResponse
  product: Product
  productGroup: ProductGroup
  productGroupTypes: Array<Scalars['String']['output']>
  productGroups: PaginatedProductGroupsResponse
  productVariants: ProductVariantsResponse
  productWarehouseBatches: PaginatedBatchItemsResponse
  productWarehouseItems: PaginatedInventoryItemsResponse
  products: PaginatedProductsResponse
  publicLoginOptions: Array<PublicLoginOption>
  purchasePriceList: PurchasePriceList
  purchasePriceListMapping: PurchasePriceListMapping
  purchasePriceLists: PaginatedPurchasePriceListsResponse
  reasonCode: ReasonCode
  replenishmentItems: PaginatedReplenishmentResponse
  report: Report
  reportJob: ReportJob
  reportJobs: ReportJobListResponse
  reportResults: ReportResults
  reports: PaginatedReportsResponse
  rewardsSetting: RewardsSettings
  salesTax: SalesTax
  salesTaxGroup: SalesTaxGroup
  salesTaxGroups: PaginatedSalesTaxGroupsResponse
  salesTaxes: PaginatedSalesTaxesResponse
  searchOrders: PaginatedOrdersSearchResponse
  serviceOrder: ServiceOrder
  serviceOrders: PaginatedServiceOrdersResponse
  settings: Settings
  shipment: Shipment
  shipments: PaginatedShipmentsResponse
  staffUsers: Array<User>
  stocktakingTransactions: Array<WarehouseBatchTransactionChange>
  store: Store
  stores: Array<Store>
  systemReports: Array<Report>
  user: User
  userAvailableRoles: Array<Scalars['String']['output']>
  userPermissionsById: UserPermissions
  userPermissionsByRole: UserPermissions
  users: PaginatedUsersResponse
  warehouse: Warehouse
  warehouseBatch?: Maybe<WarehouseBatch>
  warehouseBatchItems: PaginatedWarehouseBatchItemsResponse
  warehouseBatchStatus: WarehouseBatchStatus
  /** Get batches for a specific warehouse */
  warehouseBatches: PaginatedWarehouseBatchesResponse
  warehouseItems: PaginatedWarehouseItemsResponse
}

export type QueryAccessLogsArgs = {
  actor?: InputMaybe<Scalars['String']['input']>
  dateFrom: Scalars['UnixDate']['input']
  dateTo: Scalars['UnixDate']['input']
  next?: InputMaybe<Scalars['String']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  source?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
  type?: InputMaybe<Scalars['String']['input']>
}

export type QueryAllCashRegistersArgs = {
  includenotregistered?: InputMaybe<Scalars['Boolean']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<GetRegistersSort>
  start?: InputMaybe<Scalars['Int']['input']>
}

export type QueryAllCustomAttributesArgs = {
  customAttributeSiteId?: InputMaybe<Scalars['Int']['input']>
}

export type QueryAllOpenWarehouseBatchesArgs = {
  transactiontype?: InputMaybe<WarehouseBatchTransaction>
}

export type QueryAllProductWarehouseItemsArgs = {
  skus: Array<Scalars['String']['input']>
  warehouseids?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type QueryAllReasonCodesArgs = {
  type?: InputMaybe<Array<ReasonCodeType>>
}

export type QueryAllReplenishmentItemsArgs = {
  skus?: InputMaybe<Array<Scalars['String']['input']>>
  warehouseFromId: Scalars['Int']['input']
  warehouseToId: Scalars['Int']['input']
}

export type QueryAllShipmentActionsArgs = {
  shipmentId: Scalars['String']['input']
}

export type QueryAllWarehouseBatchesArgs = {
  batchState?: InputMaybe<WarehouseBatchState>
  num?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
  transactionType?: InputMaybe<WarehouseBatchTransaction>
  warehouseid?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type QueryAllWarehousesArgs = {
  sort?: InputMaybe<GetWarehousesSort>
  storeid?: InputMaybe<Scalars['Int']['input']>
}

export type QueryAuthenticateOidcArgs = {
  code: Scalars['String']['input']
  state: Scalars['String']['input']
}

export type QueryCampaignArgs = {
  voucherid?: InputMaybe<Scalars['Int']['input']>
}

export type QueryCampaignsArgs = {
  num?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
}

export type QueryCashRegisterArgs = {
  id: Scalars['String']['input']
}

export type QueryCashRegistersArgs = {
  includenotregistered?: InputMaybe<Scalars['Boolean']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<GetRegistersSort>
  start?: InputMaybe<Scalars['Int']['input']>
}

export type QueryCategoriesArgs = {
  categorySiteId?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
}

export type QueryConvertShipmentPackageItemsCsvArgs = {
  csv: Scalars['String']['input']
}

export type QueryConvertWarehouseBatchItemsCsvArgs = {
  csv: Scalars['String']['input']
}

export type QueryCustomAttributeArgs = {
  id: Scalars['String']['input']
}

export type QueryCustomAttributesArgs = {
  num?: InputMaybe<Scalars['Int']['input']>
  siteId?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
}

export type QueryDashboardArgs = {
  id: Scalars['String']['input']
}

export type QueryDashboardConfigurationArgs = {
  storeId?: InputMaybe<Scalars['String']['input']>
}

export type QueryDashboardDataArgs = {
  dashboardId: Scalars['String']['input']
  storeId: Scalars['String']['input']
  timeZone: Scalars['String']['input']
  timestamp: Scalars['Int']['input']
  userIdStaff: Scalars['String']['input']
}

export type QueryGetPaymentTypeArgs = {
  id: Scalars['Float']['input']
}

export type QueryGetShipmentV2Args = {
  shipmentId: Scalars['Int']['input']
}

export type QueryGetShipmentsV2Args = {
  anyWarehouseid?: InputMaybe<Array<Scalars['Int']['input']>>
  archived?: InputMaybe<ArchivedFilterTypeV2>
  num?: InputMaybe<Scalars['Int']['input']>
  receiverWarehouseid?: InputMaybe<Array<Scalars['Int']['input']>>
  senderWarehouseid?: InputMaybe<Array<Scalars['Int']['input']>>
  shipmentid?: InputMaybe<Array<Scalars['Int']['input']>>
  start?: InputMaybe<Scalars['Int']['input']>
}

export type QueryGetUserTagsArgs = {
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>
}

export type QueryInfluencerCodeArgs = {
  code: Scalars['String']['input']
}

export type QueryInfluencerCodesArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  code?: InputMaybe<Scalars['String']['input']>
  dateendFrom?: InputMaybe<Scalars['UnixDate']['input']>
  dateendTo?: InputMaybe<Scalars['UnixDate']['input']>
  datelastusedFrom?: InputMaybe<Scalars['UnixDate']['input']>
  datelastusedTo?: InputMaybe<Scalars['UnixDate']['input']>
  datestartFrom?: InputMaybe<Scalars['UnixDate']['input']>
  datestartTo?: InputMaybe<Scalars['UnixDate']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  redeemable?: InputMaybe<Scalars['Boolean']['input']>
  sort?: InputMaybe<GetInfluencerCodesSort>
  start?: InputMaybe<Scalars['Int']['input']>
  vouchername?: InputMaybe<Scalars['String']['input']>
  voucherpassword?: InputMaybe<Scalars['String']['input']>
}

export type QueryJournalEntriesArgs = {
  dateFrom?: InputMaybe<Scalars['UnixDate']['input']>
  dateTo?: InputMaybe<Scalars['UnixDate']['input']>
  dateType?: InputMaybe<JournalFilterDateType>
  next?: InputMaybe<Scalars['String']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  registerIds?: InputMaybe<Array<Scalars['String']['input']>>
  start?: InputMaybe<Scalars['Int']['input']>
  storeIds?: InputMaybe<Array<Scalars['Int']['input']>>
  type?: InputMaybe<JournalEntryType>
}

export type QueryLoginOptionArgs = {
  optionId: Scalars['String']['input']
}

export type QueryManufacturerArgs = {
  id: Scalars['Float']['input']
}

export type QueryManufacturersArgs = {
  num?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
}

export type QueryMfaCodeArgs = {
  accountId: Scalars['Int']['input']
  email: Scalars['String']['input']
  mfaId?: InputMaybe<Scalars['String']['input']>
}

export type QueryOidcAccountIdArgs = {
  state: Scalars['String']['input']
}

export type QueryOrderArgs = {
  orderId: Scalars['Int']['input']
}

export type QueryOrdersArgs = {
  creditorderid?: InputMaybe<Scalars['Int']['input']>
  externalid?: InputMaybe<Array<Scalars['String']['input']>>
  num?: InputMaybe<Scalars['Int']['input']>
  orderdatefrom?: InputMaybe<Scalars['UnixDate']['input']>
  orderdateto?: InputMaybe<Scalars['UnixDate']['input']>
  orderid?: InputMaybe<Array<Scalars['Int']['input']>>
  orderstate?: InputMaybe<Array<OrderState>>
  ordertype?: InputMaybe<Array<OrderType>>
  paymentstate?: InputMaybe<Array<PaymentState>>
  receiptid?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<GetOrdersSort>
  start?: InputMaybe<Scalars['Int']['input']>
  warehouseid?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type QueryPluginArgs = {
  id: PluginId
}

export type QueryPriceListArgs = {
  id: Scalars['Int']['input']
}

export type QueryPriceListsArgs = {
  num?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
}

export type QueryProductArgs = {
  productId?: InputMaybe<Scalars['Int']['input']>
  productSiteId?: InputMaybe<Scalars['Int']['input']>
}

export type QueryProductGroupArgs = {
  id: Scalars['Int']['input']
}

export type QueryProductGroupsArgs = {
  num?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
}

export type QueryProductVariantsArgs = {
  productSiteId?: InputMaybe<Scalars['Int']['input']>
  productid?: InputMaybe<Scalars['Int']['input']>
}

export type QueryProductWarehouseBatchesArgs = {
  category?: InputMaybe<Array<Scalars['Int']['input']>>
  inventoryState?: InputMaybe<InventoryItemBatchState>
  manufacturer?: InputMaybe<Array<Scalars['Int']['input']>>
  num?: InputMaybe<Scalars['Int']['input']>
  productState?: InputMaybe<InventoryProductState>
  productgroup?: InputMaybe<Array<Scalars['Int']['input']>>
  searchtext?: InputMaybe<Scalars['String']['input']>
  siteId?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<GetInventoryBatchesSort>
  start?: InputMaybe<Scalars['Int']['input']>
  warehousebatchid: Scalars['Int']['input']
  warehouseid: Scalars['Int']['input']
}

export type QueryProductWarehouseItemsArgs = {
  category?: InputMaybe<Array<Scalars['Int']['input']>>
  manufacturer?: InputMaybe<Array<Scalars['Int']['input']>>
  num?: InputMaybe<Scalars['Int']['input']>
  productState?: InputMaybe<InventoryProductState>
  productgroup?: InputMaybe<Array<Scalars['Int']['input']>>
  searchtext?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<GetInventoryProductsSort>
  start?: InputMaybe<Scalars['Int']['input']>
  warehouseid: Scalars['Int']['input']
}

export type QueryProductsArgs = {
  campaignIds?: InputMaybe<Array<Scalars['Int']['input']>>
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>
  includeVariants?: InputMaybe<Scalars['Boolean']['input']>
  manufacturerIds?: InputMaybe<Array<Scalars['Int']['input']>>
  num?: InputMaybe<Scalars['Int']['input']>
  productGroupIds?: InputMaybe<Array<Scalars['Int']['input']>>
  productGroupType?: InputMaybe<ProductGroupType>
  productSiteId?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  skus?: InputMaybe<Array<Scalars['String']['input']>>
  sort?: InputMaybe<GetProductsSort>
  start?: InputMaybe<Scalars['Int']['input']>
}

export type QueryPublicLoginOptionsArgs = {
  accountId: Scalars['Int']['input']
}

export type QueryPurchasePriceListArgs = {
  id: Scalars['String']['input']
}

export type QueryPurchasePriceListsArgs = {
  next?: InputMaybe<Scalars['String']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
}

export type QueryReasonCodeArgs = {
  id: Scalars['Int']['input']
}

export type QueryReplenishmentItemsArgs = {
  next?: InputMaybe<Scalars['String']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  skus?: InputMaybe<Array<Scalars['String']['input']>>
  sort?: InputMaybe<GetReplenishmentItemsSort>
  start?: InputMaybe<Scalars['Int']['input']>
  warehouseFromId: Scalars['Int']['input']
  warehouseToId: Scalars['Int']['input']
}

export type QueryReportArgs = {
  reportId: Scalars['Float']['input']
}

export type QueryReportJobArgs = {
  jobId: Scalars['Float']['input']
}

export type QueryReportJobsArgs = {
  reportId: Scalars['Float']['input']
}

export type QueryReportResultsArgs = {
  jobId: Scalars['Float']['input']
}

export type QueryReportsArgs = {
  eshopid?: InputMaybe<Scalars['Int']['input']>
  fields?: InputMaybe<Array<Scalars['String']['input']>>
  num?: InputMaybe<Scalars['Int']['input']>
  reportid?: InputMaybe<Array<Scalars['Int']['input']>>
  reporttype?: InputMaybe<Array<ReportType>>
  searchtext?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<GetReportListSort>
  start?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySalesTaxArgs = {
  id: Scalars['Int']['input']
}

export type QuerySalesTaxGroupArgs = {
  id: Scalars['Int']['input']
}

export type QuerySalesTaxGroupsArgs = {
  num?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySalesTaxesArgs = {
  num?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchOrdersArgs = {
  creditorderid?: InputMaybe<Scalars['Int']['input']>
  crm_customer_id?: InputMaybe<Array<Scalars['String']['input']>>
  crm_member_number?: InputMaybe<Array<Scalars['String']['input']>>
  date_from?: InputMaybe<Scalars['UnixDate']['input']>
  date_to?: InputMaybe<Scalars['UnixDate']['input']>
  email?: InputMaybe<Array<Scalars['String']['input']>>
  externalid?: InputMaybe<Array<Scalars['String']['input']>>
  item_product_group_name?: InputMaybe<Array<Scalars['String']['input']>>
  item_reason_code_manual_discount?: InputMaybe<
    Array<Scalars['String']['input']>
  >
  item_reason_code_manual_price?: InputMaybe<Array<Scalars['String']['input']>>
  item_sku?: InputMaybe<Array<Scalars['String']['input']>>
  item_voucher_id?: InputMaybe<Array<Scalars['Int']['input']>>
  item_voucher_name?: InputMaybe<Array<Scalars['String']['input']>>
  next?: InputMaybe<Scalars['String']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  order_transaction_type?: InputMaybe<OrderLookupFilterOrderTransactionTypeEnum>
  order_type?: InputMaybe<OrderLookupFilterOrderTypeEnum>
  orderid?: InputMaybe<Array<Scalars['Int']['input']>>
  payment_amount_max?: InputMaybe<Scalars['Money']['input']>
  payment_amount_min?: InputMaybe<Scalars['Money']['input']>
  payment_card_issuer?: InputMaybe<Array<Scalars['String']['input']>>
  payment_card_masked_pan?: InputMaybe<Scalars['String']['input']>
  payment_method?: InputMaybe<Array<OrderLookupFilterPaymentMethodEnum>>
  payment_validated?: InputMaybe<Array<Scalars['String']['input']>>
  payment_virtual?: InputMaybe<Array<Scalars['String']['input']>>
  phone?: InputMaybe<Array<Scalars['String']['input']>>
  receiptid?: InputMaybe<Scalars['String']['input']>
  refund_initiation_mode?: InputMaybe<
    Array<OrderLookupFilterReturnLookupMethodEnum>
  >
  register_id?: InputMaybe<Array<Scalars['String']['input']>>
  site_id?: InputMaybe<Array<Scalars['Int']['input']>>
  sort?: InputMaybe<OrderLookupFilterSortEnum>
  staff_external_id?: InputMaybe<Array<Scalars['String']['input']>>
  staff_user_id?: InputMaybe<Array<Scalars['String']['input']>>
  staff_user_id_helpers?: InputMaybe<Array<Scalars['String']['input']>>
  start?: InputMaybe<Scalars['Int']['input']>
  store_id?: InputMaybe<Array<Scalars['String']['input']>>
  total_amount_max?: InputMaybe<Scalars['Money']['input']>
  total_amount_min?: InputMaybe<Scalars['Money']['input']>
  warehouse_id?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type QueryServiceOrderArgs = {
  serviceOrderId: Scalars['String']['input']
}

export type QueryServiceOrdersArgs = {
  next?: InputMaybe<Scalars['String']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<GetServiceOrdersSort>
  start?: InputMaybe<Scalars['Int']['input']>
  state?: InputMaybe<Array<ServiceOrderState>>
  store?: InputMaybe<Scalars['String']['input']>
}

export type QueryShipmentArgs = {
  shipmentId: Scalars['String']['input']
}

export type QueryShipmentsArgs = {
  destinationWarehouseId?: InputMaybe<Array<Scalars['Int']['input']>>
  includePackages?: InputMaybe<Scalars['Boolean']['input']>
  next?: InputMaybe<Scalars['String']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  originWarehouseId?: InputMaybe<Array<Scalars['Int']['input']>>
  shipmentState?: InputMaybe<Array<ShipmentState>>
  start?: InputMaybe<Scalars['Int']['input']>
  warehouseFilterMode?: InputMaybe<WarehouseFilterMode>
}

export type QueryStocktakingTransactionsArgs = {
  warehousebatchid: Scalars['Int']['input']
  warehouseid: Scalars['Int']['input']
}

export type QueryStoreArgs = {
  storeId: Scalars['Int']['input']
}

export type QueryStoresArgs = {
  siteId?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<GetStoresSort>
}

export type QueryUserArgs = {
  id: Scalars['String']['input']
}

export type QueryUserPermissionsByIdArgs = {
  targetUserId: Scalars['String']['input']
}

export type QueryUserPermissionsByRoleArgs = {
  targetUserRole?: InputMaybe<UserRole>
}

export type QueryUsersArgs = {
  customernumber?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  mobile?: InputMaybe<Scalars['String']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  personalid?: InputMaybe<Scalars['String']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<GetUsersSort>
  start?: InputMaybe<Scalars['Int']['input']>
  tags?: InputMaybe<Array<Scalars['String']['input']>>
  userid?: InputMaybe<Array<Scalars['String']['input']>>
}

export type QueryWarehouseArgs = {
  warehouseId: Scalars['Int']['input']
}

export type QueryWarehouseBatchArgs = {
  warehouseBatchId: Scalars['Int']['input']
  warehouseId: Scalars['Int']['input']
}

export type QueryWarehouseBatchItemsArgs = {
  num?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
  warehousebatchid: Scalars['Int']['input']
  warehouseid: Scalars['Int']['input']
}

export type QueryWarehouseBatchStatusArgs = {
  warehousebatchid: Scalars['Int']['input']
  warehouseid: Scalars['Int']['input']
}

export type QueryWarehouseBatchesArgs = {
  num?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
  transactiontype?: InputMaybe<WarehouseBatchTransaction>
  warehousebatchstate?: InputMaybe<WarehouseBatchState>
  warehouseid: Scalars['Int']['input']
}

export type QueryWarehouseItemsArgs = {
  datelastmodifiedfrom?: InputMaybe<Scalars['UnixDate']['input']>
  datelastmodifiedto?: InputMaybe<Scalars['UnixDate']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  sku?: InputMaybe<Array<Scalars['String']['input']>>
  start?: InputMaybe<Scalars['Int']['input']>
  warehouseid: Scalars['Int']['input']
}

export type ReasonCode = {
  __typename?: 'ReasonCode'
  name?: Maybe<Scalars['String']['output']>
  reasoncode: Scalars['String']['output']
  reasoncodeid: Scalars['Int']['output']
  type: ReasonCodeType
}

export type ReasonCodeInput = {
  name?: InputMaybe<Scalars['String']['input']>
  reasoncode: Scalars['String']['input']
  type: ReasonCodeType
}

export enum ReasonCodeType {
  CashIn = 'CashIn',
  CashOut = 'CashOut',
  ClosingCashDiff = 'ClosingCashDiff',
  ItemManualDiscount = 'ItemManualDiscount',
  ItemManualPrice = 'ItemManualPrice',
  ItemReclaim = 'ItemReclaim',
  ItemRefund = 'ItemRefund',
  ManualIn = 'ManualIn',
  ManualOut = 'ManualOut',
  Move = 'Move',
  PickupRejected = 'PickupRejected',
  Refund = 'Refund',
  Sales = 'Sales',
  SalesTaxExemption = 'SalesTaxExemption',
  ShipmentIn = 'ShipmentIn',
  ShipmentOut = 'ShipmentOut',
  ShipmentReceiveItems = 'ShipmentReceiveItems',
  StockTaking = 'StockTaking',
}

export type Report = {
  __typename?: 'Report'
  datemode?: Maybe<ReportDateMode>
  forcewarehousefilter?: Maybe<Scalars['Boolean']['output']>
  franchiseblocked?: Maybe<Scalars['Boolean']['output']>
  reportdefinition: ReportDefinition
  reportid: Scalars['Int']['output']
  reportname: Scalars['String']['output']
  reporttype: ReportType
}

export enum ReportDateMode {
  Default = 'Default',
  Month = 'Month',
  MonthPrev = 'MonthPrev',
  Today = 'Today',
  Week = 'Week',
  WeekPrev = 'WeekPrev',
  Year = 'Year',
  YearPrev = 'YearPrev',
  Yesterday = 'Yesterday',
}

export type ReportDefinition = {
  __typename?: 'ReportDefinition'
  filters: ReportDefinitionFilter
  groups: Array<ReportGroupingProperty>
  measures: Array<ReportMeasureProperty>
  sort: Array<ReportDefinitionSort>
}

export type ReportDefinitionFilter = {
  __typename?: 'ReportDefinitionFilter'
  creditNoteDateEnd?: Maybe<Scalars['UnixDate']['output']>
  creditNoteDateStart?: Maybe<Scalars['UnixDate']['output']>
  giftCardDateEnd?: Maybe<Scalars['UnixDate']['output']>
  giftCardDateStart?: Maybe<Scalars['UnixDate']['output']>
  giftCardType?: Maybe<GiftCardType>
  omniFulfillmentDateEnd?: Maybe<Scalars['UnixDate']['output']>
  omniFulfillmentDateStart?: Maybe<Scalars['UnixDate']['output']>
  omniFulfillmentStoreDelivery?: Maybe<Scalars['String']['output']>
  omniFulfillmentStoreSales?: Maybe<Scalars['String']['output']>
  orderDateEnd?: Maybe<Scalars['UnixDate']['output']>
  orderDateStart?: Maybe<Scalars['UnixDate']['output']>
  orderHasRegisterId?: Maybe<Scalars['Boolean']['output']>
  orderIsNegative?: Maybe<Scalars['Boolean']['output']>
  orderPaymentState?: Maybe<OrderPaymentState>
  orderRegisterId?: Maybe<Scalars['String']['output']>
  orderSearchText?: Maybe<Scalars['String']['output']>
  orderState?: Maybe<Array<ReportOrderState>>
  orderVoucherCode?: Maybe<Scalars['String']['output']>
  orderVoucherName?: Maybe<Scalars['String']['output']>
  orderWarehouseId?: Maybe<Scalars['Int']['output']>
  productManufacturerId?: Maybe<Scalars['Int']['output']>
  productProductGroupType?: Maybe<ReportProductGroupType>
  productVatId?: Maybe<Scalars['Int']['output']>
  salesTaxDateEnd?: Maybe<Scalars['UnixDate']['output']>
  salesTaxDateStart?: Maybe<Scalars['UnixDate']['output']>
  salesTaxGroupRegion?: Maybe<Scalars['String']['output']>
  salesTaxIsRefund?: Maybe<Scalars['Boolean']['output']>
  stockDateEnd?: Maybe<Scalars['UnixDate']['output']>
  warehouseCurrencyCode?: Maybe<Scalars['String']['output']>
  warehouseDateEnd?: Maybe<Scalars['UnixDate']['output']>
  warehouseDateStart?: Maybe<Scalars['UnixDate']['output']>
  warehouseId?: Maybe<Array<Scalars['Int']['output']>>
  warehouseSearchText?: Maybe<Scalars['String']['output']>
  warehouseTransactionType?: Maybe<WarehouseTransactionType>
}

export type ReportDefinitionFilterInput = {
  creditNoteDateEnd?: InputMaybe<Scalars['UnixDate']['input']>
  creditNoteDateStart?: InputMaybe<Scalars['UnixDate']['input']>
  giftCardDateEnd?: InputMaybe<Scalars['UnixDate']['input']>
  giftCardDateStart?: InputMaybe<Scalars['UnixDate']['input']>
  giftCardType?: InputMaybe<GiftCardType>
  omniFulfillmentDateEnd?: InputMaybe<Scalars['UnixDate']['input']>
  omniFulfillmentDateStart?: InputMaybe<Scalars['UnixDate']['input']>
  omniFulfillmentStoreDelivery?: InputMaybe<Scalars['String']['input']>
  omniFulfillmentStoreSales?: InputMaybe<Scalars['String']['input']>
  orderDateEnd?: InputMaybe<Scalars['UnixDate']['input']>
  orderDateStart?: InputMaybe<Scalars['UnixDate']['input']>
  orderHasRegisterId?: InputMaybe<Scalars['Boolean']['input']>
  orderIsNegative?: InputMaybe<Scalars['Boolean']['input']>
  orderPaymentState?: InputMaybe<OrderPaymentState>
  orderRegisterId?: InputMaybe<Scalars['String']['input']>
  orderSearchText?: InputMaybe<Scalars['String']['input']>
  orderState?: InputMaybe<Array<ReportOrderState>>
  orderVoucherCode?: InputMaybe<Scalars['String']['input']>
  orderVoucherName?: InputMaybe<Scalars['String']['input']>
  orderWarehouseId?: InputMaybe<Scalars['Int']['input']>
  productManufacturerId?: InputMaybe<Scalars['Int']['input']>
  productProductGroupType?: InputMaybe<ReportProductGroupType>
  productVatId?: InputMaybe<Scalars['Int']['input']>
  salesTaxDateEnd?: InputMaybe<Scalars['UnixDate']['input']>
  salesTaxDateStart?: InputMaybe<Scalars['UnixDate']['input']>
  salesTaxGroupRegion?: InputMaybe<Scalars['String']['input']>
  salesTaxIsRefund?: InputMaybe<Scalars['Boolean']['input']>
  stockDateEnd?: InputMaybe<Scalars['UnixDate']['input']>
  warehouseCurrencyCode?: InputMaybe<Scalars['String']['input']>
  warehouseDateEnd?: InputMaybe<Scalars['UnixDate']['input']>
  warehouseDateStart?: InputMaybe<Scalars['UnixDate']['input']>
  warehouseId?: InputMaybe<Array<Scalars['Int']['input']>>
  warehouseSearchText?: InputMaybe<Scalars['String']['input']>
  warehouseTransactionType?: InputMaybe<WarehouseTransactionType>
}

export enum ReportDefinitionFilterKey {
  GroupCreditNoteCode = 'GroupCreditNoteCode',
  GroupCreditNoteYear = 'GroupCreditNoteYear',
  GroupCreditNoteYearMonth = 'GroupCreditNoteYearMonth',
  GroupCreditNoteYearMonthDay = 'GroupCreditNoteYearMonthDay',
  GroupCreditNoteYearWeek = 'GroupCreditNoteYearWeek',
  GroupGiftCardCode = 'GroupGiftCardCode',
  GroupGiftCardMerchantReference = 'GroupGiftCardMerchantReference',
  GroupGiftCardStaffReference = 'GroupGiftCardStaffReference',
  GroupGiftCardType = 'GroupGiftCardType',
  GroupGiftCardYear = 'GroupGiftCardYear',
  GroupGiftCardYearMonth = 'GroupGiftCardYearMonth',
  GroupGiftCardYearMonthDay = 'GroupGiftCardYearMonthDay',
  GroupGiftCardYearWeek = 'GroupGiftCardYearWeek',
  GroupOrderCheckoutTypeName = 'GroupOrderCheckoutTypeName',
  GroupOrderCompany = 'GroupOrderCompany',
  GroupOrderDeliveryCity = 'GroupOrderDeliveryCity',
  GroupOrderDeliveryCountryName = 'GroupOrderDeliveryCountryName',
  GroupOrderDeliveryState = 'GroupOrderDeliveryState',
  GroupOrderDeliveryTypeName = 'GroupOrderDeliveryTypeName',
  GroupOrderEmail = 'GroupOrderEmail',
  GroupOrderHasRegisterId = 'GroupOrderHasRegisterId',
  GroupOrderHour = 'GroupOrderHour',
  GroupOrderId = 'GroupOrderId',
  GroupOrderInvoiceCity = 'GroupOrderInvoiceCity',
  GroupOrderInvoiceCountryName = 'GroupOrderInvoiceCountryName',
  GroupOrderInvoiceState = 'GroupOrderInvoiceState',
  GroupOrderNameFull = 'GroupOrderNameFull',
  GroupOrderPaymentState = 'GroupOrderPaymentState',
  GroupOrderRegisterId = 'GroupOrderRegisterId',
  GroupOrderState = 'GroupOrderState',
  GroupOrderUserStaffNameFull = 'GroupOrderUserStaffNameFull',
  GroupOrderWarehouseName = 'GroupOrderWarehouseName',
  GroupOrderYear = 'GroupOrderYear',
  GroupOrderYearMonth = 'GroupOrderYearMonth',
  GroupOrderYearMonthDay = 'GroupOrderYearMonthDay',
  GroupOrderYearWeek = 'GroupOrderYearWeek',
  GroupProductCategoryName = 'GroupProductCategoryName',
  GroupProductManufacturerName = 'GroupProductManufacturerName',
  GroupProductName = 'GroupProductName',
  GroupProductNameAndVariant = 'GroupProductNameAndVariant',
  GroupProductProductGroupName = 'GroupProductProductGroupName',
  GroupProductProductGroupType = 'GroupProductProductGroupType',
  GroupProductReasonCodeManualDiscount = 'GroupProductReasonCodeManualDiscount',
  GroupProductReasonCodeManualPrice = 'GroupProductReasonCodeManualPrice',
  GroupProductReasonCodeReturn = 'GroupProductReasonCodeReturn',
  GroupProductSku = 'GroupProductSku',
  GroupProductSkuManufacturer = 'GroupProductSkuManufacturer',
  GroupProductVat = 'GroupProductVat',
  GroupSalesTaxGroupRegion = 'GroupSalesTaxGroupRegion',
  GroupSalesTaxNameCodeExternalId = 'GroupSalesTaxNameCodeExternalId',
  GroupSalesTaxValue = 'GroupSalesTaxValue',
  GroupWarehouseName = 'GroupWarehouseName',
  GroupWarehouseTransactionDesc = 'GroupWarehouseTransactionDesc',
  GroupWarehouseTransactionId = 'GroupWarehouseTransactionId',
  GroupWarehouseTransactionReasonCode = 'GroupWarehouseTransactionReasonCode',
  GroupWarehouseTransactionType = 'GroupWarehouseTransactionType',
  GroupWarehouseYear = 'GroupWarehouseYear',
  GroupWarehouseYearMonth = 'GroupWarehouseYearMonth',
  GroupWarehouseYearMonthDay = 'GroupWarehouseYearMonthDay',
  GroupWarehouseYearWeek = 'GroupWarehouseYearWeek',
  MeasureCreditNoteCreatedAmount = 'MeasureCreditNoteCreatedAmount',
  MeasureCreditNoteCreatedCount = 'MeasureCreditNoteCreatedCount',
  MeasureCreditNoteExpiresAmount = 'MeasureCreditNoteExpiresAmount',
  MeasureCreditNoteExpiresCount = 'MeasureCreditNoteExpiresCount',
  MeasureCreditNoteUsedAmount = 'MeasureCreditNoteUsedAmount',
  MeasureCreditNoteUsedCount = 'MeasureCreditNoteUsedCount',
  MeasureGiftCardCreatedCount = 'MeasureGiftCardCreatedCount',
  MeasureGiftCardExpiresAmount = 'MeasureGiftCardExpiresAmount',
  MeasureGiftCardExpiresCount = 'MeasureGiftCardExpiresCount',
  MeasureGiftCardSoldAmount = 'MeasureGiftCardSoldAmount',
  MeasureGiftCardUsedAmount = 'MeasureGiftCardUsedAmount',
  MeasureOrderCheckoutNet = 'MeasureOrderCheckoutNet',
  MeasureOrderCheckoutVat = 'MeasureOrderCheckoutVat',
  MeasureOrderCount = 'MeasureOrderCount',
  MeasureOrderCountFirst = 'MeasureOrderCountFirst',
  MeasureOrderCustomerCount = 'MeasureOrderCustomerCount',
  MeasureOrderDeliveryNet = 'MeasureOrderDeliveryNet',
  MeasureOrderDeliveryVat = 'MeasureOrderDeliveryVat',
  MeasureOrderFinalNet = 'MeasureOrderFinalNet',
  MeasureOrderFinalVat = 'MeasureOrderFinalVat',
  MeasureOrderTotalGrossAll = 'MeasureOrderTotalGrossAll',
  MeasureOrderTotalGrossAllAvg = 'MeasureOrderTotalGrossAllAvg',
  MeasureOrderTotalGrossCheckout = 'MeasureOrderTotalGrossCheckout',
  MeasureOrderTotalGrossDelivery = 'MeasureOrderTotalGrossDelivery',
  MeasureOrderTotalGrossRoundoff = 'MeasureOrderTotalGrossRoundoff',
  MeasureOrderTotalNet = 'MeasureOrderTotalNet',
  MeasureOrderTotalQuantity = 'MeasureOrderTotalQuantity',
  MeasureOrderTotalQuantityAvg = 'MeasureOrderTotalQuantityAvg',
  MeasureOrderTotalVat = 'MeasureOrderTotalVat',
  MeasureOrderWithCustomerCount = 'MeasureOrderWithCustomerCount',
  MeasureProductDeliveredGross = 'MeasureProductDeliveredGross',
  MeasureProductDeliveredNet = 'MeasureProductDeliveredNet',
  MeasureProductDeliveredNetMargin = 'MeasureProductDeliveredNetMargin',
  MeasureProductDeliveredNetMarginRatio = 'MeasureProductDeliveredNetMarginRatio',
  MeasureProductDeliveredStockValue = 'MeasureProductDeliveredStockValue',
  MeasureProductDiscountNet = 'MeasureProductDiscountNet',
  MeasureProductItemTotalGross = 'MeasureProductItemTotalGross',
  MeasureProductItemTotalNet = 'MeasureProductItemTotalNet',
  MeasureProductItemTotalVat = 'MeasureProductItemTotalVat',
  MeasureProductOrderCount = 'MeasureProductOrderCount',
  MeasureProductPriceInTotalNet = 'MeasureProductPriceInTotalNet',
  MeasureProductQuantity = 'MeasureProductQuantity',
  MeasureProductQuantityAvg = 'MeasureProductQuantityAvg',
  MeasureProductTotalGrossAvg = 'MeasureProductTotalGrossAvg',
  MeasureProductTotalNetAvg = 'MeasureProductTotalNetAvg',
  MeasureSalesTaxTax = 'MeasureSalesTaxTax',
  MeasureSalesTaxTotal = 'MeasureSalesTaxTotal',
  MeasureStockTotalQuantity = 'MeasureStockTotalQuantity',
  MeasureStockTotalValue = 'MeasureStockTotalValue',
  MeasureWarehouseQuantity = 'MeasureWarehouseQuantity',
  MeasureWarehouseValue = 'MeasureWarehouseValue',
}

export type ReportDefinitionInput = {
  filters: ReportDefinitionFilterInput
  groups: Array<ReportGroupingProperty>
  measures: Array<ReportMeasureProperty>
  sort: Array<ReportDefinitionSortInput>
}

export type ReportDefinitionSort = {
  __typename?: 'ReportDefinitionSort'
  asc: Scalars['Boolean']['output']
  key: ReportDefinitionFilterKey
}

export type ReportDefinitionSortInput = {
  asc: Scalars['Boolean']['input']
  key: ReportDefinitionFilterKey
}

export enum ReportGroupingProperty {
  GroupCreditNoteCode = 'GroupCreditNoteCode',
  GroupCreditNoteYear = 'GroupCreditNoteYear',
  GroupCreditNoteYearMonth = 'GroupCreditNoteYearMonth',
  GroupCreditNoteYearMonthDay = 'GroupCreditNoteYearMonthDay',
  GroupCreditNoteYearWeek = 'GroupCreditNoteYearWeek',
  GroupGiftCardCode = 'GroupGiftCardCode',
  GroupGiftCardMerchantReference = 'GroupGiftCardMerchantReference',
  GroupGiftCardStaffReference = 'GroupGiftCardStaffReference',
  GroupGiftCardType = 'GroupGiftCardType',
  GroupGiftCardYear = 'GroupGiftCardYear',
  GroupGiftCardYearMonth = 'GroupGiftCardYearMonth',
  GroupGiftCardYearMonthDay = 'GroupGiftCardYearMonthDay',
  GroupGiftCardYearWeek = 'GroupGiftCardYearWeek',
  GroupOrderCheckoutTypeName = 'GroupOrderCheckoutTypeName',
  GroupOrderCompany = 'GroupOrderCompany',
  GroupOrderDeliveryCity = 'GroupOrderDeliveryCity',
  GroupOrderDeliveryCountryName = 'GroupOrderDeliveryCountryName',
  GroupOrderDeliveryState = 'GroupOrderDeliveryState',
  GroupOrderDeliveryTypeName = 'GroupOrderDeliveryTypeName',
  GroupOrderEmail = 'GroupOrderEmail',
  GroupOrderHasRegisterId = 'GroupOrderHasRegisterId',
  GroupOrderHour = 'GroupOrderHour',
  GroupOrderId = 'GroupOrderId',
  GroupOrderInvoiceCity = 'GroupOrderInvoiceCity',
  GroupOrderInvoiceCountryName = 'GroupOrderInvoiceCountryName',
  GroupOrderInvoiceState = 'GroupOrderInvoiceState',
  GroupOrderNameFull = 'GroupOrderNameFull',
  GroupOrderPaymentState = 'GroupOrderPaymentState',
  GroupOrderRegisterId = 'GroupOrderRegisterId',
  GroupOrderState = 'GroupOrderState',
  GroupOrderUserStaffNameFull = 'GroupOrderUserStaffNameFull',
  GroupOrderWarehouseName = 'GroupOrderWarehouseName',
  GroupOrderYear = 'GroupOrderYear',
  GroupOrderYearMonth = 'GroupOrderYearMonth',
  GroupOrderYearMonthDay = 'GroupOrderYearMonthDay',
  GroupOrderYearWeek = 'GroupOrderYearWeek',
  GroupProductCategoryName = 'GroupProductCategoryName',
  GroupProductManufacturerName = 'GroupProductManufacturerName',
  GroupProductName = 'GroupProductName',
  GroupProductNameAndVariant = 'GroupProductNameAndVariant',
  GroupProductProductGroupName = 'GroupProductProductGroupName',
  GroupProductProductGroupType = 'GroupProductProductGroupType',
  GroupProductReasonCodeManualDiscount = 'GroupProductReasonCodeManualDiscount',
  GroupProductReasonCodeManualPrice = 'GroupProductReasonCodeManualPrice',
  GroupProductReasonCodeReturn = 'GroupProductReasonCodeReturn',
  GroupProductSku = 'GroupProductSku',
  GroupProductSkuManufacturer = 'GroupProductSkuManufacturer',
  GroupProductVat = 'GroupProductVat',
  GroupSalesTaxGroupRegion = 'GroupSalesTaxGroupRegion',
  GroupSalesTaxNameCodeExternalId = 'GroupSalesTaxNameCodeExternalId',
  GroupSalesTaxValue = 'GroupSalesTaxValue',
  GroupWarehouseName = 'GroupWarehouseName',
  GroupWarehouseTransactionDesc = 'GroupWarehouseTransactionDesc',
  GroupWarehouseTransactionId = 'GroupWarehouseTransactionId',
  GroupWarehouseTransactionReasonCode = 'GroupWarehouseTransactionReasonCode',
  GroupWarehouseTransactionType = 'GroupWarehouseTransactionType',
  GroupWarehouseYear = 'GroupWarehouseYear',
  GroupWarehouseYearMonth = 'GroupWarehouseYearMonth',
  GroupWarehouseYearMonthDay = 'GroupWarehouseYearMonthDay',
  GroupWarehouseYearWeek = 'GroupWarehouseYearWeek',
}

export type ReportInput = {
  datemode?: InputMaybe<ReportDateMode>
  forcewarehousefilter?: InputMaybe<Scalars['Boolean']['input']>
  franchiseblocked?: InputMaybe<Scalars['Boolean']['input']>
  reportdefinition: ReportDefinitionInput
  reportid: Scalars['Int']['input']
  reportname: Scalars['String']['input']
  reporttype: ReportType
}

export type ReportJob = {
  __typename?: 'ReportJob'
  commoneshopid?: Maybe<Scalars['Int']['output']>
  datecreated: Scalars['UnixDate']['output']
  dateexecuted?: Maybe<Scalars['UnixDate']['output']>
  eshopid: Scalars['Int']['output']
  jobid: Scalars['Int']['output']
  jobname?: Maybe<Scalars['String']['output']>
  jobstate: ReportJobState
  langid: ReportJobLangidEnum
  owner?: Maybe<Scalars['String']['output']>
  reportdefinition?: Maybe<ReportDefinition>
  reportid?: Maybe<Scalars['Int']['output']>
  reporttype?: Maybe<ReportType>
  timezone: Scalars['String']['output']
}

export type ReportJobInput = {
  commoneshopid?: InputMaybe<Scalars['Int']['input']>
  eshopid?: InputMaybe<Scalars['Int']['input']>
  jobname?: InputMaybe<Scalars['String']['input']>
  langid: ReportJobLangidEnum
  owner?: InputMaybe<Scalars['String']['input']>
  reportdefinition?: InputMaybe<ReportDefinitionInput>
  reportid?: InputMaybe<Scalars['Int']['input']>
  reporttype?: InputMaybe<ReportType>
  timezone: Scalars['String']['input']
}

export enum ReportJobLangidEnum {
  En = 'En',
  Sv = 'Sv',
}

export type ReportJobListResponse = {
  __typename?: 'ReportJobListResponse'
  items?: Maybe<Array<ReportJob>>
  totalcount?: Maybe<Scalars['Int']['output']>
}

export enum ReportJobState {
  Failed = 'Failed',
  Finished = 'Finished',
  InProgress = 'InProgress',
  Pending = 'Pending',
}

export enum ReportMeasureProperty {
  MeasureCreditNoteCreatedAmount = 'MeasureCreditNoteCreatedAmount',
  MeasureCreditNoteCreatedCount = 'MeasureCreditNoteCreatedCount',
  MeasureCreditNoteExpiresAmount = 'MeasureCreditNoteExpiresAmount',
  MeasureCreditNoteExpiresCount = 'MeasureCreditNoteExpiresCount',
  MeasureCreditNoteUsedAmount = 'MeasureCreditNoteUsedAmount',
  MeasureCreditNoteUsedCount = 'MeasureCreditNoteUsedCount',
  MeasureGiftCardCreatedCount = 'MeasureGiftCardCreatedCount',
  MeasureGiftCardExpiresAmount = 'MeasureGiftCardExpiresAmount',
  MeasureGiftCardExpiresCount = 'MeasureGiftCardExpiresCount',
  MeasureGiftCardSoldAmount = 'MeasureGiftCardSoldAmount',
  MeasureGiftCardUsedAmount = 'MeasureGiftCardUsedAmount',
  MeasureOmniFulfillmentDeliveryDate = 'MeasureOmniFulfillmentDeliveryDate',
  MeasureOmniFulfillmentEshopIdDelivery = 'MeasureOmniFulfillmentEshopIdDelivery',
  MeasureOmniFulfillmentEshopIdSales = 'MeasureOmniFulfillmentEshopIdSales',
  MeasureOmniFulfillmentEshopNameDelivery = 'MeasureOmniFulfillmentEshopNameDelivery',
  MeasureOmniFulfillmentEshopNameSales = 'MeasureOmniFulfillmentEshopNameSales',
  MeasureOmniFulfillmentMoneyNet = 'MeasureOmniFulfillmentMoneyNet',
  MeasureOmniFulfillmentMoneyPriceFifo = 'MeasureOmniFulfillmentMoneyPriceFifo',
  MeasureOmniFulfillmentMoneyPriceInTotalNet = 'MeasureOmniFulfillmentMoneyPriceInTotalNet',
  MeasureOmniFulfillmentMoneyTotal = 'MeasureOmniFulfillmentMoneyTotal',
  MeasureOmniFulfillmentMoneyVat = 'MeasureOmniFulfillmentMoneyVat',
  MeasureOmniFulfillmentOrderDate = 'MeasureOmniFulfillmentOrderDate',
  MeasureOmniFulfillmentOrderId = 'MeasureOmniFulfillmentOrderId',
  MeasureOmniFulfillmentProductName = 'MeasureOmniFulfillmentProductName',
  MeasureOmniFulfillmentProductVariant = 'MeasureOmniFulfillmentProductVariant',
  MeasureOmniFulfillmentQuantity = 'MeasureOmniFulfillmentQuantity',
  MeasureOmniFulfillmentQuantityDelivered = 'MeasureOmniFulfillmentQuantityDelivered',
  MeasureOmniFulfillmentSku = 'MeasureOmniFulfillmentSku',
  MeasureOmniFulfillmentStoreIdDelivery = 'MeasureOmniFulfillmentStoreIdDelivery',
  MeasureOmniFulfillmentStoreIdSales = 'MeasureOmniFulfillmentStoreIdSales',
  MeasureOmniFulfillmentStoreNameDelivery = 'MeasureOmniFulfillmentStoreNameDelivery',
  MeasureOmniFulfillmentStoreNameSales = 'MeasureOmniFulfillmentStoreNameSales',
  MeasureOmniFulfillmentWarehouseTransactionId = 'MeasureOmniFulfillmentWarehouseTransactionId',
  MeasureOrderCheckoutNet = 'MeasureOrderCheckoutNet',
  MeasureOrderCheckoutVat = 'MeasureOrderCheckoutVat',
  MeasureOrderCount = 'MeasureOrderCount',
  MeasureOrderCountFirst = 'MeasureOrderCountFirst',
  MeasureOrderCustomerCount = 'MeasureOrderCustomerCount',
  MeasureOrderDeliveryNet = 'MeasureOrderDeliveryNet',
  MeasureOrderDeliveryVat = 'MeasureOrderDeliveryVat',
  MeasureOrderFinalNet = 'MeasureOrderFinalNet',
  MeasureOrderFinalVat = 'MeasureOrderFinalVat',
  MeasureOrderTotalGrossAll = 'MeasureOrderTotalGrossAll',
  MeasureOrderTotalGrossAllAvg = 'MeasureOrderTotalGrossAllAvg',
  MeasureOrderTotalGrossCheckout = 'MeasureOrderTotalGrossCheckout',
  MeasureOrderTotalGrossDelivery = 'MeasureOrderTotalGrossDelivery',
  MeasureOrderTotalGrossRoundoff = 'MeasureOrderTotalGrossRoundoff',
  MeasureOrderTotalNet = 'MeasureOrderTotalNet',
  MeasureOrderTotalQuantity = 'MeasureOrderTotalQuantity',
  MeasureOrderTotalQuantityAvg = 'MeasureOrderTotalQuantityAvg',
  MeasureOrderTotalVat = 'MeasureOrderTotalVat',
  MeasureOrderWithCustomerCount = 'MeasureOrderWithCustomerCount',
  MeasureProductDeliveredGross = 'MeasureProductDeliveredGross',
  MeasureProductDeliveredNet = 'MeasureProductDeliveredNet',
  MeasureProductDeliveredNetMargin = 'MeasureProductDeliveredNetMargin',
  MeasureProductDeliveredNetMarginRatio = 'MeasureProductDeliveredNetMarginRatio',
  MeasureProductDeliveredStockValue = 'MeasureProductDeliveredStockValue',
  MeasureProductDiscountNet = 'MeasureProductDiscountNet',
  MeasureProductItemTotalGross = 'MeasureProductItemTotalGross',
  MeasureProductItemTotalNet = 'MeasureProductItemTotalNet',
  MeasureProductItemTotalVat = 'MeasureProductItemTotalVat',
  MeasureProductOrderCount = 'MeasureProductOrderCount',
  MeasureProductPriceInTotalNet = 'MeasureProductPriceInTotalNet',
  MeasureProductQuantity = 'MeasureProductQuantity',
  MeasureProductQuantityAvg = 'MeasureProductQuantityAvg',
  MeasureProductTotalGrossAvg = 'MeasureProductTotalGrossAvg',
  MeasureProductTotalNetAvg = 'MeasureProductTotalNetAvg',
  MeasureSalesTaxTax = 'MeasureSalesTaxTax',
  MeasureSalesTaxTotal = 'MeasureSalesTaxTotal',
  MeasureStockTotalQuantity = 'MeasureStockTotalQuantity',
  MeasureStockTotalValue = 'MeasureStockTotalValue',
  MeasureWarehouseQuantity = 'MeasureWarehouseQuantity',
  MeasureWarehouseValue = 'MeasureWarehouseValue',
}

export enum ReportOrderState {
  Cancelled = 'Cancelled',
  Closed = 'Closed',
  Open = 'Open',
}

export enum ReportProductGroupType {
  NUMBER_10 = 'NUMBER_10',
  NUMBER_20 = 'NUMBER_20',
  NUMBER_30 = 'NUMBER_30',
}

export type ReportResultColumnsInner = {
  __typename?: 'ReportResultColumnsInner'
  key?: Maybe<Scalars['String']['output']>
  type?: Maybe<ReportResultItemValueDisplayType>
}

export enum ReportResultItemValueDisplayType {
  Decimalquantity = 'Decimalquantity',
  Double = 'Double',
  DoublePercentage = 'DoublePercentage',
  Integer = 'Integer',
  IntegerYearWeek = 'IntegerYearWeek',
  Money = 'Money',
  MoneyFixed = 'MoneyFixed',
  String = 'String',
  TimestampYear = 'TimestampYear',
  TimestampYearMonth = 'TimestampYearMonth',
  TimestampYearMonthDay = 'TimestampYearMonthDay',
  TimestampYearMonthDayVerbose = 'TimestampYearMonthDayVerbose',
}

export type ReportResults = {
  __typename?: 'ReportResults'
  columns: Array<ReportResultColumnsInner>
  rows: Array<Scalars['JSONObject']['output']>
  totals: Scalars['JSONObject']['output']
}

export enum ReportType {
  Creditnote = 'Creditnote',
  GiftCard = 'GiftCard',
  OmniFulfillment = 'OmniFulfillment',
  Order = 'Order',
  Product = 'Product',
  SalesTax = 'SalesTax',
  Stock = 'Stock',
  Warehouse = 'Warehouse',
}

export type RewardsSettings = {
  __typename?: 'RewardsSettings'
  api_token?: Maybe<Scalars['String']['output']>
  api_url: Scalars['String']['output']
}

export type SalesTax = {
  __typename?: 'SalesTax'
  code?: Maybe<Scalars['String']['output']>
  decimalvaluedefault: Scalars['Decimal']['output']
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  productgrouprules?: Maybe<Array<SalesTaxProductGroupRule>>
}

export type SalesTaxGroup = {
  __typename?: 'SalesTaxGroup'
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  region?: Maybe<Scalars['String']['output']>
  salestaxes: Array<SalesTax>
}

export type SalesTaxGroupInput = {
  name: Scalars['String']['input']
  region?: InputMaybe<Scalars['String']['input']>
  salestaxes: Array<SalesTaxGroupSalesTaxInput>
}

export type SalesTaxGroupSalesTaxInput = {
  salestaxid?: InputMaybe<Scalars['Int']['input']>
}

export type SalesTaxInput = {
  code?: InputMaybe<Scalars['String']['input']>
  decimalvaluedefault: Scalars['Decimal']['input']
  name: Scalars['String']['input']
  productgrouprules?: InputMaybe<Array<SalesTaxProductGroupRuleInput>>
}

export type SalesTaxProductGroupRule = {
  __typename?: 'SalesTaxProductGroupRule'
  decimalvalue: Scalars['Decimal']['output']
  externalid?: Maybe<Scalars['String']['output']>
  groupName?: Maybe<Scalars['String']['output']>
  moneymax?: Maybe<Scalars['Money']['output']>
  moneymin?: Maybe<Scalars['Money']['output']>
  type?: Maybe<SalesTaxProductGroupRuleType>
  vatid?: Maybe<Scalars['Int']['output']>
}

export type SalesTaxProductGroupRuleInput = {
  decimalvalue: Scalars['Decimal']['input']
  externalid?: InputMaybe<Scalars['String']['input']>
  moneymax?: InputMaybe<Scalars['Money']['input']>
  moneymin?: InputMaybe<Scalars['Money']['input']>
  type?: InputMaybe<SalesTaxProductGroupRuleType>
  vatid?: InputMaybe<Scalars['Int']['input']>
}

export enum SalesTaxProductGroupRuleType {
  EXEMPT = 'EXEMPT',
  OVER_MIN = 'OVER_MIN',
  TOTAL = 'TOTAL',
}

export type ServiceOrder = {
  __typename?: 'ServiceOrder'
  author: ServiceOrderUser
  created?: Maybe<Scalars['UnixDate']['output']>
  currency: Scalars['String']['output']
  customer: ServiceOrderCustomer
  id?: Maybe<Scalars['String']['output']>
  modified?: Maybe<Scalars['UnixDate']['output']>
  note?: Maybe<Scalars['String']['output']>
  owner: ServiceOrderUser
  product: ServiceOrderProduct
  revision?: Maybe<Scalars['Int']['output']>
  state?: Maybe<ServiceOrderState>
  store: ServiceOrderStore
  version?: Maybe<Scalars['String']['output']>
}

export type ServiceOrderCustomer = {
  __typename?: 'ServiceOrderCustomer'
  email?: Maybe<Scalars['String']['output']>
  first_name: Scalars['String']['output']
  id?: Maybe<Scalars['String']['output']>
  last_name?: Maybe<Scalars['String']['output']>
  mobile?: Maybe<Scalars['String']['output']>
}

export type ServiceOrderCustomerInput = {
  email?: InputMaybe<Scalars['String']['input']>
  first_name: Scalars['String']['input']
  last_name?: InputMaybe<Scalars['String']['input']>
  mobile?: InputMaybe<Scalars['String']['input']>
}

export type ServiceOrderItem = {
  __typename?: 'ServiceOrderItem'
  cost_share?: Maybe<Scalars['Float']['output']>
  name: Scalars['String']['output']
  note: Scalars['String']['output']
  quantity: Scalars['Int']['output']
  sku: Scalars['String']['output']
  unit_price?: Maybe<Scalars['String']['output']>
}

export type ServiceOrderItemInput = {
  cost_share?: InputMaybe<Scalars['Float']['input']>
  name: Scalars['String']['input']
  note: Scalars['String']['input']
  quantity: Scalars['Int']['input']
  sku: Scalars['String']['input']
  unit_price?: InputMaybe<Scalars['String']['input']>
}

export type ServiceOrderProduct = {
  __typename?: 'ServiceOrderProduct'
  id?: Maybe<Scalars['String']['output']>
  images?: Maybe<Array<Scalars['String']['output']>>
  name: Scalars['String']['output']
  serial?: Maybe<Scalars['String']['output']>
  services: Array<ServiceOrderItem>
  sku: Scalars['String']['output']
  totalAfterShare?: Maybe<Scalars['Float']['output']>
  totalBeforeShare?: Maybe<Scalars['Float']['output']>
}

export type ServiceOrderProductInput = {
  name: Scalars['String']['input']
  serial?: InputMaybe<Scalars['String']['input']>
  services: Array<ServiceOrderItemInput>
  sku: Scalars['String']['input']
}

export enum ServiceOrderState {
  Cancelled = 'Cancelled',
  Closed = 'Closed',
  InProgress = 'InProgress',
  New = 'New',
  ServiceDone = 'ServiceDone',
}

export type ServiceOrderStore = {
  __typename?: 'ServiceOrderStore'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type ServiceOrderStoreInput = {
  id: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type ServiceOrderUser = {
  __typename?: 'ServiceOrderUser'
  email: Scalars['String']['output']
  employee_id?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export type ServiceOrderUserInput = {
  email: Scalars['String']['input']
  employee_id?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type Settings = {
  __typename?: 'Settings'
  allow_store_manager_campaigns?: Maybe<Scalars['Boolean']['output']>
  allowed_sites_booking?: Maybe<Array<Scalars['Int']['output']>>
  allowed_sites_delivery?: Maybe<Array<Scalars['Int']['output']>>
  bank_bag_id_mode?: Maybe<SettingsBankBagIdModeEnum>
  cart_item_price_display_mode?: Maybe<SettingsCartItemPriceDisplayModeEnum>
  cart_print_pick_list_mode?: Maybe<SettingsCartPrintPickListModeEnum>
  cash_counting_mode?: Maybe<SettingsCashCountingModeEnum>
  commoneshopid?: Maybe<Scalars['Int']['output']>
  compress_variant_info?: Maybe<Scalars['Boolean']['output']>
  creditnoterequirespin?: Maybe<Scalars['Boolean']['output']>
  creditnotesalestype?: Maybe<SettingsCreditnotesalestypeEnum>
  creditnotevalidmonths?: Maybe<Scalars['Int']['output']>
  currency_rates?: Maybe<Array<CurrencyRate>>
  currencycode?: Maybe<Scalars['String']['output']>
  customattributes_details?: Maybe<Array<CustomAttributeMapping>>
  customattributes_in_store_replenishment?: Maybe<
    Array<Scalars['String']['output']>
  >
  customattributes_info?: Maybe<Array<CustomAttributeMapping>>
  customattributes_list?: Maybe<Array<CustomAttributeMapping>>
  customattributes_receipt?: Maybe<Array<CustomAttributeMapping>>
  dashboard_ignore_product_groups?: Maybe<Array<Scalars['Int']['output']>>
  doroundoff?: Maybe<Scalars['Boolean']['output']>
  emailfrom?: Maybe<Scalars['String']['output']>
  emailfromdisplay?: Maybe<Scalars['String']['output']>
  eshopid: Scalars['Int']['output']
  eshopname?: Maybe<Scalars['String']['output']>
  eshoptype?: Maybe<SettingsEshoptypeEnum>
  giftcardproductids_email?: Maybe<Array<Scalars['Int']['output']>>
  giftcardproductids_sms?: Maybe<Array<Scalars['Int']['output']>>
  giftcardrequirespin?: Maybe<Scalars['Boolean']['output']>
  giftcardsalestype?: Maybe<SettingsGiftcardsalestypeEnum>
  giftcardvalidmonths?: Maybe<Scalars['Int']['output']>
  limit_open_cash_drawer?: Maybe<Scalars['Boolean']['output']>
  mixed_delivery_disabled?: Maybe<Scalars['Boolean']['output']>
  num_days_until_pickup_expires?: Maybe<Scalars['Int']['output']>
  numdaysreserved?: Maybe<Scalars['Int']['output']>
  other_delivery_online?: Maybe<OtherDeliveryOption>
  other_delivery_stores?: Maybe<OtherDeliveryOption>
  password_reset_new_user?: Maybe<Scalars['Boolean']['output']>
  payments_refund_manual?: Maybe<Array<Scalars['Int']['output']>>
  payments_refund_validated?: Maybe<Array<Scalars['Int']['output']>>
  payments_refund_validated_omni?: Maybe<Array<Scalars['Int']['output']>>
  payments_sales_gift_card?: Maybe<Array<Scalars['Int']['output']>>
  payments_sales_non_delivered?: Maybe<Array<Scalars['Int']['output']>>
  paymenttypeid_split?: Maybe<Scalars['Int']['output']>
  permissions?: Maybe<PosPermissions>
  pickup_printout_barcode?: Maybe<SettingsPickupPrintoutBarcodeEnum>
  pin_days_valid?: Maybe<Scalars['Int']['output']>
  pin_days_warning?: Maybe<Scalars['Int']['output']>
  pin_hours_offline?: Maybe<Scalars['Int']['output']>
  pos_inventory_features?: Maybe<Array<SettingsPosInventoryFeaturesEnum>>
  pos_theme_data?: Maybe<Array<Scalars['Int']['output']>>
  pos_theme_id?: Maybe<Scalars['String']['output']>
  pricesstoredwithvat?: Maybe<Scalars['Boolean']['output']>
  productid_downpayment?: Maybe<Scalars['Int']['output']>
  receipt_staff_display?: Maybe<SettingsReceiptStaffDisplayEnum>
  required_fields_booking?: Maybe<Array<PosOrderField>>
  required_fields_delivery?: Maybe<Array<PosOrderField>>
  required_fields_pickup?: Maybe<Array<PosOrderField>>
  scanner_ean13_remove_leading_zero?: Maybe<Scalars['Boolean']['output']>
  shipment_auto_generate_barcodes?: Maybe<SettingsShipmentAutoGenerateBarcodesEnum>
  shipment_print_confirmation_receipt?: Maybe<SettingsShipmentPrintConfirmationReceiptEnum>
  shipment_print_delivery_note?: Maybe<SettingsShipmentPrintDeliveryNoteEnum>
  timezone?: Maybe<Scalars['String']['output']>
  uistrings?: Maybe<SettingsUiStrings>
  warehouseid?: Maybe<Scalars['Int']['output']>
  warn_close_with_open_orders?: Maybe<Scalars['Boolean']['output']>
}

export enum SettingsBankBagIdModeEnum {
  Disabled = 'Disabled',
  Optional = 'Optional',
  Required = 'Required',
}

export enum SettingsCartItemPriceDisplayModeEnum {
  Default = 'Default',
  PriceToPay = 'PriceToPay',
}

export enum SettingsCartPrintPickListModeEnum {
  Active = 'Active',
  Disabled = 'Disabled',
}

export enum SettingsCashCountingModeEnum {
  CountDenominationsOptional = 'CountDenominationsOptional',
  CountDenominationsRequired = 'CountDenominationsRequired',
  EnterTotalAmount = 'EnterTotalAmount',
}

export enum SettingsCreditnotesalestypeEnum {
  CUSTOM = 'CUSTOM',
  PRE_PRINTED_CARDS = 'PRE_PRINTED_CARDS',
  VOUCHER = 'VOUCHER',
}

export enum SettingsEshoptypeEnum {
  Common = 'Common',
  Franchise = 'Franchise',
  Main = 'Main',
}

export enum SettingsGiftcardsalestypeEnum {
  CUSTOM = 'CUSTOM',
  PRE_PRINTED_CARDS = 'PRE_PRINTED_CARDS',
  VOUCHER = 'VOUCHER',
}

export enum SettingsPickupPrintoutBarcodeEnum {
  ExternalId = 'ExternalId',
  OrderId = 'OrderId',
}

export type SettingsPlugin =
  | PluginAdyenApi
  | PluginAdyenTapToPay
  | PluginCtuNorway
  | PluginCtuPortugal
  | PluginCustomGiftCard
  | PluginCustomPayment
  | PluginCustomProductSearch
  | PluginGivex
  | PluginKlarnaV3
  | PluginNetsBaxiLogs
  | PluginNetsCurrencyConversion
  | PluginNosto
  | PluginProductSearch
  | PluginRetain24
  | PluginSelfCheckout
  | PluginSwish
  | PluginSwitchboard
  | PluginTaxFree
  | PluginZebra

export enum SettingsPosInventoryFeaturesEnum {
  ManualIn = 'ManualIn',
  ManualOut = 'ManualOut',
  Move = 'Move',
  ShipmentIn = 'ShipmentIn',
  ShipmentOut = 'ShipmentOut',
  Stocktaking = 'Stocktaking',
}

export enum SettingsReceiptStaffDisplayEnum {
  Externalid64 = 'Externalid64',
  Firstname2lastname2 = 'Firstname2lastname2',
  Firstname2lastname2externalid1 = 'Firstname2lastname2externalid1',
  Firstname2lastname2externalid2 = 'Firstname2lastname2externalid2',
  Firstname2lastname2externalid3 = 'Firstname2lastname2externalid3',
  Firstname2lastname2externalid12 = 'Firstname2lastname2externalid12',
  Firstname2lastname2externalid22 = 'Firstname2lastname2externalid22',
  Firstname2lastname2externalid32 = 'Firstname2lastname2externalid32',
}

export enum SettingsShipmentAutoGenerateBarcodesEnum {
  Active = 'Active',
  Disabled = 'Disabled',
}

export enum SettingsShipmentPrintConfirmationReceiptEnum {
  Auto = 'Auto',
  Deselected = 'Deselected',
  Disabled = 'Disabled',
  Preselected = 'Preselected',
}

export enum SettingsShipmentPrintDeliveryNoteEnum {
  Auto = 'Auto',
  Deselected = 'Deselected',
  Disabled = 'Disabled',
  Preselected = 'Preselected',
}

export type SettingsUiStrings = {
  __typename?: 'SettingsUiStrings'
  product_custom1?: Maybe<Scalars['String']['output']>
  product_custom2?: Maybe<Scalars['String']['output']>
  product_custom3?: Maybe<Scalars['String']['output']>
  product_custom4?: Maybe<Scalars['String']['output']>
  product_custom5?: Maybe<Scalars['String']['output']>
}

export type SettingsUiStringsInput = {
  product_custom1?: InputMaybe<Scalars['String']['input']>
  product_custom2?: InputMaybe<Scalars['String']['input']>
  product_custom3?: InputMaybe<Scalars['String']['input']>
  product_custom4?: InputMaybe<Scalars['String']['input']>
  product_custom5?: InputMaybe<Scalars['String']['input']>
}

export type Shipment = {
  __typename?: 'Shipment'
  all_items_received: Scalars['Boolean']['output']
  all_packages_arrived: Scalars['Boolean']['output']
  closed_state_reason_code?: Maybe<Scalars['String']['output']>
  created_at: Scalars['UnixDate']['output']
  created_by?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  info?: Maybe<ShipmentInfo>
  items_undeclared: Array<ShipmentItem>
  modified_at: Scalars['UnixDate']['output']
  packages: Array<ShipmentPackage>
  state: ShipmentState
  total_item_quantity: Scalars['Int']['output']
  total_item_undeclared_quantity: Scalars['Int']['output']
  total_package_count: Scalars['Int']['output']
  version: Scalars['Int']['output']
}

export type ShipmentAction = {
  __typename?: 'ShipmentAction'
  action: ActionType
  created_at: Scalars['UnixDate']['output']
  created_by: Scalars['String']['output']
  info?: Maybe<ShipmentInfo>
  initial_shipment?: Maybe<ShipmentActionInitialShipment>
  package?: Maybe<ShipmentPackage>
  package_id?: Maybe<Scalars['String']['output']>
  package_id_list?: Maybe<Array<Scalars['String']['output']>>
  received_items?: Maybe<ShipmentActionReceivedItems>
  received_items_undeclared?: Maybe<ShipmentActionReceivedItemsUndeclared>
  shipment_version: Scalars['Int']['output']
  state?: Maybe<ShipmentState>
  state_reason_code?: Maybe<Scalars['String']['output']>
  update_unit_prices?: Maybe<Scalars['Boolean']['output']>
  warehouse?: Maybe<Warehouse>
  warehouse_id?: Maybe<Scalars['Int']['output']>
  warehouse_values?: Maybe<Scalars['JSONObject']['output']>
}

export type ShipmentActionInitialShipment = {
  __typename?: 'ShipmentActionInitialShipment'
  all_items_received: Scalars['Boolean']['output']
  all_packages_arrived: Scalars['Boolean']['output']
  closed_state_reason_code?: Maybe<Scalars['String']['output']>
  created_at: Scalars['UnixDate']['output']
  created_by?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  info?: Maybe<ShipmentInfo>
  items_undeclared: Array<ShipmentItem>
  modified_at: Scalars['UnixDate']['output']
  packages: Array<ShipmentPackage>
  state: ShipmentState
  total_item_quantity: Scalars['Int']['output']
  total_item_undeclared_quantity: Scalars['Int']['output']
  total_package_count: Scalars['Int']['output']
  version: Scalars['Int']['output']
}

export type ShipmentActionInput = {
  action: ActionType
  info?: InputMaybe<ShipmentInfoInput>
  package?: InputMaybe<ShipmentPackageInput>
  package_id?: InputMaybe<Scalars['String']['input']>
  package_id_list?: InputMaybe<Array<Scalars['String']['input']>>
  received_items?: InputMaybe<ShipmentActionReceivedItemsInput>
  received_items_undeclared?: InputMaybe<ShipmentActionReceivedItemsUndeclaredInput>
  shipment_version: Scalars['Int']['input']
  state?: InputMaybe<ShipmentState>
  state_reason_code?: InputMaybe<Scalars['String']['input']>
  update_unit_prices?: InputMaybe<Scalars['Boolean']['input']>
  warehouse_id?: InputMaybe<Scalars['Int']['input']>
  warehouse_values?: InputMaybe<Scalars['JSONObject']['input']>
}

export type ShipmentActionReceivedItems = {
  __typename?: 'ShipmentActionReceivedItems'
  items: Array<ShipmentReceivedItem>
  note?: Maybe<Scalars['String']['output']>
  reason_code?: Maybe<Scalars['String']['output']>
  warehouse?: Maybe<Warehouse>
  warehouse_id?: Maybe<Scalars['Int']['output']>
  warehouse_transaction_id?: Maybe<Scalars['Int']['output']>
}

export type ShipmentActionReceivedItemsInput = {
  items: Array<ShipmentReceivedItemInput>
  note?: InputMaybe<Scalars['String']['input']>
  reason_code?: InputMaybe<Scalars['String']['input']>
  warehouse_id?: InputMaybe<Scalars['Int']['input']>
}

export type ShipmentActionReceivedItemsUndeclared = {
  __typename?: 'ShipmentActionReceivedItemsUndeclared'
  items: Array<ShipmentItem>
  note?: Maybe<Scalars['String']['output']>
  reason_code?: Maybe<Scalars['String']['output']>
  warehouse?: Maybe<Warehouse>
  warehouse_id?: Maybe<Scalars['Int']['output']>
  warehouse_transaction_id?: Maybe<Scalars['Int']['output']>
}

export type ShipmentActionReceivedItemsUndeclaredInput = {
  items: Array<ShipmentItemInput>
  note?: InputMaybe<Scalars['String']['input']>
  reason_code?: InputMaybe<Scalars['String']['input']>
  warehouse_id?: InputMaybe<Scalars['Int']['input']>
}

export type ShipmentAddress = {
  __typename?: 'ShipmentAddress'
  address?: Maybe<Scalars['String']['output']>
  address2?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  country_id?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  zip?: Maybe<Scalars['String']['output']>
}

export type ShipmentAddressInput = {
  address?: InputMaybe<Scalars['String']['input']>
  address2?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  country_id?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  zip?: InputMaybe<Scalars['String']['input']>
}

export type ShipmentContact = {
  __typename?: 'ShipmentContact'
  email?: Maybe<Scalars['String']['output']>
  first_name?: Maybe<Scalars['String']['output']>
  last_name?: Maybe<Scalars['String']['output']>
  mobile?: Maybe<Scalars['String']['output']>
  note?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type ShipmentContactInput = {
  email?: InputMaybe<Scalars['String']['input']>
  first_name?: InputMaybe<Scalars['String']['input']>
  last_name?: InputMaybe<Scalars['String']['input']>
  mobile?: InputMaybe<Scalars['String']['input']>
  note?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type ShipmentInfo = {
  __typename?: 'ShipmentInfo'
  additional_data?: Maybe<Scalars['JSONObject']['output']>
  barcode?: Maybe<Scalars['String']['output']>
  carrier?: Maybe<ShipmentInfoCarrier>
  currency?: Maybe<Scalars['String']['output']>
  destination?: Maybe<ShipmentInfoDestination>
  destination_warehouse_id?: Maybe<Scalars['Int']['output']>
  note?: Maybe<Scalars['String']['output']>
  origin?: Maybe<ShipmentInfoOrigin>
  origin_warehouse_id?: Maybe<Scalars['Int']['output']>
  reference_number?: Maybe<Scalars['String']['output']>
}

export type ShipmentInfoCarrier = {
  __typename?: 'ShipmentInfoCarrier'
  estimated_delivery_at?: Maybe<Scalars['UnixDate']['output']>
  estimated_pickup_at?: Maybe<Scalars['UnixDate']['output']>
  name?: Maybe<Scalars['String']['output']>
  reference?: Maybe<Scalars['String']['output']>
  service?: Maybe<Scalars['String']['output']>
  tracking_url?: Maybe<Scalars['Url']['output']>
  tracking_url_title?: Maybe<Scalars['String']['output']>
}

export type ShipmentInfoCarrierInput = {
  estimated_delivery_at?: InputMaybe<Scalars['UnixDate']['input']>
  estimated_pickup_at?: InputMaybe<Scalars['UnixDate']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  reference?: InputMaybe<Scalars['String']['input']>
  service?: InputMaybe<Scalars['String']['input']>
  tracking_url?: InputMaybe<Scalars['Url']['input']>
  tracking_url_title?: InputMaybe<Scalars['String']['input']>
}

export type ShipmentInfoDestination = {
  __typename?: 'ShipmentInfoDestination'
  address?: Maybe<ShipmentAddress>
  contact?: Maybe<ShipmentContact>
}

export type ShipmentInfoDestinationInput = {
  address?: InputMaybe<ShipmentAddressInput>
  contact?: InputMaybe<ShipmentContactInput>
}

export type ShipmentInfoInput = {
  additional_data?: InputMaybe<Scalars['JSONObject']['input']>
  barcode?: InputMaybe<Scalars['String']['input']>
  carrier?: InputMaybe<ShipmentInfoCarrierInput>
  currency?: InputMaybe<Scalars['String']['input']>
  destination?: InputMaybe<ShipmentInfoDestinationInput>
  destination_warehouse_id?: InputMaybe<Scalars['Int']['input']>
  note?: InputMaybe<Scalars['String']['input']>
  origin?: InputMaybe<ShipmentInfoOriginInput>
  origin_warehouse_id?: InputMaybe<Scalars['Int']['input']>
  reference_number?: InputMaybe<Scalars['String']['input']>
}

export type ShipmentInfoOrigin = {
  __typename?: 'ShipmentInfoOrigin'
  address?: Maybe<ShipmentAddress>
  contact?: Maybe<ShipmentContact>
}

export type ShipmentInfoOriginInput = {
  address?: InputMaybe<ShipmentAddressInput>
  contact?: InputMaybe<ShipmentContactInput>
}

export type ShipmentInput = {
  info?: InputMaybe<ShipmentInfoInput>
  packages: Array<ShipmentPackageInput>
}

export type ShipmentItem = {
  __typename?: 'ShipmentItem'
  additional_data?: Maybe<Scalars['JSONObject']['output']>
  barcode?: Maybe<Scalars['String']['output']>
  batch?: Maybe<Scalars['String']['output']>
  batch_expires?: Maybe<Scalars['UnixDate']['output']>
  id: Scalars['String']['output']
  product?: Maybe<Product>
  product_name: Scalars['String']['output']
  quantity: Scalars['Int']['output']
  quantity_received: Scalars['Int']['output']
  serial_numbers?: Maybe<Array<ShipmentItemSerialNumber>>
  sku: Scalars['String']['output']
  unit_price?: Maybe<Scalars['Int']['output']>
}

export type ShipmentItemInput = {
  additional_data?: InputMaybe<Scalars['JSONObject']['input']>
  barcode?: InputMaybe<Scalars['String']['input']>
  batch?: InputMaybe<Scalars['String']['input']>
  batch_expires?: InputMaybe<Scalars['Int']['input']>
  product_name: Scalars['String']['input']
  quantity: Scalars['Int']['input']
  serial_numbers?: InputMaybe<Array<ShipmentItemSerialNumberInput>>
  sku: Scalars['String']['input']
  unit_price?: InputMaybe<Scalars['Int']['input']>
}

export type ShipmentItemSerialNumber = {
  __typename?: 'ShipmentItemSerialNumber'
  additional_data?: Maybe<Scalars['JSONObject']['output']>
  barcode?: Maybe<Scalars['String']['output']>
  received: Scalars['Boolean']['output']
  serial_number: Scalars['String']['output']
}

export type ShipmentItemSerialNumberInput = {
  additional_data?: InputMaybe<Scalars['JSONObject']['input']>
  barcode?: InputMaybe<Scalars['String']['input']>
  received: Scalars['Boolean']['input']
  serial_number: Scalars['String']['input']
}

export type ShipmentPackage = {
  __typename?: 'ShipmentPackage'
  additional_data?: Maybe<Scalars['JSONObject']['output']>
  arrived_at?: Maybe<Scalars['UnixDate']['output']>
  barcode?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  dimensions?: Maybe<ShipmentPackageDimensions>
  id: Scalars['String']['output']
  items?: Maybe<Array<ShipmentItem>>
  total_quantity: Scalars['Int']['output']
  total_quantity_received: Scalars['Int']['output']
  value?: Maybe<Scalars['MoneyInt']['output']>
  weight?: Maybe<ShipmentPackageWeight>
}

export type ShipmentPackageDimensions = {
  __typename?: 'ShipmentPackageDimensions'
  height: Scalars['Int']['output']
  length: Scalars['Int']['output']
  unit: ShipmentPackageDimensionsUnitEnum
  width: Scalars['Int']['output']
}

export type ShipmentPackageDimensionsInput = {
  height: Scalars['Int']['input']
  length: Scalars['Int']['input']
  unit: ShipmentPackageDimensionsUnitEnum
  width: Scalars['Int']['input']
}

export enum ShipmentPackageDimensionsUnitEnum {
  Cm = 'Cm',
  In = 'In',
}

export type ShipmentPackageInput = {
  additional_data?: InputMaybe<Scalars['JSONObject']['input']>
  barcode?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  dimensions?: InputMaybe<ShipmentPackageDimensionsInput>
  items?: InputMaybe<Array<ShipmentItemInput>>
  weight?: InputMaybe<ShipmentPackageWeightInput>
}

export type ShipmentPackageWeight = {
  __typename?: 'ShipmentPackageWeight'
  unit: ShipmentPackageWeightUnitEnum
  weight: Scalars['Int']['output']
}

export type ShipmentPackageWeightInput = {
  unit: ShipmentPackageWeightUnitEnum
  weight: Scalars['Int']['input']
}

export enum ShipmentPackageWeightUnitEnum {
  Kg = 'Kg',
  Lb = 'Lb',
  Oz = 'Oz',
}

export type ShipmentReceivedItem = {
  __typename?: 'ShipmentReceivedItem'
  item_id: Scalars['String']['output']
  note?: Maybe<Scalars['String']['output']>
  quantity: Scalars['Int']['output']
  serial_numbers?: Maybe<Array<Scalars['String']['output']>>
}

export type ShipmentReceivedItemInput = {
  item_id: Scalars['String']['input']
  note?: InputMaybe<Scalars['String']['input']>
  quantity: Scalars['Int']['input']
  serial_numbers?: InputMaybe<Array<Scalars['String']['input']>>
}

export enum ShipmentState {
  Arrived = 'Arrived',
  Cancelled = 'Cancelled',
  Closed = 'Closed',
  ClosedIncomplete = 'ClosedIncomplete',
  InTransit = 'InTransit',
  New = 'New',
  Packed = 'Packed',
  ReadyForPickup = 'ReadyForPickup',
  Received = 'Received',
  TransportOrdered = 'TransportOrdered',
}

export enum ShipmentStateV2 {
  Cancelled = 'Cancelled',
  InTransit = 'InTransit',
  New = 'New',
  Received = 'Received',
}

export type ShipmentV2 = {
  __typename?: 'ShipmentV2'
  archived?: Maybe<Scalars['Boolean']['output']>
  barcode?: Maybe<Scalars['String']['output']>
  carrier_name?: Maybe<Scalars['String']['output']>
  carrier_reference?: Maybe<Scalars['String']['output']>
  carrier_trackingurl?: Maybe<Scalars['Url']['output']>
  comment?: Maybe<Scalars['String']['output']>
  datecancelled?: Maybe<Scalars['UnixDate']['output']>
  dateestimateddelivery?: Maybe<Scalars['UnixDate']['output']>
  dateestimatedpickup?: Maybe<Scalars['UnixDate']['output']>
  dateintransit?: Maybe<Scalars['UnixDate']['output']>
  datenew?: Maybe<Scalars['UnixDate']['output']>
  datereceived?: Maybe<Scalars['UnixDate']['output']>
  emailowner?: Maybe<Scalars['String']['output']>
  emailreceivedby?: Maybe<Scalars['String']['output']>
  externalid?: Maybe<Scalars['String']['output']>
  packages?: Maybe<Array<ShipmentV2Package>>
  receiver_address?: Maybe<Scalars['String']['output']>
  receiver_address2?: Maybe<Scalars['String']['output']>
  receiver_city?: Maybe<Scalars['String']['output']>
  receiver_countryid?: Maybe<Scalars['String']['output']>
  receiver_instructions?: Maybe<Scalars['String']['output']>
  receiver_name?: Maybe<Scalars['String']['output']>
  receiver_referenceemail?: Maybe<Scalars['String']['output']>
  receiver_referencemobile?: Maybe<Scalars['String']['output']>
  receiver_referencename?: Maybe<Scalars['String']['output']>
  receiver_state?: Maybe<Scalars['String']['output']>
  receiver_warehouseid?: Maybe<Scalars['Int']['output']>
  receiver_zip?: Maybe<Scalars['String']['output']>
  sender_address?: Maybe<Scalars['String']['output']>
  sender_address2?: Maybe<Scalars['String']['output']>
  sender_city?: Maybe<Scalars['String']['output']>
  sender_countryid?: Maybe<Scalars['String']['output']>
  sender_instructions?: Maybe<Scalars['String']['output']>
  sender_name?: Maybe<Scalars['String']['output']>
  sender_referenceemail?: Maybe<Scalars['String']['output']>
  sender_referencemobile?: Maybe<Scalars['String']['output']>
  sender_referencename?: Maybe<Scalars['String']['output']>
  sender_state?: Maybe<Scalars['String']['output']>
  sender_warehouseid?: Maybe<Scalars['Int']['output']>
  sender_zip?: Maybe<Scalars['String']['output']>
  shipmentid?: Maybe<Scalars['Int']['output']>
  shipmentitems?: Maybe<Array<ShipmentV2Item>>
  shipmentpackages?: Maybe<Array<ShipmentV2Package>>
  shipmentstate?: Maybe<ShipmentStateV2>
}

export type ShipmentV2Input = {
  archived?: InputMaybe<Scalars['Boolean']['input']>
  barcode?: InputMaybe<Scalars['String']['input']>
  carrier_name?: InputMaybe<Scalars['String']['input']>
  carrier_reference?: InputMaybe<Scalars['String']['input']>
  carrier_trackingurl?: InputMaybe<Scalars['Url']['input']>
  comment?: InputMaybe<Scalars['String']['input']>
  datecancelled?: InputMaybe<Scalars['UnixDate']['input']>
  dateestimateddelivery?: InputMaybe<Scalars['UnixDate']['input']>
  dateestimatedpickup?: InputMaybe<Scalars['UnixDate']['input']>
  dateintransit?: InputMaybe<Scalars['UnixDate']['input']>
  datenew?: InputMaybe<Scalars['UnixDate']['input']>
  datereceived?: InputMaybe<Scalars['UnixDate']['input']>
  emailowner?: InputMaybe<Scalars['String']['input']>
  emailreceivedby?: InputMaybe<Scalars['String']['input']>
  externalid?: InputMaybe<Scalars['String']['input']>
  packages?: InputMaybe<Array<ShipmentV2PackageInput>>
  receiver_address?: InputMaybe<Scalars['String']['input']>
  receiver_address2?: InputMaybe<Scalars['String']['input']>
  receiver_city?: InputMaybe<Scalars['String']['input']>
  receiver_countryid?: InputMaybe<Scalars['String']['input']>
  receiver_instructions?: InputMaybe<Scalars['String']['input']>
  receiver_name?: InputMaybe<Scalars['String']['input']>
  receiver_referenceemail?: InputMaybe<Scalars['String']['input']>
  receiver_referencemobile?: InputMaybe<Scalars['String']['input']>
  receiver_referencename?: InputMaybe<Scalars['String']['input']>
  receiver_state?: InputMaybe<Scalars['String']['input']>
  receiver_warehouseid?: InputMaybe<Scalars['Int']['input']>
  receiver_zip?: InputMaybe<Scalars['String']['input']>
  sender_address?: InputMaybe<Scalars['String']['input']>
  sender_address2?: InputMaybe<Scalars['String']['input']>
  sender_city?: InputMaybe<Scalars['String']['input']>
  sender_countryid?: InputMaybe<Scalars['String']['input']>
  sender_instructions?: InputMaybe<Scalars['String']['input']>
  sender_name?: InputMaybe<Scalars['String']['input']>
  sender_referenceemail?: InputMaybe<Scalars['String']['input']>
  sender_referencemobile?: InputMaybe<Scalars['String']['input']>
  sender_referencename?: InputMaybe<Scalars['String']['input']>
  sender_state?: InputMaybe<Scalars['String']['input']>
  sender_warehouseid?: InputMaybe<Scalars['Int']['input']>
  sender_zip?: InputMaybe<Scalars['String']['input']>
  shipmentid?: InputMaybe<Scalars['Int']['input']>
  shipmentstate?: InputMaybe<ShipmentStateV2>
}

export type ShipmentV2Item = {
  __typename?: 'ShipmentV2Item'
  decimalunitquantity?: Maybe<Scalars['String']['output']>
  externalid?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['String']['output']>
  moneypricein?: Maybe<Scalars['String']['output']>
  product?: Maybe<Product>
  productname?: Maybe<Scalars['String']['output']>
  quantity?: Maybe<Scalars['Int']['output']>
  quantityreceived?: Maybe<Scalars['Int']['output']>
  shipmentitemid?: Maybe<Scalars['Int']['output']>
  shipmentpackageid?: Maybe<Scalars['Int']['output']>
  sku?: Maybe<Scalars['String']['output']>
  unitlabel?: Maybe<Scalars['String']['output']>
}

export type ShipmentV2ItemInput = {
  decimalunitquantity?: InputMaybe<Scalars['String']['input']>
  externalid?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  moneypricein?: InputMaybe<Scalars['String']['input']>
  product?: InputMaybe<ShipmentV2ProductInput>
  productname?: InputMaybe<Scalars['String']['input']>
  quantity?: InputMaybe<Scalars['Int']['input']>
  quantityreceived?: InputMaybe<Scalars['Int']['input']>
  shipmentitemid?: InputMaybe<Scalars['Int']['input']>
  shipmentpackageid?: InputMaybe<Scalars['Int']['input']>
  sku?: InputMaybe<Scalars['String']['input']>
  unitlabel?: InputMaybe<Scalars['String']['input']>
}

export type ShipmentV2Package = {
  __typename?: 'ShipmentV2Package'
  barcode?: Maybe<Scalars['String']['output']>
  comment?: Maybe<Scalars['String']['output']>
  externalid?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['String']['output']>
  items?: Maybe<Array<ShipmentV2Item>>
  shipmentpackageid?: Maybe<Scalars['Int']['output']>
  totalQuantity?: Maybe<Scalars['Int']['output']>
  totalReceivedQuantity?: Maybe<Scalars['Int']['output']>
}

export type ShipmentV2PackageInput = {
  barcode?: InputMaybe<Scalars['String']['input']>
  comment?: InputMaybe<Scalars['String']['input']>
  externalid?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  items?: InputMaybe<Array<ShipmentV2ItemInput>>
  shipmentpackageid?: InputMaybe<Scalars['Int']['input']>
  totalQuantity?: InputMaybe<Scalars['Int']['input']>
  totalReceivedQuantity?: InputMaybe<Scalars['Int']['input']>
}

export type ShipmentV2ProductInput = {
  allowdecimals?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  images?: InputMaybe<Array<Scalars['String']['input']>>
  sku?: InputMaybe<Scalars['String']['input']>
  unitlabel?: InputMaybe<Scalars['String']['input']>
}

export type ShipmentsV2 = {
  __typename?: 'ShipmentsV2'
  items?: Maybe<Array<ShipmentV2>>
  start?: Maybe<Scalars['Int']['output']>
  totalcount?: Maybe<Scalars['Int']['output']>
}

export type SingleCustomAttributeInput = {
  enums?: InputMaybe<Array<Scalars['String']['input']>>
  id: Scalars['String']['input']
  searchable?: InputMaybe<Scalars['Boolean']['input']>
  title: Scalars['String']['input']
  type: CustomAttributeTypeEnum
}

export type SingleProductGroupInput = {
  comment?: InputMaybe<Scalars['String']['input']>
  productgroupname: Scalars['String']['input']
  productgrouptype?: InputMaybe<ProductGroupType>
  value?: InputMaybe<Scalars['Float']['input']>
  vatid?: InputMaybe<Scalars['Int']['input']>
}

export type SingleProductsInput = {
  accessories?: InputMaybe<Array<Scalars['Int']['input']>>
  active?: InputMaybe<Scalars['Boolean']['input']>
  activepos?: InputMaybe<Scalars['Boolean']['input']>
  allowdecimals?: InputMaybe<Scalars['Boolean']['input']>
  barcode?: InputMaybe<Scalars['String']['input']>
  barcodealiases?: InputMaybe<Array<Scalars['String']['input']>>
  categories?: InputMaybe<Array<Scalars['Int']['input']>>
  custom1?: InputMaybe<Scalars['String']['input']>
  custom2?: InputMaybe<Scalars['String']['input']>
  custom3?: InputMaybe<Scalars['String']['input']>
  custom4?: InputMaybe<Scalars['String']['input']>
  custom5?: InputMaybe<Scalars['String']['input']>
  customattributes?: InputMaybe<Scalars['JSONObject']['input']>
  datecreated?: InputMaybe<Scalars['UnixDate']['input']>
  datemodified?: InputMaybe<Scalars['UnixDate']['input']>
  defaultcategoryid?: InputMaybe<Scalars['Int']['input']>
  deliveryclassid?: InputMaybe<Scalars['Int']['input']>
  deliveryinfo?: InputMaybe<Scalars['String']['input']>
  deliverystatus?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  descriptionhtml?: InputMaybe<Scalars['String']['input']>
  descriptionshort?: InputMaybe<Scalars['String']['input']>
  externalinputtitle?: InputMaybe<Scalars['String']['input']>
  friendly?: InputMaybe<Scalars['String']['input']>
  manufacturerid?: InputMaybe<Scalars['Int']['input']>
  manufacturerurl?: InputMaybe<Scalars['String']['input']>
  moneyfinalprice?: InputMaybe<Scalars['String']['input']>
  moneyofferprice?: InputMaybe<Scalars['String']['input']>
  moneyprice?: InputMaybe<Scalars['String']['input']>
  moneypricein?: InputMaybe<Scalars['String']['input']>
  moneypriceorg?: InputMaybe<Scalars['String']['input']>
  offerdateend?: InputMaybe<Scalars['UnixDate']['input']>
  offerdatestart?: InputMaybe<Scalars['UnixDate']['input']>
  offerisactive?: InputMaybe<Scalars['Boolean']['input']>
  offerisenabled?: InputMaybe<Scalars['Boolean']['input']>
  offertitle?: InputMaybe<Scalars['String']['input']>
  pricelisthasvolume?: InputMaybe<Scalars['Boolean']['input']>
  productgrouptype?: InputMaybe<ProductGroupType>
  productid: Scalars['Int']['input']
  related?: InputMaybe<Array<Scalars['Int']['input']>>
  seo_description?: InputMaybe<Scalars['String']['input']>
  seo_keywords?: InputMaybe<Scalars['String']['input']>
  seo_title?: InputMaybe<Scalars['String']['input']>
  similar?: InputMaybe<Array<Scalars['Int']['input']>>
  sku?: InputMaybe<Scalars['String']['input']>
  skumanufacturer?: InputMaybe<Scalars['String']['input']>
  stockallowbackorder?: InputMaybe<Scalars['Boolean']['input']>
  stockcountenable?: InputMaybe<Scalars['Boolean']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  unitlabel?: InputMaybe<Scalars['String']['input']>
  variantparentid?: InputMaybe<Scalars['Int']['input']>
  vatid?: InputMaybe<Scalars['Int']['input']>
  vatvalue?: InputMaybe<Scalars['Float']['input']>
}

export type SingleUpdateProductsResponse = {
  __typename?: 'SingleUpdateProductsResponse'
  code?: Maybe<Scalars['String']['output']>
  product: Product
  success: Scalars['Boolean']['output']
}

export type Site = {
  __typename?: 'Site'
  allow_store_manager_campaigns: Scalars['Boolean']['output']
  commoneshopid?: Maybe<Scalars['Int']['output']>
  currencycode: Scalars['String']['output']
  customattributes_in_store_replenishment: Array<Scalars['String']['output']>
  eshopid: Scalars['Int']['output']
  eshopname: Scalars['String']['output']
  eshoptype: SiteSettingsEshopTypeEnum
  pricesstoredwithvat: Scalars['Boolean']['output']
  timezone: Scalars['String']['output']
}

export enum SiteSettingsEshopTypeEnum {
  Common = 'Common',
  Franchise = 'Franchise',
  Main = 'Main',
}

export type Store = {
  __typename?: 'Store'
  additional_data?: Maybe<Scalars['JSONObject']['output']>
  address?: Maybe<Scalars['String']['output']>
  address2?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  countryid?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  eshopid?: Maybe<Scalars['Int']['output']>
  externalgroupid?: Maybe<Scalars['String']['output']>
  externalid?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  latitude?: Maybe<Scalars['String']['output']>
  longitude?: Maybe<Scalars['Coordinate']['output']>
  name: Scalars['String']['output']
  nearby_stores?: Maybe<Array<Scalars['Int']['output']>>
  phone?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  storetype: StoreType
  tags?: Maybe<Array<Scalars['String']['output']>>
  usetype_manualin?: Maybe<WarehouseUseType>
  usetype_manualout?: Maybe<WarehouseUseType>
  usetype_movein?: Maybe<WarehouseUseType>
  usetype_moveout?: Maybe<WarehouseUseType>
  usetype_shipmentin?: Maybe<WarehouseUseType>
  usetype_shipmentout?: Maybe<WarehouseUseType>
  warehouses?: Maybe<Array<Warehouse>>
  zip?: Maybe<Scalars['String']['output']>
}

export type StoreInput = {
  additional_data?: InputMaybe<Scalars['JSONObject']['input']>
  address?: InputMaybe<Scalars['String']['input']>
  address2?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  countryid?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  eshopid?: InputMaybe<Scalars['Int']['input']>
  externalgroupid?: InputMaybe<Scalars['String']['input']>
  externalid?: InputMaybe<Scalars['String']['input']>
  latitude?: InputMaybe<Scalars['Coordinate']['input']>
  longitude?: InputMaybe<Scalars['Coordinate']['input']>
  name: Scalars['String']['input']
  nearby_stores?: InputMaybe<Array<Scalars['Int']['input']>>
  phone?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  storeid?: InputMaybe<Scalars['Int']['input']>
  storetype: StoreType
  tags?: InputMaybe<Array<Scalars['String']['input']>>
  usetype_manualin?: InputMaybe<WarehouseUseType>
  usetype_manualout?: InputMaybe<WarehouseUseType>
  usetype_movein?: InputMaybe<WarehouseUseType>
  usetype_moveout?: InputMaybe<WarehouseUseType>
  usetype_shipmentin?: InputMaybe<WarehouseUseType>
  usetype_shipmentout?: InputMaybe<WarehouseUseType>
  zip?: InputMaybe<Scalars['String']['input']>
}

export type StoreReplenishmentItem = {
  __typename?: 'StoreReplenishmentItem'
  binLocationFrom?: Maybe<Scalars['String']['output']>
  binLocationTo?: Maybe<Scalars['String']['output']>
  customattributes?: Maybe<Scalars['JSONObject']['output']>
  decimalThresholdFrom?: Maybe<Scalars['String']['output']>
  decimalThresholdTo?: Maybe<Scalars['String']['output']>
  decimalTotalFrom?: Maybe<Scalars['String']['output']>
  decimalTotalTo?: Maybe<Scalars['String']['output']>
  images?: Maybe<Array<Scalars['String']['output']>>
  sku: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
  toPick?: Maybe<Scalars['Int']['output']>
  variant?: Maybe<Array<ProductVariantItem>>
}

export enum StoreType {
  Mobile = 'Mobile',
  Online = 'Online',
  Outlet = 'Outlet',
  PopUp = 'PopUp',
  Store = 'Store',
}

export type UpdateCategoryInput = {
  categoryparentid?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  sortorder?: InputMaybe<Scalars['Int']['input']>
  title: Scalars['String']['input']
  visible?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateCategoryResponse = {
  __typename?: 'UpdateCategoryResponse'
  category: Category
  error?: Maybe<Error>
  success: Scalars['Boolean']['output']
}

export type UpdateOrderDeliveryInput = {
  state?: InputMaybe<OrderDeliveryItemState>
}

export type UpdateOrderInput = {
  comment?: InputMaybe<Scalars['String']['input']>
  commentinternal?: InputMaybe<Scalars['String']['input']>
  company?: InputMaybe<Scalars['String']['input']>
  creditorderid?: InputMaybe<Scalars['Int']['input']>
  customerref?: InputMaybe<Scalars['String']['input']>
  datereserved?: InputMaybe<Scalars['UnixDate']['input']>
  delivery_address?: InputMaybe<Scalars['String']['input']>
  delivery_address2?: InputMaybe<Scalars['String']['input']>
  delivery_city?: InputMaybe<Scalars['String']['input']>
  delivery_countryid?: InputMaybe<Scalars['String']['input']>
  delivery_state?: InputMaybe<Scalars['String']['input']>
  delivery_zip?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  externalid?: InputMaybe<Scalars['String']['input']>
  invoice_address?: InputMaybe<Scalars['String']['input']>
  invoice_address2?: InputMaybe<Scalars['String']['input']>
  invoice_city?: InputMaybe<Scalars['String']['input']>
  invoice_countryid?: InputMaybe<Scalars['String']['input']>
  invoice_state?: InputMaybe<Scalars['String']['input']>
  invoice_zip?: InputMaybe<Scalars['String']['input']>
  namefirst?: InputMaybe<Scalars['String']['input']>
  namelast?: InputMaybe<Scalars['String']['input']>
  orderdate?: InputMaybe<Scalars['UnixDate']['input']>
  orderstateid?: InputMaybe<OrderState>
  paymentstateid?: InputMaybe<PaymentState>
  personalid?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  warehouseid?: InputMaybe<Scalars['Int']['input']>
}

export type UpdatePaymentTypeInput = {
  externalid?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  paymentmethodid: PosPaymentMethod
  paymenttypeid: Scalars['Int']['input']
  sortorder?: InputMaybe<Scalars['Int']['input']>
}

export type UpdatePriceListInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  currencycode?: InputMaybe<Scalars['String']['input']>
  externalid?: InputMaybe<Scalars['String']['input']>
  pricelistdescription?: InputMaybe<Scalars['String']['input']>
  pricelistid?: InputMaybe<Scalars['Int']['input']>
  pricelistname: Scalars['String']['input']
  tags?: InputMaybe<Array<Scalars['String']['input']>>
}

export type UpdatePricelistItemInput = {
  moneyprice: Scalars['String']['input']
  pricelistid?: InputMaybe<Scalars['Int']['input']>
  productid?: InputMaybe<Scalars['Int']['input']>
  sku?: InputMaybe<Scalars['String']['input']>
}

export type UpdateServiceOrderInput = {
  author: ServiceOrderUserInput
  currency: Scalars['String']['input']
  customer: ServiceOrderCustomerInput
  id: Scalars['String']['input']
  note?: InputMaybe<Scalars['String']['input']>
  owner: ServiceOrderUserInput
  product: ServiceOrderProductInput
  revision?: InputMaybe<Scalars['Int']['input']>
  state?: InputMaybe<ServiceOrderState>
  store: ServiceOrderStoreInput
  version?: InputMaybe<Scalars['String']['input']>
}

export type UpdateSettingsInput = {
  allow_store_manager_campaigns?: InputMaybe<Scalars['Boolean']['input']>
  allowed_sites_booking?: InputMaybe<Array<Scalars['Int']['input']>>
  allowed_sites_delivery?: InputMaybe<Array<Scalars['Int']['input']>>
  bank_bag_id_mode?: InputMaybe<SettingsBankBagIdModeEnum>
  cart_item_price_display_mode?: InputMaybe<SettingsCartItemPriceDisplayModeEnum>
  cart_print_pick_list_mode?: InputMaybe<SettingsCartPrintPickListModeEnum>
  cash_counting_mode?: InputMaybe<SettingsCashCountingModeEnum>
  commoneshopid?: InputMaybe<Scalars['Int']['input']>
  compress_variant_info?: InputMaybe<Scalars['Boolean']['input']>
  creditnoterequirespin?: InputMaybe<Scalars['Boolean']['input']>
  creditnotesalestype?: InputMaybe<SettingsCreditnotesalestypeEnum>
  creditnotevalidmonths?: InputMaybe<Scalars['Int']['input']>
  currency_rates?: InputMaybe<Array<CurrencyRateInput>>
  currencycode?: InputMaybe<Scalars['String']['input']>
  customattributes_details?: InputMaybe<Array<CustomAttributeMappingInput>>
  customattributes_in_store_replenishment?: InputMaybe<
    Array<Scalars['String']['input']>
  >
  customattributes_info?: InputMaybe<Array<CustomAttributeMappingInput>>
  customattributes_list?: InputMaybe<Array<CustomAttributeMappingInput>>
  customattributes_receipt?: InputMaybe<Array<CustomAttributeMappingInput>>
  dashboard_ignore_product_groups?: InputMaybe<Array<Scalars['Int']['input']>>
  doroundoff?: InputMaybe<Scalars['Boolean']['input']>
  emailfrom?: InputMaybe<Scalars['String']['input']>
  emailfromdisplay?: InputMaybe<Scalars['String']['input']>
  eshopname?: InputMaybe<Scalars['String']['input']>
  eshoptype?: InputMaybe<SettingsEshoptypeEnum>
  giftcardproductids_email?: InputMaybe<Array<Scalars['Int']['input']>>
  giftcardproductids_sms?: InputMaybe<Array<Scalars['Int']['input']>>
  giftcardrequirespin?: InputMaybe<Scalars['Boolean']['input']>
  giftcardsalestype?: InputMaybe<SettingsGiftcardsalestypeEnum>
  giftcardvalidmonths?: InputMaybe<Scalars['Int']['input']>
  limit_open_cash_drawer?: InputMaybe<Scalars['Boolean']['input']>
  mixed_delivery_disabled?: InputMaybe<Scalars['Boolean']['input']>
  num_days_until_pickup_expires?: InputMaybe<Scalars['Int']['input']>
  numdaysreserved?: InputMaybe<Scalars['Int']['input']>
  other_delivery_online?: InputMaybe<OtherDeliveryOption>
  other_delivery_stores?: InputMaybe<OtherDeliveryOption>
  password_reset_new_user?: InputMaybe<Scalars['Boolean']['input']>
  payments_refund_manual?: InputMaybe<Array<Scalars['Int']['input']>>
  payments_refund_validated?: InputMaybe<Array<Scalars['Int']['input']>>
  payments_refund_validated_omni?: InputMaybe<Array<Scalars['Int']['input']>>
  payments_sales_gift_card?: InputMaybe<Array<Scalars['Int']['input']>>
  payments_sales_non_delivered?: InputMaybe<Array<Scalars['Int']['input']>>
  paymenttypeid_split?: InputMaybe<Scalars['Int']['input']>
  permissions?: InputMaybe<PosPermissionsInput>
  pickup_printout_barcode?: InputMaybe<SettingsPickupPrintoutBarcodeEnum>
  pin_days_valid?: InputMaybe<Scalars['Int']['input']>
  pin_days_warning?: InputMaybe<Scalars['Int']['input']>
  pin_hours_offline?: InputMaybe<Scalars['Int']['input']>
  pos_inventory_features?: InputMaybe<Array<SettingsPosInventoryFeaturesEnum>>
  pos_theme_data?: InputMaybe<Array<Scalars['Int']['input']>>
  pos_theme_id?: InputMaybe<Scalars['String']['input']>
  pricesstoredwithvat?: InputMaybe<Scalars['Boolean']['input']>
  productid_downpayment?: InputMaybe<Scalars['Int']['input']>
  receipt_staff_display?: InputMaybe<SettingsReceiptStaffDisplayEnum>
  required_fields_booking?: InputMaybe<Array<PosOrderField>>
  required_fields_delivery?: InputMaybe<Array<PosOrderField>>
  required_fields_pickup?: InputMaybe<Array<PosOrderField>>
  scanner_ean13_remove_leading_zero?: InputMaybe<Scalars['Boolean']['input']>
  shipment_auto_generate_barcodes?: InputMaybe<SettingsShipmentAutoGenerateBarcodesEnum>
  shipment_print_confirmation_receipt?: InputMaybe<SettingsShipmentPrintConfirmationReceiptEnum>
  shipment_print_delivery_note?: InputMaybe<SettingsShipmentPrintDeliveryNoteEnum>
  timezone?: InputMaybe<Scalars['String']['input']>
  uistrings?: InputMaybe<SettingsUiStringsInput>
  warehouseid?: InputMaybe<Scalars['Int']['input']>
  warn_close_with_open_orders?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateUserInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  address?: InputMaybe<Scalars['String']['input']>
  address2?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  company?: InputMaybe<Scalars['String']['input']>
  companyid?: InputMaybe<Scalars['String']['input']>
  countryid?: InputMaybe<Scalars['String']['input']>
  customernumber?: InputMaybe<Scalars['String']['input']>
  department?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  externalid?: InputMaybe<Scalars['String']['input']>
  mobile?: InputMaybe<Scalars['String']['input']>
  namefirst?: InputMaybe<Scalars['String']['input']>
  namelast?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  personalid?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  pin?: InputMaybe<Scalars['String']['input']>
  posallstores?: InputMaybe<Scalars['Boolean']['input']>
  posrefundcard?: InputMaybe<Scalars['Boolean']['input']>
  role?: InputMaybe<UserRole>
  staff_pos_activation?: InputMaybe<Scalars['Boolean']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  tags?: InputMaybe<Array<Scalars['String']['input']>>
  timezone?: InputMaybe<Scalars['String']['input']>
  userid: Scalars['String']['input']
  voucherpasswords?: InputMaybe<Array<Scalars['String']['input']>>
  warehouses?: InputMaybe<Array<Scalars['Int']['input']>>
  zip?: InputMaybe<Scalars['String']['input']>
}

export type UpdateWarehouseBatchInput = {
  comment?: InputMaybe<Scalars['String']['input']>
  externalid?: InputMaybe<Scalars['String']['input']>
  reasoncode?: InputMaybe<Scalars['String']['input']>
  shipmentid?: InputMaybe<Scalars['Int']['input']>
  warehousebatchstate?: InputMaybe<WarehouseBatchState>
}

export type User = {
  __typename?: 'User'
  active?: Maybe<Scalars['Boolean']['output']>
  address?: Maybe<Scalars['String']['output']>
  address2?: Maybe<Scalars['String']['output']>
  allWarehouses?: Maybe<Array<Warehouse>>
  city?: Maybe<Scalars['String']['output']>
  company?: Maybe<Scalars['String']['output']>
  companyid?: Maybe<Scalars['String']['output']>
  countryid?: Maybe<Scalars['String']['output']>
  customernumber?: Maybe<Scalars['String']['output']>
  datecreated?: Maybe<Scalars['UnixDate']['output']>
  datemodified?: Maybe<Scalars['UnixDate']['output']>
  department?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  externalid?: Maybe<Scalars['String']['output']>
  hasmfa?: Maybe<Scalars['Boolean']['output']>
  id?: Maybe<Scalars['String']['output']>
  mobile?: Maybe<Scalars['String']['output']>
  namefirst?: Maybe<Scalars['String']['output']>
  namelast?: Maybe<Scalars['String']['output']>
  notes?: Maybe<Scalars['String']['output']>
  personalid?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  posallstores?: Maybe<Scalars['Boolean']['output']>
  posrefundcard?: Maybe<Scalars['Boolean']['output']>
  resolvedTags?: Maybe<Array<UserTag>>
  role?: Maybe<UserRole>
  staff_pos_activation?: Maybe<Scalars['Boolean']['output']>
  state?: Maybe<Scalars['String']['output']>
  tags?: Maybe<Array<Scalars['String']['output']>>
  timezone?: Maybe<Scalars['String']['output']>
  voucherpasswords?: Maybe<Array<Scalars['String']['output']>>
  warehouses?: Maybe<Array<Scalars['Int']['output']>>
  zip?: Maybe<Scalars['String']['output']>
}

export type UserPermissions = {
  __typename?: 'UserPermissions'
  addMfa?: Maybe<Scalars['Boolean']['output']>
  addUser?: Maybe<Scalars['Boolean']['output']>
  changeAccess?: Maybe<Scalars['Boolean']['output']>
  changeActive?: Maybe<Scalars['Boolean']['output']>
  changeEmail?: Maybe<Scalars['Boolean']['output']>
  changePassword?: Maybe<Scalars['Boolean']['output']>
  changePin?: Maybe<Scalars['Boolean']['output']>
  changeRole?: Maybe<Scalars['Boolean']['output']>
  deleteUser?: Maybe<Scalars['Boolean']['output']>
  editUser?: Maybe<Scalars['Boolean']['output']>
  exportUser?: Maybe<Scalars['Boolean']['output']>
  importUser?: Maybe<Scalars['Boolean']['output']>
  removeMfa?: Maybe<Scalars['Boolean']['output']>
  viewMfa?: Maybe<Scalars['Boolean']['output']>
  viewUser?: Maybe<Scalars['Boolean']['output']>
}

export enum UserRole {
  Admin = 'Admin',
  Staff = 'Staff',
  StoreManager = 'StoreManager',
  StoreStaff = 'StoreStaff',
}

export type UserTag = {
  __typename?: 'UserTag'
  id: Scalars['String']['output']
  label: Scalars['String']['output']
}

export type UserTagInput = {
  id: Scalars['String']['input']
  label: Scalars['String']['input']
}

export type VariantGroup = {
  __typename?: 'VariantGroup'
  name: Scalars['String']['output']
  options: Array<Scalars['String']['output']>
}

export type VariantGroupInput = {
  name: Scalars['String']['input']
  options: Array<Scalars['String']['input']>
}

export type VariantInput = {
  accessories?: InputMaybe<Array<Scalars['Int']['input']>>
  active?: InputMaybe<Scalars['Boolean']['input']>
  activepos?: InputMaybe<Scalars['Boolean']['input']>
  allowdecimals?: InputMaybe<Scalars['Boolean']['input']>
  attributes?: InputMaybe<Array<Scalars['String']['input']>>
  barcode?: InputMaybe<Scalars['String']['input']>
  barcodealiases?: InputMaybe<Array<Scalars['String']['input']>>
  categories?: InputMaybe<Array<Scalars['Int']['input']>>
  custom1?: InputMaybe<Scalars['String']['input']>
  custom2?: InputMaybe<Scalars['String']['input']>
  custom3?: InputMaybe<Scalars['String']['input']>
  custom4?: InputMaybe<Scalars['String']['input']>
  custom5?: InputMaybe<Scalars['String']['input']>
  customattributes?: InputMaybe<Scalars['JSONObject']['input']>
  datecreated?: InputMaybe<Scalars['UnixDate']['input']>
  datemodified?: InputMaybe<Scalars['UnixDate']['input']>
  defaultcategoryid?: InputMaybe<Scalars['Int']['input']>
  deliveryclassid?: InputMaybe<Scalars['Int']['input']>
  deliveryinfo?: InputMaybe<Scalars['String']['input']>
  deliverystatus?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  descriptionhtml?: InputMaybe<Scalars['String']['input']>
  descriptionshort?: InputMaybe<Scalars['String']['input']>
  externalinputtitle?: InputMaybe<Scalars['String']['input']>
  friendly?: InputMaybe<Scalars['String']['input']>
  manufacturerid?: InputMaybe<Scalars['Int']['input']>
  manufacturerurl?: InputMaybe<Scalars['String']['input']>
  moneyfinalprice?: InputMaybe<Scalars['String']['input']>
  moneyofferprice?: InputMaybe<Scalars['Money']['input']>
  moneyprice?: InputMaybe<Scalars['Money']['input']>
  moneypricein?: InputMaybe<Scalars['Money']['input']>
  moneypriceorg?: InputMaybe<Scalars['Money']['input']>
  offerdateend?: InputMaybe<Scalars['UnixDate']['input']>
  offerdatestart?: InputMaybe<Scalars['UnixDate']['input']>
  offerisactive?: InputMaybe<Scalars['Boolean']['input']>
  offerisenabled?: InputMaybe<Scalars['Boolean']['input']>
  offertitle?: InputMaybe<Scalars['String']['input']>
  pricelisthasvolume?: InputMaybe<Scalars['Boolean']['input']>
  productid?: InputMaybe<Scalars['Int']['input']>
  related?: InputMaybe<Array<Scalars['Int']['input']>>
  seo_description?: InputMaybe<Scalars['String']['input']>
  seo_keywords?: InputMaybe<Scalars['String']['input']>
  seo_title?: InputMaybe<Scalars['String']['input']>
  similar?: InputMaybe<Array<Scalars['Int']['input']>>
  sku: Scalars['String']['input']
  skumanufacturer?: InputMaybe<Scalars['String']['input']>
  stockallowbackorder?: InputMaybe<Scalars['Boolean']['input']>
  stockcountenable?: InputMaybe<Scalars['Boolean']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  unitlabel?: InputMaybe<Scalars['String']['input']>
  variantparentid?: InputMaybe<Scalars['Int']['input']>
  vatid?: InputMaybe<Scalars['Int']['input']>
  vatvalue?: InputMaybe<Scalars['Float']['input']>
}

export type VolumePriceLevel = {
  __typename?: 'VolumePriceLevel'
  levelstart?: Maybe<Scalars['Int']['output']>
  moneyprice?: Maybe<Scalars['Money']['output']>
}

export type Warehouse = {
  __typename?: 'Warehouse'
  address?: Maybe<Scalars['String']['output']>
  address2?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  countryid?: Maybe<Scalars['String']['output']>
  currencycode: Scalars['String']['output']
  externalid?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  sellable?: Maybe<Scalars['Boolean']['output']>
  state?: Maybe<Scalars['String']['output']>
  store?: Maybe<Store>
  storeid?: Maybe<Scalars['Int']['output']>
  usetype: WarehouseUseType
  warehouseItems?: Maybe<PaginatedWarehouseItemsResponse>
  warehousetype?: Maybe<WarehouseType>
  zip?: Maybe<Scalars['String']['output']>
}

export type WarehouseWarehouseItemsArgs = {
  datelastmodifiedfrom?: InputMaybe<Scalars['UnixDate']['input']>
  datelastmodifiedto?: InputMaybe<Scalars['UnixDate']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  sku?: InputMaybe<Array<Scalars['String']['input']>>
  start?: InputMaybe<Scalars['Int']['input']>
}

export type WarehouseBatch = {
  __typename?: 'WarehouseBatch'
  comment: Scalars['String']['output']
  datecreated: Scalars['UnixDate']['output']
  emailowner: Scalars['String']['output']
  externalid?: Maybe<Scalars['String']['output']>
  /** @deprecated Should be removed */
  items: PaginatedWarehouseBatchItemsResponse
  reasoncode?: Maybe<Scalars['String']['output']>
  shipmentid?: Maybe<Scalars['Int']['output']>
  transactiontype: WarehouseBatchTransaction
  user?: Maybe<User>
  warehouse?: Maybe<Warehouse>
  warehousebatchid: Scalars['Int']['output']
  warehousebatchstate: WarehouseBatchState
  warehouseid: Scalars['Int']['output']
  warehousetransactionid?: Maybe<Scalars['Int']['output']>
}

export type WarehouseBatchItemsArgs = {
  num?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
}

export type WarehouseBatchItem = {
  __typename?: 'WarehouseBatchItem'
  comment?: Maybe<Scalars['String']['output']>
  datemodified?: Maybe<Scalars['UnixDate']['output']>
  decimalquantity?: Maybe<Scalars['String']['output']>
  emailowner?: Maybe<Scalars['String']['output']>
  moneypricein?: Maybe<Scalars['String']['output']>
  product?: Maybe<Product>
  sku: Scalars['String']['output']
  user?: Maybe<User>
  warehousebatchid?: Maybe<Scalars['Int']['output']>
  warehousebatchitemid: Scalars['Int']['output']
  warehouseitemid?: Maybe<Scalars['Int']['output']>
}

export type WarehouseBatchItemInput = {
  comment?: InputMaybe<Scalars['String']['input']>
  decimalquantity?: InputMaybe<Scalars['Decimal']['input']>
  moneypricein?: InputMaybe<Scalars['String']['input']>
  sku: Scalars['String']['input']
  warehousebatchid?: InputMaybe<Scalars['Int']['input']>
  warehousebatchitemid?: InputMaybe<Scalars['Int']['input']>
  warehouseitemid?: InputMaybe<Scalars['Int']['input']>
}

export enum WarehouseBatchState {
  Cancelled = 'Cancelled',
  Closed = 'Closed',
  Open = 'Open',
}

export type WarehouseBatchStatus = {
  __typename?: 'WarehouseBatchStatus'
  num_items: Scalars['Int']['output']
  num_stocktaking_no_stock_value?: Maybe<Scalars['Int']['output']>
  num_stocktaking_not_counted?: Maybe<Scalars['Int']['output']>
  num_stocktaking_transaction_changes?: Maybe<Scalars['Int']['output']>
  transactiontype: WarehouseBatchTransaction
  warehousebatchid: Scalars['Int']['output']
  warehousebatchstate: WarehouseBatchState
}

export enum WarehouseBatchTransaction {
  ManualIn = 'ManualIn',
  ManualOut = 'ManualOut',
  Refund = 'Refund',
  Sales = 'Sales',
  Stocktaking = 'Stocktaking',
}

export type WarehouseBatchTransactionChange = {
  __typename?: 'WarehouseBatchTransactionChange'
  decimaltranschanges?: Maybe<Scalars['String']['output']>
  sku?: Maybe<Scalars['String']['output']>
}

export enum WarehouseFilterMode {
  And = 'And',
  Or = 'Or',
}

export type WarehouseInput = {
  address?: InputMaybe<Scalars['String']['input']>
  address2?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  countryid?: InputMaybe<Scalars['String']['input']>
  currencycode: Scalars['String']['input']
  externalid?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  sellable?: InputMaybe<Scalars['Boolean']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  storeid?: InputMaybe<Scalars['Int']['input']>
  usetype: WarehouseUseType
  warehouseid?: InputMaybe<Scalars['Int']['input']>
  warehousetype?: InputMaybe<WarehouseType>
  zip?: InputMaybe<Scalars['String']['input']>
}

export type WarehouseItem = {
  __typename?: 'WarehouseItem'
  binlocation?: Maybe<Scalars['String']['output']>
  datelastmodified?: Maybe<Scalars['UnixDate']['output']>
  datelaststocktaking?: Maybe<Scalars['UnixDate']['output']>
  decimalavailable?: Maybe<Scalars['String']['output']>
  decimalreserved?: Maybe<Scalars['String']['output']>
  decimalthreshold?: Maybe<Scalars['String']['output']>
  decimaltotal?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  moneytotal?: Maybe<Scalars['Money']['output']>
  sku: Scalars['String']['output']
  warehouse?: Maybe<Warehouse>
  warehouseid: Scalars['Int']['output']
}

export type WarehouseItemInput = {
  binlocation?: InputMaybe<Scalars['String']['input']>
  datelastmodified?: InputMaybe<Scalars['UnixDate']['input']>
  datelaststocktaking?: InputMaybe<Scalars['UnixDate']['input']>
  decimalavailable?: InputMaybe<Scalars['String']['input']>
  decimalreserved?: InputMaybe<Scalars['String']['input']>
  decimalthreshold?: InputMaybe<Scalars['String']['input']>
  decimaltotal?: InputMaybe<Scalars['String']['input']>
  moneytotal?: InputMaybe<Scalars['Money']['input']>
  sku: Scalars['String']['input']
}

export type WarehouseStockTotal = {
  __typename?: 'WarehouseStockTotal'
  total: Scalars['Int']['output']
  warehouseId: Scalars['Int']['output']
}

export enum WarehouseTransactionType {
  NUMBER_10 = 'NUMBER_10',
  NUMBER_20 = 'NUMBER_20',
  NUMBER_30 = 'NUMBER_30',
  NUMBER_40 = 'NUMBER_40',
  NUMBER_50 = 'NUMBER_50',
}

export enum WarehouseType {
  FIFO = 'FIFO',
  VIRTUAL = 'VIRTUAL',
}

export enum WarehouseUseType {
  BACK = 'BACK',
  DEMO = 'DEMO',
  EXTERNAL = 'EXTERNAL',
  FRONT = 'FRONT',
  RECLAIM = 'RECLAIM',
}

export type Widget = {
  __typename?: 'Widget'
  closes?: Maybe<MetricScalar>
  color?: Maybe<DashboardColor>
  color_series?: Maybe<DashboardColor>
  color_series2?: Maybe<DashboardColor>
  colors?: Maybe<Array<DashboardColor>>
  format?: Maybe<FormatValue>
  height: Scalars['Int']['output']
  label_format?: Maybe<FormatLabel>
  line_type?: Maybe<DashboardWidgetsInnerLineTypeEnum>
  min_num_values?: Maybe<Scalars['Int']['output']>
  min_y_axis_value?: Maybe<MetricScalar>
  opens?: Maybe<MetricScalar>
  rest_label?: Maybe<Scalars['String']['output']>
  series?: Maybe<MetricSeries>
  series_label_format?: Maybe<FormatLabel>
  series_value_format?: Maybe<FormatValue>
  subtitle?: Maybe<Scalars['String']['output']>
  target_angle?: Maybe<Scalars['Int']['output']>
  target_color?: Maybe<DashboardColor>
  target_format?: Maybe<FormatValue>
  target_value?: Maybe<MetricScalar>
  time?: Maybe<MetricScalar>
  title: Scalars['String']['output']
  title_hours?: Maybe<Scalars['String']['output']>
  type: DashboardWidgetsInnerTypeEnum
  value: MetricScalar
  value_format?: Maybe<FormatValue>
  width: Scalars['Int']['output']
}

export type SendFeedbackMutationVariables = Exact<{
  message: Scalars['String']['input']
  path: Scalars['String']['input']
  files: Array<Scalars['Upload']['input']> | Scalars['Upload']['input']
}>

export type SendFeedbackMutation = {
  __typename?: 'Mutation'
  sendFeedback: boolean
}

export type ExportInfluencerCodesMutationVariables = Exact<{
  fileType: FileType
  timeZone?: InputMaybe<Scalars['String']['input']>
}>

export type ExportInfluencerCodesMutation = {
  __typename?: 'Mutation'
  exportInfluencerCodes?: string | null
}

export type ReportDefinitionFilterFragment = {
  __typename?: 'ReportDefinitionFilter'
  orderSearchText?: string | null
  orderDateStart?: string | null
  orderDateEnd?: string | null
  orderState?: Array<ReportOrderState> | null
  orderPaymentState?: OrderPaymentState | null
  orderIsNegative?: boolean | null
  orderHasRegisterId?: boolean | null
  orderRegisterId?: string | null
  orderWarehouseId?: number | null
  orderVoucherName?: string | null
  orderVoucherCode?: string | null
  productManufacturerId?: number | null
  productVatId?: number | null
  productProductGroupType?: ReportProductGroupType | null
  creditNoteDateStart?: string | null
  creditNoteDateEnd?: string | null
  giftCardDateStart?: string | null
  giftCardDateEnd?: string | null
  giftCardType?: GiftCardType | null
  warehouseSearchText?: string | null
  warehouseDateStart?: string | null
  warehouseDateEnd?: string | null
  warehouseId?: Array<number> | null
  warehouseCurrencyCode?: string | null
  warehouseTransactionType?: WarehouseTransactionType | null
  stockDateEnd?: string | null
  salesTaxDateStart?: string | null
  salesTaxDateEnd?: string | null
  salesTaxIsRefund?: boolean | null
  salesTaxGroupRegion?: string | null
  omniFulfillmentDateStart?: string | null
  omniFulfillmentDateEnd?: string | null
  omniFulfillmentStoreSales?: string | null
  omniFulfillmentStoreDelivery?: string | null
}

export type ReportDefinitionFragment = {
  __typename?: 'ReportDefinition'
  groups: Array<ReportGroupingProperty>
  measures: Array<ReportMeasureProperty>
  filters: {
    __typename?: 'ReportDefinitionFilter'
  } & ReportDefinitionFilterFragment
  sort: Array<{
    __typename?: 'ReportDefinitionSort'
    key: ReportDefinitionFilterKey
    asc: boolean
  }>
}

export type ReportFragment = {
  __typename?: 'Report'
  reportid: number
  reporttype: ReportType
  reportname: string
  datemode?: ReportDateMode | null
  forcewarehousefilter?: boolean | null
  franchiseblocked?: boolean | null
  reportdefinition: {
    __typename?: 'ReportDefinition'
  } & ReportDefinitionFragment
}

export type ReportExtendedQueryVariables = Exact<{
  reportId: Scalars['Float']['input']
}>

export type ReportExtendedQuery = {
  __typename?: 'Query'
  report: { __typename?: 'Report' } & ReportFragment
  allWarehouses: Array<{ __typename?: 'Warehouse'; id: number; name: string }>
  allCashRegisters: Array<{
    __typename?: 'CashRegister'
    warehouseid?: number | null
    registerid: string
    registernumber?: number | null
    registerkey?: string | null
  }>
}

export type ReportQueryVariables = Exact<{
  reportId: Scalars['Float']['input']
}>

export type ReportQuery = {
  __typename?: 'Query'
  report: { __typename?: 'Report' } & ReportFragment
}

export type ReportJobsQueryVariables = Exact<{
  reportId: Scalars['Float']['input']
}>

export type ReportJobsQuery = {
  __typename?: 'Query'
  reportJobs: {
    __typename?: 'ReportJobListResponse'
    totalcount?: number | null
    items?: Array<{
      __typename?: 'ReportJob'
      jobid: number
      jobstate: ReportJobState
      jobname?: string | null
      datecreated: string
      dateexecuted?: string | null
      eshopid: number
      langid: ReportJobLangidEnum
      timezone: string
      reportdefinition?: {
        __typename?: 'ReportDefinition'
        filters: {
          __typename?: 'ReportDefinitionFilter'
        } & ReportDefinitionFilterFragment
      } | null
    }> | null
  }
}

export type ReportResultsQueryVariables = Exact<{
  jobId: Scalars['Float']['input']
}>

export type ReportResultsQuery = {
  __typename?: 'Query'
  reportResults: {
    __typename?: 'ReportResults'
    rows: Array<Record<string, unknown>>
    totals: Record<string, unknown>
    columns: Array<{
      __typename?: 'ReportResultColumnsInner'
      key?: string | null
      type?: ReportResultItemValueDisplayType | null
    }>
  }
}

export type AddReportJobMutationVariables = Exact<{
  reportJob: ReportJobInput
}>

export type AddReportJobMutation = {
  __typename?: 'Mutation'
  addReportJob: {
    __typename?: 'ReportJob'
    jobid: number
    eshopid: number
    commoneshopid?: number | null
    jobstate: ReportJobState
    jobname?: string | null
    timezone: string
    langid: ReportJobLangidEnum
    owner?: string | null
    datecreated: string
    dateexecuted?: string | null
    reportid?: number | null
    reporttype?: ReportType | null
    reportdefinition?:
      | ({ __typename?: 'ReportDefinition' } & ReportDefinitionFragment)
      | null
  }
}

export type AddReportMutationVariables = Exact<{
  report: ReportInput
}>

export type AddReportMutation = {
  __typename?: 'Mutation'
  addReport: { __typename?: 'Report' } & ReportFragment
}

export type UpdateReportMutationVariables = Exact<{
  report: ReportInput
}>

export type UpdateReportMutation = {
  __typename?: 'Mutation'
  updateReport: { __typename?: 'Report' } & ReportFragment
}

export type DeleteReportMutationVariables = Exact<{
  reportId: Scalars['Float']['input']
}>

export type DeleteReportMutation = {
  __typename?: 'Mutation'
  deleteReport: boolean
}

export type ReportSummaryFragment = {
  __typename?: 'Report'
  reportid: number
  reportname: string
  reporttype: ReportType
  reportdefinition: {
    __typename?: 'ReportDefinition'
    measures: Array<ReportMeasureProperty>
  }
}

export type ReportDefinitionSortFragment = {
  __typename?: 'ReportDefinitionSort'
  key: ReportDefinitionFilterKey
  asc: boolean
}

export type ReportsQuickQueryVariables = Exact<{
  reportIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
}>

export type ReportsQuickQuery = {
  __typename?: 'Query'
  favoriteReports: {
    __typename?: 'PaginatedReportsResponse'
    items?: Array<{ __typename?: 'Report' } & ReportSummaryFragment> | null
  }
  systemReports: Array<{ __typename?: 'Report' } & ReportSummaryFragment>
}

export type ReportsAllQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  searchtext?: InputMaybe<Scalars['String']['input']>
  reportid?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  reporttype?: InputMaybe<Array<ReportType> | ReportType>
  sort?: InputMaybe<GetReportListSort>
}>

export type ReportsAllQuery = {
  __typename?: 'Query'
  reports: {
    __typename?: 'PaginatedReportsResponse'
    totalcount?: number | null
    start?: number | null
    items?: Array<
      {
        __typename?: 'Report'
        reportdefinition: {
          __typename?: 'ReportDefinition'
          measures: Array<ReportMeasureProperty>
          groups: Array<ReportGroupingProperty>
          filters: {
            __typename?: 'ReportDefinitionFilter'
          } & ReportDefinitionFilterFragment
          sort: Array<
            {
              __typename?: 'ReportDefinitionSort'
            } & ReportDefinitionSortFragment
          >
        }
      } & ReportSummaryFragment
    > | null
  }
}

export type SendResetPasswordLinkMutationVariables = Exact<{
  email: Scalars['String']['input']
  accountId: Scalars['Int']['input']
}>

export type SendResetPasswordLinkMutation = {
  __typename?: 'Mutation'
  sendResetPasswordLink: boolean
}

export type UpdatePasswordMutationVariables = Exact<{
  password: Scalars['String']['input']
  token: Scalars['String']['input']
  accountId: Scalars['Int']['input']
}>

export type UpdatePasswordMutation = {
  __typename?: 'Mutation'
  updatePassword: boolean
}

export type CustomerFragmentFragment = {
  __typename?: 'ServiceOrder'
  customer: {
    __typename?: 'ServiceOrderCustomer'
    id?: string | null
    first_name: string
    last_name?: string | null
    email?: string | null
    mobile?: string | null
  }
}

export type ProductWithImagesFragmentFragment = {
  __typename?: 'ServiceOrder'
  product: {
    __typename?: 'ServiceOrderProduct'
    id?: string | null
    sku: string
    name: string
    serial?: string | null
    totalBeforeShare?: number | null
    totalAfterShare?: number | null
    images?: Array<string> | null
    services: Array<{
      __typename?: 'ServiceOrderItem'
      sku: string
      name: string
      quantity: number
      unit_price?: string | null
      cost_share?: number | null
      note: string
    }>
  }
}

export type UserFragmentFragment = {
  __typename?: 'ServiceOrderUser'
  email: string
  name?: string | null
  employee_id?: string | null
}

export type ServiceOrdersQueryVariables = Exact<{
  sort?: InputMaybe<GetServiceOrdersSort>
  num?: InputMaybe<Scalars['Int']['input']>
  next?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Array<ServiceOrderState> | ServiceOrderState>
  store?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
}>

export type ServiceOrdersQuery = {
  __typename?: 'Query'
  serviceOrders: {
    __typename?: 'PaginatedServiceOrdersResponse'
    next?: string | null
    start?: number | null
    items?: Array<
      {
        __typename?: 'ServiceOrder'
        id?: string | null
        created?: string | null
        state?: ServiceOrderState | null
        currency: string
        note?: string | null
        store: { __typename?: 'ServiceOrderStore'; id: string; name: string }
        customer: {
          __typename?: 'ServiceOrderCustomer'
          id?: string | null
          first_name: string
          last_name?: string | null
        }
      } & ProductWithImagesFragmentFragment
    > | null
  }
}

export type ServiceOrderQueryVariables = Exact<{
  serviceOrderId: Scalars['String']['input']
}>

export type ServiceOrderQuery = {
  __typename?: 'Query'
  serviceOrder: {
    __typename?: 'ServiceOrder'
    version?: string | null
    id?: string | null
    revision?: number | null
    created?: string | null
    modified?: string | null
    state?: ServiceOrderState | null
    currency: string
    note?: string | null
    store: { __typename?: 'ServiceOrderStore'; id: string; name: string }
    owner: { __typename?: 'ServiceOrderUser' } & UserFragmentFragment
    author: { __typename?: 'ServiceOrderUser' } & UserFragmentFragment
  } & CustomerFragmentFragment &
    ProductWithImagesFragmentFragment
}

export type UpdateServiceOrderMutationVariables = Exact<{
  serviceOrder: UpdateServiceOrderInput
}>

export type UpdateServiceOrderMutation = {
  __typename?: 'Mutation'
  updateServiceOrder: {
    __typename?: 'ServiceOrder'
    version?: string | null
    revision?: number | null
    id?: string | null
    state?: ServiceOrderState | null
    currency: string
    note?: string | null
    store: { __typename?: 'ServiceOrderStore'; id: string; name: string }
    owner: { __typename?: 'ServiceOrderUser' } & UserFragmentFragment
    product: {
      __typename?: 'ServiceOrderProduct'
      sku: string
      name: string
      serial?: string | null
      services: Array<{
        __typename?: 'ServiceOrderItem'
        sku: string
        name: string
        quantity: number
        unit_price?: string | null
        cost_share?: number | null
        note: string
      }>
    }
    author: { __typename?: 'ServiceOrderUser' } & UserFragmentFragment
  } & CustomerFragmentFragment
}

export type AddServiceOrderMutationVariables = Exact<{
  serviceOrder: AddServiceOrderInput
}>

export type AddServiceOrderMutation = {
  __typename?: 'Mutation'
  addServiceOrder: {
    __typename?: 'ServiceOrder'
    version?: string | null
    id?: string | null
    revision?: number | null
    created?: string | null
    modified?: string | null
    state?: ServiceOrderState | null
    currency: string
    note?: string | null
    store: { __typename?: 'ServiceOrderStore'; id: string; name: string }
    owner: { __typename?: 'ServiceOrderUser' } & UserFragmentFragment
    product: {
      __typename?: 'ServiceOrderProduct'
      sku: string
      name: string
      serial?: string | null
      totalBeforeShare?: number | null
      totalAfterShare?: number | null
      images?: Array<string> | null
      services: Array<{
        __typename?: 'ServiceOrderItem'
        sku: string
        name: string
        quantity: number
        unit_price?: string | null
        cost_share?: number | null
        note: string
      }>
    }
    author: { __typename?: 'ServiceOrderUser' } & UserFragmentFragment
  } & CustomerFragmentFragment
}

export type ProductGroupsQueryVariables = Exact<{ [key: string]: never }>

export type ProductGroupsQuery = {
  __typename?: 'Query'
  productGroups: {
    __typename?: 'PaginatedProductGroupsResponse'
    totalcount?: number | null
    start?: number | null
    items?: Array<{
      __typename?: 'ProductGroup'
      id: number
      value?: number | null
      type?: ProductGroupType | null
      name: string
      comment?: string | null
    }> | null
  }
}

export type DashboardsQueryVariables = Exact<{ [key: string]: never }>

export type DashboardsQuery = {
  __typename?: 'Query'
  dashboards: Array<{ __typename?: 'Dashboard'; name: string; id: string }>
}

export type DashboardQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type DashboardQuery = {
  __typename?: 'Query'
  dashboard: Record<string, unknown>
  dashboardConfiguration: {
    __typename?: 'DashboardConfig'
  } & DashboardConfigFragment
}

export type ParseInfluencerCodesCsvMutationVariables = Exact<{
  csv: Scalars['String']['input']
}>

export type ParseInfluencerCodesCsvMutation = {
  __typename?: 'Mutation'
  parseInfluencerCodesCsv: {
    __typename?: 'ConvertInfluencerCodesResponse'
    items: Array<{
      __typename?: 'ImportInfluencerCode'
      code: string
      name: string
      voucherpassword: string
      active?: boolean | null
      datestart?: string | null
      dateend?: string | null
      vouchercode?: string | null
      vouchername?: string | null
      vouchercomment?: string | null
      maxuses?: number | null
    }>
    errors: Array<{
      __typename?: 'Error'
      code: string
      property?: string | null
      sizeLimit?: number | null
    }>
  }
}

export type ImportInfluencerCodesMutationVariables = Exact<{
  data: Array<ImportInfluencerCodeInput> | ImportInfluencerCodeInput
}>

export type ImportInfluencerCodesMutation = {
  __typename?: 'Mutation'
  importInfluencerCodes: {
    __typename?: 'ImportResponse'
    imported: number
    failed: number
    deleted: number
  }
}

export type ParsePricesCsvMutationVariables = Exact<{
  csv: Scalars['String']['input']
}>

export type ParsePricesCsvMutation = {
  __typename?: 'Mutation'
  parsePricesCsv: {
    __typename?: 'ConvertPricesResponse'
    items: Array<{
      __typename?: 'ImportPriceItem'
      moneyprice?: string | null
      sku?: string | null
      productid?: string | null
      pricelistid?: string | null
    }>
    errors: Array<{
      __typename?: 'Error'
      code: string
      property?: string | null
    }>
  }
}

export type ParsePurchasePricesCsvMutationVariables = Exact<{
  csv: Scalars['String']['input']
}>

export type ParsePurchasePricesCsvMutation = {
  __typename?: 'Mutation'
  parsePurchasePricesCsv: {
    __typename?: 'ConvertPurchasePricesResponse'
    items: Array<{
      __typename?: 'ImportPurchasePriceItem'
      price_list_id?: string | null
      sku?: string | null
      purchase_price?: number | null
    }>
    errors: Array<{
      __typename?: 'Error'
      code: string
      property?: string | null
    }>
  }
}

export type ImportPricesMutationVariables = Exact<{
  data: Array<ImportPriceItemInput> | ImportPriceItemInput
}>

export type ImportPricesMutation = {
  __typename?: 'Mutation'
  importPrices: {
    __typename?: 'ImportResponse'
    imported: number
    deleted: number
    failed: number
  }
}

export type ImportPurchasePricesMutationVariables = Exact<{
  data: Array<ImportPurchasePriceItemInput> | ImportPurchasePriceItemInput
}>

export type ImportPurchasePricesMutation = {
  __typename?: 'Mutation'
  importPurchasePrices: {
    __typename?: 'ImportResponse'
    imported: number
    deleted: number
    failed: number
  }
}

export type ParseStoresCsvMutationVariables = Exact<{
  csv: Scalars['String']['input']
}>

export type ParseStoresCsvMutation = {
  __typename?: 'Mutation'
  parseStoresCsv: {
    __typename?: 'ConvertStoresResponse'
    items: Array<{
      __typename?: 'ImportStore'
      storeid: number
      eshopid?: number | null
      storetype: number
      name: string
      externalid?: string | null
      externalgroupid?: string | null
      phone?: string | null
      email?: string | null
      address?: string | null
      address2?: string | null
      zip?: string | null
      city?: string | null
      state?: string | null
      countryid?: string | null
      latitude?: string | null
      longitude?: string | null
      nearby_stores?: Array<number> | null
      usetype_movein?: number | null
      usetype_moveout?: number | null
      usetype_manualin?: number | null
      usetype_manualout?: number | null
      usetype_shipmentin?: number | null
      usetype_shipmentout?: number | null
      tags?: Array<string> | null
    }>
    errors: Array<{
      __typename?: 'Error'
      code: string
      property?: string | null
    }>
  }
}

export type ImportStoresMutationVariables = Exact<{
  data: Array<ImportStoreItemInput> | ImportStoreItemInput
}>

export type ImportStoresMutation = {
  __typename?: 'Mutation'
  importStores: {
    __typename?: 'ImportResponse'
    imported: number
    failed: number
    deleted: number
  }
}

export type ParseUsersCsvMutationVariables = Exact<{
  csv: Scalars['String']['input']
}>

export type ParseUsersCsvMutation = {
  __typename?: 'Mutation'
  parseUsersCsv: {
    __typename?: 'ConvertUsersResponse'
    items: Array<{
      __typename?: 'ImportUser'
      email: string
      namefirst?: string | null
      namelast?: string | null
      personalid?: string | null
      externalid?: string | null
      company?: string | null
      department?: string | null
      companyid?: string | null
      address?: string | null
      address2?: string | null
      zip?: string | null
      city?: string | null
      state?: string | null
      countryid?: string | null
      phone?: string | null
      mobile?: string | null
      notes?: string | null
      pin?: string | null
      customernumber?: string | null
      voucherpasswords?: Array<string> | null
      role?: UserRole | null
      warehouses?: Array<number> | null
      posrefundcard?: boolean | null
      posallstores?: boolean | null
      active?: boolean | null
    }>
    errors: Array<{
      __typename?: 'Error'
      code: string
      property?: string | null
    }>
  }
}

export type ImportUsersMutationVariables = Exact<{
  data: Array<ImportUserInput> | ImportUserInput
}>

export type ImportUsersMutation = {
  __typename?: 'Mutation'
  importUsers: {
    __typename?: 'ImportResponse'
    imported: number
    deleted: number
    failed: number
  }
}

export type ParseWarehousesCsvMutationVariables = Exact<{
  csv: Scalars['String']['input']
}>

export type ParseWarehousesCsvMutation = {
  __typename?: 'Mutation'
  parseWarehousesCsv: {
    __typename?: 'ConvertWarehousesResponse'
    items: Array<{
      __typename?: 'ImportWarehouse'
      name: string
      warehouseid?: number | null
      warehousetype?: number | null
      usetype?: number | null
      storeid?: number | null
      sellable?: boolean | null
      currencycode: string
      address?: string | null
      address2?: string | null
      zip?: string | null
      city?: string | null
      state?: string | null
      countryid?: string | null
      externalid?: string | null
    }>
    errors: Array<{
      __typename?: 'Error'
      code: string
      property?: string | null
    }>
  }
}

export type ImportWarehousesMutationVariables = Exact<{
  data: Array<ImportWarehouseInput> | ImportWarehouseInput
}>

export type ImportWarehousesMutation = {
  __typename?: 'Mutation'
  importWarehouses: {
    __typename?: 'ImportResponse'
    imported: number
    failed: number
    deleted: number
  }
}

export type PosPaymentSettingsFragmentFragment = {
  __typename?: 'Settings'
  payments_refund_manual?: Array<number> | null
  payments_refund_validated?: Array<number> | null
  payments_refund_validated_omni?: Array<number> | null
  payments_sales_gift_card?: Array<number> | null
  payments_sales_non_delivered?: Array<number> | null
}

export type AllPaymentTypesQueryVariables = Exact<{ [key: string]: never }>

export type AllPaymentTypesQuery = {
  __typename?: 'Query'
  allPaymentTypes: Array<{
    __typename?: 'PaymentType'
    paymenttypeid?: number | null
    paymentmethodid: PosPaymentMethod
    name: string
    externalid?: string | null
    sortorder?: number | null
    iconUrl?: string | null
  }>
}

export type AvailablePaymentMethodsQueryVariables = Exact<{
  [key: string]: never
}>

export type AvailablePaymentMethodsQuery = {
  __typename?: 'Query'
  availablePaymentMethods: Array<PosPaymentMethod>
}

export type GetPaymentTypeQueryVariables = Exact<{
  id: Scalars['Float']['input']
}>

export type GetPaymentTypeQuery = {
  __typename?: 'Query'
  getPaymentType: {
    __typename?: 'PaymentType'
    paymenttypeid?: number | null
    paymentmethodid: PosPaymentMethod
    name: string
    externalid?: string | null
    sortorder?: number | null
    iconUrl?: string | null
  }
}

export type AddPaymentTypeMutationVariables = Exact<{
  paymentType: AddPaymentTypeInput
}>

export type AddPaymentTypeMutation = {
  __typename?: 'Mutation'
  addPaymentType: {
    __typename?: 'PaymentType'
    paymentmethodid: PosPaymentMethod
    name: string
    externalid?: string | null
    paymenttypeid?: number | null
  }
}

export type UpdatePaymentTypeMutationVariables = Exact<{
  paymentType: UpdatePaymentTypeInput
}>

export type UpdatePaymentTypeMutation = {
  __typename?: 'Mutation'
  updatePaymentType: {
    __typename?: 'PaymentType'
    paymenttypeid?: number | null
    paymentmethodid: PosPaymentMethod
    name: string
    externalid?: string | null
    sortorder?: number | null
  }
}

export type DeletePaymentTypeMutationVariables = Exact<{
  deletePaymentTypeId: Scalars['Float']['input']
}>

export type DeletePaymentTypeMutation = {
  __typename?: 'Mutation'
  deletePaymentType: boolean
}

export type PosPaymentSettingsQueryVariables = Exact<{ [key: string]: never }>

export type PosPaymentSettingsQuery = {
  __typename?: 'Query'
  settings: { __typename?: 'Settings' } & PosPaymentSettingsFragmentFragment
}

export type UpdatePaymentSettingsMutationVariables = Exact<{
  settings: UpdateSettingsInput
}>

export type UpdatePaymentSettingsMutation = {
  __typename?: 'Mutation'
  updateSettings: {
    __typename?: 'Settings'
  } & PosPaymentSettingsFragmentFragment
}

export type GetPluginsQueryVariables = Exact<{
  pluginId: PluginId
}>

export type GetPluginsQuery = {
  __typename?: 'Query'
  plugin:
    | {
        __typename?: 'PluginAdyenApi'
        id?: PluginId | null
        keyidentifier?: string | null
        keypassphrase?: string | null
        keyversion?: number | null
        istestmode?: boolean | null
      }
    | {
        __typename?: 'PluginAdyenTapToPay'
        id?: PluginId | null
        api_key?: string | null
        merchant_account?: string | null
        is_test_mode?: boolean | null
      }
    | {
        __typename?: 'PluginCtuNorway'
        id?: PluginId | null
        companyId?: string | null
        companyName?: string | null
        currencyCode?: string | null
        taxRegistrationCountry?: string | null
        dateFiscalFirst?: string | null
        dateFiscalSecond?: string | null
        taxRegistrationNumber?: string | null
      }
    | {
        __typename?: 'PluginCtuPortugal'
        id?: PluginId | null
        taxRegistrationNumberPortugal?: number | null
        companyId?: string | null
        companyName?: string | null
        companyAddress?: string | null
        companyZip?: string | null
        companyCity?: string | null
        currencyCode?: string | null
        taxEntity?: string | null
        taxCountryRegion?: PluginCtuPortugalTaxCountryRegionEnum | null
        dateFiscalFirst?: string | null
        dateFiscalSecond?: string | null
      }
    | {
        __typename?: 'PluginCustomGiftCard'
        id?: PluginId | null
        api_key?: string | null
        api_url?: string | null
      }
    | {
        __typename?: 'PluginCustomPayment'
        id?: PluginId | null
        api_key?: string | null
        api_url?: string | null
        bg_payment?: string | null
        bg_payment_small?: string | null
        icon_payment?: string | null
        icon_payment_small?: string | null
        required_customer_info?: Array<PluginCustomPaymentRequiredCustomerInfoEnum> | null
      }
    | {
        __typename?: 'PluginCustomProductSearch'
        id?: PluginId | null
        api_url?: string | null
        api_key?: string | null
      }
    | {
        __typename?: 'PluginGivex'
        id?: PluginId | null
        userId?: string | null
        userPassword?: string | null
        hostPrimary?: string | null
        hostSecondary?: string | null
        extendedLogging?: boolean | null
      }
    | {
        __typename?: 'PluginKlarnaV3'
        id?: PluginId | null
        username?: string | null
        password?: string | null
        klarna_region?: PluginKlarnaV3RegionEnum | null
        profileid?: PluginKlarnaV3ProfileidEnum | null
        profileid_selfcheckout?: PluginKlarnaV3ProfileidSelfcheckoutEnum | null
      }
    | {
        __typename?: 'PluginNetsBaxiLogs'
        id?: PluginId | null
        region?: string | null
        bucket?: string | null
        access_key_id?: string | null
        access_key_secret?: string | null
        path?: PluginNetsBaxiLogsPathEnum | null
        url?: string | null
      }
    | {
        __typename?: 'PluginNetsCurrencyConversion'
        id?: PluginId | null
        payment_types: Array<{
          __typename?: 'PluginNetsCurrencyConversionPaymentType'
          bax_number?: string | null
          currency_code?: string | null
          payment_type_id?: number | null
        }>
      }
    | {
        __typename?: 'PluginNosto'
        id?: PluginId | null
        graphQLToken?: string | null
        graphQLRecordRequests: boolean
      }
    | {
        __typename?: 'PluginProductSearch'
        id?: PluginId | null
        external_search_active: boolean
        external_scan_to_cart: boolean
        external_search_title: string
        filters: Array<{
          __typename?: 'PluginProductSearchFilter'
          id: string
          title?: string | null
          type: PluginProductSearchFiltersInnerTypeEnum
        }>
      }
    | { __typename?: 'PluginRetain24' }
    | { __typename?: 'PluginSelfCheckout' }
    | {
        __typename?: 'PluginSwish'
        id?: PluginId | null
        payee_alias: string
        message_payment: string
        message_refund: string
        moneylimitmin: string
        selfcheckoutenabled: boolean
        certificate_public: string
        certificate_priavte: string
      }
    | {
        __typename?: 'PluginSwitchboard'
        id?: PluginId | null
        handler_giftcards?: PluginSwitchboardHandlerGiftcardsEnum | null
        handler_recommendations?: PluginSwitchboardHandlerRecommendationsEnum | null
      }
    | { __typename?: 'PluginTaxFree'; id?: PluginId | null; active: boolean }
    | {
        __typename?: 'PluginZebra'
        id?: PluginId | null
        templates?: Array<{
          __typename?: 'PluginZebraTemplate'
          id: number
          name?: string | null
          type?: PluginZebraTemplatesInnerTypeEnum | null
          data?: string | null
        }> | null
      }
}

export type UpdateAdyenApiPluginMutationVariables = Exact<{
  data: PluginAdyenApiInput
}>

export type UpdateAdyenApiPluginMutation = {
  __typename?: 'Mutation'
  updateAdyenApi: {
    __typename?: 'PluginAdyenApi'
    id?: PluginId | null
    keyidentifier?: string | null
    keypassphrase?: string | null
    keyversion?: number | null
    istestmode?: boolean | null
  }
}

export type UpdateAdyenTapToPayMutationVariables = Exact<{
  data: PluginAdyenTapToPayInput
}>

export type UpdateAdyenTapToPayMutation = {
  __typename?: 'Mutation'
  updateAdyenTapToPay: {
    __typename?: 'PluginAdyenTapToPay'
    id?: PluginId | null
    merchant_account?: string | null
    api_key?: string | null
    is_test_mode?: boolean | null
  }
}

export type UpdateCtuNorwayMutationVariables = Exact<{
  data: PluginCtuNorwayInput
}>

export type UpdateCtuNorwayMutation = {
  __typename?: 'Mutation'
  updateCtuNorway: {
    __typename?: 'PluginCtuNorway'
    id?: PluginId | null
    companyId?: string | null
    companyName?: string | null
    currencyCode?: string | null
    taxRegistrationNumber?: string | null
    taxRegistrationCountry?: string | null
    dateFiscalFirst?: string | null
    dateFiscalSecond?: string | null
  }
}

export type PosGeneralSettingsFragmentFragment = {
  __typename?: 'Settings'
  bank_bag_id_mode?: SettingsBankBagIdModeEnum | null
  receipt_staff_display?: SettingsReceiptStaffDisplayEnum | null
  compress_variant_info?: boolean | null
  scanner_ean13_remove_leading_zero?: boolean | null
  limit_open_cash_drawer?: boolean | null
  pos_inventory_features?: Array<SettingsPosInventoryFeaturesEnum> | null
  warn_close_with_open_orders?: boolean | null
}

export type PosCartSettingsFragmentFragment = {
  __typename?: 'Settings'
  cart_item_price_display_mode?: SettingsCartItemPriceDisplayModeEnum | null
  cart_print_pick_list_mode?: SettingsCartPrintPickListModeEnum | null
  cash_counting_mode?: SettingsCashCountingModeEnum | null
}

export type PosRequiredFieldsFragmentFragment = {
  __typename?: 'Settings'
  required_fields_booking?: Array<PosOrderField> | null
  required_fields_pickup?: Array<PosOrderField> | null
  required_fields_delivery?: Array<PosOrderField> | null
}

export type PosGeneralSettingsQueryVariables = Exact<{ [key: string]: never }>

export type PosGeneralSettingsQuery = {
  __typename?: 'Query'
  settings: { __typename?: 'Settings' } & PosGeneralSettingsFragmentFragment
}

export type PosCartSettingsQueryVariables = Exact<{ [key: string]: never }>

export type PosCartSettingsQuery = {
  __typename?: 'Query'
  settings: { __typename?: 'Settings' } & PosCartSettingsFragmentFragment
}

export type PosRequiredFieldsSettingsQueryVariables = Exact<{
  [key: string]: never
}>

export type PosRequiredFieldsSettingsQuery = {
  __typename?: 'Query'
  settings: { __typename?: 'Settings' } & PosRequiredFieldsFragmentFragment
}

export type UpdateGeneralSettingsMutationVariables = Exact<{
  settings: UpdateSettingsInput
}>

export type UpdateGeneralSettingsMutation = {
  __typename?: 'Mutation'
  updateSettings: {
    __typename?: 'Settings'
  } & PosGeneralSettingsFragmentFragment
}

export type UpdatePosCartSettingsMutationVariables = Exact<{
  settings: UpdateSettingsInput
}>

export type UpdatePosCartSettingsMutation = {
  __typename?: 'Mutation'
  updateSettings: { __typename?: 'Settings' } & PosCartSettingsFragmentFragment
}

export type UpdateRequiredFieldsSettingsMutationVariables = Exact<{
  settings: UpdateSettingsInput
}>

export type UpdateRequiredFieldsSettingsMutation = {
  __typename?: 'Mutation'
  updateSettings: {
    __typename?: 'Settings'
  } & PosRequiredFieldsFragmentFragment
}

export type DeleteReasonCodeMutationVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type DeleteReasonCodeMutation = {
  __typename?: 'Mutation'
  deleteReasonCode: boolean
}

export type UpdateReasonCodeMutationVariables = Exact<{
  id: Scalars['Int']['input']
  reasonCode: ReasonCodeInput
}>

export type UpdateReasonCodeMutation = {
  __typename?: 'Mutation'
  updateReasonCode: {
    __typename?: 'ReasonCode'
    reasoncodeid: number
    type: ReasonCodeType
    reasoncode: string
    name?: string | null
  }
}

export type AddReasonCodeMutationVariables = Exact<{
  reasonCode: ReasonCodeInput
}>

export type AddReasonCodeMutation = {
  __typename?: 'Mutation'
  addReasonCode: number
}

export type ReasonCodeQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type ReasonCodeQuery = {
  __typename?: 'Query'
  reasonCode: {
    __typename?: 'ReasonCode'
    reasoncodeid: number
    type: ReasonCodeType
    reasoncode: string
    name?: string | null
  }
}

export type SalesTaxFieldsFragment = {
  __typename?: 'SalesTax'
  id: number
  name: string
  code?: string | null
  decimalvaluedefault: string
}

export type SalesTaxProductGroupRulesFieldsFragment = {
  __typename?: 'SalesTaxProductGroupRule'
  vatid?: number | null
  type?: SalesTaxProductGroupRuleType | null
  moneymin?: string | null
  moneymax?: string | null
  decimalvalue: string
  externalid?: string | null
}

export type SalesTaxesQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
}>

export type SalesTaxesQuery = {
  __typename?: 'Query'
  salesTaxes: {
    __typename?: 'PaginatedSalesTaxesResponse'
    totalcount?: number | null
    start?: number | null
    items?: Array<
      {
        __typename?: 'SalesTax'
        productgrouprules?: Array<
          {
            __typename?: 'SalesTaxProductGroupRule'
            groupName?: string | null
          } & SalesTaxProductGroupRulesFieldsFragment
        > | null
      } & SalesTaxFieldsFragment
    > | null
  }
}

export type AllSalesTaxesQueryVariables = Exact<{ [key: string]: never }>

export type AllSalesTaxesQuery = {
  __typename?: 'Query'
  allSalesTaxes: Array<{
    __typename?: 'SalesTax'
    id: number
    name: string
    decimalvaluedefault: string
    productgrouprules?: Array<{
      __typename?: 'SalesTaxProductGroupRule'
      groupName?: string | null
      decimalvalue: string
      vatid?: number | null
    }> | null
  }>
}

export type SalesTaxQueryVariables = Exact<{
  salesTaxId: Scalars['Int']['input']
}>

export type SalesTaxQuery = {
  __typename?: 'Query'
  salesTax: {
    __typename?: 'SalesTax'
    productgrouprules?: Array<
      {
        __typename?: 'SalesTaxProductGroupRule'
        groupName?: string | null
      } & SalesTaxProductGroupRulesFieldsFragment
    > | null
  } & SalesTaxFieldsFragment
}

export type UpdateSalesTaxMutationVariables = Exact<{
  data: SalesTaxInput
  updateSalesTaxId: Scalars['Int']['input']
}>

export type UpdateSalesTaxMutation = {
  __typename?: 'Mutation'
  updateSalesTax: {
    __typename?: 'SalesTax'
    productgrouprules?: Array<
      {
        __typename?: 'SalesTaxProductGroupRule'
      } & SalesTaxProductGroupRulesFieldsFragment
    > | null
  } & SalesTaxFieldsFragment
}

export type AddSalesTaxMutationVariables = Exact<{
  data: SalesTaxInput
}>

export type AddSalesTaxMutation = {
  __typename?: 'Mutation'
  addSalesTax: {
    __typename?: 'SalesTax'
    productgrouprules?: Array<
      {
        __typename?: 'SalesTaxProductGroupRule'
      } & SalesTaxProductGroupRulesFieldsFragment
    > | null
  } & SalesTaxFieldsFragment
}

export type DeleteSalesTaxMutationVariables = Exact<{
  deleteSalesTaxId: Scalars['Int']['input']
}>

export type DeleteSalesTaxMutation = {
  __typename?: 'Mutation'
  deleteSalesTax: boolean
}

export type SalesTaxGroupsQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
}>

export type SalesTaxGroupsQuery = {
  __typename?: 'Query'
  salesTaxGroups: {
    __typename?: 'PaginatedSalesTaxGroupsResponse'
    totalcount?: number | null
    start?: number | null
    items?: Array<{
      __typename?: 'SalesTaxGroup'
      id: number
      name: string
      region?: string | null
      salestaxes: Array<
        {
          __typename?: 'SalesTax'
          productgrouprules?: Array<
            {
              __typename?: 'SalesTaxProductGroupRule'
              groupName?: string | null
            } & SalesTaxProductGroupRulesFieldsFragment
          > | null
        } & SalesTaxFieldsFragment
      >
    }> | null
  }
}

export type SalesTaxGroupQueryVariables = Exact<{
  salesTaxGroupId: Scalars['Int']['input']
}>

export type SalesTaxGroupQuery = {
  __typename?: 'Query'
  salesTaxGroup: {
    __typename?: 'SalesTaxGroup'
    id: number
    name: string
    region?: string | null
    salestaxes: Array<
      {
        __typename?: 'SalesTax'
        productgrouprules?: Array<
          {
            __typename?: 'SalesTaxProductGroupRule'
            groupName?: string | null
          } & SalesTaxProductGroupRulesFieldsFragment
        > | null
      } & SalesTaxFieldsFragment
    >
  }
}

export type AddSalesTaxGroupMutationVariables = Exact<{
  data: SalesTaxGroupInput
}>

export type AddSalesTaxGroupMutation = {
  __typename?: 'Mutation'
  addSalesTaxGroup: {
    __typename?: 'SalesTaxGroup'
    id: number
    name: string
    region?: string | null
    salestaxes: Array<
      {
        __typename?: 'SalesTax'
        productgrouprules?: Array<
          {
            __typename?: 'SalesTaxProductGroupRule'
          } & SalesTaxProductGroupRulesFieldsFragment
        > | null
      } & SalesTaxFieldsFragment
    >
  }
}

export type UpdateSalesTaxGroupMutationVariables = Exact<{
  data: SalesTaxGroupInput
  updateSalesTaxGroupId: Scalars['Int']['input']
}>

export type UpdateSalesTaxGroupMutation = {
  __typename?: 'Mutation'
  updateSalesTaxGroup: {
    __typename?: 'SalesTaxGroup'
    id: number
    name: string
    region?: string | null
    salestaxes: Array<
      {
        __typename?: 'SalesTax'
        productgrouprules?: Array<
          {
            __typename?: 'SalesTaxProductGroupRule'
          } & SalesTaxProductGroupRulesFieldsFragment
        > | null
      } & SalesTaxFieldsFragment
    >
  }
}

export type DeleteSalesTaxGroupMutationVariables = Exact<{
  deleteSalesTaxGroupId: Scalars['Int']['input']
}>

export type DeleteSalesTaxGroupMutation = {
  __typename?: 'Mutation'
  deleteSalesTaxGroup: boolean
}

export type SettingsStoresQueryVariables = Exact<{
  siteId?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<GetStoresSort>
}>

export type SettingsStoresQuery = {
  __typename?: 'Query'
  stores: Array<{
    __typename?: 'Store'
    id: number
    externalid?: string | null
    externalgroupid?: string | null
    name: string
    storetype: StoreType
    tags?: Array<string> | null
    address?: string | null
    address2?: string | null
    nearby_stores?: Array<number> | null
    warehouses?: Array<{ __typename?: 'Warehouse'; id: number }> | null
  }>
}

export type SettingsStoreFragment = {
  __typename?: 'Store'
  id: number
  eshopid?: number | null
  storetype: StoreType
  name: string
  externalid?: string | null
  externalgroupid?: string | null
  phone?: string | null
  email?: string | null
  address?: string | null
  address2?: string | null
  zip?: string | null
  city?: string | null
  state?: string | null
  countryid?: string | null
  latitude?: string | null
  longitude?: string | null
  nearby_stores?: Array<number> | null
  usetype_movein?: WarehouseUseType | null
  usetype_moveout?: WarehouseUseType | null
  usetype_manualin?: WarehouseUseType | null
  usetype_manualout?: WarehouseUseType | null
  usetype_shipmentin?: WarehouseUseType | null
  usetype_shipmentout?: WarehouseUseType | null
  tags?: Array<string> | null
  additional_data?: Record<string, unknown> | null
}

export type SettingsStoreQueryVariables = Exact<{
  storeId: Scalars['Int']['input']
}>

export type SettingsStoreQuery = {
  __typename?: 'Query'
  store: { __typename?: 'Store' } & SettingsStoreFragment
}

export type AddUpdateSettingsStoreMutationVariables = Exact<{
  store: StoreInput
}>

export type AddUpdateSettingsStoreMutation = {
  __typename?: 'Mutation'
  addUpdateStore: { __typename?: 'Store' } & SettingsStoreFragment
}

export type DeleteSettingsStoreMutationVariables = Exact<{
  storeId: Scalars['Int']['input']
}>

export type DeleteSettingsStoreMutation = {
  __typename?: 'Mutation'
  deleteStore: boolean
}

export type ExportStoresMutationVariables = Exact<{
  fileType: FileType
}>

export type ExportStoresMutation = {
  __typename?: 'Mutation'
  exportStores?: string | null
}

export type GetUserTagsQueryVariables = Exact<{
  tagIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
}>

export type GetUserTagsQuery = {
  __typename?: 'Query'
  getUserTags: Array<{ __typename?: 'UserTag'; id: string; label: string }>
}

export type AddUserTagMutationVariables = Exact<{
  label: Scalars['String']['input']
}>

export type AddUserTagMutation = { __typename?: 'Mutation'; addUserTag: string }

export type UpdateUserTagMutationVariables = Exact<{
  userTag: UserTagInput
}>

export type UpdateUserTagMutation = {
  __typename?: 'Mutation'
  updateUserTag: boolean
}

export type DeleteUserTagMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type DeleteUserTagMutation = {
  __typename?: 'Mutation'
  deleteUserTag: boolean
}

export type SettingsWarehouseFragment = {
  __typename?: 'Warehouse'
  id: number
  usetype: WarehouseUseType
  currencycode: string
  name: string
  warehousetype?: WarehouseType | null
  storeid?: number | null
  sellable?: boolean | null
  address?: string | null
  address2?: string | null
  zip?: string | null
  city?: string | null
  state?: string | null
  countryid?: string | null
  externalid?: string | null
  store?: { __typename?: 'Store'; id: number; name: string } | null
}

export type SettingsWarehousesQueryVariables = Exact<{
  sort?: InputMaybe<GetWarehousesSort>
  storeid?: InputMaybe<Scalars['Int']['input']>
}>

export type SettingsWarehousesQuery = {
  __typename?: 'Query'
  allWarehouses: Array<{ __typename?: 'Warehouse' } & SettingsWarehouseFragment>
}

export type SettingsWarehouseQueryVariables = Exact<{
  warehouseId: Scalars['Int']['input']
}>

export type SettingsWarehouseQuery = {
  __typename?: 'Query'
  warehouse: { __typename?: 'Warehouse' } & SettingsWarehouseFragment
}

export type AddUpdateSettingsWarehouseMutationVariables = Exact<{
  warehouse: WarehouseInput
}>

export type AddUpdateSettingsWarehouseMutation = {
  __typename?: 'Mutation'
  addUpdateWarehouse: { __typename?: 'Warehouse' } & SettingsWarehouseFragment
}

export type DeleteSettingsWarehouseMutationVariables = Exact<{
  warehouseId: Scalars['Int']['input']
}>

export type DeleteSettingsWarehouseMutation = {
  __typename?: 'Mutation'
  deleteWarehouse: boolean
}

export type ExportWarehousesMutationVariables = Exact<{
  fileType: FileType
}>

export type ExportWarehousesMutation = {
  __typename?: 'Mutation'
  exportWarehouses?: string | null
}

export type InventoryProductBatchesQueryVariables = Exact<{
  num?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<GetInventoryBatchesSort>
  warehouseId: Scalars['Int']['input']
  searchText?: InputMaybe<Scalars['String']['input']>
  warehouseBatchId: Scalars['Int']['input']
  categoryIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  productGroupIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  manufacturerIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  productState?: InputMaybe<InventoryProductState>
  inventoryState?: InputMaybe<InventoryItemBatchState>
  siteId?: InputMaybe<Scalars['Int']['input']>
  includeCustomFields: Scalars['Boolean']['input']
}>

export type InventoryProductBatchesQuery = {
  __typename?: 'Query'
  productWarehouseBatches: {
    __typename?: 'PaginatedBatchItemsResponse'
    totalcount?: number | null
    start?: number | null
    items?: Array<{
      __typename?: 'InventoryItemBatch'
      activestate?: InventoryItemState | null
      binlocation?: string | null
      comment?: string | null
      datemodified?: string | null
      decimalavailable: string
      decimalcounteddiff?: string | null
      decimalnostockvalue?: string | null
      decimalquantity?: string | null
      decimalreserved: string
      decimalthreshold: string
      decimaltotal: string
      decimaltranschanges?: string | null
      emailowner?: string | null
      images?: Array<string> | null
      productid?: number | null
      sku: string
      title?: string | null
      warehousebatchitemstate?: InventoryItemBatchItemState | null
      manufacturerid?: number | null
      custom1?: string | null
      custom2?: string | null
      variant?: Array<{
        __typename?: 'ProductVariantItem'
        name?: string | null
        value?: string | null
      }> | null
      manufacturer?: {
        __typename?: 'Manufacturer'
        id: number
        name: string
      } | null
    }> | null
  }
}

export type UpdateWarehouseBatchItems2MutationVariables = Exact<{
  warehouseId: Scalars['Int']['input']
  warehouseBatchId: Scalars['Int']['input']
  warehouseBatchItems: Array<WarehouseBatchItemInput> | WarehouseBatchItemInput
  siteId?: InputMaybe<Scalars['Int']['input']>
}>

export type UpdateWarehouseBatchItems2Mutation = {
  __typename?: 'Mutation'
  updateWarehouseBatchItems2: Array<{
    __typename?: 'InventoryItemBatch'
    sku: string
    warehousebatchid: number
    warehousebatchitemid?: number | null
    warehousebatchitemstate?: InventoryItemBatchItemState | null
    decimalquantity?: string | null
    decimalcounteddiff?: string | null
    decimalnostockvalue?: string | null
    moneypricein?: string | null
    emailowner?: string | null
    datemodified?: string | null
    comment?: string | null
  }>
}

export type ExportProductWarehouseBatchItemsMutationVariables = Exact<{
  fileType: FileType
  sort?: InputMaybe<GetInventoryBatchesSort>
  warehouseId: Scalars['Int']['input']
  searchText?: InputMaybe<Scalars['String']['input']>
  warehouseBatchId: Scalars['Int']['input']
  categoryIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  productGroupIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  manufacturerIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  productState?: InputMaybe<InventoryProductState>
  inventoryState?: InputMaybe<InventoryItemBatchState>
  siteId?: InputMaybe<Scalars['Int']['input']>
}>

export type ExportProductWarehouseBatchItemsMutation = {
  __typename?: 'Mutation'
  exportProductWarehouseBatchItems?: string | null
}

export type GetWarehouseBatchStatusQueryVariables = Exact<{
  warehouseBatchId: Scalars['Int']['input']
  warehouseId: Scalars['Int']['input']
  includeNoStockValue: Scalars['Boolean']['input']
}>

export type GetWarehouseBatchStatusQuery = {
  __typename?: 'Query'
  warehouseBatchStatus: {
    __typename?: 'WarehouseBatchStatus'
    warehousebatchid: number
    warehousebatchstate: WarehouseBatchState
    transactiontype: WarehouseBatchTransaction
    num_items: number
    num_stocktaking_not_counted?: number | null
    num_stocktaking_transaction_changes?: number | null
    num_stocktaking_no_stock_value?: number | null
  }
}

export type StocktakingTransactionsQueryVariables = Exact<{
  warehousebatchid: Scalars['Int']['input']
  warehouseid: Scalars['Int']['input']
}>

export type StocktakingTransactionsQuery = {
  __typename?: 'Query'
  stocktakingTransactions: Array<{
    __typename?: 'WarehouseBatchTransactionChange'
    decimaltranschanges?: string | null
    sku?: string | null
  }>
}

export type UpdateBatchItemsRelativeMutationVariables = Exact<{
  warehouseBatchItems: Array<WarehouseBatchItemInput> | WarehouseBatchItemInput
  warehouseBatchId: Scalars['Int']['input']
  warehouseId: Scalars['Int']['input']
}>

export type UpdateBatchItemsRelativeMutation = {
  __typename?: 'Mutation'
  updateBatchItemsRelative: Array<{
    __typename?: 'InventoryItemBatch'
    sku: string
    warehousebatchitemstate?: InventoryItemBatchItemState | null
    decimalavailable: string
    decimalcounteddiff?: string | null
    decimalnostockvalue?: string | null
    decimalquantity?: string | null
    decimalreserved: string
    decimalthreshold: string
    decimaltotal: string
    decimaltranschanges?: string | null
  }>
}

export type AllWarehouseBatchesQueryVariables = Exact<{
  num: Scalars['Int']['input']
  start: Scalars['Int']['input']
  transactionType?: InputMaybe<WarehouseBatchTransaction>
  batchState?: InputMaybe<WarehouseBatchState>
}>

export type AllWarehouseBatchesQuery = {
  __typename?: 'Query'
  allWarehouseBatches: {
    __typename?: 'PaginatedWarehouseBatchesResponse'
    totalcount?: number | null
    start?: number | null
    items?: Array<{
      __typename?: 'WarehouseBatch'
      warehousebatchid: number
      warehouseid: number
      warehousetransactionid?: number | null
      transactiontype: WarehouseBatchTransaction
      datecreated: string
      warehousebatchstate: WarehouseBatchState
      emailowner: string
      comment: string
      shipmentid?: number | null
      externalid?: string | null
      reasoncode?: string | null
      warehouse?: {
        __typename?: 'Warehouse'
        name: string
        id: number
        store?: { __typename?: 'Store'; id: number; name: string } | null
      } | null
      user?: {
        __typename?: 'User'
        namefirst?: string | null
        namelast?: string | null
        email: string
      } | null
    }> | null
  }
}

export type DashboardConfigFragment = {
  __typename?: 'DashboardConfig'
  separator_comma: string
  separator_thousands: string
  format_date_time_full: string
  format_date_time: string
  format_date: string
  format_time: string
  currency_prepend: string
  currency_append: string
  num_decimals: number
}

export type DashboardDataQueryVariables = Exact<{
  dashboardId: Scalars['String']['input']
  timestamp: Scalars['Int']['input']
  timeZone: Scalars['String']['input']
  storeId: Scalars['String']['input']
  userIdStaff: Scalars['String']['input']
}>

export type DashboardDataQuery = {
  __typename?: 'Query'
  dashboardData: {
    __typename?: 'DashboardData'
    templateUrl: string
    dashboard: Record<string, unknown>
  }
  dashboardConfiguration: {
    __typename?: 'DashboardConfig'
  } & DashboardConfigFragment
}

export type UserFieldsFragment = {
  __typename?: 'User'
  id?: string | null
  personalid?: string | null
  externalid?: string | null
  role?: UserRole | null
  email: string
  namefirst?: string | null
  namelast?: string | null
  company?: string | null
  department?: string | null
  companyid?: string | null
  address?: string | null
  address2?: string | null
  zip?: string | null
  city?: string | null
  state?: string | null
  countryid?: string | null
  phone?: string | null
  mobile?: string | null
  notes?: string | null
  customernumber?: string | null
  datecreated?: string | null
  datemodified?: string | null
  voucherpasswords?: Array<string> | null
  warehouses?: Array<number> | null
  posrefundcard?: boolean | null
  staff_pos_activation?: boolean | null
  posallstores?: boolean | null
  active?: boolean | null
  tags?: Array<string> | null
  timezone?: string | null
  hasmfa?: boolean | null
  allWarehouses?: Array<{
    __typename?: 'Warehouse'
    id: number
    name: string
  }> | null
}

export type AddUserMutationVariables = Exact<{
  data: AddUserInput
}>

export type AddUserMutation = {
  __typename?: 'Mutation'
  addUser: { __typename?: 'User' } & UserFieldsFragment
}

export type UpdateUserMutationVariables = Exact<{
  data: UpdateUserInput
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  updateUser: { __typename?: 'User' } & UserFieldsFragment
}

export type UpdateUsersMutationVariables = Exact<{
  users: Array<UpdateUserInput> | UpdateUserInput
}>

export type UpdateUsersMutation = {
  __typename?: 'Mutation'
  updateUsers: Array<{ __typename?: 'User' } & UserFieldsFragment>
}

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['String']['input']
}>

export type DeleteUserMutation = {
  __typename?: 'Mutation'
  deleteUser: boolean
}

export type UserPermissionsFieldsFragment = {
  __typename?: 'UserPermissions'
  changeEmail?: boolean | null
  changePin?: boolean | null
  changePassword?: boolean | null
  changeActive?: boolean | null
  changeRole?: boolean | null
  changeAccess?: boolean | null
  addMfa?: boolean | null
  viewMfa?: boolean | null
  removeMfa?: boolean | null
  viewUser?: boolean | null
  addUser?: boolean | null
  editUser?: boolean | null
  deleteUser?: boolean | null
  importUser?: boolean | null
  exportUser?: boolean | null
}

export type UserPermissionsByIdQueryVariables = Exact<{
  targetUserId: Scalars['String']['input']
}>

export type UserPermissionsByIdQuery = {
  __typename?: 'Query'
  userPermissionsById: {
    __typename?: 'UserPermissions'
  } & UserPermissionsFieldsFragment
}

export type UserPermissionsByRoleQueryVariables = Exact<{
  targetUserRole?: InputMaybe<UserRole>
}>

export type UserPermissionsByRoleQuery = {
  __typename?: 'Query'
  userPermissionsByRole: {
    __typename?: 'UserPermissions'
  } & UserPermissionsFieldsFragment
}

export type UserAvailableRolesQueryVariables = Exact<{ [key: string]: never }>

export type UserAvailableRolesQuery = {
  __typename?: 'Query'
  userAvailableRoles: Array<string>
}

export type OidcAccountIdQueryVariables = Exact<{
  state: Scalars['String']['input']
}>

export type OidcAccountIdQuery = { __typename?: 'Query'; oidcAccountId: number }

export type MfaCodeQueryVariables = Exact<{
  accountId: Scalars['Int']['input']
  email: Scalars['String']['input']
  mfaId?: InputMaybe<Scalars['String']['input']>
}>

export type MfaCodeQuery = {
  __typename?: 'Query'
  mfaCode: { __typename?: 'Mfa'; image: string; secret: string; mfaId: string }
}

export type SetMfaMutationVariables = Exact<{
  accountId: Scalars['Int']['input']
  mfaId: Scalars['String']['input']
  code: Scalars['String']['input']
}>

export type SetMfaMutation = { __typename?: 'Mutation'; setMfa: boolean }

export type RemoveMfaMutationVariables = Exact<{
  userId: Scalars['String']['input']
}>

export type RemoveMfaMutation = { __typename?: 'Mutation'; removeMfa: boolean }

export type AuthenticateMutationVariables = Exact<{
  data: Authenticate
}>

export type AuthenticateMutation = {
  __typename?: 'Mutation'
  authenticate: boolean
}

export type AuthenticateSiteMutationVariables = Exact<{
  siteId: Scalars['Int']['input']
}>

export type AuthenticateSiteMutation = {
  __typename?: 'Mutation'
  authenticateSite: boolean
}

export type AuthenticateOidcQueryVariables = Exact<{
  code: Scalars['String']['input']
  state: Scalars['String']['input']
}>

export type AuthenticateOidcQuery = {
  __typename?: 'Query'
  authenticateOidc: boolean
}

export type DeauthenticateMutationVariables = Exact<{ [key: string]: never }>

export type DeauthenticateMutation = {
  __typename?: 'Mutation'
  deauthenticate: boolean
}

export type CampaignFieldsFragment = {
  __typename?: 'Campaign'
  id: number
  active?: boolean | null
  activepos?: boolean | null
  vouchername: string
  datestart?: string | null
  dateend?: string | null
  vouchertype: CampaignType
  voucherstate?: CampaignState | null
  pricelisttags?: Array<string> | null
  tags?: Array<string> | null
  voucherpassword?: string | null
  priority?: number | null
  value_x?: number | null
  value_y?: number | null
  value_z?: number | null
  money_m?: string | null
  money_n?: string | null
  vouchercode?: string | null
  productoptions?: Array<{
    __typename?: 'CampaignProductOption'
    num?: number | null
    products?: Array<string> | null
  }> | null
}

export type CampaignDetailFieldsFragment = {
  __typename?: 'Campaign'
  vouchercomment?: string | null
  voucherterms?: string | null
  datecreated?: string | null
  vouchername1?: string | null
  vouchercode1?: string | null
  vouchername2?: string | null
  vouchercode2?: string | null
  maxuses?: number | null
  pricelisttags?: Array<string> | null
  vouchergrouptype?: CampaignGroupType | null
  use_discounted_price?: boolean | null
  products?: Array<string> | null
  productattributes?: {
    __typename?: 'CampaignProductAttributes'
    include?: Record<string, unknown> | null
    exclude?: Record<string, unknown> | null
  } | null
  productoptions?: Array<{
    __typename?: 'CampaignProductOption'
    num?: number | null
    products?: Array<string> | null
    sitooProducts?: Array<{
      __typename?: 'Product'
      id: number
      title?: string | null
      sku: string
      variant?: Array<{
        __typename?: 'ProductVariantItem'
        name?: string | null
        value?: string | null
      }> | null
      productImages?: Array<{
        __typename?: 'ProductImage'
        fileUrl: string
      }> | null
    }> | null
  }> | null
  discountoptions?: Array<{
    __typename?: 'CampaignDiscountOption'
    num?: number | null
    products?: Array<string> | null
    moneyprice?: string | null
    moneydiscount?: string | null
    decimaldiscount?: string | null
    moneydiscounttotal?: string | null
    productattributes?: {
      __typename?: 'CampaignProductAttributes'
      include?: Record<string, unknown> | null
      exclude?: Record<string, unknown> | null
    } | null
  }> | null
} & CampaignFieldsFragment

export type GetCampaignsQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
}>

export type GetCampaignsQuery = {
  __typename?: 'Query'
  campaigns: {
    __typename?: 'PaginatedCampaignsResponse'
    totalcount?: number | null
    items?: Array<{ __typename?: 'Campaign' } & CampaignFieldsFragment> | null
  }
}

export type GetCampaignsWithProductAttributesQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
}>

export type GetCampaignsWithProductAttributesQuery = {
  __typename?: 'Query'
  campaigns: {
    __typename?: 'PaginatedCampaignsResponse'
    totalcount?: number | null
    items?: Array<
      {
        __typename?: 'Campaign'
        productattributes?: {
          __typename?: 'CampaignProductAttributes'
          include?: Record<string, unknown> | null
          exclude?: Record<string, unknown> | null
        } | null
      } & CampaignFieldsFragment
    > | null
  }
}

export type GetCampaignQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>
}>

export type GetCampaignQuery = {
  __typename?: 'Query'
  campaign: { __typename?: 'Campaign' } & CampaignDetailFieldsFragment
}

export type AddCampaignMutationVariables = Exact<{
  data: CampaignInput
}>

export type AddCampaignMutation = {
  __typename?: 'Mutation'
  addCampaign: number
}

export type UpdateCampaignMutationVariables = Exact<{
  campaignId: Scalars['Int']['input']
  data: CampaignInput
}>

export type UpdateCampaignMutation = {
  __typename?: 'Mutation'
  updateCampaign: { __typename?: 'Campaign' } & CampaignDetailFieldsFragment
}

export type GetCampaignTypesQueryVariables = Exact<{ [key: string]: never }>

export type GetCampaignTypesQuery = {
  __typename?: 'Query'
  campaignTypes: Array<string>
}

export type GetCampaignGroupTypesQueryVariables = Exact<{
  [key: string]: never
}>

export type GetCampaignGroupTypesQuery = {
  __typename?: 'Query'
  campaignGroupTypes: Array<string>
}

export type GetCampaignStatesQueryVariables = Exact<{ [key: string]: never }>

export type GetCampaignStatesQuery = {
  __typename?: 'Query'
  campaignStates: Array<string>
}

export type DeleteCampaignMutationVariables = Exact<{
  data: DeleteCampaignInput
}>

export type DeleteCampaignMutation = {
  __typename?: 'Mutation'
  deleteCampaign: boolean
}

export type CashRegisterFieldsFragment = {
  __typename?: 'CashRegister'
  registerid: string
  manufacturerid?: string | null
  registerkey?: string | null
  warehouseid?: number | null
  datelastping?: string | null
  clientdescription?: string | null
  loglevel?: CashRegisterLogLevelEnum | null
  salestaxgroupid?: number | null
  receiptlanguagetag?: string | null
  currencycode?: string | null
  city?: string | null
  state?: string | null
  countryid?: string | null
  company?: string | null
  companyid?: string | null
  address?: string | null
  address2?: string | null
  zip?: string | null
  additionaldata?: Record<string, unknown> | null
  ctu?: string | null
  registernumber?: number | null
  warehouse?: {
    __typename?: 'Warehouse'
    id: number
    name: string
    storeid?: number | null
  } | null
}

export type NumActiveCashRegistersQueryVariables = Exact<{
  [key: string]: never
}>

export type NumActiveCashRegistersQuery = {
  __typename?: 'Query'
  numActiveCashRegisters: number
}

export type GetCashRegistersQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<GetRegistersSort>
  includeNotRegistered?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GetCashRegistersQuery = {
  __typename?: 'Query'
  cashRegisters: {
    __typename?: 'PaginatedCashRegistersResponse'
    start?: number | null
    totalcount?: number | null
    items?: Array<
      { __typename?: 'CashRegister' } & CashRegisterFieldsFragment
    > | null
  }
}

export type GetAllCashRegistersQueryVariables = Exact<{
  includeNotRegistered?: InputMaybe<Scalars['Boolean']['input']>
  sort?: InputMaybe<GetRegistersSort>
}>

export type GetAllCashRegistersQuery = {
  __typename?: 'Query'
  allCashRegisters: Array<
    { __typename?: 'CashRegister' } & CashRegisterFieldsFragment
  >
}

export type GetCashRegisterQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type GetCashRegisterQuery = {
  __typename?: 'Query'
  cashRegister: { __typename?: 'CashRegister' } & CashRegisterFieldsFragment
}

export type AddCashRegisterActivationCodeMutationVariables = Exact<{
  registerId: Scalars['String']['input']
}>

export type AddCashRegisterActivationCodeMutation = {
  __typename?: 'Mutation'
  addCashRegisterActivationCode: {
    __typename?: 'ActivationCode'
    account?: string | null
    activationCode?: string | null
  }
}

export type CategoryFieldsFragment = {
  __typename?: 'Category'
  id: number
  categoryparentid?: number | null
  visible?: boolean | null
  title: string
  sortorder?: number | null
}

export type GetCategoriesQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  categorySiteId?: InputMaybe<Scalars['Int']['input']>
}>

export type GetCategoriesQuery = {
  __typename?: 'Query'
  categories: {
    __typename?: 'PaginatedCategoriesResponse'
    totalcount?: number | null
    items?: Array<{ __typename?: 'Category' } & CategoryFieldsFragment> | null
  }
}

export type UpdateCategoriesMutationVariables = Exact<{
  data: Array<UpdateCategoryInput> | UpdateCategoryInput
}>

export type UpdateCategoriesMutation = {
  __typename?: 'Mutation'
  updateCategories: Array<{
    __typename?: 'UpdateCategoryResponse'
    success: boolean
    category: { __typename?: 'Category'; id: number; title: string }
    error?: { __typename?: 'Error'; code: string } | null
  }>
}

export type DeleteCategoriesMutationVariables = Exact<{
  data: DeleteCategoriesInput
}>

export type DeleteCategoriesMutation = {
  __typename?: 'Mutation'
  deleteCategories: Array<{
    __typename?: 'DeleteCategoryResponse'
    success: boolean
    categoryId: number
    error?: { __typename?: 'Error'; code: string } | null
  }>
}

export type GetConfigVarsQueryVariables = Exact<{ [key: string]: never }>

export type GetConfigVarsQuery = {
  __typename?: 'Query'
  configVars: {
    __typename?: 'ConfigVars'
    adyenTapToPay: boolean
    adyenTerminalApi: boolean
    apps: boolean
    campaignPriceListDiscount: boolean
    campaigns: boolean
    cashRegisters: boolean
    ctuNorway: boolean
    deliveryIn: boolean
    deliveryOut: boolean
    inStoreReplenishment: boolean
    influencerCodes: boolean
    intercom: boolean
    journals: boolean
    limitUserManagement: boolean
    orderLookup: boolean
    orders: boolean
    prices: boolean
    products: boolean
    purchasePrices: boolean
    rewards: boolean
    salesTax: boolean
    serviceOrders: boolean
    shipments: boolean
    shipmentsv3: boolean
    statistics: boolean
    stock: boolean
    stocktaking: boolean
    stocktakingShowCustom: boolean
    stockValueWarning: boolean
    users: boolean
    posSettings: boolean
    paymentSettings: boolean
    directFeedback: boolean
    storeDashboard: boolean
    reports: boolean
  }
}

export type CustomAttributeFragment = {
  __typename?: 'CustomAttribute'
  id: string
  title: string
  type: CustomAttributeTypeEnum
  searchable?: boolean | null
  enums?: Array<string> | null
}

export type AllCustomAttributesQueryVariables = Exact<{
  customAttributeSiteId?: InputMaybe<Scalars['Int']['input']>
}>

export type AllCustomAttributesQuery = {
  __typename?: 'Query'
  allCustomAttributes: Array<
    { __typename?: 'CustomAttribute' } & CustomAttributeFragment
  >
}

export type GetCustomAttributesQueryVariables = Exact<{
  siteId?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
}>

export type GetCustomAttributesQuery = {
  __typename?: 'Query'
  customAttributes: {
    __typename?: 'PaginatedCustomAttributesResponse'
    totalcount?: number | null
    start?: number | null
    items?: Array<
      { __typename?: 'CustomAttribute' } & CustomAttributeFragment
    > | null
  }
}

export type GetCustomAttributeQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type GetCustomAttributeQuery = {
  __typename?: 'Query'
  customAttribute: { __typename?: 'CustomAttribute' } & CustomAttributeFragment
}

export type AddCustomAttributeMutationVariables = Exact<{
  customAttribute: SingleCustomAttributeInput
}>

export type AddCustomAttributeMutation = {
  __typename?: 'Mutation'
  addCustomAttribute: {
    __typename?: 'CustomAttribute'
  } & CustomAttributeFragment
}

export type UpdateCustomAttributeMutationVariables = Exact<{
  customAttribute: SingleCustomAttributeInput
}>

export type UpdateCustomAttributeMutation = {
  __typename?: 'Mutation'
  updateCustomAttribute: {
    __typename?: 'CustomAttribute'
  } & CustomAttributeFragment
}

export type DeleteCustomAttributeMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type DeleteCustomAttributeMutation = {
  __typename?: 'Mutation'
  deleteCustomAttribute: boolean
}

export type FilterQueryVariables = Exact<{ [key: string]: never }>

export type FilterQuery = {
  __typename?: 'Query'
  filter: Array<{
    __typename?: 'FilterValue'
    id: string
    key: string
    name: string
    value: string
  }>
}

export type ConvertWarehouseBatchItemsCsvQueryVariables = Exact<{
  csv: Scalars['String']['input']
}>

export type ConvertWarehouseBatchItemsCsvQuery = {
  __typename?: 'Query'
  convertWarehouseBatchItemsCsv: {
    __typename?: 'ConvertWarehouseBatchItemsResponse'
    items: Array<{
      __typename?: 'ImportWarehouseBatchItem'
      sku?: string | null
      decimalquantity?: number | null
      note?: string | null
      purchaseprice?: string | null
    }>
    errors: Array<{
      __typename?: 'Error'
      code: string
      property?: string | null
    }>
  }
}

export type ImportWarehouseBatchItemsMutationVariables = Exact<{
  warehouseId: Scalars['Int']['input']
  warehouseBatchId: Scalars['Int']['input']
  data: Array<ImportWarehouseBatchItemInput> | ImportWarehouseBatchItemInput
}>

export type ImportWarehouseBatchItemsMutation = {
  __typename?: 'Mutation'
  importWarehouseBatchItems: {
    __typename?: 'ImportResponse'
    imported: number
    deleted: number
    failed: number
  }
}

export type ConvertShipmentPackageItemsCsvQueryVariables = Exact<{
  csv: Scalars['String']['input']
}>

export type ConvertShipmentPackageItemsCsvQuery = {
  __typename?: 'Query'
  convertShipmentPackageItemsCsv: {
    __typename?: 'ConvertShipmentPackageItemsResponse'
    items: Array<{
      __typename?: 'ImportShipmentPackageItem'
      sku?: string | null
      barcode?: string | null
      product_name?: string | null
      unit_price?: number | null
      quantity?: number | null
      batch?: string | null
      batch_expires?: string | null
      product?: {
        __typename?: 'Product'
        id: number
        sku: string
        images?: Array<string> | null
        barcode?: string | null
        variant?: Array<{
          __typename?: 'ProductVariantItem'
          name?: string | null
          value?: string | null
        }> | null
      } | null
    }>
    errors: Array<{
      __typename?: 'Error'
      code: string
      property?: string | null
    }>
  }
}

export type InfluencerCodeFieldsFragment = {
  __typename?: 'InfluencerCode'
  code: string
  name: string
  active?: boolean | null
  datestart?: string | null
  dateend?: string | null
  voucherpassword: string
  vouchercode?: string | null
  vouchername?: string | null
  vouchercomment?: string | null
  maxuses?: number | null
  numused?: number | null
  datelastused?: string | null
  moneyordertotal?: string | null
  moneyinfluencertotal?: string | null
  moneyinfluencerdiscount?: string | null
}

export type InfluencerCodeDetailFieldsFragment = {
  __typename?: 'InfluencerCode'
  campaigns?: Array<{
    __typename?: 'Campaign'
    id: number
    vouchername: string
  }> | null
} & InfluencerCodeFieldsFragment

export type GetInfluencerCodesQueryVariables = Exact<{
  num?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<GetInfluencerCodesSort>
  active?: InputMaybe<Scalars['Boolean']['input']>
  code?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  redeemable?: InputMaybe<Scalars['Boolean']['input']>
  vouchername?: InputMaybe<Scalars['String']['input']>
  voucherpassword?: InputMaybe<Scalars['String']['input']>
  datestartFrom?: InputMaybe<Scalars['UnixDate']['input']>
  datestartTo?: InputMaybe<Scalars['UnixDate']['input']>
  dateendFrom?: InputMaybe<Scalars['UnixDate']['input']>
  dateendTo?: InputMaybe<Scalars['UnixDate']['input']>
  datelastusedFrom?: InputMaybe<Scalars['UnixDate']['input']>
  datelastusedTo?: InputMaybe<Scalars['UnixDate']['input']>
}>

export type GetInfluencerCodesQuery = {
  __typename?: 'Query'
  influencerCodes: {
    __typename?: 'PaginatedInfluencerCodesResponse'
    start?: number | null
    totalcount?: number | null
    items?: Array<
      { __typename?: 'InfluencerCode' } & InfluencerCodeFieldsFragment
    > | null
  }
}

export type GetInfluencerCodeQueryVariables = Exact<{
  code: Scalars['String']['input']
}>

export type GetInfluencerCodeQuery = {
  __typename?: 'Query'
  influencerCode: {
    __typename?: 'InfluencerCode'
  } & InfluencerCodeDetailFieldsFragment
}

export type AddInfluencerCodeMutationVariables = Exact<{
  data: InfluencerCodeInput
}>

export type AddInfluencerCodeMutation = {
  __typename?: 'Mutation'
  addInfluencerCode: {
    __typename?: 'InfluencerCode'
  } & InfluencerCodeDetailFieldsFragment
}

export type UpdateInfluencerCodeMutationVariables = Exact<{
  data: InfluencerCodeInput
}>

export type UpdateInfluencerCodeMutation = {
  __typename?: 'Mutation'
  updateInfluencerCode: {
    __typename?: 'InfluencerCode'
  } & InfluencerCodeDetailFieldsFragment
}

export type DeleteInfluencerCodesMutationVariables = Exact<{
  codes: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type DeleteInfluencerCodesMutation = {
  __typename?: 'Mutation'
  deleteInfluencerCodes: boolean
}

export type GetJournalEntriesQueryVariables = Exact<{
  registerIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  storeIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  num?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
  next?: InputMaybe<Scalars['String']['input']>
  dateFrom?: InputMaybe<Scalars['UnixDate']['input']>
  dateTo?: InputMaybe<Scalars['UnixDate']['input']>
  dateType?: InputMaybe<JournalFilterDateType>
  type?: InputMaybe<JournalEntryType>
}>

export type GetJournalEntriesQuery = {
  __typename?: 'Query'
  journalEntries: {
    __typename?: 'PaginatedJournalsResponse'
    next?: string | null
    start?: number | null
    items?: Array<{
      __typename?: 'Journal'
      type: JournalEntryType
      id: number
      dateadded?: string | null
      datecreated: string
      registerid: string
      registerkey: string
      ispractice: boolean
      data:
        | {
            __typename?: 'JournalCart'
            staff: string
            staffuserid: string
            id: string
            quantitytotal: number
            moneytotal: string
            moneydiscount: string
            additionaldata?: Record<string, unknown> | null
          }
        | {
            __typename?: 'JournalCartItem'
            staff: string
            staffuserid: string
            item_index: number
            sku: string
            quantity?: number | null
            decimalunitquantity?: string | null
            note?: string | null
            additionaldata?: Record<string, unknown> | null
            cart_id: string
            cart_quantitytotal: number
            cart_moneytotal: string
            cart_moneydiscount: string
            JournalCartItemMoneyTotal?: string | null
            JournalCartItemMoneyDiscount?: string | null
          }
        | {
            __typename?: 'JournalCartPayment'
            staff: string
            staffuserid: string
            payment_index: number
            paymentname: string
            paymentmethod: number
            moneytotal: string
            moneycaptured: string
            additionaldata?: Record<string, unknown> | null
            cart_id: string
          }
        | {
            __typename?: 'JournalCash'
            staff: string
            staffuserid: string
            currencycode: string
            moneycash: string
            comment?: string | null
            additionaldata?: Record<string, unknown> | null
          }
        | { __typename?: 'JournalCtuError'; errortext: string }
        | {
            __typename?: 'JournalPrintout'
            staff: string
            staffuserid: string
            type: JournalPrintoutTypeEnum
            receiptid?: string | null
            orderid?: number | null
            zreportid?: number | null
            additionaldata?: Record<string, unknown> | null
          }
        | {
            __typename?: 'JournalReceipt'
            receiptnumber: number
            moneytotal: string
            quantitytotal: number
            moneysubtotal?: string | null
            moneyroundoff: string
            currencycode: string
            isrefund: boolean
            staff: string
            staffuserid: string
            creditorderid?: number | null
            moneysalestaxinadvance: string
            additionaldata?: Record<string, unknown> | null
            comment?: string | null
            posname: string
            posversion: string
            items: Array<{
              __typename?: 'JournalReceiptItem'
              sku: string
              productname: string
              variant?: string | null
              discountname?: string | null
              note?: string | null
              productgrouptype: number
              productgroupname: string
              unitlabel?: string | null
              quantity: number
              moneytotal: string
              moneydiscount: string
              moneyvat: string
              moneyunitprice: string
              decimalunitquantity?: string | null
              vatid: number
              vatvalue: number
              additionaldata?: Record<string, unknown> | null
              discounts: Array<{
                __typename?: 'JournalReceiptItemDiscount'
                id: number
                discountname: string
                discountgroup?: string | null
                moneytotal: string
                moneyvat: string
                additionaldata?: Record<string, unknown> | null
              }>
              salestaxes: Array<{
                __typename?: 'JournalReceiptSalesTax'
                id: number
                name: string
                code?: string | null
                externalid?: string | null
                groupregion?: string | null
                decimalvalue: string
                moneytotal: string
                moneytax: string
              }>
              printrow: Array<{
                __typename?: 'PrintLine'
                type: number
                scope?: number | null
                value1?: string | null
                value2?: string | null
              }>
              printsection: Array<{
                __typename?: 'PrintLine'
                type: number
                scope?: number | null
                value1?: string | null
                value2?: string | null
              }>
            }>
            payments: Array<{
              __typename?: 'JournalReceiptPayment'
              paymentname: string
              paymentmethod: number
              paymentexternalid?: string | null
              moneytotal: string
              moneycaptured: string
              moneyinadvance: string
              additionaldata?: Record<string, unknown> | null
              printrow: Array<{
                __typename?: 'PrintLine'
                type: number
                scope?: number | null
                value1?: string | null
                value2?: string | null
              }>
              printsection: Array<{
                __typename?: 'PrintLine'
                type: number
                scope?: number | null
                value1?: string | null
                value2?: string | null
              }>
            }>
            vatsummary: Array<{
              __typename?: 'JournalReceiptVat'
              vatvalue: number
              moneyvat: string
              moneynet: string
            }>
            salestaxsummary: Array<{
              __typename?: 'JournalReceiptSalesTax'
              id: number
              name: string
              code?: string | null
              externalid?: string | null
              groupregion?: string | null
              decimalvalue: string
              moneytotal: string
              moneytax: string
            }>
            customer?: {
              __typename?: 'JournalReceiptCustomer'
              id?: string | null
              email?: string | null
              namefirst?: string | null
              namelast?: string | null
              personalid?: string | null
              companyid?: string | null
              company?: string | null
              mobile?: string | null
              customerref?: string | null
              invoice_address?: string | null
              invoice_address2?: string | null
              invoice_zip?: string | null
              invoice_city?: string | null
              invoice_state?: string | null
              invoice_countryid?: string | null
              delivery_address?: string | null
              delivery_address2?: string | null
              delivery_zip?: string | null
              delivery_city?: string | null
              delivery_state?: string | null
              delivery_countryid?: string | null
            } | null
            printsection: Array<{
              __typename?: 'PrintLine'
              type: number
              scope?: number | null
              value1?: string | null
              value2?: string | null
            }>
          }
        | {
            __typename?: 'JournalReport'
            zreportid?: number | null
            manufacturerid: string
            dateopened: string
            eshopid: number
            store_id?: string | null
            store_name?: string | null
            company: string
            companyid: string
            address: string
            address2: string
            address3: string
            currencycode: string
            staff: string
            staffuserid: string
            comment?: string | null
            moneysalestotal: string
            moneysalestotalnet: string
            numsales: number
            numsalesitems: number
            moneysalesnotdeliveredtotal: string
            moneysalesnotdeliveredtotalnet: string
            numsalesnotdelivered: number
            numsalesnotdelivereditems: number
            moneyrefundtotal: string
            moneyrefundtotalnet: string
            numrefund: number
            numrefunditems: number
            moneyroundoff: string
            cashdiff_reasoncode?: string | null
            cashdiff_reasoncomment?: string | null
            moneysalestaxinadvance: string
            moneysummarysales: string
            moneysummaryrefund: string
            moneysummarysubtotal: string
            moneysummarysalestax: string
            moneysummaryroundoff: string
            moneysummarytotal: string
            moneycash_in: string
            moneycash_salesrefunds: string
            moneycash_petty: string
            moneycash_bank: string
            moneycash_expected: string
            moneycash_counted: string
            moneycash_diff: string
            moneycash_bankfinal: string
            moneycash_out: string
            moneydiscount: string
            cash_bankfinal_bag_id?: string | null
            numsalestypeproduct: number
            numsalestypeservice: number
            numsalestypegiftcard: number
            numreceipts: number
            numopendrawer: number
            numpractice: number
            moneypractice: string
            moneygrandtotalsales: string
            moneygrandtotalrefund: string
            moneygrandtotalnet: string
            vatgroupssales: Array<{
              __typename?: 'JournalReportVatGroup'
              vatvalue: number
              moneytotal: string
              moneytotalnet: string
              moneytotalvat: string
            }>
            productgroupssales: Array<{
              __typename?: 'JournalReportProductGroup'
              name: string
              vatvalue: number
              numtotal: number
              moneytotal: string
              moneytotalnet: string
              moneytotalvat: string
            }>
            vatgroupssalesnotdelivered: Array<{
              __typename?: 'JournalReportVatGroup'
              vatvalue: number
              moneytotal: string
              moneytotalnet: string
              moneytotalvat: string
            }>
            productgroupssalesnotdelivered: Array<{
              __typename?: 'JournalReportProductGroup'
              name: string
              vatvalue: number
              numtotal: number
              moneytotal: string
              moneytotalnet: string
              moneytotalvat: string
            }>
            vatgroupsrefund: Array<{
              __typename?: 'JournalReportVatGroup'
              vatvalue: number
              moneytotal: string
              moneytotalnet: string
              moneytotalvat: string
            }>
            productgroupsrefund: Array<{
              __typename?: 'JournalReportProductGroup'
              name: string
              vatvalue: number
              numtotal: number
              moneytotal: string
              moneytotalnet: string
              moneytotalvat: string
            }>
            salestaxessales: Array<{
              __typename?: 'JournalReportSalesTax'
              name: string
              code?: string | null
              externalid?: string | null
              groupregion?: string | null
              moneytotal?: string | null
              moneytax?: string | null
              decimaltaxvalue: string
            }>
            salestaxesrefund: Array<{
              __typename?: 'JournalReportSalesTax'
              name: string
              code?: string | null
              externalid?: string | null
              groupregion?: string | null
              moneytotal?: string | null
              moneytax?: string | null
              decimaltaxvalue: string
            }>
            currencyconversions?: Array<{
              __typename?: 'JournalReportCurrencyConversion'
              currency_code: string
              money_expected: string
              money_counted: string
              money_expected_base: string
              money_counted_base: string
              bag_id?: string | null
            }> | null
            paymentssales: Array<{
              __typename?: 'JournalReportPayment'
              name: string
              moneytotal?: string | null
              moneycaptured?: string | null
              moneyinadvance?: string | null
              moneyreserved?: string | null
              subpayments: Array<{
                __typename?: 'JournalReportSubPayment'
                name: string
                moneysubtotal: string
                moneycaptured?: string | null
                moneyinadvance?: string | null
                moneyreserved?: string | null
              }>
            }>
            paymentsrefund: Array<{
              __typename?: 'JournalReportPayment'
              name: string
              moneytotal?: string | null
              moneycaptured?: string | null
              moneyinadvance?: string | null
              moneyreserved?: string | null
              subpayments: Array<{
                __typename?: 'JournalReportSubPayment'
                name: string
                moneysubtotal: string
                moneycaptured?: string | null
                moneyinadvance?: string | null
                moneyreserved?: string | null
              }>
            }>
            discountssales: Array<{
              __typename?: 'JournalReportDiscount'
              vouchercode: string
              moneytotal: string
              moneytotalnet: string
              moneytotalvat: string
            }>
            discountssalesnotdelivered: Array<{
              __typename?: 'JournalReportDiscount'
              vouchercode: string
              moneytotal: string
              moneytotalnet: string
              moneytotalvat: string
            }>
            discountsrefund: Array<{
              __typename?: 'JournalReportDiscount'
              vouchercode: string
              moneytotal: string
              moneytotalnet: string
              moneytotalvat: string
            }>
          }
        | { __typename?: 'JournalStaff'; staff: string; staffuserid: string }
    }> | null
  }
}

export type LogEventFragment = {
  __typename?: 'LogEvent'
  id: string
  type: string
  actor: string
  data: Record<string, unknown>
  eshop_id?: number | null
  source: string
  timestamp: string
}

export type AccessLogsQueryVariables = Exact<{
  dateFrom: Scalars['UnixDate']['input']
  dateTo: Scalars['UnixDate']['input']
  actor?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  next?: InputMaybe<Scalars['String']['input']>
}>

export type AccessLogsQuery = {
  __typename?: 'Query'
  accessLogs: {
    __typename?: 'PaginatedLogsResponse'
    next?: string | null
    start?: number | null
    items?: Array<{ __typename?: 'LogEvent' } & LogEventFragment> | null
  }
}

export type ManufacturerFragment = {
  __typename?: 'Manufacturer'
  id: number
  name: string
  description?: string | null
  address?: string | null
  zip?: string | null
  city?: string | null
  state?: string | null
  countryid?: string | null
  phone?: string | null
  email?: string | null
  url1?: string | null
  url2?: string | null
}

export type GetManufacturerQueryVariables = Exact<{
  id: Scalars['Float']['input']
}>

export type GetManufacturerQuery = {
  __typename?: 'Query'
  manufacturer: { __typename?: 'Manufacturer' } & ManufacturerFragment
}

export type GetManufacturersQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
}>

export type GetManufacturersQuery = {
  __typename?: 'Query'
  manufacturers: {
    __typename?: 'PaginatedManufacturerResponse'
    totalcount?: number | null
    start?: number | null
    items?: Array<{
      __typename?: 'Manufacturer'
      id: number
      name: string
    }> | null
  }
}

export type GetManufacturersDetailedQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
}>

export type GetManufacturersDetailedQuery = {
  __typename?: 'Query'
  manufacturers: {
    __typename?: 'PaginatedManufacturerResponse'
    totalcount?: number | null
    start?: number | null
    items?: Array<{ __typename?: 'Manufacturer' } & ManufacturerFragment> | null
  }
}

export type AddUpdateManufacturerMutationVariables = Exact<{
  manufacturer: ManufacturerInput
}>

export type AddUpdateManufacturerMutation = {
  __typename?: 'Mutation'
  addUpdateManufacturer: { __typename?: 'Manufacturer' } & ManufacturerFragment
}

export type DeleteManufacturerMutationVariables = Exact<{
  id: Scalars['Float']['input']
}>

export type DeleteManufacturerMutation = {
  __typename?: 'Mutation'
  deleteManufacturer: boolean
}

export type PublicLoginOptionFragment = {
  __typename?: 'PublicLoginOption'
  id: string
  type: LoginOptionTypeEnum
  oidc_auth_request_url?: string | null
}

export type LoginOptionFragment = {
  __typename?: 'LoginOption'
  id: string
  type: LoginOptionTypeEnum
  oidc_auth_request_url?: string | null
  oidc_private?: {
    __typename?: 'LoginOptionOidcPrivate'
    client_id: string
    client_secret: string
    auth_url: string
    token_url: string
    tenant_id?: string | null
  } | null
}

export type PublicLoginOptionsQueryVariables = Exact<{
  accountId: Scalars['Int']['input']
}>

export type PublicLoginOptionsQuery = {
  __typename?: 'Query'
  publicLoginOptions: Array<
    { __typename?: 'PublicLoginOption' } & PublicLoginOptionFragment
  >
}

export type LoginOptionsQueryVariables = Exact<{ [key: string]: never }>

export type LoginOptionsQuery = {
  __typename?: 'Query'
  loginOptions: Array<
    { __typename?: 'PublicLoginOption' } & PublicLoginOptionFragment
  >
}

export type LoginOptionQueryVariables = Exact<{
  optionId: Scalars['String']['input']
}>

export type LoginOptionQuery = {
  __typename?: 'Query'
  loginOption: { __typename?: 'LoginOption' } & LoginOptionFragment
}

export type AddUpdateLoginOptionMutationVariables = Exact<{
  data: AddUpdateLoginOptionInput
}>

export type AddUpdateLoginOptionMutation = {
  __typename?: 'Mutation'
  addUpdateLoginOption: {
    __typename?: 'LoginOption'
    id: string
    type: LoginOptionTypeEnum
    oidc_auth_request_url?: string | null
    oidc_private?: {
      __typename?: 'LoginOptionOidcPrivate'
      client_id: string
      client_secret: string
      auth_url: string
      token_url: string
      tenant_id?: string | null
    } | null
  }
}

export type DeleteLoginOptionMutationVariables = Exact<{
  loginOptionId: Scalars['String']['input']
}>

export type DeleteLoginOptionMutation = {
  __typename?: 'Mutation'
  deleteLoginOption: boolean
}

export type OrderFieldsFragment = {
  __typename?: 'Order'
  orderid: number
  externalid?: string | null
  orderdate: string
  paymentstateid: PaymentState
  creditorderid?: number | null
  orderstateid: OrderState
  moneytotal_gross_all?: string | null
  moneytotal_gross_roundoff?: string | null
  currencycode?: string | null
  datereserved?: string | null
  warehouseid?: number | null
  namefirst?: string | null
  namelast?: string | null
  additionaldata: Record<string, unknown>
  cashRegister?: {
    __typename?: 'CashRegister'
    registerkey?: string | null
    registerid: string
  } | null
  payments: Array<{
    __typename?: 'OrderPayment'
    additionaldata?: Record<string, unknown> | null
    cardissuer?: string | null
    externalid?: string | null
    moneyamount: string
    name: string
    refid?: string | null
    reftype?: string | null
    reservedpaymentid?: string | null
  }>
}

export type OrderItemsFragment = {
  __typename?: 'Order'
  orderitems: Array<{
    __typename?: 'OrderItem'
    orderitemid?: number | null
    sku?: string | null
    quantity: number
    productname: string
    productattributes?: string | null
    vouchername?: string | null
    vouchercode?: string | null
    vatvalue?: number | null
    moneyoriginalprice?: string | null
    moneyitemtotal_net?: string | null
    moneyitemtotal_vat?: string | null
    additionaldata?: Record<string, unknown> | null
    product?: {
      __typename?: 'Product'
      id: number
      title?: string | null
      images?: Array<string> | null
    } | null
    salestaxes?: Array<{
      __typename?: 'OrderItemSalesTax'
      name: string
      code?: string | null
      externalid?: string | null
      groupregion?: string | null
      moneytotal: string
      moneytax: string
      decimaltaxvalue: string
    }> | null
  }>
}

export type OrderDetailsFieldsFragment = {
  __typename?: 'Order'
  ordertypeid: OrderType
  commentinternal?: string | null
  comment?: string | null
  personalid?: string | null
  customerref?: string | null
  orderDeliveryState?: OrderDeliveryState | null
  company?: string | null
  namefirst?: string | null
  namelast?: string | null
  phone?: string | null
  email?: string | null
  delivery_address?: string | null
  delivery_address2?: string | null
  delivery_city?: string | null
  delivery_state?: string | null
  delivery_zip?: string | null
  delivery_countryid?: string | null
  invoice_address?: string | null
  invoice_address2?: string | null
  invoice_city?: string | null
  invoice_state?: string | null
  invoice_zip?: string | null
  invoice_countryid?: string | null
  moneytotal_gross_all?: string | null
  moneytotal_gross_roundoff?: string | null
  moneyfinal_vat?: string | null
  moneyfinal_net?: string | null
  currencycode?: string | null
  additionaldata: Record<string, unknown>
  orderDelivery: Array<{
    __typename?: 'OrderDelivery'
    pluginname?: string | null
    datecancelled?: string | null
    datedelivery?: string | null
    orderdeliveryid?: number | null
    orderdeliveryref?: string | null
    warehouseid: number
    state?: OrderDeliveryItemState | null
    binlocation?: string | null
    ignorestock?: boolean | null
    orderdeliveryitems: Array<{
      __typename?: 'OrderDeliveryItem'
      orderdeliveryitemid?: number | null
      orderitemid: number
      quantity: number
    }>
    warehouse?: { __typename?: 'Warehouse'; name: string } | null
  }>
  originalOrder?:
    | ({
        __typename?: 'Order'
        orderid: number
        moneytotal_gross_all?: string | null
        moneytotal_gross_roundoff?: string | null
      } & OrderItemsFragment)
    | null
  refundOrder?:
    | ({
        __typename?: 'Order'
        orderid: number
        moneytotal_gross_all?: string | null
        moneytotal_gross_roundoff?: string | null
      } & OrderItemsFragment)
    | null
  exchangeOrder?:
    | ({
        __typename?: 'Order'
        orderid: number
        moneytotal_gross_all?: string | null
        moneytotal_gross_roundoff?: string | null
      } & OrderItemsFragment)
    | null
  staff?: {
    __typename?: 'User'
    namefirst?: string | null
    namelast?: string | null
    externalid?: string | null
  } | null
  staffHelpers?: Array<{
    __typename?: 'User'
    namefirst?: string | null
    namelast?: string | null
    externalid?: string | null
  }> | null
  warehouse?: { __typename?: 'Warehouse'; name: string } | null
  reservedpayments: Array<{
    __typename?: 'OrderReservedPayment'
    id: string
    externalid?: string | null
    name: string
    moneyreserved: string
    cardissuer?: string | null
    refid?: string | null
    reftype?: string | null
    additionaldata?: Record<string, unknown> | null
  }>
  payments: Array<{
    __typename?: 'OrderPayment'
    additionaldata?: Record<string, unknown> | null
    cardissuer?: string | null
    externalid?: string | null
    moneyamount: string
    name: string
    refid?: string | null
    reftype?: string | null
    reservedpaymentid?: string | null
  }>
  cashRegister?: {
    __typename?: 'CashRegister'
    registerkey?: string | null
    registerid: string
  } | null
} & OrderFieldsFragment &
  OrderItemsFragment

export type OrderHistoryFieldsFragment = {
  __typename?: 'Order'
  orderid: number
  orderdate: string
  registerid?: string | null
  orderDelivery: Array<{
    __typename?: 'OrderDelivery'
    pluginname?: string | null
    datecancelled?: string | null
    datedelivery?: string | null
    orderdeliveryid?: number | null
    orderdeliveryref?: string | null
    warehouseid: number
    state?: OrderDeliveryItemState | null
    binlocation?: string | null
    ignorestock?: boolean | null
    orderdeliveryitems: Array<{
      __typename?: 'OrderDeliveryItem'
      orderdeliveryitemid?: number | null
      orderitemid: number
      quantity: number
    }>
    warehouse?: { __typename?: 'Warehouse'; name: string } | null
  }>
  orderlogitems: Array<{
    __typename?: 'OrderLogItem'
    datelog?: string | null
    orderlogtypeid: OrderLogType
    strvalue?: string | null
    fvalue?: number | null
  }>
  purchaseJournal?: Array<{
    __typename?: 'Journal'
    type: JournalEntryType
    id: number
    dateadded?: string | null
    datecreated: string
    registerid: string
    registerkey: string
    ispractice: boolean
    data:
      | {
          __typename?: 'JournalCart'
          staff: string
          staffuserid: string
          id: string
          quantitytotal: number
          moneytotal: string
          moneydiscount: string
        }
      | {
          __typename?: 'JournalCartItem'
          staff: string
          staffuserid: string
          sku: string
          quantity?: number | null
          decimalunitquantity?: string | null
          note?: string | null
          cart_id: string
          cart_quantitytotal: number
          cart_moneytotal: string
          cart_moneydiscount: string
          JournalCartItemMoneyTotal?: string | null
          JournalCartItemMoneyDiscount?: string | null
        }
      | {
          __typename?: 'JournalCartPayment'
          staff: string
          staffuserid: string
          paymentname: string
          paymentmethod: number
          moneytotal: string
          moneycaptured: string
          cart_id: string
        }
      | {
          __typename?: 'JournalCash'
          staff: string
          staffuserid: string
          currencycode: string
          moneycash: string
          comment?: string | null
        }
      | { __typename?: 'JournalCtuError'; errortext: string }
      | {
          __typename?: 'JournalPrintout'
          staff: string
          staffuserid: string
          type: JournalPrintoutTypeEnum
          receiptid?: string | null
          orderid?: number | null
          zreportid?: number | null
        }
      | {
          __typename?: 'JournalReceipt'
          staff: string
          staffuserid: string
          receiptnumber: number
        }
      | {
          __typename?: 'JournalReport'
          staff: string
          staffuserid: string
          zreportid?: number | null
          manufacturerid: string
          eshopid: number
          store_id?: string | null
          store_name?: string | null
          company: string
          companyid: string
        }
      | { __typename?: 'JournalStaff'; staff: string; staffuserid: string }
  }> | null
}

export type GetOrdersQueryVariables = Exact<{
  paymentstate?: InputMaybe<Array<PaymentState> | PaymentState>
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  externalid?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  orderid?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>
  receiptid?: InputMaybe<Scalars['String']['input']>
  creditorderid?: InputMaybe<Scalars['Int']['input']>
  orderdatefrom?: InputMaybe<Scalars['UnixDate']['input']>
  orderdateto?: InputMaybe<Scalars['UnixDate']['input']>
  orderstate?: InputMaybe<Array<OrderState> | OrderState>
  ordertype?: InputMaybe<Array<OrderType> | OrderType>
  sort?: InputMaybe<GetOrdersSort>
  warehouseId?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
}>

export type GetOrdersQuery = {
  __typename?: 'Query'
  orders: {
    __typename?: 'PaginatedOrdersResponse'
    start?: number | null
    totalcount?: number | null
    items?: Array<{ __typename?: 'Order' } & OrderFieldsFragment> | null
  }
}

export type GetOrdersByIdsQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  orderid?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>
}>

export type GetOrdersByIdsQuery = {
  __typename?: 'Query'
  orders: {
    __typename?: 'PaginatedOrdersResponse'
    start?: number | null
    totalcount?: number | null
    items?: Array<{ __typename?: 'Order' } & OrderDetailsFieldsFragment> | null
  }
}

export type GetOrderQueryVariables = Exact<{
  orderId: Scalars['Int']['input']
}>

export type GetOrderQuery = {
  __typename?: 'Query'
  order: { __typename?: 'Order' } & OrderDetailsFieldsFragment
}

export type GetOrderHistoryQueryVariables = Exact<{
  orderId: Scalars['Int']['input']
}>

export type GetOrderHistoryQuery = {
  __typename?: 'Query'
  order: { __typename?: 'Order' } & OrderHistoryFieldsFragment
}

export type SearchOrdersQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  next?: InputMaybe<Scalars['String']['input']>
  dateFrom?: InputMaybe<Scalars['UnixDate']['input']>
  dateTo?: InputMaybe<Scalars['UnixDate']['input']>
  sort?: InputMaybe<OrderLookupFilterSortEnum>
  siteIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>
  registerIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  warehouseIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  storeIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  orderType?: InputMaybe<OrderLookupFilterOrderTypeEnum>
  orderTransactionType?: InputMaybe<OrderLookupFilterOrderTransactionTypeEnum>
  totalAmountMin?: InputMaybe<Scalars['Money']['input']>
  totalAmountMax?: InputMaybe<Scalars['Money']['input']>
  refundInitiationMode?: InputMaybe<
    | Array<OrderLookupFilterReturnLookupMethodEnum>
    | OrderLookupFilterReturnLookupMethodEnum
  >
  staffExternalId?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  staffUserId?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  staffUserIdHelpers?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  crmCustomerId?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  crmMemberNumber?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  email?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  phone?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  paymentMethod?: InputMaybe<
    | Array<OrderLookupFilterPaymentMethodEnum>
    | OrderLookupFilterPaymentMethodEnum
  >
  paymentVirtual?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  paymentValidated?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  paymentAmountMin?: InputMaybe<Scalars['Money']['input']>
  paymentAmountMax?: InputMaybe<Scalars['Money']['input']>
  paymentCardIssuer?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  paymentCardMaskedPan?: InputMaybe<Scalars['String']['input']>
  itemProductGroupName?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  itemSku?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  itemVoucherName?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  itemVoucherId?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  itemReasonCodeManualDiscount?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  itemReasonCodeManualPrice?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  externalid?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  orderid?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>
  receiptid?: InputMaybe<Scalars['String']['input']>
  creditorderid?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchOrdersQuery = {
  __typename?: 'Query'
  searchOrders: {
    __typename?: 'PaginatedOrdersSearchResponse'
    next?: string | null
    start?: number | null
    items?: Array<{ __typename?: 'Order' } & OrderFieldsFragment> | null
  }
}

export type UpdateOrderMutationVariables = Exact<{
  data: UpdateOrderInput
  orderId: Scalars['Int']['input']
}>

export type UpdateOrderMutation = {
  __typename?: 'Mutation'
  updateOrder: {
    __typename?: 'Order'
    orderid: number
    warehouseid?: number | null
    orderdate: string
    orderstateid: OrderState
    paymentstateid: PaymentState
    ordertypeid: OrderType
    datereserved?: string | null
    commentinternal?: string | null
    comment?: string | null
    customerref?: string | null
    company?: string | null
    namefirst?: string | null
    namelast?: string | null
    phone?: string | null
    email?: string | null
    personalid?: string | null
    delivery_address?: string | null
    delivery_address2?: string | null
    delivery_city?: string | null
    delivery_state?: string | null
    delivery_zip?: string | null
    delivery_countryid?: string | null
    invoice_address?: string | null
    invoice_address2?: string | null
    invoice_city?: string | null
    invoice_state?: string | null
    invoice_zip?: string | null
    invoice_countryid?: string | null
    orderlogitems: Array<{
      __typename?: 'OrderLogItem'
      datelog?: string | null
      orderlogtypeid: OrderLogType
      strvalue?: string | null
      fvalue?: number | null
    }>
  }
}

export type OrderDeliveryFieldsFragment = {
  __typename?: 'OrderDelivery'
  orderdeliveryid?: number | null
  orderid?: number | null
  state?: OrderDeliveryItemState | null
  warehouseid: number
  orderdeliveryref?: string | null
  datedelivery?: string | null
  datecancelled?: string | null
  pluginname?: string | null
  orderdeliveryitems: Array<{
    __typename?: 'OrderDeliveryItem'
    orderdeliveryitemid?: number | null
    orderdeliveryid?: number | null
    orderitemid: number
    quantity: number
  }>
}

export type AddOrderDeliveryMutationVariables = Exact<{
  data: AddOrderDeliveryInput
  orderId: Scalars['Int']['input']
}>

export type AddOrderDeliveryMutation = {
  __typename?: 'Mutation'
  addOrderDelivery: Array<
    { __typename?: 'OrderDelivery' } & OrderDeliveryFieldsFragment
  >
}

export type UpdateOrderDeliveryMutationVariables = Exact<{
  orderId: Scalars['Int']['input']
  orderDeliveryId: Scalars['Int']['input']
  data: UpdateOrderDeliveryInput
}>

export type UpdateOrderDeliveryMutation = {
  __typename?: 'Mutation'
  updateOrderDelivery: Array<
    { __typename?: 'OrderDelivery' } & OrderDeliveryFieldsFragment
  >
}

export type ExportOrdersMutationVariables = Exact<{
  orderIds: Array<Scalars['Int']['input']> | Scalars['Int']['input']
  fileType: FileType
}>

export type ExportOrdersMutation = {
  __typename?: 'Mutation'
  exportOrders?: string | null
}

export type UpdatePricelistItemsMutationVariables = Exact<{
  pricelistId: Scalars['Int']['input']
  items: Array<UpdatePricelistItemInput> | UpdatePricelistItemInput
  productSiteId?: InputMaybe<Scalars['Int']['input']>
}>

export type UpdatePricelistItemsMutation = {
  __typename?: 'Mutation'
  updatePricelistItems: Array<{
    __typename?: 'Product'
    id: number
    sku: string
    pricelistitems?: Array<{
      __typename?: 'PricelistItem'
      productid?: number | null
      moneyprice: string
      pricelistid: number
      sku?: string | null
    }> | null
  }>
}

export type DeletePricelistItemsMutationVariables = Exact<{
  pricelistId: Scalars['Int']['input']
  items: Array<DeletePricelistItemInput> | DeletePricelistItemInput
  productSiteId?: InputMaybe<Scalars['Int']['input']>
}>

export type DeletePricelistItemsMutation = {
  __typename?: 'Mutation'
  deletePricelistItems: Array<{
    __typename?: 'Product'
    id: number
    sku: string
    pricelistitems?: Array<{
      __typename?: 'PricelistItem'
      productid?: number | null
      sku?: string | null
      moneyprice: string
      pricelistid: number
    }> | null
  }>
}

export type AllPriceListTagsQueryVariables = Exact<{ [key: string]: never }>

export type AllPriceListTagsQuery = {
  __typename?: 'Query'
  allPriceListTags: Array<string>
}

export type GetPriceListsQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
}>

export type GetPriceListsQuery = {
  __typename?: 'Query'
  priceLists: {
    __typename?: 'PaginatedPriceListsResponse'
    items?: Array<{
      __typename?: 'PriceList'
      id: number
      pricelistname: string
      active?: boolean | null
      currencycode?: string | null
      pricelistdescription?: string | null
      externalid?: string | null
      tags?: Array<string> | null
    }> | null
  }
}

export type GetPriceListQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetPriceListQuery = {
  __typename?: 'Query'
  priceList: {
    __typename?: 'PriceList'
    id: number
    pricelistname: string
    active?: boolean | null
    currencycode?: string | null
    pricelistdescription?: string | null
    externalid?: string | null
    tags?: Array<string> | null
  }
}

export type UpdatePriceListMutationVariables = Exact<{
  priceList: UpdatePriceListInput
}>

export type UpdatePriceListMutation = {
  __typename?: 'Mutation'
  updatePriceList: {
    __typename?: 'PriceList'
    id: number
    pricelistname: string
    active?: boolean | null
    currencycode?: string | null
    pricelistdescription?: string | null
    externalid?: string | null
    tags?: Array<string> | null
  }
}

export type DeletePriceListMutationVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type DeletePriceListMutation = {
  __typename?: 'Mutation'
  deletePriceList: boolean
}

export type ProductGroupFieldsFragment = {
  __typename?: 'ProductGroup'
  id: number
  value?: number | null
  name: string
  comment?: string | null
  type?: ProductGroupType | null
}

export type GetProductGroupQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetProductGroupQuery = {
  __typename?: 'Query'
  productGroup: { __typename?: 'ProductGroup' } & ProductGroupFieldsFragment
}

export type GetProductGroupsQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
}>

export type GetProductGroupsQuery = {
  __typename?: 'Query'
  productGroups: {
    __typename?: 'PaginatedProductGroupsResponse'
    totalcount?: number | null
    start?: number | null
    items?: Array<
      { __typename?: 'ProductGroup' } & ProductGroupFieldsFragment
    > | null
  }
}

export type AllProductGroupsQueryVariables = Exact<{ [key: string]: never }>

export type AllProductGroupsQuery = {
  __typename?: 'Query'
  allProductGroups: Array<{
    __typename?: 'ProductGroup'
    id: number
    value?: number | null
    type?: ProductGroupType | null
    name: string
    comment?: string | null
  }>
}

export type AddUpdateProductGroupMutationVariables = Exact<{
  productGroup: SingleProductGroupInput
}>

export type AddUpdateProductGroupMutation = {
  __typename?: 'Mutation'
  addUpdateProductGroup: {
    __typename?: 'ProductGroup'
    value?: number | null
    type?: ProductGroupType | null
    name: string
    comment?: string | null
    id: number
  }
}

export type DeleteProductGroupMutationVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type DeleteProductGroupMutation = {
  __typename?: 'Mutation'
  deleteProductGroup: boolean
}

export type GetProductVariantGroupsQueryVariables = Exact<{
  productid?: InputMaybe<Scalars['Int']['input']>
  productSiteId?: InputMaybe<Scalars['Int']['input']>
}>

export type GetProductVariantGroupsQuery = {
  __typename?: 'Query'
  productVariants: {
    __typename?: 'ProductVariantsResponse'
    groups: Array<{
      __typename?: 'ProductVariantGroup'
      name: string
      options: Array<string>
    }>
    variants: Array<{
      __typename?: 'ProductVariant'
      id: number
      attributes: Array<string>
      sku: string
    }>
  }
}

export type ProductFieldsFragment = {
  __typename?: 'Product'
  id: number
  title?: string | null
  activepos?: boolean | null
  sku: string
  moneyprice?: string | null
  datecreated?: string | null
  datemodified?: string | null
  defaultcategoryid?: number | null
  barcode?: string | null
  productgrouptype?: ProductGroupType | null
  allowdecimals?: boolean | null
  unitlabel?: string | null
  variant?: Array<{
    __typename?: 'ProductVariantItem'
    name?: string | null
    value?: string | null
  }> | null
  manufacturer?: {
    __typename?: 'Manufacturer'
    id: number
    name: string
  } | null
  pricelistitems?: Array<{
    __typename?: 'PricelistItem'
    pricelistid: number
    sku?: string | null
    productid?: number | null
    moneyprice: string
    pricelist?: {
      __typename?: 'PriceList'
      active?: boolean | null
      currencycode?: string | null
      pricelistname: string
    } | null
  }> | null
  productgroup?: {
    __typename?: 'ProductGroup'
    id: number
    name: string
    type?: ProductGroupType | null
    value?: number | null
  } | null
  allcategories?: Array<{
    __typename?: 'Category'
    id: number
    categoryparentid?: number | null
    title: string
  }> | null
  productImages?: Array<{
    __typename?: 'ProductImage'
    id: string
    fileUrl: string
  }> | null
}

export type ProductDetailsFieldsFragment = {
  __typename?: 'Product'
  moneypriceorg?: string | null
  variantparentid?: number | null
  description?: string | null
  descriptionshort?: string | null
  customattributes?: Record<string, unknown> | null
  skumanufacturer?: string | null
  manufacturerurl?: string | null
  categories?: Array<number> | null
  custom1?: string | null
  custom2?: string | null
  custom3?: string | null
  custom4?: string | null
  custom5?: string | null
  stockcountenable?: boolean | null
  related?: Array<number> | null
  accessories?: Array<number> | null
  similar?: Array<number> | null
  isMainVariant?: boolean | null
  isSingleProduct?: boolean | null
  computedPurchasePrice?: {
    __typename?: 'ComputedPurchasePriceValue'
    price?: number | null
    price_list_id?: string | null
    price_list?: { __typename?: 'PurchasePriceList'; currency: string } | null
  } | null
  childVariants?: Array<{
    __typename?: 'Product'
    id: number
    sku: string
    title?: string | null
    moneyprice?: string | null
    moneypriceorg?: string | null
    barcode?: string | null
    isMainVariant?: boolean | null
    isSingleProduct?: boolean | null
    customattributes?: Record<string, unknown> | null
    variant?: Array<{
      __typename?: 'ProductVariantItem'
      name?: string | null
      value?: string | null
    }> | null
    productImages?: Array<{
      __typename?: 'ProductImage'
      id: string
      fileUrl: string
    }> | null
    computedPurchasePrice?: {
      __typename?: 'ComputedPurchasePriceValue'
      price?: number | null
      price_list_id?: string | null
      price_list?: { __typename?: 'PurchasePriceList'; currency: string } | null
    } | null
  }> | null
  variantGroups?: Array<{
    __typename?: 'VariantGroup'
    name: string
    options: Array<string>
  }> | null
  warehouseItems?: Array<{
    __typename?: 'WarehouseItem'
    sku: string
    decimaltotal?: string | null
    warehouse?: {
      __typename?: 'Warehouse'
      id: number
      name: string
      sellable?: boolean | null
      store?: { __typename?: 'Store'; id: number; name: string } | null
    } | null
  }> | null
  relatedProducts?: Array<{
    __typename?: 'Product'
    id: number
    title?: string | null
    sku: string
    productImages?: Array<{
      __typename?: 'ProductImage'
      fileUrl: string
    }> | null
  }> | null
  accessoriesProducts?: Array<{
    __typename?: 'Product'
    id: number
    title?: string | null
    sku: string
    productImages?: Array<{
      __typename?: 'ProductImage'
      fileUrl: string
    }> | null
  }> | null
  similarProducts?: Array<{
    __typename?: 'Product'
    id: number
    title?: string | null
    sku: string
    productImages?: Array<{
      __typename?: 'ProductImage'
      fileUrl: string
    }> | null
  }> | null
} & ProductFieldsFragment

export type RelatedProductFieldsFragment = {
  __typename?: 'Product'
  id: number
  relatedProducts?: Array<{
    __typename?: 'Product'
    id: number
    title?: string | null
    variant?: Array<{
      __typename?: 'ProductVariantItem'
      name?: string | null
      value?: string | null
    }> | null
    productImages?: Array<{
      __typename?: 'ProductImage'
      fileUrl: string
    }> | null
  }> | null
  accessoriesProducts?: Array<{
    __typename?: 'Product'
    id: number
    title?: string | null
    variant?: Array<{
      __typename?: 'ProductVariantItem'
      name?: string | null
      value?: string | null
    }> | null
    productImages?: Array<{
      __typename?: 'ProductImage'
      fileUrl: string
    }> | null
  }> | null
  similarProducts?: Array<{
    __typename?: 'Product'
    id: number
    title?: string | null
    variant?: Array<{
      __typename?: 'ProductVariantItem'
      name?: string | null
      value?: string | null
    }> | null
    productImages?: Array<{
      __typename?: 'ProductImage'
      fileUrl: string
    }> | null
  }> | null
}

export type ProductVariantFieldsFragment = {
  __typename?: 'Product'
  id: number
  sku: string
  moneypriceorg?: string | null
  pricelisthasvolume?: boolean | null
  title?: string | null
  activepos?: boolean | null
  barcode?: string | null
  barcodealiases?: Array<string> | null
  description?: string | null
  descriptionshort?: string | null
  moneyofferprice?: string | null
}

export type GetProductsQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  includeVariants?: InputMaybe<Scalars['Boolean']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  productGroupIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  campaignIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  manufacturerIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  categoryIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  sort?: InputMaybe<GetProductsSort>
  skus?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>
  productSiteId?: InputMaybe<Scalars['Int']['input']>
  fallbackPriceToCommon?: InputMaybe<Scalars['Boolean']['input']>
  productGroupType?: InputMaybe<ProductGroupType>
}>

export type GetProductsQuery = {
  __typename?: 'Query'
  products: {
    __typename?: 'PaginatedProductsResponse'
    totalcount?: number | null
    start?: number | null
    items?: Array<{ __typename?: 'Product' } & ProductFieldsFragment> | null
  }
}

export type GetRelatedProductsSearchQueryVariables = Exact<{
  sku: Scalars['String']['input']
}>

export type GetRelatedProductsSearchQuery = {
  __typename?: 'Query'
  products: {
    __typename?: 'PaginatedProductsResponse'
    totalcount?: number | null
    start?: number | null
    items?: Array<{
      __typename?: 'Product'
      id: number
      title?: string | null
      sku: string
      productImages?: Array<{
        __typename?: 'ProductImage'
        id: string
        fileUrl: string
      }> | null
    }> | null
  }
}

export type GetProductQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  productSiteId?: InputMaybe<Scalars['Int']['input']>
  fallbackPriceToCommon?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GetProductQuery = {
  __typename?: 'Query'
  product: { __typename?: 'Product' } & ProductDetailsFieldsFragment
}

export type GetRelatedProductsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>
  productSiteId?: InputMaybe<Scalars['Int']['input']>
}>

export type GetRelatedProductsQuery = {
  __typename?: 'Query'
  product: { __typename?: 'Product' } & RelatedProductFieldsFragment
}

export type GetProductStockLevelsQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  includeVariants?: InputMaybe<Scalars['Boolean']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  categoryIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  sort?: InputMaybe<GetProductsSort>
  warehouseId: Scalars['Int']['input']
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>
  productSiteId?: InputMaybe<Scalars['Int']['input']>
}>

export type GetProductStockLevelsQuery = {
  __typename?: 'Query'
  products: {
    __typename?: 'PaginatedProductsResponse'
    totalcount?: number | null
    start?: number | null
    items?: Array<{
      __typename?: 'Product'
      id: number
      sku: string
      title?: string | null
      activepos?: boolean | null
      images?: Array<string> | null
      variant?: Array<{
        __typename?: 'ProductVariantItem'
        name?: string | null
        value?: string | null
      }> | null
      warehouseItems?: Array<{
        __typename?: 'WarehouseItem'
        sku: string
        decimaltotal?: string | null
        decimalreserved?: string | null
        decimalavailable?: string | null
        datelastmodified?: string | null
        binlocation?: string | null
        decimalthreshold?: string | null
        moneytotal?: string | null
        warehouse?: {
          __typename?: 'Warehouse'
          id: number
          name: string
          sellable?: boolean | null
          store?: { __typename?: 'Store'; id: number; name: string } | null
        } | null
      }> | null
    }> | null
  }
}

export type GetProductPricesQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  includeproductvariants?: InputMaybe<Scalars['Boolean']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  categoryIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  sort?: InputMaybe<GetProductsSort>
  currency: Scalars['String']['input']
  fallbackPriceToCommon?: InputMaybe<Scalars['Boolean']['input']>
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>
  productSiteId?: InputMaybe<Scalars['Int']['input']>
}>

export type GetProductPricesQuery = {
  __typename?: 'Query'
  products: {
    __typename?: 'PaginatedProductsResponse'
    totalcount?: number | null
    start?: number | null
    items?: Array<{
      __typename?: 'Product'
      id: number
      sku: string
      title?: string | null
      activepos?: boolean | null
      images?: Array<string> | null
      moneypriceorg?: string | null
      moneypricein?: string | null
      variant?: Array<{
        __typename?: 'ProductVariantItem'
        name?: string | null
        value?: string | null
      }> | null
      productgroup?: {
        __typename?: 'ProductGroup'
        value?: number | null
      } | null
      computedPurchasePrice?: {
        __typename?: 'ComputedPurchasePriceValue'
        price?: number | null
        price_list_id?: string | null
        price_list?: {
          __typename?: 'PurchasePriceList'
          currency: string
        } | null
      } | null
      pricelistitems?: Array<{
        __typename?: 'PricelistItem'
        productid?: number | null
        moneyprice: string
        pricelistid: number
        sku?: string | null
      }> | null
    }> | null
  }
}

export type GetProductPurchasePricesQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  includeproductvariants?: InputMaybe<Scalars['Boolean']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  categoryIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  sort?: InputMaybe<GetProductsSort>
  priceListId: Scalars['String']['input']
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>
  productSiteId?: InputMaybe<Scalars['Int']['input']>
  fallbackPriceToCommon?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GetProductPurchasePricesQuery = {
  __typename?: 'Query'
  products: {
    __typename?: 'PaginatedProductsResponse'
    totalcount?: number | null
    start?: number | null
    items?: Array<{
      __typename?: 'Product'
      id: number
      sku: string
      title?: string | null
      activepos?: boolean | null
      images?: Array<string> | null
      moneypriceorg?: string | null
      variant?: Array<{
        __typename?: 'ProductVariantItem'
        name?: string | null
        value?: string | null
      }> | null
      productgroup?: {
        __typename?: 'ProductGroup'
        value?: number | null
      } | null
      pricelistitems?: Array<{
        __typename?: 'PricelistItem'
        productid?: number | null
        moneyprice: string
        pricelistid: number
        sku?: string | null
        pricelist?: {
          __typename?: 'PriceList'
          currencycode?: string | null
        } | null
      }> | null
      purchasePrice?: {
        __typename?: 'PurchasePriceListItem'
        price: number
        sku: string
        priceListId: string
      } | null
    }> | null
  }
}

export type GetProductsBatchItemsQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  includeVariants?: InputMaybe<Scalars['Boolean']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  categoryIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  sort?: InputMaybe<GetProductsSort>
  warehouseId: Scalars['Int']['input']
  warehouseBatchId: Scalars['Int']['input']
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>
  productSiteId?: InputMaybe<Scalars['Int']['input']>
}>

export type GetProductsBatchItemsQuery = {
  __typename?: 'Query'
  products: {
    __typename?: 'PaginatedProductsResponse'
    totalcount?: number | null
    start?: number | null
    items?: Array<
      {
        __typename?: 'Product'
        warehouseBatchItem?: {
          __typename?: 'WarehouseBatchItem'
          sku: string
          warehousebatchitemid: number
          decimalquantity?: string | null
          moneypricein?: string | null
        } | null
      } & WarehouseBatchItemProductFieldsFragment
    > | null
  }
}

export type GetProductsStocktakingItemsQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  includeVariants?: InputMaybe<Scalars['Boolean']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  categoryIds?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  sort?: InputMaybe<GetProductsSort>
  warehouseId: Scalars['Int']['input']
  warehouseBatchId: Scalars['Int']['input']
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>
  productSiteId?: InputMaybe<Scalars['Int']['input']>
}>

export type GetProductsStocktakingItemsQuery = {
  __typename?: 'Query'
  products: {
    __typename?: 'PaginatedProductsResponse'
    totalcount?: number | null
    start?: number | null
    items?: Array<
      {
        __typename?: 'Product'
        warehouseBatchItem?: {
          __typename?: 'WarehouseBatchItem'
          sku: string
          warehousebatchitemid: number
          decimalquantity?: string | null
          emailowner?: string | null
          datemodified?: string | null
          comment?: string | null
          user?: {
            __typename?: 'User'
            namefirst?: string | null
            namelast?: string | null
          } | null
        } | null
      } & WarehouseBatchItemProductFieldsFragment
    > | null
  }
}

export type AddProductMutationVariables = Exact<{
  data: ProductInput
}>

export type AddProductMutation = { __typename?: 'Mutation'; addProduct: number }

export type UpdateProductMutationVariables = Exact<{
  productId: Scalars['Int']['input']
  data: ProductInput
  currency?: InputMaybe<Scalars['String']['input']>
  fallbackPriceToCommon?: InputMaybe<Scalars['Boolean']['input']>
}>

export type UpdateProductMutation = {
  __typename?: 'Mutation'
  updateProduct: { __typename?: 'Product' } & ProductDetailsFieldsFragment
}

export type UpdateProductsMutationVariables = Exact<{
  data: Array<SingleProductsInput> | SingleProductsInput
  currency?: InputMaybe<Scalars['String']['input']>
  fallbackPriceToCommon?: InputMaybe<Scalars['Boolean']['input']>
}>

export type UpdateProductsMutation = {
  __typename?: 'Mutation'
  updateProducts: Array<{
    __typename?: 'SingleUpdateProductsResponse'
    success: boolean
    code?: string | null
    product: { __typename?: 'Product' } & ProductDetailsFieldsFragment
  }>
}

export type UpdateProductVariantsMutationVariables = Exact<{
  productId: Scalars['Int']['input']
  variants: Array<VariantInput> | VariantInput
  groups: Array<VariantGroupInput> | VariantGroupInput
  deleteMainIfFails?: InputMaybe<Scalars['Boolean']['input']>
}>

export type UpdateProductVariantsMutation = {
  __typename?: 'Mutation'
  updateProductVariants: Array<
    { __typename?: 'Product' } & ProductVariantFieldsFragment
  >
}

export type DeleteProductsMutationVariables = Exact<{
  data: DeleteProductsInput
}>

export type DeleteProductsMutation = {
  __typename?: 'Mutation'
  deleteProducts: Array<boolean>
}

export type UploadProductImageMutationVariables = Exact<{
  productId: Scalars['Int']['input']
  file: Scalars['Upload']['input']
}>

export type UploadProductImageMutation = {
  __typename?: 'Mutation'
  uploadProductImage: boolean
}

export type UpdateProductImagesMutationVariables = Exact<{
  productId: Scalars['Int']['input']
  imageIds: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type UpdateProductImagesMutation = {
  __typename?: 'Mutation'
  updateProductImages: boolean
}

export type UpdatePurchasePriceListItemsMutationVariables = Exact<{
  items: Array<PurchasePriceListItemInput> | PurchasePriceListItemInput
  priceListId: Scalars['String']['input']
}>

export type UpdatePurchasePriceListItemsMutation = {
  __typename?: 'Mutation'
  updatePurchasePriceListItems: Array<{
    __typename?: 'Product'
    id: number
    sku: string
    purchasePrice?: {
      __typename?: 'PurchasePriceListItem'
      price: number
      sku: string
      priceListId: string
    } | null
  }>
}

export type DeletePurchasePriceListItemsMutationVariables = Exact<{
  skus: Array<Scalars['String']['input']> | Scalars['String']['input']
  priceListId: Scalars['String']['input']
}>

export type DeletePurchasePriceListItemsMutation = {
  __typename?: 'Mutation'
  deletePurchasePriceListItems: boolean
}

export type GetPurchasePriceListMappingQueryVariables = Exact<{
  [key: string]: never
}>

export type GetPurchasePriceListMappingQuery = {
  __typename?: 'Query'
  purchasePriceListMapping: {
    __typename?: 'PurchasePriceListMapping'
    id: number
    shared?: Record<string, unknown> | null
    assigned?: {
      __typename?: 'PurchasePriceListMappingItem'
      price_list_id: string
      stores?: Record<string, unknown> | null
    } | null
  }
}

export type UpdatePurchasePriceListMappingMutationVariables = Exact<{
  mapping: PurchasePriceListMappingInput
}>

export type UpdatePurchasePriceListMappingMutation = {
  __typename?: 'Mutation'
  updatePurchasePriceListMapping: {
    __typename?: 'PurchasePriceListMapping'
    id: number
    shared?: Record<string, unknown> | null
    assigned?: {
      __typename?: 'PurchasePriceListMappingItem'
      price_list_id: string
      stores?: Record<string, unknown> | null
    } | null
  }
}

export type PurchasePriceListFragment = {
  __typename?: 'PurchasePriceList'
  id: string
  created_at: string
  modified_at: string
  owner_eshop_id: number
  name: string
  currency: string
  shared_store_ids?: Array<string> | null
  sharedStores?: Array<{
    __typename?: 'Store'
    id: number
    name: string
  }> | null
  ownerSite?: { __typename?: 'Site'; eshopid: number; eshopname: string } | null
}

export type GetPurchasePriceListsQueryVariables = Exact<{
  num?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
  next?: InputMaybe<Scalars['String']['input']>
}>

export type GetPurchasePriceListsQuery = {
  __typename?: 'Query'
  purchasePriceLists: {
    __typename?: 'PaginatedPurchasePriceListsResponse'
    next?: string | null
    start?: number | null
    items?: Array<
      { __typename?: 'PurchasePriceList' } & PurchasePriceListFragment
    > | null
  }
}

export type GetPurchasePriceListQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type GetPurchasePriceListQuery = {
  __typename?: 'Query'
  purchasePriceList: {
    __typename?: 'PurchasePriceList'
  } & PurchasePriceListFragment
}

export type AddPurchasePriceListMutationVariables = Exact<{
  priceList: AddUpdatePurchasePriceListInput
}>

export type AddPurchasePriceListMutation = {
  __typename?: 'Mutation'
  addPurchasePriceList: {
    __typename?: 'PurchasePriceList'
  } & PurchasePriceListFragment
}

export type UpdatePurchasePriceListMutationVariables = Exact<{
  priceList: AddUpdatePurchasePriceListInput
}>

export type UpdatePurchasePriceListMutation = {
  __typename?: 'Mutation'
  updatePurchasePriceList: {
    __typename?: 'PurchasePriceList'
  } & PurchasePriceListFragment
}

export type DeletePurchasePriceListMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type DeletePurchasePriceListMutation = {
  __typename?: 'Mutation'
  deletePurchasePriceList: boolean
}

export type AllReasonCodesQueryVariables = Exact<{
  type?: InputMaybe<Array<ReasonCodeType> | ReasonCodeType>
}>

export type AllReasonCodesQuery = {
  __typename?: 'Query'
  allReasonCodes: Array<{
    __typename?: 'ReasonCode'
    name?: string | null
    reasoncode: string
    reasoncodeid: number
    type: ReasonCodeType
  }>
}

export type RewardsSettingsQueryVariables = Exact<{ [key: string]: never }>

export type RewardsSettingsQuery = {
  __typename?: 'Query'
  rewardsSetting: {
    __typename?: 'RewardsSettings'
    api_url: string
    api_token?: string | null
  }
}

export type UpdateRewardsSettingsMutationVariables = Exact<{
  apiUrl: Scalars['Url']['input']
  apiToken: Scalars['String']['input']
}>

export type UpdateRewardsSettingsMutation = {
  __typename?: 'Mutation'
  updateRewardsSettings: boolean
}

export type ShipmentActionFieldsFragment = {
  __typename?: 'ShipmentAction'
  created_at: string
  created_by: string
  action: ActionType
  state?: ShipmentState | null
  package_id_list?: Array<string> | null
  shipment_version: number
  state_reason_code?: string | null
  warehouse_id?: number | null
  received_items?: {
    __typename?: 'ShipmentActionReceivedItems'
    reason_code?: string | null
    note?: string | null
    warehouse_id?: number | null
    warehouse_transaction_id?: number | null
    items: Array<{
      __typename?: 'ShipmentReceivedItem'
      item_id: string
      note?: string | null
      serial_numbers?: Array<string> | null
      quantity: number
    }>
    warehouse?: { __typename?: 'Warehouse'; id: number; name: string } | null
  } | null
  received_items_undeclared?: {
    __typename?: 'ShipmentActionReceivedItemsUndeclared'
    reason_code?: string | null
    note?: string | null
    warehouse_id?: number | null
    warehouse_transaction_id?: number | null
    items: Array<{
      __typename?: 'ShipmentItem'
      id: string
      sku: string
      barcode?: string | null
      product_name: string
      unit_price?: number | null
      quantity: number
      batch?: string | null
      batch_expires?: string | null
      additional_data?: Record<string, unknown> | null
      quantity_received: number
      serial_numbers?: Array<{
        __typename?: 'ShipmentItemSerialNumber'
        serial_number: string
        barcode?: string | null
        additional_data?: Record<string, unknown> | null
        received: boolean
      }> | null
    }>
    warehouse?: { __typename?: 'Warehouse'; id: number; name: string } | null
  } | null
  warehouse?: { __typename?: 'Warehouse'; id: number; name: string } | null
  package?: { __typename?: 'ShipmentPackage'; barcode?: string | null } | null
}

export type AllShipmentActionsQueryVariables = Exact<{
  shipmentId: Scalars['String']['input']
}>

export type AllShipmentActionsQuery = {
  __typename?: 'Query'
  allShipmentActions: Array<
    { __typename?: 'ShipmentAction' } & ShipmentActionFieldsFragment
  >
}

export type AddShipmentActionMutationVariables = Exact<{
  data: ShipmentActionInput
  shipmentId: Scalars['String']['input']
}>

export type AddShipmentActionMutation = {
  __typename?: 'Mutation'
  addShipmentAction: {
    __typename?: 'ShipmentAction'
  } & ShipmentActionFieldsFragment
}

export type ShipmentDetailFieldsFragment = {
  __typename?: 'Shipment'
  id: string
  created_by?: string | null
  version: number
  created_at: string
  modified_at: string
  state: ShipmentState
  all_packages_arrived: boolean
  total_package_count: number
  total_item_quantity: number
  closed_state_reason_code?: string | null
  all_items_received: boolean
  info?: {
    __typename?: 'ShipmentInfo'
    additional_data?: Record<string, unknown> | null
    destination_warehouse_id?: number | null
    origin_warehouse_id?: number | null
    note?: string | null
    currency?: string | null
    barcode?: string | null
    reference_number?: string | null
    destination?: {
      __typename?: 'ShipmentInfoDestination'
      address?: {
        __typename?: 'ShipmentAddress'
        name?: string | null
        address?: string | null
        address2?: string | null
        city?: string | null
        zip?: string | null
        state?: string | null
        country_id?: string | null
      } | null
      contact?: {
        __typename?: 'ShipmentContact'
        title?: string | null
        first_name?: string | null
        last_name?: string | null
        email?: string | null
        mobile?: string | null
        note?: string | null
      } | null
    } | null
    carrier?: {
      __typename?: 'ShipmentInfoCarrier'
      name?: string | null
      service?: string | null
      reference?: string | null
      tracking_url?: string | null
      tracking_url_title?: string | null
      estimated_pickup_at?: string | null
      estimated_delivery_at?: string | null
    } | null
    origin?: {
      __typename?: 'ShipmentInfoOrigin'
      address?: {
        __typename?: 'ShipmentAddress'
        address?: string | null
        address2?: string | null
        city?: string | null
        zip?: string | null
        state?: string | null
        country_id?: string | null
        name?: string | null
      } | null
      contact?: {
        __typename?: 'ShipmentContact'
        title?: string | null
        first_name?: string | null
        last_name?: string | null
        email?: string | null
        mobile?: string | null
        note?: string | null
      } | null
    } | null
  } | null
  packages: Array<{
    __typename?: 'ShipmentPackage'
    description?: string | null
    id: string
    barcode?: string | null
    total_quantity_received: number
    total_quantity: number
    arrived_at?: string | null
    additional_data?: Record<string, unknown> | null
    value?: number | null
    weight?: {
      __typename?: 'ShipmentPackageWeight'
      unit: ShipmentPackageWeightUnitEnum
      weight: number
    } | null
    items?: Array<{
      __typename?: 'ShipmentItem'
      id: string
      sku: string
      product_name: string
      unit_price?: number | null
      quantity: number
      batch?: string | null
      batch_expires?: string | null
      barcode?: string | null
      additional_data?: Record<string, unknown> | null
      quantity_received: number
      serial_numbers?: Array<{
        __typename?: 'ShipmentItemSerialNumber'
        serial_number: string
        barcode?: string | null
        additional_data?: Record<string, unknown> | null
        received: boolean
      }> | null
      product?: {
        __typename?: 'Product'
        id: number
        sku: string
        barcode?: string | null
        images?: Array<string> | null
      } | null
    }> | null
    dimensions?: {
      __typename?: 'ShipmentPackageDimensions'
      width: number
      height: number
      length: number
      unit: ShipmentPackageDimensionsUnitEnum
    } | null
  }>
  items_undeclared: Array<{
    __typename?: 'ShipmentItem'
    id: string
    additional_data?: Record<string, unknown> | null
    barcode?: string | null
    batch?: string | null
    batch_expires?: string | null
    product_name: string
    quantity: number
    quantity_received: number
    sku: string
    unit_price?: number | null
    product?: {
      __typename?: 'Product'
      id: number
      sku: string
      images?: Array<string> | null
      barcode?: string | null
    } | null
    serial_numbers?: Array<{
      __typename?: 'ShipmentItemSerialNumber'
      additional_data?: Record<string, unknown> | null
      barcode?: string | null
      received: boolean
      serial_number: string
    }> | null
  }>
}

export type ShipmentFieldsFragment = {
  __typename?: 'Shipment'
  id: string
  created_by?: string | null
  version: number
  created_at: string
  modified_at: string
  state: ShipmentState
  all_packages_arrived: boolean
  total_package_count: number
  total_item_quantity: number
  closed_state_reason_code?: string | null
  all_items_received: boolean
  info?: {
    __typename?: 'ShipmentInfo'
    additional_data?: Record<string, unknown> | null
    destination_warehouse_id?: number | null
    origin_warehouse_id?: number | null
    note?: string | null
    currency?: string | null
    barcode?: string | null
    reference_number?: string | null
    destination?: {
      __typename?: 'ShipmentInfoDestination'
      address?: {
        __typename?: 'ShipmentAddress'
        name?: string | null
        address?: string | null
        address2?: string | null
        city?: string | null
        zip?: string | null
        state?: string | null
        country_id?: string | null
      } | null
      contact?: {
        __typename?: 'ShipmentContact'
        title?: string | null
        first_name?: string | null
        last_name?: string | null
        email?: string | null
        mobile?: string | null
        note?: string | null
      } | null
    } | null
    carrier?: {
      __typename?: 'ShipmentInfoCarrier'
      name?: string | null
      service?: string | null
      reference?: string | null
      tracking_url?: string | null
      tracking_url_title?: string | null
      estimated_pickup_at?: string | null
      estimated_delivery_at?: string | null
    } | null
    origin?: {
      __typename?: 'ShipmentInfoOrigin'
      address?: {
        __typename?: 'ShipmentAddress'
        address?: string | null
        address2?: string | null
        city?: string | null
        zip?: string | null
        state?: string | null
        country_id?: string | null
        name?: string | null
      } | null
      contact?: {
        __typename?: 'ShipmentContact'
        title?: string | null
        first_name?: string | null
        last_name?: string | null
        email?: string | null
        mobile?: string | null
        note?: string | null
      } | null
    } | null
  } | null
}

export type GetShipmentsQueryVariables = Exact<{
  num?: InputMaybe<Scalars['Int']['input']>
  shipmentState?: InputMaybe<Array<ShipmentState> | ShipmentState>
  warehouseFilterMode?: InputMaybe<WarehouseFilterMode>
  originWarehouseId?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  destinationWarehouseId?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  next?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
}>

export type GetShipmentsQuery = {
  __typename?: 'Query'
  shipments: {
    __typename?: 'PaginatedShipmentsResponse'
    next?: string | null
    start?: number | null
    items?: Array<{ __typename?: 'Shipment' } & ShipmentFieldsFragment> | null
  }
}

export type GetShipmentQueryVariables = Exact<{
  shipmentId: Scalars['String']['input']
}>

export type GetShipmentQuery = {
  __typename?: 'Query'
  shipment: { __typename?: 'Shipment' } & ShipmentDetailFieldsFragment
}

export type AddShipmentMutationVariables = Exact<{
  data: ShipmentInput
}>

export type AddShipmentMutation = {
  __typename?: 'Mutation'
  addShipment: { __typename?: 'Shipment' } & ShipmentDetailFieldsFragment
}

export type DeleteShipmentsMutationVariables = Exact<{
  shipmentIds: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type DeleteShipmentsMutation = {
  __typename?: 'Mutation'
  deleteShipments: Array<string>
}

export type NumTransitShipmentsQueryVariables = Exact<{ [key: string]: never }>

export type NumTransitShipmentsQuery = {
  __typename?: 'Query'
  numTransitShipments: number
}

export type ShipmentsV2FieldsFragment = {
  __typename?: 'ShipmentV2'
  archived?: boolean | null
  carrier_name?: string | null
  carrier_reference?: string | null
  carrier_trackingurl?: string | null
  datenew?: string | null
  dateintransit?: string | null
  receiver_address?: string | null
  receiver_name?: string | null
  sender_address?: string | null
  sender_name?: string | null
  shipmentid?: number | null
  shipmentstate?: ShipmentStateV2 | null
  comment?: string | null
  dateestimatedpickup?: string | null
  dateestimateddelivery?: string | null
}

export type ShipmentV2DetailFieldsFragment = {
  __typename?: 'ShipmentV2'
  externalid?: string | null
  barcode?: string | null
  sender_warehouseid?: number | null
  receiver_warehouseid?: number | null
}

export type ShipmentV2SenderContactFragment = {
  __typename?: 'ShipmentV2'
  sender_referenceemail?: string | null
  sender_referencename?: string | null
  sender_referencemobile?: string | null
  sender_instructions?: string | null
}

export type ShipmentV2ReceiverContactFragment = {
  __typename?: 'ShipmentV2'
  receiver_referenceemail?: string | null
  receiver_referencename?: string | null
  receiver_referencemobile?: string | null
  receiver_instructions?: string | null
}

export type ShipmentV2SenderAddressFragment = {
  __typename?: 'ShipmentV2'
  sender_address?: string | null
  sender_address2?: string | null
  sender_city?: string | null
  sender_countryid?: string | null
  sender_name?: string | null
  sender_state?: string | null
  sender_zip?: string | null
}

export type ShipmentV2ReceiverAddressFragment = {
  __typename?: 'ShipmentV2'
  receiver_address?: string | null
  receiver_address2?: string | null
  receiver_city?: string | null
  receiver_countryid?: string | null
  receiver_name?: string | null
  receiver_state?: string | null
  receiver_zip?: string | null
}

export type ShipmentV2PackagesFragment = {
  __typename?: 'ShipmentV2'
  packages?: Array<{
    __typename?: 'ShipmentV2Package'
    id?: string | null
    shipmentpackageid?: number | null
    barcode?: string | null
    externalid?: string | null
    comment?: string | null
    totalQuantity?: number | null
    totalReceivedQuantity?: number | null
    items?: Array<{
      __typename?: 'ShipmentV2Item'
      id?: string | null
      decimalunitquantity?: string | null
      externalid?: string | null
      moneypricein?: string | null
      productname?: string | null
      quantity?: number | null
      quantityreceived?: number | null
      shipmentitemid?: number | null
      shipmentpackageid?: number | null
      sku?: string | null
      unitlabel?: string | null
      product?: {
        __typename?: 'Product'
        id: number
        sku: string
        images?: Array<string> | null
        allowdecimals?: boolean | null
        unitlabel?: string | null
      } | null
    }> | null
  }> | null
}

export type GetShipmentsV2QueryVariables = Exact<{
  num?: InputMaybe<Scalars['Int']['input']>
  shipmentid?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  archived?: InputMaybe<ArchivedFilterTypeV2>
  senderWarehouseid?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  receiverWarehouseid?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
  start?: InputMaybe<Scalars['Int']['input']>
}>

export type GetShipmentsV2Query = {
  __typename?: 'Query'
  getShipmentsV2: {
    __typename?: 'ShipmentsV2'
    totalcount?: number | null
    start?: number | null
    items?: Array<
      { __typename?: 'ShipmentV2' } & ShipmentsV2FieldsFragment
    > | null
  }
}

export type GetShipmentV2QueryVariables = Exact<{
  shipmentId: Scalars['Int']['input']
}>

export type GetShipmentV2Query = {
  __typename?: 'Query'
  getShipmentV2: {
    __typename?: 'ShipmentV2'
  } & ShipmentV2DetailFieldsFragment &
    ShipmentsV2FieldsFragment &
    ShipmentV2SenderContactFragment &
    ShipmentV2ReceiverContactFragment &
    ShipmentV2SenderAddressFragment &
    ShipmentV2ReceiverAddressFragment &
    ShipmentV2PackagesFragment
}

export type UpdateArchiveStateShipmentsV2MutationVariables = Exact<{
  shipments: Array<ShipmentV2Input> | ShipmentV2Input
}>

export type UpdateArchiveStateShipmentsV2Mutation = {
  __typename?: 'Mutation'
  updateShipmentsV2: Array<{
    __typename?: 'ShipmentV2'
    archived?: boolean | null
    shipmentid?: number | null
  }>
}

export type UpdateShipmentV2MutationVariables = Exact<{
  shipment: ShipmentV2Input
}>

export type UpdateShipmentV2Mutation = {
  __typename?: 'Mutation'
  updateShipmentV2: {
    __typename?: 'ShipmentV2'
  } & ShipmentV2DetailFieldsFragment &
    ShipmentsV2FieldsFragment &
    ShipmentV2SenderContactFragment &
    ShipmentV2ReceiverContactFragment &
    ShipmentV2SenderAddressFragment &
    ShipmentV2ReceiverAddressFragment &
    ShipmentV2PackagesFragment
}

export type AddShipmentV2MutationVariables = Exact<{
  shipment: ShipmentV2Input
}>

export type AddShipmentV2Mutation = {
  __typename?: 'Mutation'
  addShipmentV2: {
    __typename?: 'ShipmentV2'
  } & ShipmentV2DetailFieldsFragment &
    ShipmentsV2FieldsFragment &
    ShipmentV2SenderContactFragment &
    ShipmentV2ReceiverContactFragment &
    ShipmentV2SenderAddressFragment &
    ShipmentV2ReceiverAddressFragment &
    ShipmentV2PackagesFragment
}

export type AllSitesQueryVariables = Exact<{ [key: string]: never }>

export type AllSitesQuery = {
  __typename?: 'Query'
  allSites: Array<{ __typename?: 'Site' } & SiteFieldsFragment>
}

export type AllFranchiseSitesQueryVariables = Exact<{ [key: string]: never }>

export type AllFranchiseSitesQuery = {
  __typename?: 'Query'
  allFranchiseSites: Array<{ __typename?: 'Site' } & SiteFieldsFragment>
}

export type UpdateWarehouseItemsMutationVariables = Exact<{
  warehouseId: Scalars['Int']['input']
  items: Array<WarehouseItemInput> | WarehouseItemInput
}>

export type UpdateWarehouseItemsMutation = {
  __typename?: 'Mutation'
  updateWarehouseItems: Array<{
    __typename?: 'WarehouseItem'
    sku: string
    binlocation?: string | null
    decimalthreshold?: string | null
  }>
}

export type StoreReplenishmentItemFragment = {
  __typename?: 'StoreReplenishmentItem'
  sku: string
  title?: string | null
  images?: Array<string> | null
  customattributes?: Record<string, unknown> | null
  decimalTotalTo?: string | null
  decimalTotalFrom?: string | null
  decimalThresholdFrom?: string | null
  decimalThresholdTo?: string | null
  binLocationTo?: string | null
  binLocationFrom?: string | null
  toPick?: number | null
  variant?: Array<{
    __typename?: 'ProductVariantItem'
    name?: string | null
    value?: string | null
  }> | null
}

export type StoreReplenishmentQueryVariables = Exact<{
  warehouseToId: Scalars['Int']['input']
  warehouseFromId: Scalars['Int']['input']
  num?: InputMaybe<Scalars['Int']['input']>
  next?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<GetReplenishmentItemsSort>
}>

export type StoreReplenishmentQuery = {
  __typename?: 'Query'
  replenishmentItems: {
    __typename?: 'PaginatedReplenishmentResponse'
    next?: string | null
    start?: number | null
    items?: Array<
      { __typename?: 'StoreReplenishmentItem' } & StoreReplenishmentItemFragment
    > | null
  }
}

export type AllReplenishmentItemsQueryVariables = Exact<{
  warehouseToId: Scalars['Int']['input']
  warehouseFromId: Scalars['Int']['input']
  skus?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
}>

export type AllReplenishmentItemsQuery = {
  __typename?: 'Query'
  allReplenishmentItems: Array<
    { __typename?: 'StoreReplenishmentItem' } & StoreReplenishmentItemFragment
  >
}

export type ExportStoreReplenishmentMutationVariables = Exact<{
  fileType: FileType
  warehouseToId: Scalars['Int']['input']
  warehouseFromId: Scalars['Int']['input']
  skus?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
}>

export type ExportStoreReplenishmentMutation = {
  __typename?: 'Mutation'
  exportAllReplenishmentItems?: string | null
}

export type AllStoreTagsQueryVariables = Exact<{ [key: string]: never }>

export type AllStoreTagsQuery = {
  __typename?: 'Query'
  allStoreTags: Array<string>
}

export type GetStoresQueryVariables = Exact<{ [key: string]: never }>

export type GetStoresQuery = {
  __typename?: 'Query'
  stores: Array<{
    __typename?: 'Store'
    id: number
    externalid?: string | null
    name: string
    warehouses?: Array<{ __typename?: 'Warehouse'; id: number }> | null
  }>
}

export type SiteFieldsFragment = {
  __typename?: 'Site'
  eshopid: number
  eshopname: string
  eshoptype: SiteSettingsEshopTypeEnum
  commoneshopid?: number | null
  currencycode: string
  timezone: string
  pricesstoredwithvat: boolean
  allow_store_manager_campaigns: boolean
  customattributes_in_store_replenishment: Array<string>
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'Me'
    intercomHash?: string | null
    role: PermissionRole
    accountId: number
    siteId: number
    warehouseIds: Array<number>
    storeIds: Array<number>
    resourceUrl: string
    timeZone: string
    user?: {
      __typename?: 'User'
      id?: string | null
      externalid?: string | null
      email: string
      namefirst?: string | null
      namelast?: string | null
      company?: string | null
      staff_pos_activation?: boolean | null
      timezone?: string | null
      hasmfa?: boolean | null
    } | null
    currentSite: { __typename?: 'Site' } & SiteFieldsFragment
    sites: Array<{ __typename?: 'Site' } & SiteFieldsFragment>
  }
}

export type StaffUsersQueryVariables = Exact<{ [key: string]: never }>

export type StaffUsersQuery = {
  __typename?: 'Query'
  staffUsers: Array<{
    __typename?: 'User'
    id?: string | null
    externalid?: string | null
    namefirst?: string | null
    namelast?: string | null
  }>
}

export type GetUsersQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<GetUsersSort>
  search?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  customernumber?: InputMaybe<Scalars['String']['input']>
  personalid?: InputMaybe<Scalars['String']['input']>
  mobile?: InputMaybe<Scalars['String']['input']>
  userid?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  tags?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
}>

export type GetUsersQuery = {
  __typename?: 'Query'
  users: {
    __typename?: 'PaginatedUsersResponse'
    totalcount?: number | null
    start?: number | null
    items?: Array<{
      __typename?: 'User'
      id?: string | null
      personalid?: string | null
      externalid?: string | null
      role?: UserRole | null
      email: string
      namefirst?: string | null
      namelast?: string | null
      datecreated?: string | null
      datemodified?: string | null
      warehouses?: Array<number> | null
      hasmfa?: boolean | null
      tags?: Array<string> | null
      active?: boolean | null
      customernumber?: string | null
      allWarehouses?: Array<{
        __typename?: 'Warehouse'
        id: number
        name: string
      }> | null
      resolvedTags?: Array<{
        __typename?: 'UserTag'
        id: string
        label: string
      }> | null
    }> | null
  }
}

export type GetUserQueryVariables = Exact<{
  userId: Scalars['String']['input']
}>

export type GetUserQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'User'
    id?: string | null
    personalid?: string | null
    externalid?: string | null
    role?: UserRole | null
    email: string
    namefirst?: string | null
    namelast?: string | null
    company?: string | null
    department?: string | null
    companyid?: string | null
    address?: string | null
    address2?: string | null
    zip?: string | null
    city?: string | null
    state?: string | null
    countryid?: string | null
    phone?: string | null
    mobile?: string | null
    notes?: string | null
    customernumber?: string | null
    datecreated?: string | null
    datemodified?: string | null
    voucherpasswords?: Array<string> | null
    warehouses?: Array<number> | null
    posrefundcard?: boolean | null
    staff_pos_activation?: boolean | null
    posallstores?: boolean | null
    active?: boolean | null
    tags?: Array<string> | null
    timezone?: string | null
    hasmfa?: boolean | null
    resolvedTags?: Array<{
      __typename?: 'UserTag'
      id: string
      label: string
    }> | null
    allWarehouses?: Array<{
      __typename?: 'Warehouse'
      id: number
      name: string
    }> | null
  }
}

export type ExportUsersMutationVariables = Exact<{
  fileType: FileType
  userIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
}>

export type ExportUsersMutation = {
  __typename?: 'Mutation'
  exportUsers?: string | null
}

export type WarehouseBatchItemProductFieldsFragment = {
  __typename?: 'Product'
  id: number
  title?: string | null
  sku: string
  activepos?: boolean | null
  images?: Array<string> | null
  variant?: Array<{
    __typename?: 'ProductVariantItem'
    name?: string | null
    value?: string | null
  }> | null
  computedPurchasePrice?: {
    __typename?: 'ComputedPurchasePriceValue'
    price?: number | null
  } | null
  warehouseItems?: Array<{
    __typename?: 'WarehouseItem'
    sku: string
    decimaltotal?: string | null
    binlocation?: string | null
    warehouse?: { __typename?: 'Warehouse'; id: number } | null
  }> | null
}

export type GetWarehouseBatchItemsQueryVariables = Exact<{
  warehouseid: Scalars['Int']['input']
  warehousebatchid: Scalars['Int']['input']
  start?: InputMaybe<Scalars['Int']['input']>
  num?: InputMaybe<Scalars['Int']['input']>
}>

export type GetWarehouseBatchItemsQuery = {
  __typename?: 'Query'
  warehouseBatchItems: {
    __typename?: 'PaginatedWarehouseBatchItemsResponse'
    totalcount?: number | null
    start?: number | null
    items?: Array<{
      __typename?: 'WarehouseBatchItem'
      comment?: string | null
      datemodified?: string | null
      decimalquantity?: string | null
      emailowner?: string | null
      moneypricein?: string | null
      sku: string
      warehousebatchitemid: number
      warehouseitemid?: number | null
      product?:
        | ({ __typename?: 'Product' } & WarehouseBatchItemProductFieldsFragment)
        | null
      user?: {
        __typename?: 'User'
        namefirst?: string | null
        namelast?: string | null
      } | null
    }> | null
  }
}

export type AllOpenWarehouseBatchesQueryVariables = Exact<{
  transactiontype?: InputMaybe<WarehouseBatchTransaction>
}>

export type AllOpenWarehouseBatchesQuery = {
  __typename?: 'Query'
  allOpenWarehouseBatches: Array<{
    __typename?: 'WarehouseBatch'
    externalid?: string | null
    emailowner: string
    datecreated: string
    comment: string
    reasoncode?: string | null
    shipmentid?: number | null
    transactiontype: WarehouseBatchTransaction
    warehousebatchid: number
    warehousebatchstate: WarehouseBatchState
    warehouseid: number
    warehousetransactionid?: number | null
    warehouse?: {
      __typename?: 'Warehouse'
      name: string
      id: number
      store?: { __typename?: 'Store'; id: number; name: string } | null
    } | null
    user?: {
      __typename?: 'User'
      namefirst?: string | null
      namelast?: string | null
    } | null
  }>
}

export type GetWarehouseBatchQueryVariables = Exact<{
  warehouseId: Scalars['Int']['input']
  warehouseBatchId: Scalars['Int']['input']
}>

export type GetWarehouseBatchQuery = {
  __typename?: 'Query'
  warehouseBatch?: {
    __typename?: 'WarehouseBatch'
    warehousebatchid: number
    warehouseid: number
    transactiontype: WarehouseBatchTransaction
    warehousebatchstate: WarehouseBatchState
    warehouse?: {
      __typename?: 'Warehouse'
      name: string
      id: number
      store?: { __typename?: 'Store'; id: number; name: string } | null
    } | null
  } | null
}

export type GetWarehouseBatchSummaryQueryVariables = Exact<{
  warehouseId: Scalars['Int']['input']
  warehouseBatchId: Scalars['Int']['input']
}>

export type GetWarehouseBatchSummaryQuery = {
  __typename?: 'Query'
  warehouseBatch?: {
    __typename?: 'WarehouseBatch'
    warehousebatchid: number
    warehouseid: number
    transactiontype: WarehouseBatchTransaction
    warehousebatchstate: WarehouseBatchState
    items: {
      __typename?: 'PaginatedWarehouseBatchItemsResponse'
      totalcount?: number | null
      items?: Array<{
        __typename?: 'WarehouseBatchItem'
        warehousebatchitemid: number
      }> | null
    }
  } | null
}

export type UpdateWarehouseBatchMutationVariables = Exact<{
  data: UpdateWarehouseBatchInput
  warehouseId: Scalars['Int']['input']
  warehouseBatchId: Scalars['Int']['input']
}>

export type UpdateWarehouseBatchMutation = {
  __typename?: 'Mutation'
  updateWarehouseBatch: {
    __typename?: 'WarehouseBatch'
    warehousebatchid: number
    warehousebatchstate: WarehouseBatchState
    emailowner: string
    comment: string
    shipmentid?: number | null
    externalid?: string | null
    reasoncode?: string | null
  }
}

export type AddWarehouseBatchMutationVariables = Exact<{
  addWarehouseBatchData: AddWarehouseBatchInput
  warehouseId: Scalars['Int']['input']
}>

export type AddWarehouseBatchMutation = {
  __typename?: 'Mutation'
  addWarehouseBatch: {
    __typename?: 'WarehouseBatch'
    warehouseid: number
    warehousebatchid: number
    warehousebatchstate: WarehouseBatchState
  }
}

export type UpdateWarehouseBatchItemsMutationVariables = Exact<{
  warehouseId: Scalars['Int']['input']
  warehouseBatchId: Scalars['Int']['input']
  warehouseBatchItems: Array<WarehouseBatchItemInput> | WarehouseBatchItemInput
}>

export type UpdateWarehouseBatchItemsMutation = {
  __typename?: 'Mutation'
  updateWarehouseBatchItems: Array<{
    __typename?: 'WarehouseBatchItem'
    sku: string
    warehousebatchid?: number | null
    warehousebatchitemid: number
    decimalquantity?: string | null
    moneypricein?: string | null
    emailowner?: string | null
    datemodified?: string | null
    comment?: string | null
    user?: {
      __typename?: 'User'
      namefirst?: string | null
      namelast?: string | null
    } | null
  }>
}

export type DeleteWarehouseBatchItemsMutationVariables = Exact<{
  warehouseId: Scalars['Int']['input']
  warehouseBatchId: Scalars['Int']['input']
  skus: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type DeleteWarehouseBatchItemsMutation = {
  __typename?: 'Mutation'
  deleteWarehouseBatchItems: boolean
}

export type GetWarehousesQueryVariables = Exact<{ [key: string]: never }>

export type GetWarehousesQuery = {
  __typename?: 'Query'
  allWarehouses: Array<{
    __typename?: 'Warehouse'
    id: number
    warehousetype?: WarehouseType | null
    storeid?: number | null
    name: string
    address?: string | null
    address2?: string | null
    sellable?: boolean | null
    city?: string | null
    zip?: string | null
    state?: string | null
    countryid?: string | null
  }>
}

export type NumNegativeStockLevelsQueryVariables = Exact<{
  [key: string]: never
}>

export type NumNegativeStockLevelsQuery = {
  __typename?: 'Query'
  numNegativeStockLevels: Array<{
    __typename?: 'WarehouseStockTotal'
    total: number
    warehouseId: number
  }>
}

export const ReportDefinitionFilterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportDefinitionFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportDefinitionFilter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderSearchText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderPaymentState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIsNegative' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderHasRegisterId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRegisterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderWarehouseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderVoucherName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderVoucherCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productManufacturerId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'productVatId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productProductGroupType' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditNoteDateStart' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creditNoteDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseSearchText' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseDateStart' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseCurrencyCode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseTransactionType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'stockDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxIsRefund' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesTaxGroupRegion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentDateStart' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentDateEnd' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentStoreSales' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentStoreDelivery' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportDefinitionFilterFragment, unknown>
export const ReportDefinitionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportDefinition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportDefinition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'groups' } },
          { kind: 'Field', name: { kind: 'Name', value: 'measures' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportDefinitionFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sort' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'asc' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportDefinitionFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportDefinitionFilter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderSearchText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderPaymentState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIsNegative' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderHasRegisterId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRegisterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderWarehouseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderVoucherName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderVoucherCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productManufacturerId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'productVatId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productProductGroupType' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditNoteDateStart' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creditNoteDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseSearchText' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseDateStart' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseCurrencyCode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseTransactionType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'stockDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxIsRefund' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesTaxGroupRegion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentDateStart' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentDateEnd' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentStoreSales' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentStoreDelivery' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportDefinitionFragment, unknown>
export const ReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reportid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reporttype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datemode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forcewarehousefilter' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'franchiseblocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportdefinition' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportDefinition' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportDefinitionFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportDefinitionFilter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderSearchText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderPaymentState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIsNegative' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderHasRegisterId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRegisterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderWarehouseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderVoucherName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderVoucherCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productManufacturerId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'productVatId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productProductGroupType' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditNoteDateStart' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creditNoteDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseSearchText' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseDateStart' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseCurrencyCode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseTransactionType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'stockDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxIsRefund' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesTaxGroupRegion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentDateStart' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentDateEnd' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentStoreSales' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentStoreDelivery' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportDefinition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportDefinition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'groups' } },
          { kind: 'Field', name: { kind: 'Name', value: 'measures' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportDefinitionFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sort' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'asc' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportFragment, unknown>
export const ReportSummaryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportSummary' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reportid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reporttype' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportdefinition' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'measures' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportSummaryFragment, unknown>
export const ReportDefinitionSortFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportDefinitionSort' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportDefinitionSort' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asc' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportDefinitionSortFragment, unknown>
export const CustomerFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomerFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ServiceOrder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mobile' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomerFragmentFragment, unknown>
export const ProductWithImagesFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductWithImagesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ServiceOrder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'serial' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalBeforeShare' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalAfterShare' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'services' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'unit_price' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost_share' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'images' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductWithImagesFragmentFragment, unknown>
export const UserFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ServiceOrderUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employee_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFragmentFragment, unknown>
export const PosPaymentSettingsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PosPaymentSettingsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Settings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments_refund_manual' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments_refund_validated' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments_refund_validated_omni' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments_sales_gift_card' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments_sales_non_delivered' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PosPaymentSettingsFragmentFragment, unknown>
export const PosGeneralSettingsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PosGeneralSettingsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Settings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bank_bag_id_mode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receipt_staff_display' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'compress_variant_info' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scanner_ean13_remove_leading_zero' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'limit_open_cash_drawer' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pos_inventory_features' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warn_close_with_open_orders' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PosGeneralSettingsFragmentFragment, unknown>
export const PosCartSettingsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PosCartSettingsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Settings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cart_item_price_display_mode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cart_print_pick_list_mode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cash_counting_mode' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PosCartSettingsFragmentFragment, unknown>
export const PosRequiredFieldsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PosRequiredFieldsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Settings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'required_fields_booking' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'required_fields_pickup' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'required_fields_delivery' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PosRequiredFieldsFragmentFragment, unknown>
export const SalesTaxFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesTaxFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalesTax' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'decimalvaluedefault' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SalesTaxFieldsFragment, unknown>
export const SalesTaxProductGroupRulesFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesTaxProductGroupRulesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalesTaxProductGroupRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vatid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneymin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneymax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'decimalvalue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SalesTaxProductGroupRulesFieldsFragment, unknown>
export const SettingsStoreFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SettingsStore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Store' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eshopid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalgroupid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nearby_stores' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usetype_movein' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usetype_moveout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usetype_manualin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usetype_manualout' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usetype_shipmentin' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usetype_shipmentout' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additional_data' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SettingsStoreFragment, unknown>
export const SettingsWarehouseFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SettingsWarehouse' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Warehouse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencycode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehousetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SettingsWarehouseFragment, unknown>
export const DashboardConfigFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'separator_comma' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'separator_thousands' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'format_date_time_full' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'format_date_time' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format_time' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency_prepend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency_append' } },
          { kind: 'Field', name: { kind: 'Name', value: 'num_decimals' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardConfigFragment, unknown>
export const UserFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
          { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'department' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customernumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datecreated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datemodified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherpasswords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'posrefundcard' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'staff_pos_activation' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'posallstores' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasmfa' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allWarehouses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFieldsFragment, unknown>
export const UserPermissionsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserPermissionsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserPermissions' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'changeEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'changePin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'changePassword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'changeActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'changeRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'changeAccess' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addMfa' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewMfa' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeMfa' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deleteUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exportUser' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserPermissionsFieldsFragment, unknown>
export const CampaignFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activepos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchername' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datestart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchertype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherstate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricelisttags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherpassword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'money_m' } },
          { kind: 'Field', name: { kind: 'Name', value: 'money_n' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productoptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'num' } },
                { kind: 'Field', name: { kind: 'Name', value: 'products' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignFieldsFragment, unknown>
export const CampaignDetailFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignDetailFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CampaignFields' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercomment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherterms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datecreated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchername1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercode1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchername2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercode2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxuses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricelisttags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchergrouptype' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'use_discounted_price' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'products' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productattributes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'include' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exclude' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productoptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'num' } },
                { kind: 'Field', name: { kind: 'Name', value: 'products' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sitooProducts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productImages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fileUrl' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discountoptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'num' } },
                { kind: 'Field', name: { kind: 'Name', value: 'products' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyprice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneydiscount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimaldiscount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneydiscounttotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productattributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'include' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'exclude' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activepos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchername' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datestart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchertype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherstate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricelisttags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherpassword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'money_m' } },
          { kind: 'Field', name: { kind: 'Name', value: 'money_n' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productoptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'num' } },
                { kind: 'Field', name: { kind: 'Name', value: 'products' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignDetailFieldsFragment, unknown>
export const CashRegisterFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CashRegisterFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CashRegister' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'registerid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manufacturerid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registerkey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datelastping' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clientdescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loglevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salestaxgroupid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiptlanguagetag' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencycode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionaldata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registernumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CashRegisterFieldsFragment, unknown>
export const CategoryFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Category' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'categoryparentid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortorder' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CategoryFieldsFragment, unknown>
export const CustomAttributeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomAttribute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomAttribute' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enums' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomAttributeFragment, unknown>
export const InfluencerCodeFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InfluencerCodeFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InfluencerCode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datestart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherpassword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchername' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercomment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxuses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numused' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datelastused' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneyordertotal' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneyinfluencertotal' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneyinfluencerdiscount' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InfluencerCodeFieldsFragment, unknown>
export const InfluencerCodeDetailFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InfluencerCodeDetailFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InfluencerCode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'InfluencerCodeFields' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaigns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vouchername' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InfluencerCodeFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InfluencerCode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datestart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherpassword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchername' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercomment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxuses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numused' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datelastused' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneyordertotal' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneyinfluencertotal' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneyinfluencerdiscount' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InfluencerCodeDetailFieldsFragment, unknown>
export const LogEventFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LogEvent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LogEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eshop_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LogEventFragment, unknown>
export const ManufacturerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Manufacturer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Manufacturer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url2' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ManufacturerFragment, unknown>
export const PublicLoginOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicLoginOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PublicLoginOption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oidc_auth_request_url' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PublicLoginOptionFragment, unknown>
export const LoginOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoginOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoginOption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oidc_auth_request_url' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oidc_private' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'client_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client_secret' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'auth_url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token_url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenant_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginOptionFragment, unknown>
export const OrderFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentstateid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creditorderid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderstateid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneytotal_gross_all' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneytotal_gross_roundoff' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencycode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datereserved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cashRegister' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'registerkey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registerid' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionaldata' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cardissuer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyamount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reftype' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reservedpaymentid' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
          { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionaldata' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrderFieldsFragment, unknown>
export const OrderItemsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderItems' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderitems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'orderitemid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productname' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productattributes' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salestaxes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupregion' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moneytotal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moneytax' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decimaltaxvalue' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'vouchername' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vouchercode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vatvalue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneyoriginalprice' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneyitemtotal_net' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneyitemtotal_vat' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionaldata' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrderItemsFragment, unknown>
export const OrderDetailsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderDetailsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'OrderFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'OrderItems' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ordertypeid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commentinternal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerref' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderDeliveryState' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderDelivery' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pluginname' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'datecancelled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'datedelivery' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderdeliveryid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderdeliveryref' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'binlocation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ignorestock' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderdeliveryitems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderdeliveryitemid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderitemid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalOrder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'orderid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneytotal_gross_all' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneytotal_gross_roundoff' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderItems' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refundOrder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'orderid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneytotal_gross_all' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneytotal_gross_roundoff' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderItems' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exchangeOrder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'orderid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneytotal_gross_all' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneytotal_gross_roundoff' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderItems' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'customerref' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
          { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delivery_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delivery_address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delivery_city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delivery_state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delivery_zip' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delivery_countryid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_countryid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneytotal_gross_all' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneytotal_gross_roundoff' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'moneyfinal_vat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneyfinal_net' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencycode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionaldata' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'staff' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
                { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'staffHelpers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
                { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reservedpayments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneyreserved' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cardissuer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reftype' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionaldata' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionaldata' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cardissuer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyamount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reftype' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reservedpaymentid' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cashRegister' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'registerkey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registerid' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentstateid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creditorderid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderstateid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneytotal_gross_all' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneytotal_gross_roundoff' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencycode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datereserved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cashRegister' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'registerkey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registerid' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionaldata' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cardissuer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyamount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reftype' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reservedpaymentid' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
          { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionaldata' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderItems' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderitems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'orderitemid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productname' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productattributes' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salestaxes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupregion' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moneytotal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moneytax' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decimaltaxvalue' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'vouchername' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vouchercode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vatvalue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneyoriginalprice' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneyitemtotal_net' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneyitemtotal_vat' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionaldata' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrderDetailsFieldsFragment, unknown>
export const OrderHistoryFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderHistoryFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registerid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderDelivery' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pluginname' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'datecancelled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'datedelivery' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderdeliveryid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderdeliveryref' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'binlocation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ignorestock' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderdeliveryitems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderdeliveryitemid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderitemid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderlogitems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'datelog' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderlogtypeid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'strvalue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fvalue' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchaseJournal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateadded' } },
                { kind: 'Field', name: { kind: 'Name', value: 'datecreated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registerid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registerkey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ispractice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'JournalCtuError' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'errortext' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'JournalStaff' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staff' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffuserid' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'JournalCart' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staff' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffuserid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'quantitytotal' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'moneytotal' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'moneydiscount' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'JournalCartItem' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staff' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffuserid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sku' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'quantity' },
                            },
                            {
                              kind: 'Field',
                              alias: {
                                kind: 'Name',
                                value: 'JournalCartItemMoneyTotal',
                              },
                              name: { kind: 'Name', value: 'moneytotal' },
                            },
                            {
                              kind: 'Field',
                              alias: {
                                kind: 'Name',
                                value: 'JournalCartItemMoneyDiscount',
                              },
                              name: { kind: 'Name', value: 'moneydiscount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'decimalunitquantity',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'note' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cart_id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'cart_quantitytotal',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cart_moneytotal' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'cart_moneydiscount',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'JournalCartPayment' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staff' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffuserid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'paymentname' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'paymentmethod' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'moneytotal' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'moneycaptured' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cart_id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'JournalCash' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staff' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffuserid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currencycode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'moneycash' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'comment' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'JournalPrintout' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staff' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffuserid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'receiptid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'orderid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zreportid' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'JournalReport' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staff' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffuserid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zreportid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'manufacturerid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eshopid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'store_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'store_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'company' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyid' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'JournalReceipt' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staff' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffuserid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'receiptnumber' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrderHistoryFieldsFragment, unknown>
export const OrderDeliveryFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderDeliveryFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OrderDelivery' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderdeliveryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderdeliveryref' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datedelivery' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datecancelled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pluginname' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderdeliveryitems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderdeliveryitemid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderdeliveryid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'orderitemid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrderDeliveryFieldsFragment, unknown>
export const ProductGroupFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductGroupFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductGroupFieldsFragment, unknown>
export const ProductFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activepos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneyprice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datecreated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datemodified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultcategoryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productgrouptype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowdecimals' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitlabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manufacturer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricelistitems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fallbackPriceToCommon' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fallbackPriceToCommon' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pricelistid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricelistid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyprice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricelist' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencycode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricelistname' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productgroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allcategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categoryparentid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductFieldsFragment, unknown>
export const ProductDetailsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductDetailsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProductFields' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'moneypriceorg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'computedPurchasePrice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'currency' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'price_list_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'price_list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'variantparentid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionshort' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customattributes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skumanufacturer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manufacturerurl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockcountenable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'related' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accessories' } },
          { kind: 'Field', name: { kind: 'Name', value: 'similar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMainVariant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSingleProduct' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'childVariants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyprice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneypriceorg' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isMainVariant' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSingleProduct' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customattributes' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'computedPurchasePrice' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'currency' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'currency' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price_list_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price_list' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currency' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variantGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'options' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimaltotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sellable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'store' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedProducts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accessoriesProducts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'similarProducts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activepos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneyprice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datecreated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datemodified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultcategoryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productgrouptype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowdecimals' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitlabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manufacturer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricelistitems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fallbackPriceToCommon' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fallbackPriceToCommon' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pricelistid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricelistid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyprice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricelist' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencycode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricelistname' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productgroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allcategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categoryparentid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductDetailsFieldsFragment, unknown>
export const RelatedProductFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RelatedProductFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedProducts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accessoriesProducts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'similarProducts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RelatedProductFieldsFragment, unknown>
export const ProductVariantFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductVariantFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneypriceorg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricelisthasvolume' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activepos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barcodealiases' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionshort' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneyofferprice' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductVariantFieldsFragment, unknown>
export const PurchasePriceListFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PurchasePriceList' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PurchasePriceList' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner_eshop_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared_store_ids' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sharedStores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownerSite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'eshopid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eshopname' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PurchasePriceListFragment, unknown>
export const ShipmentActionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentActionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'package_id_list' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state_reason_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'received_items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'reason_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'item_id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'serial_numbers' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse_transaction_id' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'received_items_undeclared' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'reason_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'barcode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'unit_price' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'batch' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'batch_expires' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'barcode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'serial_numbers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'serial_number' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'barcode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'additional_data' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'received' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additional_data' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity_received' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse_transaction_id' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouse_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'package' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShipmentActionFieldsFragment, unknown>
export const ShipmentDetailFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentDetailFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Shipment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'info' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_data' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'destination_warehouse_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'destination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address2' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zip' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country_id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mobile' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'note' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'carrier' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'service' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reference' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tracking_url' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tracking_url_title' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_pickup_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_delivery_at' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'origin_warehouse_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'origin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address2' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zip' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mobile' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'note' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reference_number' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'packages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'weight' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weight' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'total_quantity_received' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'total_quantity' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'arrived_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_data' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'unit_price' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'batch' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'batch_expires' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'barcode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'serial_numbers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'serial_number' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'barcode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'additional_data' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'received' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additional_data' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity_received' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sku' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'barcode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'images' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dimensions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'height' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'length' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'all_packages_arrived' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total_package_count' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total_item_quantity' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'closed_state_reason_code' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'all_items_received' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items_undeclared' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_data' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'batch' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'batch_expires' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quantity_received' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit_price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'barcode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'serial_numbers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additional_data' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'barcode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'received' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'serial_number' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShipmentDetailFieldsFragment, unknown>
export const ShipmentFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Shipment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'info' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_data' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'destination_warehouse_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'destination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address2' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zip' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country_id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mobile' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'note' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'carrier' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'service' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reference' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tracking_url' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tracking_url_title' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_pickup_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_delivery_at' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'origin_warehouse_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'origin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address2' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zip' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mobile' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'note' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reference_number' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'all_packages_arrived' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total_package_count' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total_item_quantity' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'closed_state_reason_code' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'all_items_received' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShipmentFieldsFragment, unknown>
export const ShipmentsV2FieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentsV2Fields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_reference' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carrier_trackingurl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'datenew' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateintransit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipmentid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipmentstate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateestimatedpickup' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateestimateddelivery' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShipmentsV2FieldsFragment, unknown>
export const ShipmentV2DetailFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2DetailFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender_warehouseid' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_warehouseid' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShipmentV2DetailFieldsFragment, unknown>
export const ShipmentV2SenderContactFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2SenderContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender_referenceemail' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender_referencename' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender_referencemobile' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender_instructions' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShipmentV2SenderContactFragment, unknown>
export const ShipmentV2ReceiverContactFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2ReceiverContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_referenceemail' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_referencename' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_referencemobile' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_instructions' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShipmentV2ReceiverContactFragment, unknown>
export const ShipmentV2SenderAddressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2SenderAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sender_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_zip' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShipmentV2SenderAddressFragment, unknown>
export const ShipmentV2ReceiverAddressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2ReceiverAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_countryid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_zip' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShipmentV2ReceiverAddressFragment, unknown>
export const ShipmentV2PackagesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2Packages' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'packages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipmentpackageid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalQuantity' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalReceivedQuantity' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decimalunitquantity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moneypricein' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productname' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantityreceived' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shipmentitemid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shipmentpackageid' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'unitlabel' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sku' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'images' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'allowdecimals' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'unitlabel' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShipmentV2PackagesFragment, unknown>
export const StoreReplenishmentItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreReplenishmentItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StoreReplenishmentItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customattributes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'decimalTotalTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'decimalTotalFrom' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'decimalThresholdFrom' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'decimalThresholdTo' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'binLocationTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'binLocationFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'toPick' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreReplenishmentItemFragment, unknown>
export const SiteFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SiteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Site' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'eshopid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eshopname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eshoptype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commoneshopid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencycode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricesstoredwithvat' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allow_store_manager_campaigns' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'customattributes_in_store_replenishment',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SiteFieldsFragment, unknown>
export const WarehouseBatchItemProductFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarehouseBatchItemProductFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activepos' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'computedPurchasePrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimaltotal' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'binlocation' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WarehouseBatchItemProductFieldsFragment, unknown>
export const SendFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'message' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'files' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Upload' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendFeedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'message' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'message' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'path' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'path' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'files' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'files' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendFeedbackMutation,
  SendFeedbackMutationVariables
>
export const ExportInfluencerCodesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ExportInfluencerCodes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fileType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FileType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timeZone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportInfluencerCodes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fileType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timeZone' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'timeZone' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExportInfluencerCodesMutation,
  ExportInfluencerCodesMutationVariables
>
export const ReportExtendedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReportExtended' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reportId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reportId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Report' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allWarehouses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allCashRegisters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registerid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'registernumber' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'registerkey' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportDefinitionFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportDefinitionFilter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderSearchText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderPaymentState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIsNegative' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderHasRegisterId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRegisterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderWarehouseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderVoucherName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderVoucherCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productManufacturerId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'productVatId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productProductGroupType' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditNoteDateStart' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creditNoteDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseSearchText' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseDateStart' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseCurrencyCode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseTransactionType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'stockDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxIsRefund' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesTaxGroupRegion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentDateStart' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentDateEnd' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentStoreSales' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentStoreDelivery' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportDefinition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportDefinition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'groups' } },
          { kind: 'Field', name: { kind: 'Name', value: 'measures' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportDefinitionFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sort' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'asc' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reportid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reporttype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datemode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forcewarehousefilter' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'franchiseblocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportdefinition' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportDefinition' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportExtendedQuery, ReportExtendedQueryVariables>
export const ReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Report' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reportId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reportId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Report' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportDefinitionFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportDefinitionFilter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderSearchText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderPaymentState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIsNegative' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderHasRegisterId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRegisterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderWarehouseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderVoucherName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderVoucherCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productManufacturerId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'productVatId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productProductGroupType' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditNoteDateStart' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creditNoteDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseSearchText' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseDateStart' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseCurrencyCode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseTransactionType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'stockDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxIsRefund' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesTaxGroupRegion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentDateStart' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentDateEnd' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentStoreSales' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentStoreDelivery' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportDefinition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportDefinition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'groups' } },
          { kind: 'Field', name: { kind: 'Name', value: 'measures' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportDefinitionFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sort' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'asc' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reportid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reporttype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datemode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forcewarehousefilter' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'franchiseblocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportdefinition' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportDefinition' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportQuery, ReportQueryVariables>
export const ReportJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReportJobs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportJobs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reportId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reportId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'jobid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobstate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobname' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'datecreated' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateexecuted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eshopid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'langid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reportdefinition' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'ReportDefinitionFilter',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportDefinitionFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportDefinitionFilter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderSearchText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderPaymentState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIsNegative' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderHasRegisterId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRegisterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderWarehouseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderVoucherName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderVoucherCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productManufacturerId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'productVatId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productProductGroupType' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditNoteDateStart' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creditNoteDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseSearchText' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseDateStart' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseCurrencyCode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseTransactionType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'stockDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxIsRefund' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesTaxGroupRegion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentDateStart' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentDateEnd' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentStoreSales' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentStoreDelivery' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportJobsQuery, ReportJobsQueryVariables>
export const ReportResultsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReportResults' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportResults' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'columns' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rows' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totals' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportResultsQuery, ReportResultsQueryVariables>
export const AddReportJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddReportJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportJob' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReportJobInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addReportJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reportJob' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reportJob' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eshopid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'commoneshopid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'jobstate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'langid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'datecreated' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dateexecuted' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reportid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reporttype' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportdefinition' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReportDefinition' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportDefinitionFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportDefinitionFilter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderSearchText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderPaymentState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIsNegative' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderHasRegisterId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRegisterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderWarehouseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderVoucherName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderVoucherCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productManufacturerId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'productVatId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productProductGroupType' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditNoteDateStart' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creditNoteDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseSearchText' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseDateStart' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseCurrencyCode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseTransactionType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'stockDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxIsRefund' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesTaxGroupRegion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentDateStart' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentDateEnd' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentStoreSales' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentStoreDelivery' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportDefinition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportDefinition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'groups' } },
          { kind: 'Field', name: { kind: 'Name', value: 'measures' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportDefinitionFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sort' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'asc' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddReportJobMutation,
  AddReportJobMutationVariables
>
export const AddReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'report' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReportInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'report' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'report' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Report' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportDefinitionFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportDefinitionFilter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderSearchText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderPaymentState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIsNegative' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderHasRegisterId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRegisterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderWarehouseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderVoucherName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderVoucherCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productManufacturerId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'productVatId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productProductGroupType' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditNoteDateStart' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creditNoteDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseSearchText' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseDateStart' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseCurrencyCode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseTransactionType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'stockDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxIsRefund' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesTaxGroupRegion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentDateStart' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentDateEnd' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentStoreSales' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentStoreDelivery' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportDefinition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportDefinition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'groups' } },
          { kind: 'Field', name: { kind: 'Name', value: 'measures' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportDefinitionFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sort' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'asc' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reportid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reporttype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datemode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forcewarehousefilter' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'franchiseblocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportdefinition' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportDefinition' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddReportMutation, AddReportMutationVariables>
export const UpdateReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'report' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReportInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'report' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'report' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Report' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportDefinitionFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportDefinitionFilter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderSearchText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderPaymentState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIsNegative' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderHasRegisterId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRegisterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderWarehouseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderVoucherName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderVoucherCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productManufacturerId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'productVatId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productProductGroupType' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditNoteDateStart' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creditNoteDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseSearchText' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseDateStart' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseCurrencyCode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseTransactionType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'stockDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxIsRefund' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesTaxGroupRegion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentDateStart' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentDateEnd' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentStoreSales' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentStoreDelivery' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportDefinition' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportDefinition' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'groups' } },
          { kind: 'Field', name: { kind: 'Name', value: 'measures' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportDefinitionFilter' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sort' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'asc' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reportid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reporttype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datemode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forcewarehousefilter' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'franchiseblocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportdefinition' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportDefinition' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateReportMutation,
  UpdateReportMutationVariables
>
export const DeleteReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reportId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reportId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteReportMutation,
  DeleteReportMutationVariables
>
export const ReportsQuickDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReportsQuick' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'favoriteReports' },
            name: { kind: 'Name', value: 'reports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reportid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reportIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReportSummary' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'systemReports' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReportSummary' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportSummary' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reportid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reporttype' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportdefinition' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'measures' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportsQuickQuery, ReportsQuickQueryVariables>
export const ReportsAllDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReportsAll' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchtext' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportid' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reporttype' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ReportType' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GetReportListSort' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchtext' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchtext' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reportid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reportid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reporttype' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reporttype' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReportSummary' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reportdefinition' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'measures' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'groups' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'ReportDefinitionFilter',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sort' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'ReportDefinitionSort',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportSummary' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Report' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'reportid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reportname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reporttype' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportdefinition' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'measures' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportDefinitionFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportDefinitionFilter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderSearchText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderPaymentState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderIsNegative' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderHasRegisterId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRegisterId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderWarehouseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderVoucherName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderVoucherCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productManufacturerId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'productVatId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productProductGroupType' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creditNoteDateStart' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'creditNoteDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftCardType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseSearchText' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseDateStart' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseCurrencyCode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseTransactionType' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'stockDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxDateStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxDateEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salesTaxIsRefund' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesTaxGroupRegion' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentDateStart' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentDateEnd' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentStoreSales' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'omniFulfillmentStoreDelivery' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportDefinitionSort' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReportDefinitionSort' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'asc' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportsAllQuery, ReportsAllQueryVariables>
export const SendResetPasswordLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendResetPasswordLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendResetPasswordLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendResetPasswordLinkMutation,
  SendResetPasswordLinkMutationVariables
>
export const UpdatePasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables
>
export const ServiceOrdersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ServiceOrders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GetServiceOrdersSort' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'next' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ServiceOrderState' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'store' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'serviceOrders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'next' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'next' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'state' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'store' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'store' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'next' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'store' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ProductWithImagesFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductWithImagesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ServiceOrder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'serial' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalBeforeShare' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalAfterShare' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'services' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'unit_price' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost_share' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'images' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ServiceOrdersQuery, ServiceOrdersQueryVariables>
export const ServiceOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ServiceOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'serviceOrderId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'serviceOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serviceOrderId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'serviceOrderId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'store' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomerFragment' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProductWithImagesFragment' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ServiceOrderUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employee_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomerFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ServiceOrder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mobile' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductWithImagesFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ServiceOrder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'serial' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalBeforeShare' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalAfterShare' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'services' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'unit_price' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cost_share' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'images' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ServiceOrderQuery, ServiceOrderQueryVariables>
export const UpdateServiceOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateServiceOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'serviceOrder' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateServiceOrderInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateServiceOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serviceOrder' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'serviceOrder' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'store' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomerFragment' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'serial' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'services' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sku' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'quantity' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'unit_price' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost_share' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'note' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ServiceOrderUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employee_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomerFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ServiceOrder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mobile' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateServiceOrderMutation,
  UpdateServiceOrderMutationVariables
>
export const AddServiceOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddServiceOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'serviceOrder' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddServiceOrderInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addServiceOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serviceOrder' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'serviceOrder' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'store' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomerFragment' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'serial' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalBeforeShare' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalAfterShare' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'services' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sku' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'quantity' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'unit_price' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cost_share' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'note' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ServiceOrderUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'employee_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomerFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ServiceOrder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mobile' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddServiceOrderMutation,
  AddServiceOrderMutationVariables
>
export const ProductGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProductGroups' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductGroupsQuery, ProductGroupsQueryVariables>
export const DashboardsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Dashboards' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardsQuery, DashboardsQueryVariables>
export const DashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Dashboard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboardConfiguration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardConfig' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'separator_comma' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'separator_thousands' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'format_date_time_full' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'format_date_time' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format_time' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency_prepend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency_append' } },
          { kind: 'Field', name: { kind: 'Name', value: 'num_decimals' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardQuery, DashboardQueryVariables>
export const ParseInfluencerCodesCsvDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ParseInfluencerCodesCsv' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'csv' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parseInfluencerCodesCsv' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'csv' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'csv' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voucherpassword' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'datestart' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateend' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vouchercode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vouchername' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vouchercomment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maxuses' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'property' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sizeLimit' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ParseInfluencerCodesCsvMutation,
  ParseInfluencerCodesCsvMutationVariables
>
export const ImportInfluencerCodesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ImportInfluencerCodes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ImportInfluencerCodeInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'importInfluencerCodes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'imported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ImportInfluencerCodesMutation,
  ImportInfluencerCodesMutationVariables
>
export const ParsePricesCsvDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ParsePricesCsv' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'csv' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parsePricesCsv' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'csv' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'csv' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moneyprice' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricelistid' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'property' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ParsePricesCsvMutation,
  ParsePricesCsvMutationVariables
>
export const ParsePurchasePricesCsvDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ParsePurchasePricesCsv' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'csv' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parsePurchasePricesCsv' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'csv' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'csv' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price_list_id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'purchase_price' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'property' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ParsePurchasePricesCsvMutation,
  ParsePurchasePricesCsvMutationVariables
>
export const ImportPricesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ImportPrices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ImportPriceItemInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'importPrices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'imported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ImportPricesMutation,
  ImportPricesMutationVariables
>
export const ImportPurchasePricesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ImportPurchasePrices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ImportPurchasePriceItemInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'importPurchasePrices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'imported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ImportPurchasePricesMutation,
  ImportPurchasePricesMutationVariables
>
export const ParseStoresCsvDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ParseStoresCsv' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'csv' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parseStoresCsv' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'csv' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'csv' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eshopid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storetype' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalgroupid' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address2' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countryid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nearby_stores' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'usetype_movein' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'usetype_moveout' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'usetype_manualin' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'usetype_manualout' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'usetype_shipmentin' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'usetype_shipmentout' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'property' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ParseStoresCsvMutation,
  ParseStoresCsvMutationVariables
>
export const ImportStoresDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ImportStores' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ImportStoreItemInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'importStores' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'imported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ImportStoresMutation,
  ImportStoresMutationVariables
>
export const ParseUsersCsvDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ParseUsersCsv' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'csv' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parseUsersCsv' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'csv' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'csv' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'namefirst' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'namelast' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'personalid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'department' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address2' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countryid' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobile' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pin' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customernumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'voucherpasswords' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'warehouses' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'posrefundcard' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'posallstores' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'property' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ParseUsersCsvMutation,
  ParseUsersCsvMutationVariables
>
export const ImportUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ImportUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ImportUserInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'importUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'imported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImportUsersMutation, ImportUsersMutationVariables>
export const ParseWarehousesCsvDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ParseWarehousesCsv' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'csv' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parseWarehousesCsv' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'csv' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'csv' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'warehouseid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'warehousetype' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'usetype' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storeid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sellable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencycode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address2' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countryid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalid' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'property' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ParseWarehousesCsvMutation,
  ParseWarehousesCsvMutationVariables
>
export const ImportWarehousesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ImportWarehouses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ImportWarehouseInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'importWarehouses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'imported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ImportWarehousesMutation,
  ImportWarehousesMutationVariables
>
export const AllPaymentTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllPaymentTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allPaymentTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymenttypeid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentmethodid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortorder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllPaymentTypesQuery,
  AllPaymentTypesQueryVariables
>
export const AvailablePaymentMethodsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AvailablePaymentMethods' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availablePaymentMethods' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AvailablePaymentMethodsQuery,
  AvailablePaymentMethodsQueryVariables
>
export const GetPaymentTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPaymentType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPaymentType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymenttypeid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentmethodid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortorder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iconUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPaymentTypeQuery, GetPaymentTypeQueryVariables>
export const AddPaymentTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddPaymentType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddPaymentTypeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPaymentType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paymentType' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentmethodid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymenttypeid' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddPaymentTypeMutation,
  AddPaymentTypeMutationVariables
>
export const UpdatePaymentTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePaymentType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePaymentTypeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePaymentType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paymentType' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymenttypeid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentmethodid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortorder' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePaymentTypeMutation,
  UpdatePaymentTypeMutationVariables
>
export const DeletePaymentTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePaymentType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deletePaymentTypeId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePaymentType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'deletePaymentTypeId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePaymentTypeMutation,
  DeletePaymentTypeMutationVariables
>
export const PosPaymentSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PosPaymentSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PosPaymentSettingsFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PosPaymentSettingsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Settings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments_refund_manual' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments_refund_validated' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments_refund_validated_omni' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments_sales_gift_card' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments_sales_non_delivered' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PosPaymentSettingsQuery,
  PosPaymentSettingsQueryVariables
>
export const UpdatePaymentSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePaymentSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'settings' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateSettingsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'settings' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'settings' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PosPaymentSettingsFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PosPaymentSettingsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Settings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments_refund_manual' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments_refund_validated' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments_refund_validated_omni' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments_sales_gift_card' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments_sales_non_delivered' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePaymentSettingsMutation,
  UpdatePaymentSettingsMutationVariables
>
export const GetPluginsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPlugins' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pluginId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PluginId' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plugin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pluginId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PluginAdyenApi' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'keyidentifier' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'keypassphrase' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'keyversion' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'istestmode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PluginAdyenTapToPay' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'api_key' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'merchant_account' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'is_test_mode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PluginCtuNorway' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxRegistrationCountry' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateFiscalFirst' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateFiscalSecond' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxRegistrationNumber' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PluginCtuPortugal' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'taxRegistrationNumberPortugal',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyAddress' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyZip' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyCity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxEntity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxCountryRegion' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateFiscalFirst' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateFiscalSecond' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PluginCustomGiftCard' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'api_key' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'api_url' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PluginCustomPayment' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'api_key' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'api_url' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bg_payment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bg_payment_small' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'icon_payment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'icon_payment_small' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'required_customer_info' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PluginCustomProductSearch' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'api_url' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'api_key' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PluginGivex' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userPassword' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hostPrimary' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hostSecondary' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'extendedLogging' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PluginKlarnaV3' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'username' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'password' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'klarna_region' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profileid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profileid_selfcheckout' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PluginNetsBaxiLogs' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'region' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bucket' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'access_key_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'access_key_secret' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'PluginNetsCurrencyConversion',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payment_types' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bax_number' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currency_code' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'payment_type_id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PluginNosto' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graphQLToken' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graphQLRecordRequests' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PluginProductSearch' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'external_search_active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'external_scan_to_cart' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'external_search_title' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PluginSwish' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payee_alias' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message_payment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message_refund' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moneylimitmin' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'selfcheckoutenabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'certificate_public' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'certificate_priavte' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PluginSwitchboard' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handler_giftcards' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'handler_recommendations',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PluginTaxFree' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PluginZebra' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'templates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPluginsQuery, GetPluginsQueryVariables>
export const UpdateAdyenApiPluginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAdyenApiPlugin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PluginAdyenApiInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAdyenApi' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'keyidentifier' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'keypassphrase' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'keyversion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'istestmode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAdyenApiPluginMutation,
  UpdateAdyenApiPluginMutationVariables
>
export const UpdateAdyenTapToPayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAdyenTapToPay' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PluginAdyenTapToPayInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAdyenTapToPay' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'merchant_account' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'api_key' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_test_mode' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAdyenTapToPayMutation,
  UpdateAdyenTapToPayMutationVariables
>
export const UpdateCtuNorwayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCtuNorway' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PluginCtuNorwayInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCtuNorway' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currencyCode' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taxRegistrationNumber' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'taxRegistrationCountry' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dateFiscalFirst' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dateFiscalSecond' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCtuNorwayMutation,
  UpdateCtuNorwayMutationVariables
>
export const PosGeneralSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PosGeneralSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PosGeneralSettingsFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PosGeneralSettingsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Settings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bank_bag_id_mode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receipt_staff_display' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'compress_variant_info' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scanner_ean13_remove_leading_zero' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'limit_open_cash_drawer' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pos_inventory_features' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warn_close_with_open_orders' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PosGeneralSettingsQuery,
  PosGeneralSettingsQueryVariables
>
export const PosCartSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PosCartSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PosCartSettingsFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PosCartSettingsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Settings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cart_item_price_display_mode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cart_print_pick_list_mode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cash_counting_mode' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PosCartSettingsQuery,
  PosCartSettingsQueryVariables
>
export const PosRequiredFieldsSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PosRequiredFieldsSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'settings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PosRequiredFieldsFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PosRequiredFieldsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Settings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'required_fields_booking' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'required_fields_pickup' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'required_fields_delivery' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PosRequiredFieldsSettingsQuery,
  PosRequiredFieldsSettingsQueryVariables
>
export const UpdateGeneralSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateGeneralSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'settings' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateSettingsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'settings' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'settings' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PosGeneralSettingsFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PosGeneralSettingsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Settings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bank_bag_id_mode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receipt_staff_display' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'compress_variant_info' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scanner_ean13_remove_leading_zero' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'limit_open_cash_drawer' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pos_inventory_features' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warn_close_with_open_orders' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateGeneralSettingsMutation,
  UpdateGeneralSettingsMutationVariables
>
export const UpdatePosCartSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePosCartSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'settings' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateSettingsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'settings' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'settings' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PosCartSettingsFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PosCartSettingsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Settings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cart_item_price_display_mode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cart_print_pick_list_mode' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cash_counting_mode' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePosCartSettingsMutation,
  UpdatePosCartSettingsMutationVariables
>
export const UpdateRequiredFieldsSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRequiredFieldsSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'settings' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateSettingsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'settings' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'settings' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PosRequiredFieldsFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PosRequiredFieldsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Settings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'required_fields_booking' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'required_fields_pickup' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'required_fields_delivery' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateRequiredFieldsSettingsMutation,
  UpdateRequiredFieldsSettingsMutationVariables
>
export const DeleteReasonCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteReasonCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteReasonCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteReasonCodeMutation,
  DeleteReasonCodeMutationVariables
>
export const UpdateReasonCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateReasonCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reasonCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReasonCodeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateReasonCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reasonCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reasonCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reasoncodeid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reasoncode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateReasonCodeMutation,
  UpdateReasonCodeMutationVariables
>
export const AddReasonCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddReasonCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reasonCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReasonCodeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addReasonCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reasonCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reasonCode' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddReasonCodeMutation,
  AddReasonCodeMutationVariables
>
export const ReasonCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReasonCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reasonCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reasoncodeid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reasoncode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReasonCodeQuery, ReasonCodeQueryVariables>
export const SalesTaxesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SalesTaxes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesTaxes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SalesTaxFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productgrouprules' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'SalesTaxProductGroupRulesFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'groupName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesTaxFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalesTax' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'decimalvaluedefault' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesTaxProductGroupRulesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalesTaxProductGroupRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vatid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneymin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneymax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'decimalvalue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SalesTaxesQuery, SalesTaxesQueryVariables>
export const AllSalesTaxesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllSalesTaxes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allSalesTaxes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimalvaluedefault' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productgrouprules' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decimalvalue' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'vatid' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllSalesTaxesQuery, AllSalesTaxesQueryVariables>
export const SalesTaxDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SalesTax' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'salesTaxId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesTax' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'salesTaxId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalesTaxFields' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productgrouprules' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SalesTaxProductGroupRulesFields',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesTaxFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalesTax' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'decimalvaluedefault' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesTaxProductGroupRulesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalesTaxProductGroupRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vatid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneymin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneymax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'decimalvalue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SalesTaxQuery, SalesTaxQueryVariables>
export const UpdateSalesTaxDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSalesTax' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SalesTaxInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateSalesTaxId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSalesTax' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateSalesTaxId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalesTaxFields' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productgrouprules' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SalesTaxProductGroupRulesFields',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesTaxFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalesTax' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'decimalvaluedefault' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesTaxProductGroupRulesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalesTaxProductGroupRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vatid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneymin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneymax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'decimalvalue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSalesTaxMutation,
  UpdateSalesTaxMutationVariables
>
export const AddSalesTaxDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddSalesTax' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SalesTaxInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addSalesTax' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalesTaxFields' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productgrouprules' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'SalesTaxProductGroupRulesFields',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesTaxFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalesTax' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'decimalvaluedefault' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesTaxProductGroupRulesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalesTaxProductGroupRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vatid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneymin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneymax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'decimalvalue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddSalesTaxMutation, AddSalesTaxMutationVariables>
export const DeleteSalesTaxDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSalesTax' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deleteSalesTaxId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSalesTax' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'deleteSalesTaxId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteSalesTaxMutation,
  DeleteSalesTaxMutationVariables
>
export const SalesTaxGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SalesTaxGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesTaxGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'region' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salestaxes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SalesTaxFields' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'productgrouprules',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'SalesTaxProductGroupRulesFields',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'groupName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesTaxFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalesTax' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'decimalvaluedefault' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesTaxProductGroupRulesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalesTaxProductGroupRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vatid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneymin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneymax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'decimalvalue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SalesTaxGroupsQuery, SalesTaxGroupsQueryVariables>
export const SalesTaxGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SalesTaxGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'salesTaxGroupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'salesTaxGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'salesTaxGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'region' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salestaxes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SalesTaxFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productgrouprules' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'SalesTaxProductGroupRulesFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'groupName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesTaxFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalesTax' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'decimalvaluedefault' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesTaxProductGroupRulesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalesTaxProductGroupRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vatid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneymin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneymax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'decimalvalue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SalesTaxGroupQuery, SalesTaxGroupQueryVariables>
export const AddSalesTaxGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddSalesTaxGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SalesTaxGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addSalesTaxGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'region' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salestaxes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SalesTaxFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productgrouprules' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'SalesTaxProductGroupRulesFields',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesTaxFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalesTax' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'decimalvaluedefault' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesTaxProductGroupRulesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalesTaxProductGroupRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vatid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneymin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneymax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'decimalvalue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddSalesTaxGroupMutation,
  AddSalesTaxGroupMutationVariables
>
export const UpdateSalesTaxGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSalesTaxGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SalesTaxGroupInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateSalesTaxGroupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSalesTaxGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updateSalesTaxGroupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'region' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salestaxes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SalesTaxFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productgrouprules' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'SalesTaxProductGroupRulesFields',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesTaxFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalesTax' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'decimalvaluedefault' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesTaxProductGroupRulesFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SalesTaxProductGroupRule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vatid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneymin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneymax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'decimalvalue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSalesTaxGroupMutation,
  UpdateSalesTaxGroupMutationVariables
>
export const DeleteSalesTaxGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSalesTaxGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deleteSalesTaxGroupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSalesTaxGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'deleteSalesTaxGroupId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteSalesTaxGroupMutation,
  DeleteSalesTaxGroupMutationVariables
>
export const SettingsStoresDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SettingsStores' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GetStoresSort' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stores' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'externalgroupid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storetype' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nearby_stores' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SettingsStoresQuery, SettingsStoresQueryVariables>
export const SettingsStoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SettingsStore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'storeId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storeId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'storeId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SettingsStore' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SettingsStore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Store' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eshopid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalgroupid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nearby_stores' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usetype_movein' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usetype_moveout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usetype_manualin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usetype_manualout' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usetype_shipmentin' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usetype_shipmentout' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additional_data' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SettingsStoreQuery, SettingsStoreQueryVariables>
export const AddUpdateSettingsStoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddUpdateSettingsStore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'store' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StoreInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUpdateStore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'store' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'store' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SettingsStore' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SettingsStore' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Store' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eshopid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalgroupid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nearby_stores' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usetype_movein' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usetype_moveout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usetype_manualin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usetype_manualout' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usetype_shipmentin' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usetype_shipmentout' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additional_data' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddUpdateSettingsStoreMutation,
  AddUpdateSettingsStoreMutationVariables
>
export const DeleteSettingsStoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSettingsStore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'storeId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteStore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storeId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'storeId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteSettingsStoreMutation,
  DeleteSettingsStoreMutationVariables
>
export const ExportStoresDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ExportStores' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fileType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FileType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportStores' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fileType' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExportStoresMutation,
  ExportStoresMutationVariables
>
export const GetUserTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tagIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserTags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tagIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tagIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserTagsQuery, GetUserTagsQueryVariables>
export const AddUserTagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddUserTag' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'label' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUserTag' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'label' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'label' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddUserTagMutation, AddUserTagMutationVariables>
export const UpdateUserTagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserTag' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userTag' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserTagInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserTag' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userTag' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userTag' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserTagMutation,
  UpdateUserTagMutationVariables
>
export const DeleteUserTagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUserTag' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUserTag' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteUserTagMutation,
  DeleteUserTagMutationVariables
>
export const SettingsWarehousesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SettingsWarehouses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GetWarehousesSort' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'storeid' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allWarehouses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storeid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'storeid' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SettingsWarehouse' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SettingsWarehouse' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Warehouse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencycode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehousetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SettingsWarehousesQuery,
  SettingsWarehousesQueryVariables
>
export const SettingsWarehouseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SettingsWarehouse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SettingsWarehouse' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SettingsWarehouse' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Warehouse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencycode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehousetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SettingsWarehouseQuery,
  SettingsWarehouseQueryVariables
>
export const AddUpdateSettingsWarehouseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddUpdateSettingsWarehouse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouse' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WarehouseInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUpdateWarehouse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouse' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouse' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SettingsWarehouse' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SettingsWarehouse' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Warehouse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencycode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehousetype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sellable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddUpdateSettingsWarehouseMutation,
  AddUpdateSettingsWarehouseMutationVariables
>
export const DeleteSettingsWarehouseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSettingsWarehouse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteWarehouse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteSettingsWarehouseMutation,
  DeleteSettingsWarehouseMutationVariables
>
export const ExportWarehousesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ExportWarehouses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fileType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FileType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportWarehouses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fileType' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExportWarehousesMutation,
  ExportWarehousesMutationVariables
>
export const InventoryProductBatchesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InventoryProductBatches' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GetInventoryBatchesSort' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseBatchId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'categoryIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productGroupIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'manufacturerIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productState' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InventoryProductState' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inventoryState' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InventoryItemBatchState' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeCustomFields' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productWarehouseBatches' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchtext' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchText' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehousebatchid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseBatchId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'category' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'categoryIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productgroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productGroupIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'manufacturer' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'manufacturerIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productState' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productState' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inventoryState' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inventoryState' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activestate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'binlocation' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'datemodified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decimalavailable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decimalcounteddiff' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decimalnostockvalue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decimalquantity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decimalreserved' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decimalthreshold' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decimaltotal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decimaltranschanges' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailowner' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productid' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'warehousebatchitemstate',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manufacturerid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manufacturer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'custom1' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: {
                                    kind: 'Name',
                                    value: 'includeCustomFields',
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'custom2' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: {
                                    kind: 'Name',
                                    value: 'includeCustomFields',
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InventoryProductBatchesQuery,
  InventoryProductBatchesQueryVariables
>
export const UpdateWarehouseBatchItems2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateWarehouseBatchItems2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseBatchId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseBatchItems' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'WarehouseBatchItemInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWarehouseBatchItems2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseBatchId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseBatchId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseBatchItems' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseBatchItems' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehousebatchid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehousebatchitemid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehousebatchitemstate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimalquantity' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimalcounteddiff' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimalnostockvalue' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneypricein' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'emailowner' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'datemodified' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateWarehouseBatchItems2Mutation,
  UpdateWarehouseBatchItems2MutationVariables
>
export const ExportProductWarehouseBatchItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ExportProductWarehouseBatchItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fileType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FileType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GetInventoryBatchesSort' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseBatchId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'categoryIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productGroupIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'manufacturerIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productState' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InventoryProductState' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'inventoryState' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'InventoryItemBatchState' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportProductWarehouseBatchItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fileType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchtext' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchText' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'category' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'categoryIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productgroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productGroupIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'manufacturer' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'manufacturerIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productState' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productState' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehousebatchid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseBatchId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inventoryState' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'inventoryState' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExportProductWarehouseBatchItemsMutation,
  ExportProductWarehouseBatchItemsMutationVariables
>
export const GetWarehouseBatchStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWarehouseBatchStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseBatchId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeNoStockValue' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseBatchStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehousebatchid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseBatchId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehousebatchid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehousebatchstate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactiontype' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'num_items' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'num_stocktaking_not_counted' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'num_stocktaking_transaction_changes',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'num_stocktaking_no_stock_value',
                  },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: {
                              kind: 'Name',
                              value: 'includeNoStockValue',
                            },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWarehouseBatchStatusQuery,
  GetWarehouseBatchStatusQueryVariables
>
export const StocktakingTransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StocktakingTransactions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehousebatchid' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseid' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stocktakingTransactions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehousebatchid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehousebatchid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseid' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimaltranschanges' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StocktakingTransactionsQuery,
  StocktakingTransactionsQueryVariables
>
export const UpdateBatchItemsRelativeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBatchItemsRelative' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseBatchItems' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'WarehouseBatchItemInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseBatchId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBatchItemsRelative' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseBatchItems' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseBatchItems' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseBatchId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseBatchId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehousebatchitemstate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimalavailable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimalcounteddiff' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimalnostockvalue' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimalquantity' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimalreserved' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimalthreshold' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimaltotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimaltranschanges' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBatchItemsRelativeMutation,
  UpdateBatchItemsRelativeMutationVariables
>
export const AllWarehouseBatchesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllWarehouseBatches' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'transactionType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'WarehouseBatchTransaction' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'batchState' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'WarehouseBatchState' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allWarehouseBatches' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'transactionType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'transactionType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'batchState' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'batchState' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'warehousebatchid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'warehouseid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'warehousetransactionid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'transactiontype' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'datecreated' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'warehousebatchstate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailowner' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shipmentid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reasoncode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'warehouse' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'store' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'namefirst' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'namelast' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllWarehouseBatchesQuery,
  AllWarehouseBatchesQueryVariables
>
export const DashboardDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DashboardData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dashboardId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timestamp' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timeZone' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'storeId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userIdStaff' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboardData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dashboardId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dashboardId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timestamp' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'timestamp' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timeZone' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'timeZone' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storeId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'storeId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userIdStaff' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userIdStaff' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'templateUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dashboard' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboardConfiguration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storeId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'storeId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DashboardConfig' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardConfig' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DashboardConfig' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'separator_comma' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'separator_thousands' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'format_date_time_full' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'format_date_time' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'format_time' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency_prepend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency_append' } },
          { kind: 'Field', name: { kind: 'Name', value: 'num_decimals' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardDataQuery, DashboardDataQueryVariables>
export const AddUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
          { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'department' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customernumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datecreated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datemodified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherpasswords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'posrefundcard' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'staff_pos_activation' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'posallstores' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasmfa' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allWarehouses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddUserMutation, AddUserMutationVariables>
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
          { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'department' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customernumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datecreated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datemodified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherpasswords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'posrefundcard' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'staff_pos_activation' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'posallstores' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasmfa' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allWarehouses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>
export const UpdateUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'users' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'UpdateUserInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'users' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'users' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
          { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'department' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customernumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datecreated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datemodified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherpasswords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'posrefundcard' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'staff_pos_activation' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'posallstores' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasmfa' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allWarehouses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUsersMutation, UpdateUsersMutationVariables>
export const DeleteUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>
export const UserPermissionsByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserPermissionsById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'targetUserId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userPermissionsById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'targetUserId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'targetUserId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserPermissionsFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserPermissionsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserPermissions' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'changeEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'changePin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'changePassword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'changeActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'changeRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'changeAccess' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addMfa' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewMfa' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeMfa' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deleteUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exportUser' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserPermissionsByIdQuery,
  UserPermissionsByIdQueryVariables
>
export const UserPermissionsByRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserPermissionsByRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'targetUserRole' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserRole' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userPermissionsByRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'targetUserRole' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'targetUserRole' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserPermissionsFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserPermissionsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserPermissions' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'changeEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'changePin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'changePassword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'changeActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'changeRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'changeAccess' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addMfa' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewMfa' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removeMfa' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'editUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deleteUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exportUser' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserPermissionsByRoleQuery,
  UserPermissionsByRoleQueryVariables
>
export const UserAvailableRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserAvailableRoles' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAvailableRoles' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserAvailableRolesQuery,
  UserAvailableRolesQueryVariables
>
export const OidcAccountIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OidcAccountId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oidcAccountId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'state' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OidcAccountIdQuery, OidcAccountIdQueryVariables>
export const MfaCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MfaCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mfaId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mfaCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mfaId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mfaId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secret' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfaId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MfaCodeQuery, MfaCodeQueryVariables>
export const SetMfaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetMfa' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mfaId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setMfa' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mfaId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mfaId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'code' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetMfaMutation, SetMfaMutationVariables>
export const RemoveMfaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveMfa' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeMfa' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveMfaMutation, RemoveMfaMutationVariables>
export const AuthenticateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Authenticate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Authenticate' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authenticate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AuthenticateMutation,
  AuthenticateMutationVariables
>
export const AuthenticateSiteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AuthenticateSite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authenticateSite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AuthenticateSiteMutation,
  AuthenticateSiteMutationVariables
>
export const AuthenticateOidcDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AuthenticateOidc' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authenticateOidc' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'code' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'state' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'state' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AuthenticateOidcQuery,
  AuthenticateOidcQueryVariables
>
export const DeauthenticateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Deauthenticate' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'deauthenticate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeauthenticateMutation,
  DeauthenticateMutationVariables
>
export const GetCampaignsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCampaigns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CampaignFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activepos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchername' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datestart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchertype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherstate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricelisttags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherpassword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'money_m' } },
          { kind: 'Field', name: { kind: 'Name', value: 'money_n' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productoptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'num' } },
                { kind: 'Field', name: { kind: 'Name', value: 'products' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCampaignsQuery, GetCampaignsQueryVariables>
export const GetCampaignsWithProductAttributesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCampaignsWithProductAttributes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaigns' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CampaignFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productattributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'include' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'exclude' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activepos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchername' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datestart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchertype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherstate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricelisttags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherpassword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'money_m' } },
          { kind: 'Field', name: { kind: 'Name', value: 'money_n' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productoptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'num' } },
                { kind: 'Field', name: { kind: 'Name', value: 'products' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCampaignsWithProductAttributesQuery,
  GetCampaignsWithProductAttributesQueryVariables
>
export const GetCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'voucherid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CampaignDetailFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activepos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchername' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datestart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchertype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherstate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricelisttags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherpassword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'money_m' } },
          { kind: 'Field', name: { kind: 'Name', value: 'money_n' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productoptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'num' } },
                { kind: 'Field', name: { kind: 'Name', value: 'products' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignDetailFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CampaignFields' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercomment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherterms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datecreated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchername1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercode1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchername2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercode2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxuses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricelisttags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchergrouptype' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'use_discounted_price' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'products' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productattributes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'include' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exclude' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productoptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'num' } },
                { kind: 'Field', name: { kind: 'Name', value: 'products' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sitooProducts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productImages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fileUrl' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discountoptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'num' } },
                { kind: 'Field', name: { kind: 'Name', value: 'products' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyprice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneydiscount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimaldiscount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneydiscounttotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productattributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'include' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'exclude' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCampaignQuery, GetCampaignQueryVariables>
export const AddCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CampaignInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddCampaignMutation, AddCampaignMutationVariables>
export const UpdateCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'campaignId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CampaignInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'campaignId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CampaignDetailFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activepos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchername' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datestart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchertype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherstate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricelisttags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherpassword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_z' } },
          { kind: 'Field', name: { kind: 'Name', value: 'money_m' } },
          { kind: 'Field', name: { kind: 'Name', value: 'money_n' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productoptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'num' } },
                { kind: 'Field', name: { kind: 'Name', value: 'products' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignDetailFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Campaign' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'CampaignFields' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercomment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherterms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datecreated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchername1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercode1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchername2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercode2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxuses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pricelisttags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchergrouptype' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'use_discounted_price' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'products' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productattributes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'include' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exclude' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productoptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'num' } },
                { kind: 'Field', name: { kind: 'Name', value: 'products' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sitooProducts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productImages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fileUrl' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discountoptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'num' } },
                { kind: 'Field', name: { kind: 'Name', value: 'products' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyprice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneydiscount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimaldiscount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneydiscounttotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productattributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'include' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'exclude' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCampaignMutation,
  UpdateCampaignMutationVariables
>
export const GetCampaignTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCampaignTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'campaignTypes' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCampaignTypesQuery,
  GetCampaignTypesQueryVariables
>
export const GetCampaignGroupTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCampaignGroupTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignGroupTypes' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCampaignGroupTypesQuery,
  GetCampaignGroupTypesQueryVariables
>
export const GetCampaignStatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCampaignStates' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'campaignStates' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCampaignStatesQuery,
  GetCampaignStatesQueryVariables
>
export const DeleteCampaignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCampaign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteCampaignInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCampaign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCampaignMutation,
  DeleteCampaignMutationVariables
>
export const NumActiveCashRegistersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NumActiveCashRegisters' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numActiveCashRegisters' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NumActiveCashRegistersQuery,
  NumActiveCashRegistersQueryVariables
>
export const GetCashRegistersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCashRegisters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GetRegistersSort' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeNotRegistered' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cashRegisters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includenotregistered' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'includeNotRegistered' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CashRegisterFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CashRegisterFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CashRegister' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'registerid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manufacturerid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registerkey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datelastping' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clientdescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loglevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salestaxgroupid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiptlanguagetag' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencycode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionaldata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registernumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCashRegistersQuery,
  GetCashRegistersQueryVariables
>
export const GetAllCashRegistersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllCashRegisters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeNotRegistered' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GetRegistersSort' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allCashRegisters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includenotregistered' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'includeNotRegistered' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CashRegisterFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CashRegisterFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CashRegister' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'registerid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manufacturerid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registerkey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datelastping' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clientdescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loglevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salestaxgroupid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiptlanguagetag' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencycode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionaldata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registernumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllCashRegistersQuery,
  GetAllCashRegistersQueryVariables
>
export const GetCashRegisterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCashRegister' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cashRegister' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CashRegisterFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CashRegisterFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CashRegister' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'registerid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manufacturerid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registerkey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datelastping' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clientdescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loglevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salestaxgroupid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiptlanguagetag' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencycode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionaldata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ctu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registernumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCashRegisterQuery,
  GetCashRegisterQueryVariables
>
export const AddCashRegisterActivationCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddCashRegisterActivationCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'registerId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addCashRegisterActivationCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'registerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'registerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'account' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activationCode' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddCashRegisterActivationCodeMutation,
  AddCashRegisterActivationCodeMutationVariables
>
export const GetCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'categorySiteId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'categorySiteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'categorySiteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CategoryFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Category' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'categoryparentid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortorder' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCategoriesQuery, GetCategoriesQueryVariables>
export const UpdateCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'UpdateCategoryInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'error' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCategoriesMutation,
  UpdateCategoriesMutationVariables
>
export const DeleteCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteCategoriesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'categoryId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'error' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCategoriesMutation,
  DeleteCategoriesMutationVariables
>
export const GetConfigVarsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetConfigVars' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'configVars' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'adyenTapToPay' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'adyenTerminalApi' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'apps' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaignPriceListDiscount' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'campaigns' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cashRegisters' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ctuNorway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryIn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryOut' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inStoreReplenishment' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'influencerCodes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'intercom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'journals' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'limitUserManagement' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'orderLookup' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orders' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prices' } },
                { kind: 'Field', name: { kind: 'Name', value: 'products' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasePrices' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rewards' } },
                { kind: 'Field', name: { kind: 'Name', value: 'salesTax' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'serviceOrders' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shipments' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipmentsv3' } },
                { kind: 'Field', name: { kind: 'Name', value: 'statistics' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stock' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stocktaking' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stocktakingShowCustom' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stockValueWarning' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'users' } },
                { kind: 'Field', name: { kind: 'Name', value: 'posSettings' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentSettings' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'directFeedback' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storeDashboard' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reports' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetConfigVarsQuery, GetConfigVarsQueryVariables>
export const AllCustomAttributesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllCustomAttributes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customAttributeSiteId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allCustomAttributes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customAttributeSiteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customAttributeSiteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomAttribute' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomAttribute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomAttribute' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enums' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllCustomAttributesQuery,
  AllCustomAttributesQueryVariables
>
export const GetCustomAttributesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomAttributes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customAttributes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'siteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CustomAttribute' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomAttribute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomAttribute' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enums' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCustomAttributesQuery,
  GetCustomAttributesQueryVariables
>
export const GetCustomAttributeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomAttribute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customAttribute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomAttribute' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomAttribute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomAttribute' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enums' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCustomAttributeQuery,
  GetCustomAttributeQueryVariables
>
export const AddCustomAttributeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddCustomAttribute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customAttribute' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SingleCustomAttributeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addCustomAttribute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customAttribute' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customAttribute' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomAttribute' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomAttribute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomAttribute' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enums' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddCustomAttributeMutation,
  AddCustomAttributeMutationVariables
>
export const UpdateCustomAttributeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCustomAttribute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customAttribute' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SingleCustomAttributeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomAttribute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customAttribute' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customAttribute' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CustomAttribute' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomAttribute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CustomAttribute' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enums' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCustomAttributeMutation,
  UpdateCustomAttributeMutationVariables
>
export const DeleteCustomAttributeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCustomAttribute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCustomAttribute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCustomAttributeMutation,
  DeleteCustomAttributeMutationVariables
>
export const FilterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Filter' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filter' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FilterQuery, FilterQueryVariables>
export const ConvertWarehouseBatchItemsCsvDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ConvertWarehouseBatchItemsCsv' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'csv' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'convertWarehouseBatchItemsCsv' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'csv' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'csv' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decimalquantity' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'purchaseprice' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'property' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConvertWarehouseBatchItemsCsvQuery,
  ConvertWarehouseBatchItemsCsvQueryVariables
>
export const ImportWarehouseBatchItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ImportWarehouseBatchItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseBatchId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'ImportWarehouseBatchItemInput',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'importWarehouseBatchItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseBatchId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseBatchId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'imported' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failed' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ImportWarehouseBatchItemsMutation,
  ImportWarehouseBatchItemsMutationVariables
>
export const ConvertShipmentPackageItemsCsvDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ConvertShipmentPackageItemsCsv' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'csv' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'convertShipmentPackageItemsCsv' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'csv' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'csv' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'barcode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'unit_price' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'batch' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'batch_expires' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sku' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'images' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'barcode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'variant' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'property' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConvertShipmentPackageItemsCsvQuery,
  ConvertShipmentPackageItemsCsvQueryVariables
>
export const GetInfluencerCodesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInfluencerCodes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GetInfluencerCodesSort' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'active' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'redeemable' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vouchername' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'voucherpassword' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'datestartFrom' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UnixDate' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'datestartTo' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UnixDate' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateendFrom' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UnixDate' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateendTo' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UnixDate' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'datelastusedFrom' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UnixDate' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'datelastusedTo' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UnixDate' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'influencerCodes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'active' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'active' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'code' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'redeemable' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'redeemable' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vouchername' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'vouchername' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'voucherpassword' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'voucherpassword' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datestartFrom' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'datestartFrom' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datestartTo' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'datestartTo' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateendFrom' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dateendFrom' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateendTo' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dateendTo' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datelastusedFrom' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'datelastusedFrom' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'datelastusedTo' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'datelastusedTo' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'InfluencerCodeFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InfluencerCodeFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InfluencerCode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datestart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherpassword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchername' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercomment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxuses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numused' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datelastused' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneyordertotal' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneyinfluencertotal' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneyinfluencerdiscount' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInfluencerCodesQuery,
  GetInfluencerCodesQueryVariables
>
export const GetInfluencerCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInfluencerCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'influencerCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'code' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InfluencerCodeDetailFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InfluencerCodeFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InfluencerCode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datestart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherpassword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchername' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercomment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxuses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numused' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datelastused' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneyordertotal' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneyinfluencertotal' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneyinfluencerdiscount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InfluencerCodeDetailFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InfluencerCode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'InfluencerCodeFields' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaigns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vouchername' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInfluencerCodeQuery,
  GetInfluencerCodeQueryVariables
>
export const AddInfluencerCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddInfluencerCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InfluencerCodeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addInfluencerCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InfluencerCodeDetailFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InfluencerCodeFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InfluencerCode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datestart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherpassword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchername' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercomment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxuses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numused' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datelastused' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneyordertotal' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneyinfluencertotal' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneyinfluencerdiscount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InfluencerCodeDetailFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InfluencerCode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'InfluencerCodeFields' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaigns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vouchername' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddInfluencerCodeMutation,
  AddInfluencerCodeMutationVariables
>
export const UpdateInfluencerCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateInfluencerCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InfluencerCodeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateInfluencerCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InfluencerCodeDetailFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InfluencerCodeFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InfluencerCode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datestart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voucherpassword' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchername' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vouchercomment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxuses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numused' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datelastused' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneyordertotal' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneyinfluencertotal' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneyinfluencerdiscount' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InfluencerCodeDetailFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InfluencerCode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'InfluencerCodeFields' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaigns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vouchername' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateInfluencerCodeMutation,
  UpdateInfluencerCodeMutationVariables
>
export const DeleteInfluencerCodesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteInfluencerCodes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'codes' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteInfluencerCodes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'codes' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'codes' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteInfluencerCodesMutation,
  DeleteInfluencerCodesMutationVariables
>
export const GetJournalEntriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetJournalEntries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'registerIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'storeIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'next' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateFrom' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UnixDate' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateTo' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UnixDate' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'JournalFilterDateType' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'JournalEntryType' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'journalEntries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'registerIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'registerIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storeIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'storeIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'next' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'next' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateFrom' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dateFrom' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateTo' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dateTo' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dateType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateadded' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'datecreated' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registerid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registerkey' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ispractice' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'JournalCtuError',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'errortext' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'JournalStaff' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'staff' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'staffuserid',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'JournalCart' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'staff' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'staffuserid',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'quantitytotal',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'moneytotal' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneydiscount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionaldata',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'JournalCartItem',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'staff' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'staffuserid',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item_index' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sku' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'quantity' },
                                  },
                                  {
                                    kind: 'Field',
                                    alias: {
                                      kind: 'Name',
                                      value: 'JournalCartItemMoneyTotal',
                                    },
                                    name: { kind: 'Name', value: 'moneytotal' },
                                  },
                                  {
                                    kind: 'Field',
                                    alias: {
                                      kind: 'Name',
                                      value: 'JournalCartItemMoneyDiscount',
                                    },
                                    name: {
                                      kind: 'Name',
                                      value: 'moneydiscount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'decimalunitquantity',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'note' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionaldata',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cart_id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cart_quantitytotal',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cart_moneytotal',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cart_moneydiscount',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'JournalCartPayment',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'staff' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'staffuserid',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'payment_index',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'paymentname',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'paymentmethod',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'moneytotal' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneycaptured',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionaldata',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cart_id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'JournalCash' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'staff' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'staffuserid',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'currencycode',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'moneycash' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'comment' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionaldata',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'JournalPrintout',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'staff' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'staffuserid',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'receiptid' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'orderid' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'zreportid' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionaldata',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'JournalReport' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'zreportid' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'manufacturerid',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dateopened' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'eshopid' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'store_id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'store_name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'company' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyid' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address2' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address3' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'currencycode',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'staff' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'staffuserid',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'comment' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneysalestotal',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneysalestotalnet',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'numsales' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'numsalesitems',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'vatgroupssales',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'vatvalue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotal',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotalnet',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotalvat',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'productgroupssales',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'vatvalue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'numtotal',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotal',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotalnet',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotalvat',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneysalesnotdeliveredtotal',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneysalesnotdeliveredtotalnet',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'numsalesnotdelivered',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'numsalesnotdelivereditems',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'vatgroupssalesnotdelivered',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'vatvalue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotal',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotalnet',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotalvat',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'productgroupssalesnotdelivered',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'vatvalue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'numtotal',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotal',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotalnet',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotalvat',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneyrefundtotal',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneyrefundtotalnet',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'numrefund' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'numrefunditems',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'vatgroupsrefund',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'vatvalue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotal',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotalnet',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotalvat',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'productgroupsrefund',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'vatvalue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'numtotal',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotal',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotalnet',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotalvat',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneyroundoff',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'salestaxessales',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'code' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'externalid',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'groupregion',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotal',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytax',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'decimaltaxvalue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'salestaxesrefund',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'code' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'externalid',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'groupregion',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotal',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytax',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'decimaltaxvalue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cashdiff_reasoncode',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cashdiff_reasoncomment',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneysalestaxinadvance',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneysummarysales',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneysummaryrefund',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneysummarysubtotal',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneysummarysalestax',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneysummaryroundoff',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneysummarytotal',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'currencyconversions',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'currency_code',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'money_expected',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'money_counted',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'money_expected_base',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'money_counted_base',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'bag_id',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'paymentssales',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotal',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneycaptured',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneyinadvance',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneyreserved',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subpayments',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'moneysubtotal',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'moneycaptured',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'moneyinadvance',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'moneyreserved',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'paymentsrefund',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotal',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneycaptured',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneyinadvance',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneyreserved',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subpayments',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'moneysubtotal',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'moneycaptured',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'moneyinadvance',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'moneyreserved',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'discountssales',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'vouchercode',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotal',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotalnet',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotalvat',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'discountssalesnotdelivered',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'vouchercode',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotal',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotalnet',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotalvat',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'discountsrefund',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'vouchercode',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotal',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotalnet',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotalvat',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneycash_in',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneycash_salesrefunds',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneycash_petty',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneycash_bank',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneycash_expected',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneycash_counted',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneycash_diff',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneycash_bankfinal',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneycash_out',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneydiscount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cash_bankfinal_bag_id',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'numsalestypeproduct',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'numsalestypeservice',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'numsalestypegiftcard',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'numreceipts',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'numopendrawer',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'numpractice',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneypractice',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneygrandtotalsales',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneygrandtotalrefund',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneygrandtotalnet',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'JournalReceipt' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'receiptnumber',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'moneytotal' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'quantitytotal',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneysubtotal',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneyroundoff',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'currencycode',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isrefund' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'staff' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'staffuserid',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'creditorderid',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'sku' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'productname',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'variant',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'discountname',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'note' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'productgrouptype',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'productgroupname',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'unitlabel',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'quantity',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotal',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneydiscount',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneyvat',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneyunitprice',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'decimalunitquantity',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'vatid',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'vatvalue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'discounts',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'discountname',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'discountgroup',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'moneytotal',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'moneyvat',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'additionaldata',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'salestaxes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'code',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'externalid',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'groupregion',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'decimalvalue',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'moneytotal',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'moneytax',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'additionaldata',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'printrow',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'type',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'scope',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'value1',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'value2',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'printsection',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'type',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'scope',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'value1',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'value2',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'payments' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'paymentname',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'paymentmethod',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'paymentexternalid',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotal',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneycaptured',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneyinadvance',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'additionaldata',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'printrow',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'type',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'scope',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'value1',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'value2',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'printsection',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'type',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'scope',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'value1',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'value2',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vatsummary' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'vatvalue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneyvat',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneynet',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'salestaxsummary',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'code' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'externalid',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'groupregion',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'decimalvalue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytotal',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'moneytax',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'moneysalestaxinadvance',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'customer' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'email',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'namefirst',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'namelast',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'personalid',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'companyid',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'company',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'mobile',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'customerref',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'invoice_address',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'invoice_address2',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'invoice_zip',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'invoice_city',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'invoice_state',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'invoice_countryid',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'delivery_address',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'delivery_address2',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'delivery_zip',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'delivery_city',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'delivery_state',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'delivery_countryid',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionaldata',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'printsection',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'type' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'scope',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value1',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value2',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'comment' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'posname' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'posversion' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'next' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetJournalEntriesQuery,
  GetJournalEntriesQueryVariables
>
export const AccessLogsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccessLogs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateFrom' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UnixDate' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateTo' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UnixDate' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'actor' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'next' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accessLogs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateFrom' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dateFrom' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dateTo' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dateTo' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'actor' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'actor' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'next' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'next' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'next' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'LogEvent' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LogEvent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LogEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eshop_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccessLogsQuery, AccessLogsQueryVariables>
export const GetManufacturerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetManufacturer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manufacturer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Manufacturer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Manufacturer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Manufacturer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url2' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetManufacturerQuery,
  GetManufacturerQueryVariables
>
export const GetManufacturersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetManufacturers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manufacturers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetManufacturersQuery,
  GetManufacturersQueryVariables
>
export const GetManufacturersDetailedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetManufacturersDetailed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manufacturers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Manufacturer' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Manufacturer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Manufacturer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url2' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetManufacturersDetailedQuery,
  GetManufacturersDetailedQueryVariables
>
export const AddUpdateManufacturerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddUpdateManufacturer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'manufacturer' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ManufacturerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUpdateManufacturer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'manufacturer' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'manufacturer' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Manufacturer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Manufacturer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Manufacturer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url2' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddUpdateManufacturerMutation,
  AddUpdateManufacturerMutationVariables
>
export const DeleteManufacturerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteManufacturer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteManufacturer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteManufacturerMutation,
  DeleteManufacturerMutationVariables
>
export const PublicLoginOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PublicLoginOptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicLoginOptions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PublicLoginOption' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicLoginOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PublicLoginOption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oidc_auth_request_url' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PublicLoginOptionsQuery,
  PublicLoginOptionsQueryVariables
>
export const LoginOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoginOptions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loginOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PublicLoginOption' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicLoginOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PublicLoginOption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oidc_auth_request_url' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginOptionsQuery, LoginOptionsQueryVariables>
export const LoginOptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LoginOption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loginOption' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'optionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'optionId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LoginOption' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LoginOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LoginOption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oidc_auth_request_url' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oidc_private' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'client_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client_secret' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'auth_url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token_url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenant_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginOptionQuery, LoginOptionQueryVariables>
export const AddUpdateLoginOptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddUpdateLoginOption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddUpdateLoginOptionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUpdateLoginOption' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'oidc_auth_request_url' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'oidc_private' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'client_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'client_secret' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'auth_url' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'token_url' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenant_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddUpdateLoginOptionMutation,
  AddUpdateLoginOptionMutationVariables
>
export const DeleteLoginOptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteLoginOption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'loginOptionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteLoginOption' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'loginOptionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'loginOptionId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteLoginOptionMutation,
  DeleteLoginOptionMutationVariables
>
export const GetOrdersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentstate' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PaymentState' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'externalid' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderid' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'receiptid' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'creditorderid' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderdatefrom' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UnixDate' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderdateto' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UnixDate' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderstate' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'OrderState' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ordertype' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'OrderType' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GetOrdersSort' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseId' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentstate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paymentstate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'externalid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'externalid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'receiptid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'receiptid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creditorderid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'creditorderid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderdatefrom' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderdatefrom' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderdateto' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderdateto' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderstate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderstate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ordertype' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ordertype' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OrderFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentstateid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creditorderid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderstateid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneytotal_gross_all' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneytotal_gross_roundoff' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencycode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datereserved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cashRegister' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'registerkey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registerid' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionaldata' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cardissuer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyamount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reftype' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reservedpaymentid' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
          { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionaldata' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrdersQuery, GetOrdersQueryVariables>
export const GetOrdersByIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrdersByIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderid' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderid' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OrderDetailsFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentstateid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creditorderid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderstateid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneytotal_gross_all' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneytotal_gross_roundoff' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencycode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datereserved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cashRegister' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'registerkey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registerid' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionaldata' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cardissuer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyamount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reftype' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reservedpaymentid' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
          { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionaldata' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderItems' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderitems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'orderitemid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productname' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productattributes' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salestaxes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupregion' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moneytotal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moneytax' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decimaltaxvalue' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'vouchername' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vouchercode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vatvalue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneyoriginalprice' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneyitemtotal_net' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneyitemtotal_vat' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionaldata' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderDetailsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'OrderFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'OrderItems' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ordertypeid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commentinternal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerref' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderDeliveryState' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderDelivery' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pluginname' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'datecancelled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'datedelivery' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderdeliveryid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderdeliveryref' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'binlocation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ignorestock' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderdeliveryitems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderdeliveryitemid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderitemid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalOrder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'orderid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneytotal_gross_all' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneytotal_gross_roundoff' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderItems' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refundOrder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'orderid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneytotal_gross_all' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneytotal_gross_roundoff' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderItems' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exchangeOrder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'orderid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneytotal_gross_all' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneytotal_gross_roundoff' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderItems' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'customerref' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
          { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delivery_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delivery_address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delivery_city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delivery_state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delivery_zip' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delivery_countryid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_countryid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneytotal_gross_all' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneytotal_gross_roundoff' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'moneyfinal_vat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneyfinal_net' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencycode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionaldata' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'staff' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
                { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'staffHelpers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
                { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reservedpayments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneyreserved' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cardissuer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reftype' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionaldata' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionaldata' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cardissuer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyamount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reftype' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reservedpaymentid' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cashRegister' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'registerkey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registerid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrdersByIdsQuery, GetOrdersByIdsQueryVariables>
export const GetOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'order' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderDetailsFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentstateid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creditorderid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderstateid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneytotal_gross_all' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneytotal_gross_roundoff' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencycode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datereserved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cashRegister' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'registerkey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registerid' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionaldata' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cardissuer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyamount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reftype' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reservedpaymentid' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
          { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionaldata' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderItems' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderitems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'orderitemid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productname' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productattributes' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salestaxes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupregion' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moneytotal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moneytax' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decimaltaxvalue' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'vouchername' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vouchercode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vatvalue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneyoriginalprice' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneyitemtotal_net' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneyitemtotal_vat' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionaldata' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderDetailsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'OrderFields' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'OrderItems' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ordertypeid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commentinternal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerref' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderDeliveryState' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderDelivery' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pluginname' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'datecancelled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'datedelivery' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderdeliveryid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderdeliveryref' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'binlocation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ignorestock' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderdeliveryitems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderdeliveryitemid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderitemid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalOrder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'orderid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneytotal_gross_all' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneytotal_gross_roundoff' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderItems' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refundOrder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'orderid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneytotal_gross_all' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneytotal_gross_roundoff' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderItems' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exchangeOrder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'orderid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneytotal_gross_all' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneytotal_gross_roundoff' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderItems' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'customerref' } },
          { kind: 'Field', name: { kind: 'Name', value: 'company' } },
          { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
          { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delivery_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delivery_address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delivery_city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delivery_state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'delivery_zip' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delivery_countryid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invoice_countryid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneytotal_gross_all' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneytotal_gross_roundoff' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'moneyfinal_vat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneyfinal_net' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencycode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionaldata' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'staff' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
                { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'staffHelpers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
                { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reservedpayments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneyreserved' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cardissuer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reftype' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionaldata' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionaldata' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cardissuer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyamount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reftype' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reservedpaymentid' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cashRegister' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'registerkey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registerid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrderQuery, GetOrderQueryVariables>
export const GetOrderHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrderHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'order' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderHistoryFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderHistoryFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'registerid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderDelivery' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pluginname' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'datecancelled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'datedelivery' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderdeliveryid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderdeliveryref' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'binlocation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ignorestock' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderdeliveryitems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderdeliveryitemid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderitemid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderlogitems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'datelog' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderlogtypeid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'strvalue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fvalue' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchaseJournal' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateadded' } },
                { kind: 'Field', name: { kind: 'Name', value: 'datecreated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registerid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registerkey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ispractice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'JournalCtuError' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'errortext' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'JournalStaff' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staff' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffuserid' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'JournalCart' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staff' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffuserid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'quantitytotal' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'moneytotal' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'moneydiscount' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'JournalCartItem' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staff' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffuserid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sku' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'quantity' },
                            },
                            {
                              kind: 'Field',
                              alias: {
                                kind: 'Name',
                                value: 'JournalCartItemMoneyTotal',
                              },
                              name: { kind: 'Name', value: 'moneytotal' },
                            },
                            {
                              kind: 'Field',
                              alias: {
                                kind: 'Name',
                                value: 'JournalCartItemMoneyDiscount',
                              },
                              name: { kind: 'Name', value: 'moneydiscount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'decimalunitquantity',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'note' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cart_id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'cart_quantitytotal',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cart_moneytotal' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'cart_moneydiscount',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'JournalCartPayment' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staff' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffuserid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'paymentname' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'paymentmethod' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'moneytotal' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'moneycaptured' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cart_id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'JournalCash' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staff' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffuserid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currencycode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'moneycash' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'comment' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'JournalPrintout' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staff' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffuserid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'receiptid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'orderid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zreportid' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'JournalReport' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staff' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffuserid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zreportid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'manufacturerid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eshopid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'store_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'store_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'company' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyid' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'JournalReceipt' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staff' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'staffuserid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'receiptnumber' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrderHistoryQuery,
  GetOrderHistoryQueryVariables
>
export const SearchOrdersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchOrders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'next' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateFrom' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UnixDate' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateTo' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UnixDate' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'OrderLookupFilterSortEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'siteIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'registerIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'storeIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'OrderLookupFilterOrderTypeEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderTransactionType' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'OrderLookupFilterOrderTransactionTypeEnum',
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'totalAmountMin' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Money' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'totalAmountMax' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Money' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'refundInitiationMode' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'OrderLookupFilterReturnLookupMethodEnum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'staffExternalId' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'staffUserId' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'staffUserIdHelpers' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'crmCustomerId' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'crmMemberNumber' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phone' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentMethod' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'OrderLookupFilterPaymentMethodEnum',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentVirtual' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentValidated' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentAmountMin' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Money' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentAmountMax' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Money' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentCardIssuer' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paymentCardMaskedPan' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemProductGroupName' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemSku' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemVoucherName' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemVoucherId' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemReasonCodeManualDiscount' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'itemReasonCodeManualPrice' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'externalid' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderid' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'receiptid' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'creditorderid' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchOrders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'next' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'next' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date_from' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dateFrom' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date_to' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dateTo' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'site_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'siteIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'register_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'registerIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouse_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'store_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'storeIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_transaction_type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderTransactionType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'total_amount_min' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'totalAmountMin' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'total_amount_max' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'totalAmountMax' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'refund_initiation_mode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'refundInitiationMode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'staff_external_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'staffExternalId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'staff_user_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'staffUserId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'staff_user_id_helpers' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'staffUserIdHelpers' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'crm_customer_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'crmCustomerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'crm_member_number' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'crmMemberNumber' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phone' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'phone' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payment_method' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paymentMethod' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payment_virtual' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paymentVirtual' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payment_validated' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paymentValidated' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payment_amount_min' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paymentAmountMin' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payment_amount_max' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paymentAmountMax' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payment_card_issuer' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paymentCardIssuer' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'payment_card_masked_pan' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paymentCardMaskedPan' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'item_product_group_name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'itemProductGroupName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'item_sku' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'itemSku' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'item_voucher_name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'itemVoucherName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'item_voucher_id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'itemVoucherId' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'item_reason_code_manual_discount',
                },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'itemReasonCodeManualDiscount' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'item_reason_code_manual_price' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'itemReasonCodeManualPrice' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'externalid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'externalid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'receiptid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'receiptid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'creditorderid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'creditorderid' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OrderFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'next' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Order' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentstateid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creditorderid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderstateid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneytotal_gross_all' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moneytotal_gross_roundoff' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencycode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datereserved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cashRegister' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'registerkey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'registerid' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'payments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionaldata' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cardissuer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyamount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reftype' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reservedpaymentid' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
          { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
          { kind: 'Field', name: { kind: 'Name', value: 'additionaldata' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchOrdersQuery, SearchOrdersQueryVariables>
export const UpdateOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateOrderInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'orderid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderdate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderstateid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentstateid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ordertypeid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'datereserved' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'commentinternal' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customerref' } },
                { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
                { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'personalid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'delivery_address' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'delivery_address2' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'delivery_city' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'delivery_state' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'delivery_zip' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'delivery_countryid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoice_address' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoice_address2' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoice_city' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoice_state' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'invoice_zip' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoice_countryid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderlogitems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'datelog' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderlogtypeid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'strvalue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fvalue' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOrderMutation, UpdateOrderMutationVariables>
export const AddOrderDeliveryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddOrderDelivery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddOrderDeliveryInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addOrderDelivery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderDeliveryFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderDeliveryFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OrderDelivery' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderdeliveryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderdeliveryref' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datedelivery' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datecancelled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pluginname' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderdeliveryitems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderdeliveryitemid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderdeliveryid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'orderitemid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddOrderDeliveryMutation,
  AddOrderDeliveryMutationVariables
>
export const UpdateOrderDeliveryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOrderDelivery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderDeliveryId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateOrderDeliveryInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrderDelivery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderDeliveryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderDeliveryId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OrderDeliveryFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderDeliveryFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OrderDelivery' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'orderdeliveryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderdeliveryref' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datedelivery' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datecancelled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pluginname' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderdeliveryitems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderdeliveryitemid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderdeliveryid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'orderitemid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateOrderDeliveryMutation,
  UpdateOrderDeliveryMutationVariables
>
export const ExportOrdersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ExportOrders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Int' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fileType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FileType' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportOrders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fileType' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExportOrdersMutation,
  ExportOrdersMutationVariables
>
export const UpdatePricelistItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePricelistItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pricelistId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'items' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'UpdatePricelistItemInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productSiteId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePricelistItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'priceListId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pricelistId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'items' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'items' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productSiteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productSiteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricelistitems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moneyprice' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricelistid' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePricelistItemsMutation,
  UpdatePricelistItemsMutationVariables
>
export const DeletePricelistItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePricelistItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pricelistId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'items' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'DeletePricelistItemInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productSiteId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePricelistItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'priceListId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pricelistId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'items' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'items' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productSiteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productSiteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricelistitems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productid' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moneyprice' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricelistid' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePricelistItemsMutation,
  DeletePricelistItemsMutationVariables
>
export const AllPriceListTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllPriceListTags' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'allPriceListTags' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllPriceListTagsQuery,
  AllPriceListTagsQueryVariables
>
export const GetPriceListsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPriceLists' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceLists' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricelistname' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencycode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricelistdescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalid' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPriceListsQuery, GetPriceListsQueryVariables>
export const GetPriceListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPriceList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricelistname' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currencycode' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricelistdescription' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPriceListQuery, GetPriceListQueryVariables>
export const UpdatePriceListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePriceList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priceList' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePriceListInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePriceList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'priceList' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'priceList' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricelistname' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currencycode' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricelistdescription' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePriceListMutation,
  UpdatePriceListMutationVariables
>
export const DeletePriceListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePriceList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePriceList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePriceListMutation,
  DeletePriceListMutationVariables
>
export const GetProductGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProductGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProductGroupFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductGroupFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProductGroupQuery,
  GetProductGroupQueryVariables
>
export const GetProductGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProductGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProductGroupFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductGroupFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProductGroupsQuery,
  GetProductGroupsQueryVariables
>
export const AllProductGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllProductGroups' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allProductGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllProductGroupsQuery,
  AllProductGroupsQueryVariables
>
export const AddUpdateProductGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddUpdateProductGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productGroup' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SingleProductGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUpdateProductGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ProductGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productGroup' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddUpdateProductGroupMutation,
  AddUpdateProductGroupMutationVariables
>
export const DeleteProductGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProductGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProductGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteProductGroupMutation,
  DeleteProductGroupMutationVariables
>
export const GetProductVariantGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProductVariantGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productid' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productSiteId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productVariants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productSiteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productSiteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'options' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variants' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProductVariantGroupsQuery,
  GetProductVariantGroupsQueryVariables
>
export const GetProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeVariants' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productGroupIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'campaignIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'manufacturerIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'categoryIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GetProductsSort' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skus' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeInactive' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productSiteId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fallbackPriceToCommon' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productGroupType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ProductGroupType' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeVariants' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'includeVariants' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'search' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productGroupIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productGroupIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'campaignIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'manufacturerIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'manufacturerIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'categoryIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'categoryIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skus' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeInactive' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'includeInactive' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productSiteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productSiteId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productGroupType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productGroupType' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProductFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activepos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneyprice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datecreated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datemodified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultcategoryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productgrouptype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowdecimals' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitlabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manufacturer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricelistitems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fallbackPriceToCommon' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fallbackPriceToCommon' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pricelistid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricelistid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyprice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricelist' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencycode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricelistname' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productgroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allcategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categoryparentid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProductsQuery, GetProductsQueryVariables>
export const GetRelatedProductsSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRelatedProductsSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skus' },
                value: {
                  kind: 'ListValue',
                  values: [
                    { kind: 'Variable', name: { kind: 'Name', value: 'sku' } },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productImages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fileUrl' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRelatedProductsSearchQuery,
  GetRelatedProductsSearchQueryVariables
>
export const GetProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'currency' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productSiteId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fallbackPriceToCommon' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productSiteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productSiteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProductDetailsFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activepos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneyprice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datecreated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datemodified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultcategoryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productgrouptype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowdecimals' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitlabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manufacturer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricelistitems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fallbackPriceToCommon' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fallbackPriceToCommon' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pricelistid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricelistid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyprice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricelist' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencycode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricelistname' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productgroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allcategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categoryparentid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductDetailsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProductFields' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'moneypriceorg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'computedPurchasePrice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'currency' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'price_list_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'price_list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'variantparentid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionshort' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customattributes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skumanufacturer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manufacturerurl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockcountenable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'related' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accessories' } },
          { kind: 'Field', name: { kind: 'Name', value: 'similar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMainVariant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSingleProduct' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'childVariants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyprice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneypriceorg' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isMainVariant' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSingleProduct' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customattributes' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'computedPurchasePrice' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'currency' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'currency' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price_list_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price_list' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currency' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variantGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'options' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimaltotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sellable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'store' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedProducts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accessoriesProducts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'similarProducts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProductQuery, GetProductQueryVariables>
export const GetRelatedProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRelatedProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productSiteId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productSiteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productSiteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RelatedProductFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RelatedProductFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedProducts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accessoriesProducts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'similarProducts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRelatedProductsQuery,
  GetRelatedProductsQueryVariables
>
export const GetProductStockLevelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProductStockLevels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeVariants' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'categoryIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GetProductsSort' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeInactive' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productSiteId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeVariants' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'includeVariants' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'search' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'categoryIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'categoryIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeInactive' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'includeInactive' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productSiteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productSiteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activepos' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'warehouseItems' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'warehouseid' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'warehouseId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sku' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'decimaltotal' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'decimalreserved' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'decimalavailable' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'datelastmodified' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'binlocation' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'decimalthreshold' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'moneytotal' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'warehouse' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sellable' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'store' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProductStockLevelsQuery,
  GetProductStockLevelsQueryVariables
>
export const GetProductPricesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProductPrices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeproductvariants' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'categoryIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GetProductsSort' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'currency' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fallbackPriceToCommon' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeInactive' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productSiteId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeVariants' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'includeproductvariants' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'search' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'categoryIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'categoryIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeInactive' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'includeInactive' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productSiteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productSiteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activepos' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productgroup' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'computedPurchasePrice' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'currency' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'currency' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price_list_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price_list' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'currency' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moneypriceorg' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moneypricein' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricelistitems' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {
                              kind: 'Name',
                              value: 'fallbackPriceToCommon',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'fallbackPriceToCommon',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'productid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'moneyprice' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pricelistid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sku' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProductPricesQuery,
  GetProductPricesQueryVariables
>
export const GetProductPurchasePricesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProductPurchasePrices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeproductvariants' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'categoryIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GetProductsSort' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priceListId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeInactive' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productSiteId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fallbackPriceToCommon' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeVariants' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'includeproductvariants' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'search' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'categoryIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'categoryIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeInactive' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'includeInactive' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productSiteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productSiteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activepos' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variant' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productgroup' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricelistitems' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {
                              kind: 'Name',
                              value: 'fallbackPriceToCommon',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'fallbackPriceToCommon',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'productid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'moneyprice' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pricelistid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sku' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pricelist' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'currencycode',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'purchasePrice' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'priceListId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'priceListId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sku' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'priceListId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moneypriceorg' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProductPurchasePricesQuery,
  GetProductPurchasePricesQueryVariables
>
export const GetProductsBatchItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProductsBatchItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeVariants' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'categoryIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GetProductsSort' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseBatchId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeInactive' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productSiteId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeVariants' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'includeVariants' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'search' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'categoryIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'categoryIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeInactive' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'includeInactive' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productSiteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productSiteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'WarehouseBatchItemProductFields',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'warehouseBatchItem' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'warehouseId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'warehouseId' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'warehouseBatchId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'warehouseBatchId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sku' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'warehousebatchitemid',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'decimalquantity' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'moneypricein' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarehouseBatchItemProductFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activepos' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'computedPurchasePrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimaltotal' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'binlocation' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProductsBatchItemsQuery,
  GetProductsBatchItemsQueryVariables
>
export const GetProductsStocktakingItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProductsStocktakingItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeVariants' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'categoryIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GetProductsSort' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseBatchId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeInactive' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productSiteId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeVariants' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'includeVariants' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'search' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'categoryIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'categoryIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeInactive' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'includeInactive' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productSiteId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productSiteId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'WarehouseBatchItemProductFields',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'warehouseBatchItem' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'warehouseId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'warehouseId' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'warehouseBatchId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'warehouseBatchId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sku' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'warehousebatchitemid',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'decimalquantity' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailowner' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'datemodified' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'comment' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'namefirst' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'namelast' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarehouseBatchItemProductFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activepos' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'computedPurchasePrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimaltotal' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'binlocation' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProductsStocktakingItemsQuery,
  GetProductsStocktakingItemsQueryVariables
>
export const AddProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProductInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddProductMutation, AddProductMutationVariables>
export const UpdateProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProductInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'currency' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fallbackPriceToCommon' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProductDetailsFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activepos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneyprice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datecreated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datemodified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultcategoryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productgrouptype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowdecimals' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitlabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manufacturer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricelistitems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fallbackPriceToCommon' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fallbackPriceToCommon' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pricelistid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricelistid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyprice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricelist' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencycode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricelistname' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productgroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allcategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categoryparentid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductDetailsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProductFields' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'moneypriceorg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'computedPurchasePrice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'currency' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'price_list_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'price_list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'variantparentid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionshort' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customattributes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skumanufacturer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manufacturerurl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockcountenable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'related' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accessories' } },
          { kind: 'Field', name: { kind: 'Name', value: 'similar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMainVariant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSingleProduct' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'childVariants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyprice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneypriceorg' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isMainVariant' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSingleProduct' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customattributes' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'computedPurchasePrice' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'currency' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'currency' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price_list_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price_list' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currency' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variantGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'options' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimaltotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sellable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'store' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedProducts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accessoriesProducts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'similarProducts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProductMutation,
  UpdateProductMutationVariables
>
export const UpdateProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'SingleProductsInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'currency' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fallbackPriceToCommon' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProducts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ProductDetailsFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activepos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneyprice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datecreated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'datemodified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultcategoryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productgrouptype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowdecimals' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitlabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manufacturer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricelistitems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fallbackPriceToCommon' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fallbackPriceToCommon' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pricelistid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricelistid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyprice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricelist' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencycode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricelistname' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productgroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allcategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categoryparentid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productImages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductDetailsFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ProductFields' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'moneypriceorg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'computedPurchasePrice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'currency' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'price_list_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'price_list' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'variantparentid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionshort' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customattributes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'skumanufacturer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manufacturerurl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'custom5' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockcountenable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'related' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accessories' } },
          { kind: 'Field', name: { kind: 'Name', value: 'similar' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMainVariant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSingleProduct' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'childVariants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'moneyprice' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneypriceorg' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isMainVariant' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isSingleProduct' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customattributes' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'computedPurchasePrice' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'currency' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'currency' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price_list_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price_list' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currency' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variantGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'options' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimaltotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sellable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'store' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relatedProducts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accessoriesProducts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'similarProducts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productImages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProductsMutation,
  UpdateProductsMutationVariables
>
export const UpdateProductVariantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProductVariants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'variants' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'VariantInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groups' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'VariantGroupInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deleteMainIfFails' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProductVariants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'variants' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'variants' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groups' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groups' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deleteMainIfFails' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'deleteMainIfFails' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProductVariantFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductVariantFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneypriceorg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricelisthasvolume' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activepos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barcodealiases' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descriptionshort' } },
          { kind: 'Field', name: { kind: 'Name', value: 'moneyofferprice' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProductVariantsMutation,
  UpdateProductVariantsMutationVariables
>
export const DeleteProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteProductsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteProducts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteProductsMutation,
  DeleteProductsMutationVariables
>
export const UploadProductImageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UploadProductImage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Upload' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadProductImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'file' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UploadProductImageMutation,
  UploadProductImageMutationVariables
>
export const UpdateProductImagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProductImages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'imageIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProductImages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'imageIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'imageIds' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProductImagesMutation,
  UpdateProductImagesMutationVariables
>
export const UpdatePurchasePriceListItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePurchasePriceListItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'items' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'PurchasePriceListItemInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priceListId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePurchasePriceListItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'items' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'items' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'priceListId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'priceListId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasePrice' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'priceListId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'priceListId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'priceListId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePurchasePriceListItemsMutation,
  UpdatePurchasePriceListItemsMutationVariables
>
export const DeletePurchasePriceListItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePurchasePriceListItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skus' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priceListId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePurchasePriceListItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skus' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'priceListId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'priceListId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePurchasePriceListItemsMutation,
  DeletePurchasePriceListItemsMutationVariables
>
export const GetPurchasePriceListMappingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPurchasePriceListMapping' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchasePriceListMapping' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assigned' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price_list_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stores' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPurchasePriceListMappingQuery,
  GetPurchasePriceListMappingQueryVariables
>
export const UpdatePurchasePriceListMappingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePurchasePriceListMapping' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mapping' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PurchasePriceListMappingInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePurchasePriceListMapping' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mapping' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mapping' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assigned' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price_list_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stores' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'shared' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePurchasePriceListMappingMutation,
  UpdatePurchasePriceListMappingMutationVariables
>
export const GetPurchasePriceListsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPurchasePriceLists' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'next' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchasePriceLists' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'next' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'next' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PurchasePriceList' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'next' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PurchasePriceList' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PurchasePriceList' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner_eshop_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared_store_ids' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sharedStores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownerSite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'eshopid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eshopname' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPurchasePriceListsQuery,
  GetPurchasePriceListsQueryVariables
>
export const GetPurchasePriceListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPurchasePriceList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchasePriceList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PurchasePriceList' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PurchasePriceList' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PurchasePriceList' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner_eshop_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared_store_ids' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sharedStores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownerSite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'eshopid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eshopname' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPurchasePriceListQuery,
  GetPurchasePriceListQueryVariables
>
export const AddPurchasePriceListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddPurchasePriceList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priceList' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddUpdatePurchasePriceListInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addPurchasePriceList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'priceList' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'priceList' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PurchasePriceList' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PurchasePriceList' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PurchasePriceList' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner_eshop_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared_store_ids' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sharedStores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownerSite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'eshopid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eshopname' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddPurchasePriceListMutation,
  AddPurchasePriceListMutationVariables
>
export const UpdatePurchasePriceListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePurchasePriceList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'priceList' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddUpdatePurchasePriceListInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePurchasePriceList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'priceList' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'priceList' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PurchasePriceList' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PurchasePriceList' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PurchasePriceList' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner_eshop_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shared_store_ids' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sharedStores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ownerSite' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'eshopid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eshopname' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePurchasePriceListMutation,
  UpdatePurchasePriceListMutationVariables
>
export const DeletePurchasePriceListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePurchasePriceList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePurchasePriceList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePurchasePriceListMutation,
  DeletePurchasePriceListMutationVariables
>
export const AllReasonCodesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllReasonCodes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ReasonCodeType' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allReasonCodes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reasoncode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reasoncodeid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllReasonCodesQuery, AllReasonCodesQueryVariables>
export const RewardsSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RewardsSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rewardsSetting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'api_url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'api_token' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RewardsSettingsQuery,
  RewardsSettingsQueryVariables
>
export const UpdateRewardsSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRewardsSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'apiUrl' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Url' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'apiToken' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRewardsSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'api_url' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'apiUrl' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'api_token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'apiToken' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateRewardsSettingsMutation,
  UpdateRewardsSettingsMutationVariables
>
export const AllShipmentActionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllShipmentActions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'shipmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allShipmentActions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shipmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentActionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentActionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'package_id_list' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state_reason_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'received_items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'reason_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'item_id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'serial_numbers' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse_transaction_id' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'received_items_undeclared' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'reason_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'barcode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'unit_price' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'batch' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'batch_expires' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'barcode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'serial_numbers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'serial_number' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'barcode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'additional_data' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'received' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additional_data' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity_received' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse_transaction_id' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouse_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'package' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllShipmentActionsQuery,
  AllShipmentActionsQueryVariables
>
export const AddShipmentActionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addShipmentAction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ShipmentActionInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'shipmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addShipmentAction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shipmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentActionFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentActionFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentAction' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'package_id_list' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state_reason_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'received_items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'reason_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'item_id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'serial_numbers' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse_transaction_id' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'received_items_undeclared' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'reason_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'barcode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'unit_price' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'batch' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'batch_expires' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'barcode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'serial_numbers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'serial_number' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'barcode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'additional_data' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'received' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additional_data' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity_received' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse_transaction_id' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'warehouse_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'package' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddShipmentActionMutation,
  AddShipmentActionMutationVariables
>
export const GetShipmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetShipments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'shipmentState' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ShipmentState' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseFilterMode' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'WarehouseFilterMode' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'originWarehouseId' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'destinationWarehouseId' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'next' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipmentState' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shipmentState' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'originWarehouseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'originWarehouseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'destinationWarehouseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'destinationWarehouseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseFilterMode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseFilterMode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'next' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'next' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'next' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ShipmentFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Shipment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'info' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_data' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'destination_warehouse_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'destination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address2' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zip' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country_id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mobile' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'note' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'carrier' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'service' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reference' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tracking_url' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tracking_url_title' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_pickup_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_delivery_at' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'origin_warehouse_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'origin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address2' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zip' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mobile' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'note' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reference_number' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'all_packages_arrived' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total_package_count' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total_item_quantity' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'closed_state_reason_code' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'all_items_received' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetShipmentsQuery, GetShipmentsQueryVariables>
export const GetShipmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetShipment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'shipmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shipmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentDetailFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentDetailFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Shipment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'info' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_data' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'destination_warehouse_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'destination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address2' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zip' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country_id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mobile' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'note' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'carrier' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'service' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reference' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tracking_url' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tracking_url_title' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_pickup_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_delivery_at' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'origin_warehouse_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'origin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address2' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zip' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mobile' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'note' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reference_number' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'packages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'weight' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weight' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'total_quantity_received' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'total_quantity' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'arrived_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_data' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'unit_price' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'batch' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'batch_expires' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'barcode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'serial_numbers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'serial_number' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'barcode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'additional_data' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'received' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additional_data' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity_received' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sku' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'barcode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'images' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dimensions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'height' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'length' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'all_packages_arrived' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total_package_count' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total_item_quantity' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'closed_state_reason_code' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'all_items_received' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items_undeclared' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_data' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'batch' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'batch_expires' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quantity_received' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit_price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'barcode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'serial_numbers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additional_data' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'barcode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'received' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'serial_number' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetShipmentQuery, GetShipmentQueryVariables>
export const AddShipmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddShipment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ShipmentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addShipment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentDetailFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentDetailFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Shipment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_by' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modified_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'info' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_data' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'destination_warehouse_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'destination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address2' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zip' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country_id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mobile' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'note' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'carrier' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'service' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reference' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tracking_url' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tracking_url_title' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_pickup_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'estimated_delivery_at' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'origin_warehouse_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'origin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address2' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zip' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mobile' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'note' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reference_number' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'packages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'weight' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weight' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'total_quantity_received' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'total_quantity' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'arrived_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_data' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'unit_price' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'batch' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'batch_expires' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'barcode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'serial_numbers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'serial_number' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'barcode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'additional_data' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'received' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additional_data' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity_received' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sku' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'barcode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'images' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dimensions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'height' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'length' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'all_packages_arrived' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total_package_count' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total_item_quantity' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'closed_state_reason_code' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'all_items_received' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items_undeclared' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additional_data' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'batch' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'batch_expires' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quantity_received' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit_price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'barcode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'serial_numbers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additional_data' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'barcode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'received' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'serial_number' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddShipmentMutation, AddShipmentMutationVariables>
export const DeleteShipmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteShipments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'shipmentIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteShipments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipmentIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shipmentIds' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteShipmentsMutation,
  DeleteShipmentsMutationVariables
>
export const NumTransitShipmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NumTransitShipments' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numTransitShipments' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NumTransitShipmentsQuery,
  NumTransitShipmentsQueryVariables
>
export const GetShipmentsV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetShipmentsV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'shipmentid' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'archived' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ArchivedFilterTypeV2' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'senderWarehouseid' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'receiverWarehouseid' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getShipmentsV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipmentid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shipmentid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'archived' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'senderWarehouseid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'senderWarehouseid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'receiverWarehouseid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'receiverWarehouseid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ShipmentsV2Fields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentsV2Fields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_reference' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carrier_trackingurl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'datenew' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateintransit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipmentid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipmentstate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateestimatedpickup' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateestimateddelivery' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetShipmentsV2Query, GetShipmentsV2QueryVariables>
export const GetShipmentV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetShipmentV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'shipmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getShipmentV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shipmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentV2DetailFields' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentsV2Fields' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentV2SenderContact' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentV2ReceiverContact' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentV2SenderAddress' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentV2ReceiverAddress' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentV2Packages' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2DetailFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender_warehouseid' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_warehouseid' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentsV2Fields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_reference' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carrier_trackingurl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'datenew' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateintransit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipmentid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipmentstate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateestimatedpickup' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateestimateddelivery' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2SenderContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender_referenceemail' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender_referencename' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender_referencemobile' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender_instructions' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2ReceiverContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_referenceemail' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_referencename' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_referencemobile' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_instructions' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2SenderAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sender_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_zip' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2ReceiverAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_countryid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_zip' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2Packages' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'packages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipmentpackageid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalQuantity' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalReceivedQuantity' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decimalunitquantity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moneypricein' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productname' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantityreceived' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shipmentitemid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shipmentpackageid' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'unitlabel' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sku' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'images' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'allowdecimals' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'unitlabel' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetShipmentV2Query, GetShipmentV2QueryVariables>
export const UpdateArchiveStateShipmentsV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateArchiveStateShipmentsV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'shipments' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ShipmentV2Input' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateShipmentsV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shipments' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipmentid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateArchiveStateShipmentsV2Mutation,
  UpdateArchiveStateShipmentsV2MutationVariables
>
export const UpdateShipmentV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateShipmentV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'shipment' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ShipmentV2Input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateShipmentV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shipment' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentV2DetailFields' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentsV2Fields' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentV2SenderContact' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentV2ReceiverContact' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentV2SenderAddress' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentV2ReceiverAddress' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentV2Packages' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2DetailFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender_warehouseid' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_warehouseid' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentsV2Fields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_reference' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carrier_trackingurl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'datenew' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateintransit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipmentid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipmentstate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateestimatedpickup' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateestimateddelivery' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2SenderContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender_referenceemail' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender_referencename' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender_referencemobile' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender_instructions' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2ReceiverContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_referenceemail' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_referencename' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_referencemobile' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_instructions' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2SenderAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sender_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_zip' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2ReceiverAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_countryid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_zip' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2Packages' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'packages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipmentpackageid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalQuantity' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalReceivedQuantity' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decimalunitquantity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moneypricein' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productname' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantityreceived' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shipmentitemid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shipmentpackageid' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'unitlabel' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sku' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'images' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'allowdecimals' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'unitlabel' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateShipmentV2Mutation,
  UpdateShipmentV2MutationVariables
>
export const AddShipmentV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddShipmentV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'shipment' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ShipmentV2Input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addShipmentV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shipment' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shipment' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentV2DetailFields' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentsV2Fields' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentV2SenderContact' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentV2ReceiverContact' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentV2SenderAddress' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentV2ReceiverAddress' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ShipmentV2Packages' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2DetailFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender_warehouseid' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_warehouseid' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentsV2Fields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'archived' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_reference' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carrier_trackingurl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'datenew' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateintransit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipmentid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipmentstate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateestimatedpickup' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dateestimateddelivery' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2SenderContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender_referenceemail' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender_referencename' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender_referencemobile' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender_instructions' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2ReceiverContact' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_referenceemail' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_referencename' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_referencemobile' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_instructions' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2SenderAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sender_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_countryid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sender_zip' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2ReceiverAddress' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receiver_countryid' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receiver_zip' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShipmentV2Packages' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShipmentV2' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'packages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shipmentpackageid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalQuantity' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalReceivedQuantity' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decimalunitquantity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moneypricein' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productname' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantityreceived' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shipmentitemid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shipmentpackageid' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'unitlabel' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sku' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'images' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'allowdecimals' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'unitlabel' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddShipmentV2Mutation,
  AddShipmentV2MutationVariables
>
export const AllSitesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllSites' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allSites' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SiteFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SiteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Site' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'eshopid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eshopname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eshoptype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commoneshopid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencycode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricesstoredwithvat' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allow_store_manager_campaigns' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'customattributes_in_store_replenishment',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllSitesQuery, AllSitesQueryVariables>
export const AllFranchiseSitesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllFranchiseSites' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allFranchiseSites' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SiteFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SiteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Site' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'eshopid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eshopname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eshoptype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commoneshopid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencycode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricesstoredwithvat' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allow_store_manager_campaigns' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'customattributes_in_store_replenishment',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllFranchiseSitesQuery,
  AllFranchiseSitesQueryVariables
>
export const UpdateWarehouseItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateWarehouseItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'items' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'WarehouseItemInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWarehouseItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'items' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'items' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                { kind: 'Field', name: { kind: 'Name', value: 'binlocation' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimalthreshold' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateWarehouseItemsMutation,
  UpdateWarehouseItemsMutationVariables
>
export const StoreReplenishmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StoreReplenishment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseToId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseFromId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'next' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GetReplenishmentItemsSort' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'replenishmentItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseToId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseToId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseFromId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseFromId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'next' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'next' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'next' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'StoreReplenishmentItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreReplenishmentItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StoreReplenishmentItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customattributes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'decimalTotalTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'decimalTotalFrom' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'decimalThresholdFrom' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'decimalThresholdTo' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'binLocationTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'binLocationFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'toPick' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StoreReplenishmentQuery,
  StoreReplenishmentQueryVariables
>
export const AllReplenishmentItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllReplenishmentItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseToId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseFromId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skus' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allReplenishmentItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseToId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseToId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseFromId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseFromId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skus' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StoreReplenishmentItem' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreReplenishmentItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StoreReplenishmentItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customattributes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'decimalTotalTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'decimalTotalFrom' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'decimalThresholdFrom' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'decimalThresholdTo' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'binLocationTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'binLocationFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'toPick' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllReplenishmentItemsQuery,
  AllReplenishmentItemsQueryVariables
>
export const ExportStoreReplenishmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ExportStoreReplenishment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fileType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FileType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseToId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseFromId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skus' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportAllReplenishmentItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fileType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseToId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseToId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseFromId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseFromId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skus' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExportStoreReplenishmentMutation,
  ExportStoreReplenishmentMutationVariables
>
export const AllStoreTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllStoreTags' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'allStoreTags' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllStoreTagsQuery, AllStoreTagsQueryVariables>
export const GetStoresDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStores' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetStoresQuery, GetStoresQueryVariables>
export const MeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Me' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalid' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'namefirst' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'namelast' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'staff_pos_activation' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasmfa' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'intercomHash' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'siteId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouseIds' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'storeIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'resourceUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentSite' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SiteFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sites' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SiteFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SiteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Site' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'eshopid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eshopname' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eshoptype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commoneshopid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencycode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricesstoredwithvat' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allow_store_manager_campaigns' },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'customattributes_in_store_replenishment',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeQuery, MeQueryVariables>
export const StaffUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StaffUsers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'staffUsers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
                { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StaffUsersQuery, StaffUsersQueryVariables>
export const GetUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GetUsersSort' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customernumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'personalid' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mobile' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userid' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'search' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customernumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customernumber' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'personalid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'personalid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mobile' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mobile' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tags' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tags' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'personalid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'externalid' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'namefirst' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'namelast' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'datecreated' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'datemodified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'warehouses' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasmfa' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allWarehouses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resolvedTags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customernumber' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUsersQuery, GetUsersQueryVariables>
export const GetUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'personalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'namefirst' } },
                { kind: 'Field', name: { kind: 'Name', value: 'namelast' } },
                { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                { kind: 'Field', name: { kind: 'Name', value: 'department' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customernumber' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'datecreated' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'datemodified' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'voucherpasswords' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'warehouses' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'posrefundcard' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'staff_pos_activation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'posallstores' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasmfa' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resolvedTags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allWarehouses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>
export const ExportUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ExportUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fileType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FileType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exportUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fileType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userIds' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExportUsersMutation, ExportUsersMutationVariables>
export const GetWarehouseBatchItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWarehouseBatchItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseid' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehousebatchid' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'num' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseBatchItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehousebatchid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehousebatchid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'num' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'num' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalcount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'datemodified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decimalquantity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailowner' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'moneypricein' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'WarehouseBatchItemProductFields',
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'namefirst' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'namelast' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'warehousebatchitemid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'warehouseitemid' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarehouseBatchItemProductFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Product' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activepos' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'computedPurchasePrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimaltotal' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'binlocation' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWarehouseBatchItemsQuery,
  GetWarehouseBatchItemsQueryVariables
>
export const AllOpenWarehouseBatchesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllOpenWarehouseBatches' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'transactiontype' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'WarehouseBatchTransaction' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allOpenWarehouseBatches' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'transactiontype' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'transactiontype' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailowner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'datecreated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reasoncode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipmentid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactiontype' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehousebatchid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehousebatchstate' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehousetransactionid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'store' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'namefirst' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'namelast' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllOpenWarehouseBatchesQuery,
  AllOpenWarehouseBatchesQueryVariables
>
export const GetWarehouseBatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWarehouseBatch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseBatchId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseBatchId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseBatchId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehousebatchid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactiontype' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehousebatchstate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehouse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'store' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWarehouseBatchQuery,
  GetWarehouseBatchQueryVariables
>
export const GetWarehouseBatchSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWarehouseBatchSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseBatchId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseBatchId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseBatchId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehousebatchid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'transactiontype' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehousebatchstate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'start' },
                      value: { kind: 'IntValue', value: '0' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'num' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'warehousebatchitemid',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalcount' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetWarehouseBatchSummaryQuery,
  GetWarehouseBatchSummaryQueryVariables
>
export const UpdateWarehouseBatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateWarehouseBatch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateWarehouseBatchInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseBatchId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWarehouseBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseBatchId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseBatchId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehousebatchid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehousebatchstate' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'emailowner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipmentid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reasoncode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateWarehouseBatchMutation,
  UpdateWarehouseBatchMutationVariables
>
export const AddWarehouseBatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddWarehouseBatch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addWarehouseBatchData' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddWarehouseBatchInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addWarehouseBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addWarehouseBatchData' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'warehouseid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehousebatchid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehousebatchstate' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddWarehouseBatchMutation,
  AddWarehouseBatchMutationVariables
>
export const UpdateWarehouseBatchItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateWarehouseBatchItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseBatchId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseBatchItems' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'WarehouseBatchItemInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateWarehouseBatchItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseBatchId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseBatchId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseBatchItems' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseBatchItems' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehousebatchid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehousebatchitemid' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'decimalquantity' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'moneypricein' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'emailowner' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'datemodified' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'namefirst' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'namelast' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateWarehouseBatchItemsMutation,
  UpdateWarehouseBatchItemsMutationVariables
>
export const DeleteWarehouseBatchItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteWarehouseBatchItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'warehouseBatchId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skus' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteWarehouseBatchItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'warehouseBatchId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'warehouseBatchId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'skus' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteWarehouseBatchItemsMutation,
  DeleteWarehouseBatchItemsMutationVariables
>
export const GetWarehousesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWarehouses' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allWarehouses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'warehousetype' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'storeid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sellable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWarehousesQuery, GetWarehousesQueryVariables>
export const NumNegativeStockLevelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'numNegativeStockLevels' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numNegativeStockLevels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'warehouseId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NumNegativeStockLevelsQuery,
  NumNegativeStockLevelsQueryVariables
>
