import {
  Navigate,
  Outlet,
  Route,
  createRoutesFromElements,
} from 'react-router-dom'
import { RootRoute } from '.'
import { DefaultLayout } from '../layout/default'
import { LoginLayout } from '../layout/login'
import { AuthProvider } from '../providers/auth'
import { PrintThemeProvider } from '../providers/theme/print'
import { ReceiptThemeProvider } from '../providers/theme/receipt'
import { TrackingProvider } from '../providers/tracking'
import { CampaignPage } from './campaign'
import { CampaignsPage } from './campaigns'
import { CashRegistersPage } from './cash-registers'
import { ErrorPage } from './error'
import { HomePage } from './home'
import { InfluencerCodePage } from './influencer-code'
import { InfluencerCodesPage } from './influencer-codes'
import { JournalsPage } from './journals'
import { JournalPrinter } from './journals/journal-print'
import { LoginPage } from './login'
import { ResetPasswordPage } from './reset-password'
import { LogoutPage } from './logout'
import { NotFoundPage } from './not-found'
import { OidcCallbackPage } from './oidc-callback'
import { OrderSearchPage } from './order-search'
import { OrdersPage } from './orders'
import { OrdersPrinter } from './orders/orders-print'
import { Permission } from './permission'
import { PricesPage } from './prices/prices'
import { PurchasePricesPage } from './prices/purchase-prices'
import { ProductPage } from './product'
import { ProductsPage } from './products'
import { ServiceOrderPage } from './service-order'
import { ServiceOrdersPage } from './service-orders'
import { SettingsAccessLogs } from './settings/access-logs'
import { SettingsAuthenticationPage } from './settings/authentication'
import { SettingsCustomAPIPage } from './settings/custom-api'
import { SettingsGeneralPage } from './settings/general'
import { SettingsImportDataPage } from './settings/import'
import { SettingsPriceListsPage } from './settings/price-lists'
import { SettingsProductsBrandsPage } from './settings/products/brands'
import { SettingsCustomAttributesPage } from './settings/custom-attributes'
import { SettingsProductGroupsPage } from './settings/products/product-groups'
import { SettingsPurchasePriceListsPage } from './settings/purchase-price-lists'
import { SettingsSalesTaxesPage } from './settings/sales-taxes'
import { ReasonCodesPage } from './settings/reason-codes'
import { ReasonCodeDialog } from './settings/reason-codes/reason-code-dialog'
import { SettingsStoresPage } from './settings/stores'
import { StoreDialog } from './settings/stores/store-dialog'
import { SettingsWarehousesPage } from './settings/warehouses'
import { WarehouseDialog } from './settings/warehouses/warehouse-dialog'
import { EditShipmentV2Page } from './shipment-v2/edit-shipment'
import { NewShipmentV2Page } from './shipment-v2/new-shipment'
import { EditShipmentPage } from './shipment/edit-shipment'
import { NewShipmentPage } from './shipment/new-shipment'
import { ShipmentsPage } from './shipments'
import { ShipmentsV2Page } from './shipments-v2'
import { StatisticsPage } from './statistics'
import { DeliveriesInPage } from './stock/deliveries-in'
import { DeliveriesOutPage } from './stock/deliveries-out'
import { DeliveryInPage } from './stock/delivery-in'
import { DeliveryOutPage } from './stock/delivery-out'
import { InStoreReplenishmentPage } from './stock/in-store-replenishment'
import { InStoreReplenishmentPrintPage } from './stock/in-store-replenishment/print'
import { StockLevelsPage } from './stock/stock-levels'
import { UsersPage } from './users'
import { SettingsProfile } from './settings/profile'
import { UserPage } from './user'
import { UserTagsPage } from './settings/user-tags'
import { SettingsSalesTaxPage } from './settings/sales-tax'
import { SettingsSalesTaxGroupsPage } from './settings/sales-tax-groups'
import { SettingsSalesTaxGroupPage } from './settings/sales-tax-group'
import { SettingsPosGeneralPage } from './settings/pos-settings/pos-general'
import { SettingsPosCartPage } from './settings/pos-settings/pos-cart'
import { PosSettingsRequiredFieldsPage } from './settings/pos-settings/pos-required-fields'
import { SettingsAdyenTerminalApiPage } from './settings/plugins/adyen-terminal-api'
import { SettingsAdyenTapToPayPage } from './settings/plugins/adyen-tap-to-pay'
import { SettingsCtuNorwayPage } from './settings/plugins/ctu-norway'
import { SettingsDashboardsPage } from './settings/dashboards'
import { SettingsDashboardPage } from './settings/dashboard'
import { Suspense } from 'react'
import { SettingsPaymentOptionsPage } from './settings/payment-options'
import { PaymentOptionDialog } from './settings/payment-options/payment-option-dialog'
import { PriceListDialog } from './settings/price-lists/price-list-dialog'
import { PurchasePriceDialog } from './settings/purchase-price-lists/purchase-price-dialog'
import { CustomAttributeDialog } from './settings/custom-attributes/custom-attribute-dialog'
import { StoreDashboardsPage } from './store-dashboards'
import { ProductGroupDialog } from './settings/products/product-groups/product-group-dialog/index'
import { StocktakingPage } from './stock/stocktaking'
import { StocktakingsPage } from './stock/stocktakings'
import { ProductBrandDialog } from './settings/products/brands/brand-dialog'
import { ReportPage } from './report'
import { ReportsQuickPage } from './reports/quick'
import { ReportsAllPage } from './reports/all'
import { ReportsPage } from './reports'

export const routes = createRoutesFromElements(
  <Route element={<TrackingProvider />} errorElement={<ErrorPage />}>
    <Route path={RootRoute.Callback} element={<OidcCallbackPage />} />

    <Route path="/" element={<Navigate to={RootRoute.Login} />} />

    <Route path={RootRoute.Logout} element={<LogoutPage />} />

    <Route element={<AuthProvider allowAnonymous />}>
      <Route element={<LoginLayout />}>
        <Route path={RootRoute.Login} element={<LoginPage />} />
      </Route>
      <Route element={<LoginLayout />}>
        <Route path={RootRoute.ResetPassword} element={<ResetPasswordPage />} />
      </Route>
    </Route>

    <Route element={<AuthProvider />}>
      {/* Print Layout */}
      <Route
        path={RootRoute.OrdersPrint}
        element={
          <Permission isAllowed="orders">
            <PrintThemeProvider>
              <OrdersPrinter />
            </PrintThemeProvider>
          </Permission>
        }
      />

      <Route
        path={RootRoute.CashRegistersPrint}
        element={
          <Permission isAllowed="cashRegisters">
            <ReceiptThemeProvider>
              <JournalPrinter />
            </ReceiptThemeProvider>
          </Permission>
        }
      />

      <Route
        path={RootRoute.InStoreReplenishmentPrint}
        element={
          <Permission isAllowed="inStoreReplenishment">
            <PrintThemeProvider>
              <InStoreReplenishmentPrintPage />
            </PrintThemeProvider>
          </Permission>
        }
      />

      {/* Empty Layout */}
      <Route
        path={RootRoute.SettingsPosDashboard}
        element={
          <Suspense>
            <SettingsDashboardPage />
          </Suspense>
        }
      />

      {/* Default Layout */}
      <Route path={RootRoute.Root} element={<DefaultLayout />}>
        <Route path="*" element={<NotFoundPage />} />

        <Route
          index
          element={
            <Navigate to={RootRoute.Home.replace(`${RootRoute.Root}/`, '')} />
          }
        />

        <Route path={RootRoute.Home} element={<HomePage />} />

        <Route
          path={RootRoute.Orders}
          element={
            <Permission isAllowed="orders">
              <OrdersPage />
            </Permission>
          }
        />

        <Route
          path={RootRoute.OrderSearch}
          element={
            <Permission isAllowed="orderLookup">
              <OrderSearchPage />
            </Permission>
          }
        />

        <Route
          path={RootRoute.Journals}
          element={
            <Permission isAllowed="journals">
              <JournalsPage />
            </Permission>
          }
        />
        <Route
          path={RootRoute.StoreDashboards}
          element={
            <Permission isAllowed="storeDashboards">
              <StoreDashboardsPage />
            </Permission>
          }
        />

        <Route path={RootRoute.Shipments}>
          <Route
            index
            element={
              <Permission
                isAllowed={(modules) =>
                  modules.shipments || modules.shipmentsV2
                }
              >
                <Permission isAllowed="shipments" silent>
                  <ShipmentsPage />
                </Permission>
                <Permission isAllowed={(modules) => !modules.shipments} silent>
                  <ShipmentsV2Page />
                </Permission>
              </Permission>
            }
          />
          <Route
            path={RootRoute.Shipment}
            element={
              <Permission
                isAllowed={(modules) =>
                  modules.shipments || modules.shipmentsV2
                }
              >
                <Permission isAllowed="shipments" silent>
                  <EditShipmentPage />
                </Permission>
                <Permission isAllowed={(modules) => !modules.shipments} silent>
                  <EditShipmentV2Page />
                </Permission>
              </Permission>
            }
          />
          <Route
            path={RootRoute.ShipmentNew}
            element={
              <Permission
                isAllowed={(modules) =>
                  modules.writeShipments || modules.writeShipmentsV2
                }
              >
                <Permission isAllowed="writeShipments" silent>
                  <NewShipmentPage />
                </Permission>
                <Permission
                  isAllowed={(modules) => !modules.writeShipments}
                  silent
                >
                  <NewShipmentV2Page />
                </Permission>
              </Permission>
            }
          />
        </Route>

        <Route path={RootRoute.ServiceOrders}>
          <Route
            index
            element={
              <Permission isAllowed="serviceOrders">
                <ServiceOrdersPage />
              </Permission>
            }
          />
          <Route
            path={RootRoute.ServiceOrderNew}
            element={
              <Permission isAllowed="serviceOrders">
                <ServiceOrderPage />
              </Permission>
            }
          />
          <Route
            path={RootRoute.ServiceOrder}
            element={
              <Permission isAllowed="serviceOrders">
                <ServiceOrderPage />
              </Permission>
            }
          />
        </Route>

        <Route
          path={RootRoute.CashRegisters}
          element={
            <Permission isAllowed="cashRegisters">
              <CashRegistersPage />
            </Permission>
          }
        />

        <Route
          path={RootRoute.Products}
          element={
            <Permission isAllowed="products">
              <Outlet />
            </Permission>
          }
        >
          <Route index element={<ProductsPage />} />
          <Route
            path={RootRoute.Product}
            element={
              <Permission isAllowed="writeProducts">
                <ProductPage />
              </Permission>
            }
          />
          <Route
            path={RootRoute.ProductNew}
            element={
              <Permission isAllowed="writeProducts">
                <ProductPage />
              </Permission>
            }
          />
        </Route>

        <Route
          path={RootRoute.Prices}
          element={
            <Permission isAllowed="prices">
              <PricesPage />
            </Permission>
          }
        />

        <Route
          path={RootRoute.PurchasePrices}
          element={
            <Permission isAllowed="purchasePrices">
              <PurchasePricesPage />
            </Permission>
          }
        />

        <Route
          path={RootRoute.StockLevels}
          element={
            <Permission isAllowed="stock">
              <StockLevelsPage />
            </Permission>
          }
        />

        <Route
          path={RootRoute.DeliveriesIn}
          element={
            <Permission isAllowed="deliveryIn">
              <Outlet />
            </Permission>
          }
        >
          <Route index element={<DeliveriesInPage />} />
          <Route path={RootRoute.DeliveryIn} element={<DeliveryInPage />} />
        </Route>

        <Route
          path={RootRoute.DeliveriesOut}
          element={
            <Permission isAllowed="deliveryOut">
              <Outlet />
            </Permission>
          }
        >
          <Route index element={<DeliveriesOutPage />} />
          <Route path={RootRoute.DeliveryOut} element={<DeliveryOutPage />} />
        </Route>

        <Route
          path={RootRoute.Stocktakings}
          element={
            <Permission isAllowed="stocktaking">
              <Outlet />
            </Permission>
          }
        >
          <Route index element={<StocktakingsPage />} />

          <Route path={RootRoute.Stocktaking} element={<StocktakingPage />} />
        </Route>

        <Route
          path={RootRoute.InStoreReplenishment}
          element={
            <Permission isAllowed="inStoreReplenishment">
              <InStoreReplenishmentPage />
            </Permission>
          }
        />

        <Route
          path={RootRoute.Campaigns}
          element={
            <Permission isAllowed="campaigns">
              <Outlet />
            </Permission>
          }
        >
          <Route index element={<CampaignsPage />} />
          <Route path={RootRoute.Campaign} element={<CampaignPage />} />
          <Route path={RootRoute.CampaignNew} element={<CampaignPage />} />
        </Route>

        <Route
          path={RootRoute.InfluencerCodes}
          element={<Permission isAllowed="influencerCodes" />}
        >
          <Route index element={<InfluencerCodesPage />} />

          <Route
            path={RootRoute.InfluencerCode}
            element={<InfluencerCodePage />}
          />

          <Route
            path={RootRoute.InfluencerCodeNew}
            element={<InfluencerCodePage />}
          />
        </Route>

        <Route
          path={RootRoute.Statistics}
          element={
            <Permission isAllowed="statistics">
              <StatisticsPage />
            </Permission>
          }
        />

        <Route
          path={RootRoute.Users}
          element={
            <Permission isAllowed="users">
              <UsersPage />
            </Permission>
          }
        />

        <Route
          path={RootRoute.UserNew}
          element={
            <Permission isAllowed="users">
              <UserPage />
            </Permission>
          }
        />
        <Route
          path={RootRoute.User}
          element={
            <Permission isAllowed="users">
              <UserPage />
            </Permission>
          }
        />

        <Route
          path={RootRoute.Reports}
          element={
            <Permission isAllowed="reports">
              <ReportsPage />
            </Permission>
          }
        >
          <Route index element={<ReportsQuickPage />} />
          <Route path={RootRoute.ReportsAll} element={<ReportsAllPage />} />
        </Route>

        <Route
          path={RootRoute.Report}
          element={
            <Permission isAllowed="reports">
              <ReportPage />
            </Permission>
          }
        />

        <Route path={RootRoute.Settings}>
          <Route
            index
            element={
              <Navigate
                to={RootRoute.SettingsGeneral.replace(
                  `${RootRoute.Settings}/`,
                  '',
                )}
              />
            }
          />

          <Route
            path={RootRoute.SettingsGeneral}
            element={<SettingsGeneralPage />}
          />

          <Route
            path={RootRoute.SettingsPosGeneral}
            element={<SettingsPosGeneralPage />}
          />

          <Route
            path={RootRoute.SettingsPosDashboards}
            element={<SettingsDashboardsPage />}
          />

          <Route
            path={RootRoute.SettingsPaymentOptions}
            element={<SettingsPaymentOptionsPage />}
          >
            <Route
              path={RootRoute.SettingsPaymentOption}
              element={<PaymentOptionDialog />}
            />
            <Route
              path={RootRoute.SettingsPaymentOptionNew}
              element={<PaymentOptionDialog />}
            />
          </Route>

          <Route
            path={RootRoute.SettingsPosCart}
            element={<SettingsPosCartPage />}
          />

          <Route
            path={RootRoute.SettingsPosRequiredFields}
            element={<PosSettingsRequiredFieldsPage />}
          />

          <Route
            path={RootRoute.SettingsAdyenTerminalApi}
            element={<SettingsAdyenTerminalApiPage />}
          />

          <Route
            path={RootRoute.SettingsAdyenTapToPay}
            element={<SettingsAdyenTapToPayPage />}
          />

          <Route
            path={RootRoute.SettingsCtuNorway}
            element={<SettingsCtuNorwayPage />}
          />

          <Route
            path={RootRoute.SettingsProfile}
            element={<SettingsProfile />}
          />

          <Route
            path={RootRoute.SettingsAuthentication}
            element={
              <Permission isAllowed="settingsAuthentication">
                <SettingsAuthenticationPage />
              </Permission>
            }
          />

          <Route path={RootRoute.SettingsProductsBase}>
            <Route
              path={RootRoute.SettingsProductsGroups}
              element={
                <Permission isAllowed="settingsProductGroups">
                  <SettingsProductGroupsPage />
                </Permission>
              }
            >
              <Route
                path={RootRoute.SettingsProductsGroup}
                element={<ProductGroupDialog />}
              />
              <Route
                path={RootRoute.SettingsProductsGroupsNew}
                element={<ProductGroupDialog />}
              />
            </Route>

            <Route
              path={RootRoute.SettingsProductsBrands}
              element={
                <Permission isAllowed="settingsBrands">
                  <SettingsProductsBrandsPage />
                </Permission>
              }
            >
              <Route
                path={RootRoute.SettingsProductsBrand}
                element={<ProductBrandDialog />}
              />
              <Route
                path={RootRoute.SettingsProductsBrandsNew}
                element={<ProductBrandDialog />}
              />
            </Route>

            <Route
              path={RootRoute.SettingsCustomAttributes}
              element={
                <Permission isAllowed="settingsCustomAttributes">
                  <SettingsCustomAttributesPage />
                </Permission>
              }
            >
              <Route
                path={RootRoute.SettingsCustomAttribute}
                element={<CustomAttributeDialog />}
              />
              <Route
                path={RootRoute.SettingsCustomAttributeNew}
                element={<CustomAttributeDialog />}
              />
            </Route>

            {/* 
            Disabled until we can refactor it properly
            
            <Route
              path={RootRoute.SettingsProductsCategories}
              element={
                <Permission isAllowed="settingsCategoriesNavigation">
                  <SettingsProductsCategoriesPage />
                </Permission>
              }
            /> */}
          </Route>

          <Route
            path={RootRoute.SettingsImportData}
            element={
              <Permission
                isAllowed={(modules) =>
                  modules.settingsImportPrices || modules.settingsImportUsers
                }
              >
                <SettingsImportDataPage />
              </Permission>
            }
          />

          <Route
            path={RootRoute.SettingsAccessLogs}
            element={
              <Permission isAllowed={(modules) => modules.settingsAccessLogs}>
                <SettingsAccessLogs />
              </Permission>
            }
          />

          <Route
            path={RootRoute.SettingsCustomAPI}
            element={
              <Permission isAllowed="settingsRewards">
                <SettingsCustomAPIPage />
              </Permission>
            }
          />

          <Route
            path={RootRoute.SettingsStores}
            element={
              <Permission isAllowed={(modules) => modules.settingsStores}>
                <SettingsStoresPage />
              </Permission>
            }
          >
            <Route path={RootRoute.SettingsStore} element={<StoreDialog />} />
            <Route
              path={RootRoute.SettingsStoreNew}
              element={<StoreDialog />}
            />
          </Route>
          <Route
            path={RootRoute.SettingsUserTags}
            element={
              <Permission isAllowed={'users'}>
                <UserTagsPage />
              </Permission>
            }
          />

          <Route
            path={RootRoute.SettingsWarehouses}
            element={
              <Permission isAllowed={(modules) => modules.settingsWarehouses}>
                <SettingsWarehousesPage />
              </Permission>
            }
          >
            <Route
              path={RootRoute.SettingsWarehouse}
              element={<WarehouseDialog />}
            />
            <Route
              path={RootRoute.SettingsWarehouseNew}
              element={<WarehouseDialog />}
            />
          </Route>

          <Route
            path={RootRoute.SettingsPriceLists}
            element={
              <Permission isAllowed={(modules) => modules.settingsPriceList}>
                <SettingsPriceListsPage />
              </Permission>
            }
          >
            <Route
              path={RootRoute.SettingsPriceList}
              element={<PriceListDialog />}
            />
            <Route
              path={RootRoute.SettingsPriceListNew}
              element={<PriceListDialog />}
            />
          </Route>

          <Route
            path={RootRoute.SettingsPurchasePriceLists}
            element={
              <Permission
                isAllowed={(modules) => modules.settingsPurchasePriceList}
              >
                <SettingsPurchasePriceListsPage />
              </Permission>
            }
          >
            <Route
              path={RootRoute.SettingsPurchasePriceList}
              element={<PurchasePriceDialog />}
            />
            <Route
              path={RootRoute.SettingsPurchasePriceListNew}
              element={<PurchasePriceDialog />}
            />
          </Route>

          <Route
            path={RootRoute.SettingsReasonCodes}
            element={
              <Permission isAllowed="settingsReasonCodes">
                <ReasonCodesPage />
              </Permission>
            }
          >
            <Route
              path={RootRoute.SettingsReasonCode}
              element={<ReasonCodeDialog />}
            />
            <Route
              path={RootRoute.SettingsReasonCodeNew}
              element={<ReasonCodeDialog />}
            />
          </Route>

          <Route
            path={RootRoute.SettingsSalesTaxes}
            element={
              <Permission isAllowed="settingsSalesTaxes">
                <SettingsSalesTaxesPage />
              </Permission>
            }
          />
          <Route
            path={RootRoute.SettingsSalesTaxNew}
            element={
              <Permission isAllowed="settingsSalesTaxes">
                <SettingsSalesTaxPage />
              </Permission>
            }
          />
          <Route
            path={RootRoute.SettingsSalesTax}
            element={
              <Permission isAllowed="settingsSalesTaxes">
                <SettingsSalesTaxPage />
              </Permission>
            }
          />

          <Route
            path={RootRoute.SettingsSalesTaxGroups}
            element={
              <Permission isAllowed="settingsSalesTaxes">
                <SettingsSalesTaxGroupsPage />
              </Permission>
            }
          />

          <Route
            path={RootRoute.SettingsSalesTaxGroupNew}
            element={
              <Permission isAllowed="settingsSalesTaxes">
                <SettingsSalesTaxGroupPage />
              </Permission>
            }
          />
          <Route
            path={RootRoute.SettingsSalesTaxGroup}
            element={
              <Permission isAllowed="settingsSalesTaxes">
                <SettingsSalesTaxGroupPage />
              </Permission>
            }
          />
        </Route>
      </Route>
    </Route>
  </Route>,
)
