import { t } from 'i18next'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDayJs } from '../day-js'
import { useMe } from '../me'

export const useLocalizedDate = () => {
  const dayJs = useDayJs()
  const { i18n } = useTranslation()
  const { timeZone } = useMe()

  const defaultFormatDate = useCallback(
    (options?: Intl.DateTimeFormatOptions) => {
      const locale = i18n.language === 'en' ? 'en-UK' : i18n.language

      const defaultOptions: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      }

      return Intl.DateTimeFormat(locale, {
        timeZone,
        ...(options || defaultOptions),
      })
    },
    [i18n.language, timeZone],
  )

  const getDateFormatPart = (x: Intl.DateTimeFormatPart) => {
    switch (x.type) {
      case 'day':
        return 'DD'
      case 'month':
        return 'MM'
      case 'year':
        return 'YYYY'
      case 'hour':
        return 'HH'
      case 'minute':
        return 'mm'
      case 'second':
        return 'ss'

      default:
        return x.value
    }
  }

  const getDateFormat = () => {
    return defaultFormatDate({
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour12: false,
    })
      .formatToParts(new Date())
      .filter((x) => ['day', 'literal', 'month', 'year'].includes(x.type))
      .map(getDateFormatPart)
      .join('')
      .replace(',', '')
  }

  const getDateTimeFormat = (opt?: { seconds?: boolean }) => {
    return defaultFormatDate({
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: opt?.seconds ? '2-digit' : undefined,
      hour12: false,
    })
      .formatToParts(new Date())
      .filter((x) =>
        [
          'day',
          'literal',
          'month',
          'year',
          'hour',
          'minute',
          'second',
        ].includes(x.type),
      )
      .map(getDateFormatPart)
      .join('')
      .replace(',', '')
  }

  const formatDate = useCallback(
    (date: Date | string, options?: Intl.DateTimeFormatOptions) => {
      const _date = new Date(date)
      return defaultFormatDate(options).format(_date).replace(',', '')
    },
    [defaultFormatDate],
  )

  const formatDateToParts = useCallback(
    (date: Date, options?: Intl.DateTimeFormatOptions) => {
      return defaultFormatDate(options).formatToParts(date)
    },
    [defaultFormatDate],
  )

  const formatRelativeDate = useCallback(
    (
      date: Date | string,
      options?: { showTime?: boolean; showSeconds?: boolean },
    ) => {
      const _date = new Date(date)

      const time = formatDate(_date, {
        hour: '2-digit',
        minute: '2-digit',
        second: options?.showSeconds ? '2-digit' : undefined,
      })

      const day = formatDate(_date, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })

      return dayJs(date).isToday() || dayJs(date).isYesterday()
        ? `${
            dayJs(date).isToday()
              ? t('shared:date.today')
              : t('shared:date.yesterday')
          }${options?.showTime === false ? '' : `, ${time}`}`
        : `${day}${options?.showTime ? `, ${time}` : ''}`
    },
    [dayJs, formatDate],
  )

  return {
    defaultFormatDate,
    getDateFormat,
    getDateTimeFormat,
    formatRelativeDate,
    formatDate,
    formatDateToParts,
  }
}
