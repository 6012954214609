export const tryParseEnum = <T>(
  enumObject: T,
  key: string,
): T[keyof T] | undefined => {
  return enumObject[key as keyof T]
}

export const tryParseEnumList = <T>(
  enumObject: T,
  keys: string[],
): T[keyof T][] => {
  return keys
    .map((key) => tryParseEnum(enumObject, key))
    .filter((key): key is T[keyof T] => key !== undefined)
}
