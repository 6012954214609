import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../components/data-grid/context'
import { InventoryItemBatchState } from '../generated/graphql'
import { useFormFilter } from '../hooks/form-filter'
import { SelectInput } from '../inputs/select-input'

export type WarehouseItemStateFilter = {
  inventoryState?: InventoryItemBatchState
}

type Props = {
  type: 'stocktaking' | 'manual-in' | 'manual-out'
}

export const WarehouseItemStateFilter = (props: Props) => {
  const { type } = props
  const { t } = useTranslation('filter')

  const filterKey = 'inventoryState'

  const { setFilter, subscribeOnResetFilter, registerFilter } =
    useContext<FilterContext<WarehouseItemStateFilter>>(FilterContext)

  const options = useMemo(() => {
    const options = [
      {
        name: t('warehouse_item_state.AllStatus'),
        value: InventoryItemBatchState.AllStatus,
      },
    ]

    if (type === 'stocktaking') {
      options.push(
        ...[
          {
            name: t('warehouse_item_state.NotCounted'),
            value: InventoryItemBatchState.NotCounted,
          },
          {
            name: t('warehouse_item_state.CountedOk'),
            value: InventoryItemBatchState.CountedOk,
          },
          {
            name: t('warehouse_item_state.CountedDiff'),
            value: InventoryItemBatchState.CountedDiff,
          },
          {
            name: t('warehouse_item_state.CountedModified'),
            value: InventoryItemBatchState.CountedModified,
          },
          {
            name: t('warehouse_item_state.DiffAndModified'),
            value: InventoryItemBatchState.DiffAndModified,
          },
          {
            name: t('warehouse_item_state.AllCounted'),
            value: InventoryItemBatchState.AllCounted,
          },
        ],
      )
    }

    if (type === 'manual-in') {
      options.push(
        ...[
          {
            name: t('warehouse_item_state.ManualIn'),
            value: InventoryItemBatchState.QuantityAdded,
          },
          {
            name: t('warehouse_item_state.NotIncluded'),
            value: InventoryItemBatchState.QuantityNotAdded,
          },
        ],
      )
    }

    if (type === 'manual-out') {
      options.push(
        ...[
          {
            name: t('warehouse_item_state.ManualOut'),
            value: InventoryItemBatchState.QuantityAdded,
          },
          {
            name: t('warehouse_item_state.NotIncluded'),
            value: InventoryItemBatchState.QuantityNotAdded,
          },
        ],
      )
    }

    return options
  }, [t, type])

  const { queryParams, formContext, resetForm } =
    useFormFilter<WarehouseItemStateFilter>({
      formProps: {
        defaultValues: { inventoryState: InventoryItemBatchState.AllStatus },
      },
      parseOptions: {
        types: {
          state: (value) =>
            (Object.values(InventoryItemBatchState) as string[]).includes(value)
              ? value
              : undefined,
        },
      },
    })

  const { inventoryState } = queryParams

  useEffect(() => {
    registerFilter({ key: filterKey })
  }, [registerFilter])

  useEffect(() => {
    if (inventoryState?.length) {
      setFilter(filterKey, {
        label: t('warehouse_item_state.label'),
        value: inventoryState,
        labelValues: [t(`warehouse_item_state.${inventoryState}`)],
        isDefault: inventoryState === InventoryItemBatchState.AllStatus,
      })
    } else {
      setFilter(filterKey, {
        label: t('warehouse_item_state.label'),
        value: InventoryItemBatchState.AllStatus,
        labelValues: [t(`warehouse_item_state.AllStatus`)],
        isDefault: true,
      })
    }
  }, [options, inventoryState, setFilter, t])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        resetForm()
      }
    })

    return () => unsubscribe()
  }, [resetForm, subscribeOnResetFilter])

  return (
    <SelectInput
      name="inventoryState"
      control={formContext.control}
      options={options}
      label={t('warehouse_item_state.label')}
    />
  )
}
