import { useQuery } from '@apollo/client'
import {
  GetWarehouseBatchDocument,
  WarehouseBatchTransaction,
  WarehouseBatchState,
} from '../../generated/graphql'
import { useNavigate } from 'react-router-dom'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { useEffect } from 'react'

type Props = {
  warehouseId: number
  warehouseBatchId: number
  transactionType: WarehouseBatchTransaction
  listRoute: string
}

export const useWarehouseBatch = (props: Props) => {
  const { warehouseId, warehouseBatchId, transactionType, listRoute } = props

  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const { data, loading } = useQuery(GetWarehouseBatchDocument, {
    variables: { warehouseBatchId, warehouseId },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!data) return

    const isMatchingTransaction =
      data.warehouseBatch?.transactiontype === transactionType

    const isOpen =
      data.warehouseBatch?.warehousebatchstate === WarehouseBatchState.Open

    if (!data.warehouseBatch || !isMatchingTransaction || !isOpen) {
      return void navigate(generatePath(listRoute))
    }
  }, [data, generatePath, listRoute, navigate, transactionType])

  return { data, loading }
}
