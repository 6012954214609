import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Chip,
  SxProps,
  Box,
} from '@mui/material'
import { ChevronSmallUpIcon, ChevronSmallDownIcon } from '@sitoo/mui-components'
import { useState, useCallback, useEffect } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { SidebarLink } from '../sidebar-list-item-link'
import { useIsSettings } from '../../hooks/is-settings/index.ts'

export type MenuItem = {
  name: string
  to?: string
  active?: boolean
  icon?: React.ReactNode
  counter?: number
  items?: MenuItem[]
  sx?: SxProps
  keepSingleChildNested?: boolean
}

export const SidebarListItem = (props: MenuItem) => {
  const { name, icon, to, counter, items = [], sx } = props
  const { pathname } = useLocation()
  const [isOpenOrSelected, setIsOpenOrSelected] = useState(false)
  const isMainItem = !!items?.length
  const generatePath = useAbsolutePath()
  const isSettings = useIsSettings()

  const matches = useCallback(
    (to: string | undefined) => {
      if (to) {
        return !!matchPath({ path: to, end: false }, pathname)
      }
      return false
    },
    [pathname],
  )

  useEffect(() => {
    const hasSelectedItems = items?.some(({ to }) => matches(to)) || matches(to)

    setIsOpenOrSelected(hasSelectedItems)
  }, [items, matches, pathname, to])

  if (isMainItem && items.length === 0) return null

  return (
    <Box sx={sx} data-testid="sidebar">
      <ListItemButton
        component={!isMainItem && to ? SidebarLink : 'div'}
        to={to ? generatePath(to) : undefined}
        variant="sidebar"
        selected={isMainItem ? false : isOpenOrSelected}
        onClick={
          isMainItem ? () => setIsOpenOrSelected((state) => !state) : undefined
        }
        sx={{ pl: 3 }}
      >
        {icon && (
          <ListItemIcon sx={{ color: 'inherit', minWidth: 'auto', mr: 2 }}>
            {icon}
          </ListItemIcon>
        )}
        <ListItemText
          primary={name}
          primaryTypographyProps={{ variant: 'body02' }}
          sx={{
            marginLeft: (theme) => theme.spacing(icon ? 0 : 4.5),
          }}
        />
        {Number.isFinite(counter) && (
          <Chip
            color="blue"
            size="small"
            label={counter}
            sx={{ border: (theme) => `1px solid ${theme.palette.darkMode60}` }}
          />
        )}
        {items.length > 0 && (
          <ListItemIcon sx={{ color: 'inherit' }}>
            {isOpenOrSelected ? (
              <ChevronSmallUpIcon />
            ) : (
              <ChevronSmallDownIcon />
            )}
          </ListItemIcon>
        )}
      </ListItemButton>
      <Box sx={{ display: isOpenOrSelected ? 'initial' : 'none' }}>
        {items.map((child) => (
          <SidebarListItem
            key={child.name}
            {...child}
            sx={{
              bgcolor: isSettings ? 'gray10' : 'darkMode70',
            }}
          />
        ))}
      </Box>
    </Box>
  )
}
