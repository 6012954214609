import { useMutation, useQuery } from '@apollo/client'
import { Button } from '@mui/material'
import { Alert, AlertTitle, Divider, Container } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { FormProvider, SubmitErrorHandler, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '..'
import { PageHeader } from '../../components/page-header'
import {
  AddUserDocument,
  GetUserDocument,
  UpdateUserDocument,
  UserPermissionsByRoleDocument,
} from '../../generated/graphql'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { useServerValidation } from '../../hooks/server-validation'
import { useTracking } from '../../hooks/tracking'
import { getErrorMessage } from '../../utils/error-mapping'
import { userViewPanelVar } from '../users'
import { ContactField } from './fields/contact-field'
import { DetailsField } from './fields/details-field'
import { LoginField } from './fields/login-field'
import { RolesField } from './fields/roles-field'
import { BaseUserContext } from './shared'

import { useEffect, useState } from 'react'
import { useMe } from '../../hooks/me'
import { DeleteUserField } from './fields/delete-user-field'
import { TagsField } from './fields/tags-field'
import { TimeZoneField } from './fields/timezone-field'
import { formatUserPayload } from './format-user-payload'
import { FormRouteLeavingGuard } from '../../components/form-route-leaving-guard'

export const UserPage = () => {
  const { id: userId } = useParams()
  const { t } = useTranslation(['shared', 'users'])
  const generatePath = useAbsolutePath()
  const { trackButtonClick, trackFormError, trackFormSuccess } = useTracking()
  const { me } = useMe()

  const defaultValues: BaseUserContext = {
    active: true,
    address: '',
    address2: '',
    city: '',
    company: '',
    companyid: '',
    countryid: undefined,
    customernumber: '',
    department: '',
    email: '',
    externalid: '',
    mobile: '',
    namefirst: '',
    namelast: '',
    notes: '',
    personalid: '',
    phone: '',
    role: null,
    state: '',
    tags: undefined,
    timezone: undefined,
    voucherpasswords: undefined,
    zip: '',
  }
  const formContext = useForm<BaseUserContext>({
    defaultValues,
  })
  const navigate = useNavigate()

  const [addUserMutation, { loading: isAddingUser }] =
    useMutation(AddUserDocument)

  const [updateUserMutation, { loading: isUpdatingUser }] =
    useMutation(UpdateUserDocument)

  const role = formContext.watch('role')
  const isNewUser = !userId

  const [isReady, setReady] = useState(isNewUser)

  const { data: userData, loading: isUserLoading } = useQuery(GetUserDocument, {
    variables: { userId: String(userId) },
    skip: !userId,
  })

  const { data: permissionData, loading: isPermissionsLoading } = useQuery(
    UserPermissionsByRoleDocument,
    { variables: { targetUserRole: role || null }, skip: !isReady },
  )

  const permissions = permissionData?.userPermissionsByRole

  const setFormError = useServerValidation<BaseUserContext>(
    'users',
    formContext.setError,
    { resolveFieldFromProperty: (property) => property },
  )
  const onSubmit = async (submittedData: BaseUserContext) => {
    try {
      let userIdToOpen = String(userId)
      if (!isNewUser) {
        // This prevents sending an empty string if the field is left unedited which would reset the pin.
        if (!formContext.getFieldState('pin').isDirty) {
          delete submittedData.pin
        }

        await updateUserMutation({
          variables: {
            data: { ...formatUserPayload(submittedData), userid: userId },
          },
        })
        trackButtonClick({ name: 'user-save' })
      } else {
        const { data } = await addUserMutation({
          variables: { data: formatUserPayload(submittedData) },
        })
        userIdToOpen = String(data?.addUser.id)
        trackButtonClick({ name: 'user-add' })
      }

      trackFormSuccess({ name: 'user' })
      userViewPanelVar({
        isOpen: true,
        userId: userIdToOpen,
      })
      enqueueSnackbar(
        t(
          isNewUser
            ? 'users:user_message.success_add'
            : 'users:user_message.success_update',
          { count: 1 },
        ),
      )
      void navigate(generatePath(RootRoute.Users))
    } catch (error) {
      setFormError(error)
      const errorMessage = getErrorMessage(
        error,
        'users',
        t(
          isNewUser
            ? 'users:user_message.failure_add'
            : 'users:user_message.failure_update',
          { count: 1 },
        ),
      )

      trackFormError({
        name: 'users',
        errorMessage,
      })

      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  const onError: SubmitErrorHandler<BaseUserContext> = (_errors) => {
    enqueueSnackbar(t('users:error.generic'), {
      variant: 'error',
    })
  }

  useEffect(() => {
    if (userData) {
      formContext.reset(
        { ...userData.user, tags: userData.user.resolvedTags || [] },
        { keepDefaultValues: false },
      )

      setReady(true)
    }
  }, [userData, userData?.user.resolvedTags, formContext])

  const isLoading =
    isAddingUser ||
    isUpdatingUser ||
    isUserLoading ||
    isPermissionsLoading ||
    !isReady

  return (
    <>
      <PageHeader
        title={t(isNewUser ? 'users:add_user' : 'users:edit_user')}
        backTo={generatePath(RootRoute.Users)}
        backText={t('users:page_title')}
        showBackButton={true}
        isSticky={true}
        rightColumn={
          <Button
            loading={isLoading}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={formContext.handleSubmit(onSubmit, onError)}
            data-testid="submit-user-form-button"
          >
            {t('shared:action.save')}
          </Button>
        }
      />
      {!isLoading && !permissionData?.userPermissionsByRole.editUser && (
        <Container>
          <Alert
            color="error"
            data-testid="no-permissions-alert"
            severity="error"
          >
            <AlertTitle>{t('users:error.no_permissions_title')}</AlertTitle>
            <Trans
              i18nKey="users:error.no_permissions_description"
              values={{
                role: t(`shared:roles.${me?.role.toLowerCase()}`),
              }}
            />
          </Alert>
        </Container>
      )}

      <Container data-testid="user-page">
        <FormProvider {...formContext}>
          <FormRouteLeavingGuard />
          <LoginField
            email={userData?.user.email || ''}
            permissions={permissions}
            isNewUser={isNewUser}
            hasMfa={userData?.user.hasmfa}
            userId={userId}
          />

          <Divider />
          <RolesField permissions={permissions} />

          <Divider />
          <ContactField permissions={permissions} />

          <Divider />
          <DetailsField permissions={permissions} />

          <Divider />
          <TagsField permissions={permissions} />

          <Divider />
          <TimeZoneField />

          <DeleteUserField userId={userId} permissions={permissions} />
        </FormProvider>
      </Container>
    </>
  )
}
