import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import {
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Button,
  TextField,
  List,
  ListItemText,
  ListItem,
  Divider,
  Chip,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { SectionHeader } from '@sitoo/mui-components'
import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { SearchProductField } from '../../../components/search-product-field'
import {
  ActionType,
  AllShipmentActionsDocument,
  GetShipmentDocument,
  GetShipmentQuery,
  ReasonCodeType,
  AddShipmentActionDocument,
  AllReasonCodesDocument,
  GetWarehousesDocument,
} from '../../../generated/graphql'
import { useMe } from '../../../hooks/me'
import { useTracking } from '../../../hooks/tracking'
import { getErrorMessage } from '../../../utils/error-mapping'
import { ArrayElement } from '../../../utils/types'
import { UndeclaredItems } from './undeclared-items'

export type UndeclaredItem = Omit<
  ArrayElement<GetShipmentQuery['shipment']['items_undeclared']>,
  'id' | 'quantity_received'
> & {
  id?: string
  quantity_received?: number
}

type UndeclaredItems = {
  items: UndeclaredItem[]
  reasonCode: string
  warehouseId?: number
  note?: string
}

export type UndeclaredItemsDialogProps = {
  open: boolean
  shipmentVersion: number
  shipmentId: string
  onClose(): void
  onSuccess?(): void
  onError?(errorMessage: string): void
  dataTestid?: string
}

type Form = UndeclaredItems

export const SetUndeclaredItemsDataDialog = (
  props: UndeclaredItemsDialogProps,
) => {
  const { t } = useTranslation(['shipments', 'shared'])
  const dialogName = 'set-undeclared-items-data'
  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const { displayName } = useMe()

  const { data } = useQuery(GetWarehousesDocument)
  const warehouses = data?.allWarehouses

  const prevOpen = usePrevious(props.open)
  const [addShipmentAction] = useMutation(AddShipmentActionDocument)
  const [loading, setLoading] = useState(false)
  const { formState, reset, handleSubmit, control, watch, setValue, register } =
    useForm<Form>({
      mode: 'onChange',
      defaultValues: {
        items: [],
      },
    })
  const apiRef = useGridApiRef()
  const items = watch('items')
  const [state, setState] = useState<'items' | 'confirm'>('items')
  const client = useApolloClient()

  const { data: reasonCodesData } = useQuery(AllReasonCodesDocument, {
    variables: {
      type: ReasonCodeType.ShipmentReceiveItems,
    },
  })
  const reasonCodes = reasonCodesData?.allReasonCodes || []

  const onSubmit = async (formData: Form) => {
    try {
      setLoading(true)

      await addShipmentAction({
        variables: {
          shipmentId: props.shipmentId,
          data: {
            shipment_version: props.shipmentVersion,
            action: ActionType.AddReceivedItemsUndeclared,
            received_items_undeclared: {
              note: formData?.note,
              reason_code: formData?.reasonCode,
              warehouse_id: formData?.warehouseId,
              items:
                formData?.items
                  .filter((x) => x.quantity > 0)
                  .map((x) => ({
                    quantity: x.quantity,
                    sku: x.sku,
                    product_name: x.product_name,
                    barcode: x.product?.barcode || '',
                  })) || [],
            },
          },
        },
      })

      trackFormSuccess({ name: `${dialogName}-dialog` })

      await client.refetchQueries({
        include: [GetShipmentDocument, AllShipmentActionsDocument],
      })

      props.onSuccess?.()
    } catch (error) {
      const errorMessage = getErrorMessage(
        error,
        'shipments',
        t('shipments:shipment_message.failure_update'),
      )

      trackFormError({ name: `${dialogName}-dialog`, errorMessage })
      props.onError?.(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
      setState('items')
      reset(
        { items: [], note: '', reasonCode: '' },
        { keepDefaultValues: false },
      )
    }
  }, [prevOpen, props.open, reset, trackDialogOpen])

  const totalItems = items?.reduce((p, c) => p + c.quantity, 0) || 0

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      data-testid="undeclared-items-receive-dialog"
    >
      <DialogTitle
        type="extended"
        sx={{
          paddingBottom: (theme) => theme.spacing(2),
        }}
      >
        {t('shipments:undeclared_items.title')}
      </DialogTitle>
      <DialogContent sx={{ px: state === 'items' ? 0 : undefined }}>
        {state === 'items' ? (
          <>
            <SearchProductField
              data-testid="input-undeclared-item"
              sx={{ mb: 2, px: 3 }}
              onChange={(product) => {
                const index = items?.findIndex(
                  (item) => item.sku === product.sku,
                )

                if (index !== -1) {
                  apiRef.current.scrollToIndexes({ rowIndex: index })
                  return
                }

                setValue('items', [
                  ...items,
                  {
                    id: nanoid(),
                    product_name:
                      [product.title, product.variant?.at(0)?.value]
                        .filter(Boolean)
                        .join(', ') || '',
                    sku: product.sku,
                    quantity: 1,
                    quantity_received: 0,
                    product: {
                      id: product.id,
                      sku: 'productsku',
                      barcode: product.barcode,
                      images: product.productImages?.map(
                        (image) => image.fileUrl,
                      ),
                    },
                  },
                ])

                apiRef.current.scrollToIndexes({ rowIndex: 0 })
              }}
            />
            <UndeclaredItems
              items={items}
              updateItem={(item) => {
                const existingItem = items.find((x) => x.sku === item.sku)
                if (existingItem) {
                  existingItem.quantity = item.quantity
                  setValue('items', items)
                }
              }}
              apiRef={apiRef}
            />
          </>
        ) : (
          <>
            <Controller
              control={control}
              name="warehouseId"
              render={({ field, fieldState: { error } }) => (
                <>
                  <InputLabel>
                    {t('shipments:undeclared_items.receive_stock_warehouse')}
                  </InputLabel>
                  <Select<number | string>
                    value={field.value || ''}
                    onChange={(event) => {
                      const id = Number(event.target.value)

                      field.onChange(
                        event.target.value && !Number.isNaN(id)
                          ? id
                          : undefined,
                      )
                    }}
                    data-testid="warehouses-select"
                    inputProps={{ 'data-testid': 'warehouses-input' }}
                    displayEmpty
                    error={!!error?.message}
                  >
                    <MenuItem value={''}>
                      {t('shipments:undeclared_items.no_stock_change')}
                    </MenuItem>
                    {warehouses?.map(({ id, name }) => (
                      <MenuItem
                        value={id}
                        key={id}
                        data-testid={`warehouse-${id}-button`}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={!!error?.message}>
                    {error?.message}
                  </FormHelperText>
                </>
              )}
            />
            {reasonCodes.length > 0 && (
              <Controller
                control={control}
                name="reasonCode"
                rules={{
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <InputLabel sx={{ mt: 2 }}>
                      {t('shipments:undeclared_items.reason_code')}
                    </InputLabel>
                    <Select
                      data-testid="select-reason-code"
                      value={field.value || ''}
                      onChange={(event) => {
                        field.onChange(event.target.value)
                      }}
                      inputProps={{
                        'data-testid': 'shipment-reason-code-field',
                      }}
                      displayEmpty
                      error={!!error?.message}
                    >
                      <MenuItem value={''} disabled>
                        {t('shared:label.select')}
                      </MenuItem>
                      {reasonCodes.map(({ reasoncode, reasoncodeid, name }) => (
                        <MenuItem
                          value={reasoncode}
                          key={reasoncodeid}
                          data-testid={`reason-code-${reasoncodeid}-button`}
                        >
                          {name || reasoncode}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={!!error?.message} sx={{ mb: 2 }}>
                      {error?.message}
                    </FormHelperText>
                  </>
                )}
              />
            )}

            <TextField
              fullWidth
              error={!!formState.errors.note}
              helperText={formState.errors.note?.message}
              label={t('shipments:undeclared_items.note')}
              {...register('note')}
              inputProps={{ 'data-testid': 'note' }}
              multiline={true}
              rows={3}
              sx={{ mb: 2 }}
            />

            <SectionHeader variant="transparent" sx={{ px: 0 }}>
              {t('shipments:undeclared_items.summary')}
            </SectionHeader>
            <List sx={{ mx: -3 }}>
              <ListItem
                secondaryAction={
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Chip
                      color="grayLight"
                      size="small"
                      label={`${totalItems}`}
                    />
                  </Box>
                }
              >
                <ListItemText primary={t('shipments:undeclared_items.items')} />
              </ListItem>
              <Divider />
              <ListItem
                secondaryAction={<ListItemText primary={displayName} />}
              >
                <ListItemText
                  primary={t('shipments:undeclared_items.responsible')}
                />
              </ListItem>
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={trackButtonClickEvent(
            {
              name: `${dialogName}-dialog-cancel`,
            },
            onClose,
          )}
          color="secondary"
          size="small"
          type="button"
        >
          {t('shared:action.cancel')}
        </Button>
        {state === 'items' && (
          <Button
            size="small"
            type="button"
            onClick={trackButtonClickEvent(
              {
                name: `${dialogName}-dialog-receive`,
              },
              () => {
                setState('confirm')
              },
            )}
            disabled={items.length === 0 || !items.some((x) => x.quantity > 0)}
            data-testid="receive-button"
          >
            {t('shipments:undeclared_items.receive')}
          </Button>
        )}

        {state === 'confirm' && (
          <Button
            type="submit"
            size="small"
            onClick={trackButtonClickEvent(
              {
                name: `${dialogName}-dialog-confirm`,
              },
              handleSubmit(onSubmit),
            )}
            disabled={
              !formState.isValid ||
              items.length === 0 ||
              !items.some((x) => x.quantity > 0)
            }
            data-testid="confirm"
            loading={loading}
          >
            {t('shipments:undeclared_items.confirm')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
