import {
  Box,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  SortDirection,
  SxProps,
  Theme,
} from '@mui/material'
import { GridSortItem, GridSortModel } from '@mui/x-data-grid-pro'
import { RadioOffIcon, RadioOnIcon, SortIcon } from '@sitoo/mui-components'
import { MouseEventHandler, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../../hooks/tracking'

export type SortItem<T = unknown> = GridSortItem & {
  title: string
  type: 'text' | 'number' | 'date' | 'currency'
  sort: NonNullable<SortDirection>
  isDefault?: boolean
  value?: T
}

type SortProps = {
  gridName: string
  initialSortItem?: GridSortItem
  sortItems: SortItem[]
  onSortChange: (item: GridSortModel) => void
  sx?: SxProps<Theme>
}

export const Sort = (props: SortProps) => {
  const { trackButtonClick } = useTracking()
  const { t } = useTranslation('shared')
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpen = !!anchorEl
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(isOpen ? null : event.currentTarget)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }

  const [selectedItem, setSelectedItem] = useState<SortItem | null>()

  useEffect(() => {
    if (props.initialSortItem) {
      setSelectedItem(
        props.sortItems.find(
          ({ field, sort }) =>
            field === props.initialSortItem?.field &&
            sort === props.initialSortItem?.sort,
        ),
      )
    }
  }, [props.initialSortItem, props.sortItems])

  const onSortItemSelect = (item: SortItem) => {
    trackButtonClick({
      name: 'grid-sort',
      grid: props.gridName,
      title: item.title,
      field: item.field,
      sortDirection: item.sort,
    })
    setSelectedItem(item)
    props.onSortChange([item])
    closeMenu()
  }

  const getTypeText = (item: SortItem) => {
    switch (item.type) {
      case 'date':
        return item.sort === 'asc' ? t('sort.date_asc') : t('sort.date_desc')
      case 'text':
        return item.sort === 'asc' ? t('sort.text_asc') : t('sort.text_desc')
      case 'currency':
        return item.sort === 'asc'
          ? t('sort.currency_asc')
          : t('sort.currency_desc')

      case 'number':
      default:
        return item.sort === 'asc'
          ? t('sort.number_asc')
          : t('sort.number_desc')
    }
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <IconButton
          sx={{
            width: (theme) => theme.spacing(6.25),
            backgroundColor: (theme) => theme.palette.white,
            borderRadius: 0,
            flexGrow: 1,
          }}
          aria-controls={isOpen ? 'grid-sort-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : undefined}
          onClick={handleClick}
        >
          <SortIcon />
        </IconButton>

        <Divider sx={{ borderWidth: '1px' }} />
      </Box>

      <Menu
        id="grid-sort-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        MenuListProps={{ 'aria-labelledby': 'grid-sort-menu-button' }}
      >
        {props.sortItems.map((sortItem) => (
          <ListItemButton
            key={`${sortItem.title}-${sortItem.sort}`}
            onClick={() => onSortItemSelect(sortItem)}
            selected={sortItem === selectedItem}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              cursor: 'pointer',

              ':hover': {
                background: (theme) => theme.palette.background.default,
              },
            }}
          >
            <ListItemIcon>
              <Box
                component={
                  sortItem === selectedItem ? RadioOnIcon : RadioOffIcon
                }
              />
            </ListItemIcon>
            <ListItemText>
              {sortItem.title} ({getTypeText(sortItem)})
            </ListItemText>
          </ListItemButton>
        ))}
      </Menu>
    </>
  )
}
