import { Button } from '@mui/material'
import { Box } from '@mui/material'
import { PaginationPropsOverrides } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { CustomRowCount } from './custom-row-count'

type Props = PaginationPropsOverrides

export const CursorPagination = (props: Props) => {
  const { t } = useTranslation('shared')

  const { isLoading } = props

  const isLastPage = !props.showMore

  const onShowMoreClick = () => {
    void props.fetchMore?.()
  }

  const isDisabled = isLastPage || isLoading

  return (
    <>
      <Box
        sx={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          minHeight: 56,
          display: isLastPage ? 'none' : 'flex',
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Button
          loading={isLoading}
          disabled={isDisabled}
          onClick={onShowMoreClick}
          variant="outlined"
          fullWidth
          sx={{
            height: 56,
            bgcolor: (theme) =>
              isDisabled ? theme.palette.white : theme.palette.gray10,
            color: (theme) =>
              isDisabled ? theme.palette.gray50 : theme.palette.blue80,

            '&:hover': {
              bgcolor: (theme) =>
                isDisabled ? theme.palette.white : theme.palette.darkMode60,
              color: (theme) =>
                isDisabled ? theme.palette.gray50 : theme.palette.white,
            },
          }}
        >
          {isLastPage && !isLoading ? t('grid.no_more') : t('grid.show_more')}
        </Button>
      </Box>

      <CustomRowCount hideTotalCount={props.hideTotalCount} />
    </>
  )
}
