import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  capitalize,
  Divider,
  Paper,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { axisClasses, BarChart } from '@mui/x-charts'
import { ReportIcon } from '@sitoo/mui-components'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ReportResults } from '../../generated/graphql'

type Props = {
  reportResults?: ReportResults
}

//TODO: Highly experimental for POC purposes, trying out MUI X Charts
export const GraphSection = (props: Props) => {
  const yAxisValue = useMemo(() => {
    if (props.reportResults?.columns) {
      return props.reportResults?.columns?.find((column) =>
        column.key?.includes('measure'),
      )?.key
    }
  }, [props.reportResults?.columns])
  const { t } = useTranslation(['reports'])
  const groupingVariables = useMemo(() => {
    if (props.reportResults?.columns) {
      return props.reportResults?.columns?.filter((column) =>
        column.key?.includes('group'),
      )
    }
    return []
  }, [props.reportResults])
  const numberOfRows = props.reportResults?.rows.length || 0
  const theme = useTheme()
  const blueBaseColor = theme.palette.blueBase

  const canRenderGraph = numberOfRows < 100

  if (!props.reportResults || !yAxisValue || !canRenderGraph) return null

  const getColumnLabel = (key: string, value?: any) => {
    if (value != undefined && key.includes('group')) {
      if (key.toLowerCase().includes('year')) {
        return new Date(value).toLocaleDateString(undefined, {
          month: 'long',
          year: 'numeric',
        })
      }
      return String(value === '' ? '-' : value)
    }
  }

  return (
    <Box component={Paper} elevation={0} sx={{ mb: 2 }}>
      <Divider />
      <Accordion
        sx={{ p: 0 }}
        slotProps={{ transition: { unmountOnExit: true } }}
      >
        <AccordionSummary>
          <ReportIcon sx={{ mr: 1 }} />
          <Typography variant="body02" sx={{ p: 0 }}>
            {t('reports:graph')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <BarChart
            grid={{ horizontal: true }}
            margin={{
              left: 120,
              right: 40,
              top: 40,
              bottom: 40,
            }}
            series={[
              {
                data:
                  props.reportResults?.rows.map(
                    (row) => Number(row[yAxisValue]) || 0,
                  ) || [],
                color: blueBaseColor,
              },
            ]}
            height={300}
            xAxis={[
              {
                data: props.reportResults?.rows.map((row, index) => {
                  if (numberOfRows > 10) return index + 1
                  if (groupingVariables.length > 3) return index
                  const columnLabels: string[] = []
                  Object.entries(row).forEach(([key, value]) => {
                    const label = getColumnLabel(key, value)
                    if (label) columnLabels.push(label)
                  })
                  return columnLabels.join(', ')
                }),
                scaleType: 'band',
                tickLabelStyle: { maxWidth: '100px' },
              },
            ]}
            yAxis={[
              {
                label: t(`reports:report_keys.${capitalize(yAxisValue)}`),
              },
            ]}
            sx={{
              [`& .${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translateX(-60px)',
              },
            }}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
