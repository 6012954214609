import { debounce, TextField, TextFieldProps, useForkRef } from '@mui/material'
import { ChangeEvent, Ref, useMemo, useRef } from 'react'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../hooks/tracking'

type Props = Omit<TextFieldProps, 'name'> & {
  innerRef?: Ref<HTMLElement>
}

export const TextFieldInput = <TFieldValues extends FieldValues>(
  props: Props & UseControllerProps<TFieldValues>,
) => {
  const { t } = useTranslation(['shared'])

  const { trackInputChange } = useTracking()

  const { name, control, rules, innerRef, ...textFieldProps } = props

  const validationRules = {
    ...rules,
    required: props.required
      ? t('shared:validation.field_required', { field: props.label ?? name })
      : undefined,
  }

  const { field, formState, fieldState } = useController({
    name,
    control,
    rules: validationRules,
  })

  const isSubmitting = formState.isSubmitting

  const formError = fieldState.error

  const isRequired = rules?.required === true || props.required

  const inputRef = useRef<HTMLInputElement>(null)

  const handleInputRef = useForkRef(field.ref, innerRef || inputRef)

  const trackChangeEvent = useMemo(
    () =>
      debounce((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        trackInputChange({ name, value: event.target.value })(event)
      }, 1000),
    [name, trackInputChange],
  )

  return (
    <TextField
      {...textFieldProps}
      required={isRequired}
      value={field.value}
      onChange={(event) => {
        field.onChange(event)
        trackChangeEvent(event)
        props.onChange?.(event)
      }}
      onFocus={(event) => props.onFocus?.(event)}
      onBlur={(event) => props.onBlur?.(event)}
      error={!!formError}
      name={field.name}
      disabled={isSubmitting || textFieldProps.disabled}
      helperText={formError?.message ?? textFieldProps.helperText}
      ref={inputRef}
      inputRef={handleInputRef}
    />
  )
}
