import { useMutation } from '@apollo/client'
import { Menu, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ExportUsersDocument, FileType } from '../../generated/graphql'
import { ExportDialog } from '../../components/export-dialog'
import { useTracking } from '../../hooks/tracking'
import { useState } from 'react'
import { useSnackbar } from 'notistack'
import { MoreButton } from '../../components/more-button'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '..'
import { useAbsolutePath } from '../../hooks/absolute-path'

export const UsersContextMenu = () => {
  const { t } = useTranslation(['shared', 'users'])
  const { trackButtonClickEvent } = useTracking()
  const [isExportDialogOpen, setExportDialogOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const [exportMutation, { loading }] = useMutation(ExportUsersDocument)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  const onExport = async (fileType: FileType) => {
    try {
      const { data } = await exportMutation({ variables: { fileType } })

      const fileLink = data?.exportUsers

      if (fileLink) {
        window.location.href = fileLink
      }

      enqueueSnackbar(t('users:export_success'), {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar(t('users:export_error'), {
        variant: 'error',
      })
    } finally {
      setExportDialogOpen(false)
    }
  }

  return (
    <>
      <MoreButton onClick={handleClick} />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        data-testid="more-options-button"
        onClick={handleClose}
        onClose={handleClose}
      >
        <MenuItem
          data-testid="export-users-option"
          onClick={
            (trackButtonClickEvent({ name: 'export-users' }),
            () => setExportDialogOpen(true))
          }
        >
          {t('users:export_users')}
        </MenuItem>
        <MenuItem
          data-testid="manage-user-tags-option"
          onClick={
            (trackButtonClickEvent({ name: 'go-to-manage-user-tags' }),
            () => void navigate(generatePath(RootRoute.SettingsUserTags)))
          }
        >
          {t('users:manage_user_tags')}
        </MenuItem>
      </Menu>

      <ExportDialog
        open={isExportDialogOpen}
        onClose={() => setExportDialogOpen(false)}
        label={t('users:export_users')}
        onSuccess={onExport}
        isLoading={loading}
      />
    </>
  )
}
