import { useTranslation } from 'react-i18next'
import {
  GetManufacturersDetailedDocument,
  ManufacturerFragment,
} from '../../../../generated/graphql'
import { usePageTitle } from '../../../../hooks/title'
import { useQuery } from '@apollo/client'
import { SettingsList } from '../../../../components/settings-list'
import { GridColDef, GridRowId, useGridApiRef } from '@mui/x-data-grid-pro'
import { ClothingDressIcon } from '@sitoo/mui-components'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAbsolutePath } from '../../../../hooks/absolute-path'
import { RootRoute } from '../../..'
import { useCallback, useMemo } from 'react'
import { PageHeader } from '../../../../components/page-header'
import { CursorPagination } from '../../../../components/data-grid/cursor-pagination'
import { useTracking } from '../../../../hooks/tracking'

const PAGE_SIZE = 50

export const SettingsProductsBrandsPage = () => {
  const name = 'product-brand-list'
  const apiRef = useGridApiRef()
  const { t } = useTranslation(['shared', 'settings', 'countries'])
  const { trackButtonClick } = useTracking()
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  usePageTitle(t('shared:menu.brands'))

  const {
    loading: isLoading,
    data,
    fetchMore,
  } = useQuery(GetManufacturersDetailedDocument, {
    variables: { start: 0, num: PAGE_SIZE },
  })

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.manufacturers.totalcount) {
      return fetchMore({
        variables: {
          start: (data.manufacturers?.start ?? 0) + pageSize,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  const onAdd = () => {
    trackButtonClick({ name: `${name}-add` })
    void navigate(generatePath(RootRoute.SettingsProductsBrandsNew))
  }

  const onEdit = (id: GridRowId) => {
    trackButtonClick({ name: `${name}-edit` })
    void navigate(generatePath(RootRoute.SettingsProductsBrand, { id }))
  }

  const columns = useMemo<GridColDef<ManufacturerFragment>[]>(
    () => [
      {
        field: 'name',
        headerName: t('settings:brand.fields.name'),
        flex: 1,
      },
      {
        field: 'email',
        headerName: t('settings:brand.fields.email'),
      },
      {
        field: 'phone',
        headerName: t('settings:brand.fields.phone'),
      },
      {
        field: 'country',
        headerName: t('settings:brand.fields.country'),
        valueGetter: (_, row) =>
          row.countryid ? t(`countries:${row.countryid}`) : '',
      },
      {
        field: 'description',
        headerName: t('settings:brand.fields.description'),
        flex: 1,
      },
    ],
    [t],
  )

  return (
    <SettingsList
      name={name}
      apiRef={apiRef}
      rows={data?.manufacturers.items ?? []}
      rowCount={data?.manufacturers.totalcount ?? 0}
      columns={columns}
      loading={isLoading}
      onRowClick={({ id }) => onEdit(id)}
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      fetchMore={fetchMoreItems}
      showMore={
        Number(data?.manufacturers.items?.length) <
        Number(data?.manufacturers.totalcount)
      }
      noRowsOverlay={{
        icon: <ClothingDressIcon />,
        title: t('settings:brand.empty_title'),
        action: (
          <Button
            onClick={() =>
              void navigate(generatePath(RootRoute.SettingsProductsBrandsNew))
            }
          >
            {t('settings:brand.add')}
          </Button>
        ),
      }}
      slots={{ PageHeader, pagination: CursorPagination }}
      slotProps={{
        pageHeader: {
          title: t('shared:menu.brands'),
          isFlexible: true,
          rightColumn: (
            <Button data-testid="add-brand" onClick={onAdd}>
              {t('settings:brand.add')}
            </Button>
          ),
        },
      }}
    />
  )
}
