import { useQuery } from '@apollo/client'
import {
  Box,
  Card,
  CardActionArea,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import {
  ChevronSmallRightIcon,
  ProgressDoneIcon,
  SectionHeader,
  ShipmentIcon,
  StocktakingIcon,
} from '@sitoo/mui-components'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { createSearchParams } from 'react-router-dom'
import { RootRoute } from '..'
import {
  ShipmentState,
  WarehouseBatchTransaction,
  AllOpenWarehouseBatchesDocument,
  NumTransitShipmentsDocument,
} from '../../generated/graphql'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { useAuthorization } from '../../hooks/authorization'
import { useTracking } from '../../hooks/tracking'

type ImportantCardProps = {
  onClick?(): void
  primary?: string
  secondary?: string
  label?: string
  icon?: React.ReactNode
  loading?: boolean
  testId?: string
}

const ImportantCard = (props: ImportantCardProps) => {
  if (props.loading) {
    return (
      <Grid size={{ xs: 12, md: 4 }}>
        <Card elevation={0} sx={{ px: 3, pt: 2.5, pb: 2.5 }}>
          <Skeleton height="25px" />
          <Skeleton height="65px" />
        </Card>
      </Grid>
    )
  }

  return (
    <Grid size={{ xs: 12, md: 4 }} data-testid={props.testId}>
      <Card elevation={0}>
        <CardActionArea
          sx={{ px: 3, pt: 2.5, pb: 2.5 }}
          onClick={props.onClick}
        >
          <Stack direction="row" alignItems="center">
            <Box sx={{ width: '100%' }}>
              <List>
                <ListItem disablePadding sx={{ pb: 1, minHeight: '54px' }}>
                  <ListItemIcon>{props.icon}</ListItemIcon>
                  <ListItemText
                    primary={props.primary}
                    secondary={props.secondary}
                  />
                </ListItem>
              </List>

              <Typography variant="displayMedium">{props.label}</Typography>
            </Box>

            <ChevronSmallRightIcon />
          </Stack>
        </CardActionArea>
      </Card>
    </Grid>
  )
}

type Card = ImportantCardProps & {
  active: boolean
}

export const ImportantToday = () => {
  const { t } = useTranslation(['home'])
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const { trackButtonClick } = useTracking()
  const {
    modules: { shipments },
  } = useAuthorization()

  const { data: stockTakingData, loading: stockTakingLoading } = useQuery(
    AllOpenWarehouseBatchesDocument,
    {
      variables: {
        transactiontype: WarehouseBatchTransaction.Stocktaking,
      },
      fetchPolicy: 'cache-and-network',
      // NOTE: this completely disable the request due to a poor performance
      skip: true,
    },
  )
  const stockTakingInProgress = stockTakingData?.allOpenWarehouseBatches.length

  const { data: transitShipmentsData, loading: transitShipmentsLoading } =
    useQuery(NumTransitShipmentsDocument, { skip: !shipments })

  const numTransitShipments = transitShipmentsData?.numTransitShipments

  const isLoading = stockTakingLoading || transitShipmentsLoading

  const cards: Card[] = useMemo(
    () => [
      {
        primary: t('home:important.stocktaking_in_progress'),
        icon: <StocktakingIcon />,
        onClick: () => {
          trackButtonClick({ name: 'stocktaking-in-progress-card' })
          void navigate(generatePath(RootRoute.Stocktakings))
        },
        label: String(stockTakingInProgress),
        active: Boolean(stockTakingInProgress),
        testId: 'stocktaking-card',
      },
      {
        primary: t('home:important.shipments_in_transit'),
        icon: <ShipmentIcon />,
        onClick: () => {
          trackButtonClick({ name: 'shipments-in-progress-card' })

          void navigate({
            pathname: generatePath(RootRoute.Shipments),
            search: createSearchParams({
              shipmentState: ShipmentState.InTransit,
            }).toString(),
          })
        },
        label: String(numTransitShipments),
        active: Boolean(numTransitShipments),
        loading: transitShipmentsLoading,
        testId: 'shipments-in-transit-card',
      },
    ],
    [
      generatePath,
      navigate,
      numTransitShipments,
      stockTakingInProgress,
      t,
      trackButtonClick,
      transitShipmentsLoading,
    ],
  )

  return (
    <Container sx={{ mx: 0 }} data-testid="important-today-section">
      <SectionHeader variant="transparent" sx={{ p: 0, mb: 3 }}>
        {t('home:important_today').toUpperCase()}
      </SectionHeader>

      <Grid container spacing={2}>
        {cards.map((card, index) =>
          card.loading || card.active ? (
            <ImportantCard key={index} {...card} />
          ) : null,
        )}

        {!isLoading && cards.filter((card) => card.active).length === 0 && (
          <Grid size={{ xs: 12 }}>
            <Card sx={{ p: 4 }} elevation={0}>
              <Stack direction="column" alignItems="center" spacing={1}>
                <ProgressDoneIcon
                  sx={{ color: (theme) => theme.palette.gray50 }}
                />
                <Typography sx={{ color: (theme) => theme.palette.gray60 }}>
                  {t('home:important.all_tasks_done')}
                </Typography>
              </Stack>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  )
}
