import { useMutation } from '@apollo/client'
import { Button, Stack, TextField, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SendResetPasswordLinkDocument } from '../../generated/graphql'
import { useTracking } from '../../hooks/tracking'
import { validateEmail } from '../../utils/validate'
import { ResetPasswordForm } from '.'
import { Link as RouterLink } from 'react-router-dom'
import { RootRoute } from '..'

type Props = {
  onSuccess(): void
}

export const EmailInput = ({ onSuccess }: Props) => {
  const { register, formState, setError, handleSubmit } =
    useFormContext<ResetPasswordForm>()
  const { t } = useTranslation(['login', 'shared'])
  const { trackButtonClickEvent, trackInputBlur, trackInputFocus } =
    useTracking()

  const [sendResetLink, { loading }] = useMutation(
    SendResetPasswordLinkDocument,
  )

  const handleSendResetLink = async ({
    email,
    accountId,
  }: ResetPasswordForm) => {
    const { data } = await sendResetLink({
      variables: { email, accountId },
    })

    if (data?.sendResetPasswordLink) {
      onSuccess()
      return
    }
    if (data?.sendResetPasswordLink === false) {
      setError('email', { message: t('login:error.invalid_email') })
    }
  }

  return (
    <>
      <Typography variant="displayMedium" sx={{ mb: 1 }}>
        {t('login:reset_password_title')}
      </Typography>

      <Typography sx={{ mb: 3 }}>{t('login:reset_password_info')}</Typography>
      <form>
        <TextField
          label={t('shared:label.email')}
          type="email"
          error={!!formState.errors.email}
          helperText={formState.errors.email?.message}
          {...register('email', {
            required: t('shared:validation.field_required', {
              field: t('shared:label.email'),
            }),
            validate: (value) =>
              value && !validateEmail(value)
                ? t('shared:validation.field_invalid', {
                    field: t('shared:label.email'),
                  })
                : true,
            onBlur: trackInputBlur({ name: 'forgot-pw-email' }),
          })}
          inputProps={{ 'data-testid': 'forgot-pw-input' }}
          autoComplete="username"
          onFocus={trackInputFocus({ name: 'forgot-pw-email' })}
          sx={{ mb: 2 }}
        />

        <Stack gap={1}>
          <Button
            onClick={trackButtonClickEvent(
              { name: 'send-password-reset-link-submit' },
              handleSubmit(handleSendResetLink),
            )}
            type="submit"
            loading={loading}
            data-testid="send-password-reset-link-button"
          >
            {t('shared:label.continue')}
          </Button>

          <Button variant="text" to={RootRoute.Login} component={RouterLink}>
            {t('login:return_to_sign_in')}
          </Button>
        </Stack>
      </form>
    </>
  )
}
