import { useQuery } from '@apollo/client'
import { Button, Chip } from '@mui/material'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../..'
import { PageHeader } from '../../../components/page-header'
import { SettingsList } from '../../../components/settings-list'
import {
  GetPriceListsDocument,
  GetPriceListsQuery,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { usePageTitle } from '../../../hooks/title'
import { useTracking } from '../../../hooks/tracking'
import { ArrayElement } from '../../../utils/types'
import { OrderIcon } from '@sitoo/mui-components'
import { DEFAULT_PRICELIST_ID, MAX_NUM_REQUEST } from '../../../utils/constants'

type Row = ArrayElement<GetPriceListsQuery['priceLists']['items']>

export const SettingsPriceListsPage = () => {
  const name = 'price-lists'
  const { t } = useTranslation(['shared', 'settings'])
  usePageTitle(t('shared:menu.price_lists'))
  const apiRef = useGridApiRef()
  const { trackButtonClick } = useTracking()

  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const { loading: fetchLoading, data } = useQuery(GetPriceListsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { start: 0, num: MAX_NUM_REQUEST },
  })

  const onEdit = (id: number) => {
    trackButtonClick({ name: `${name}-edit` })
    void navigate(generatePath(RootRoute.SettingsPriceList, { id }))
  }

  const onAdd = () => {
    trackButtonClick({ name: `${name}-add` })
    void navigate(generatePath(RootRoute.SettingsPriceListNew))
  }

  const isLoading = fetchLoading

  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'pricelistname',
        minWidth: 240,
        headerName: t('settings:price_lists.name'),
        renderCell: (params) => (
          <>
            {params.formattedValue}
            {params.row.id === DEFAULT_PRICELIST_ID && (
              <Chip
                label={t('settings:price_lists.default')}
                size="small"
                color="grayLight"
                sx={{ ml: 1 }}
              />
            )}
          </>
        ),
      },
      {
        field: 'currencycode',
        minWidth: 100,
        headerName: t('settings:price_lists.currency'),
      },
      {
        field: 'externalid',
        minWidth: 100,
        headerName: t('settings:price_lists.external_id'),
      },
      {
        field: 'pricelistdescription',
        minWidth: 240,
        headerName: t('settings:price_lists.description'),
      },
      {
        field: 'tags',
        minWidth: 240,
        headerName: t('settings:price_lists.tags'),

        renderCell: (params) =>
          params.row.tags?.map((tag) => (
            <Chip
              key={tag}
              label={tag}
              size="small"
              color="grayLight"
              sx={{ mr: 0.5 }}
            />
          )),
      },
    ],
    [t],
  )

  return (
    <SettingsList
      name="price-lists"
      apiRef={apiRef}
      rows={data?.priceLists.items}
      columns={columns}
      loading={isLoading}
      onRowClick={({ id }) => onEdit(Number(id))}
      noRowsOverlay={{
        icon: <OrderIcon />,
        title: t('settings:price_lists.empty_title'),
        description: t('settings:price_lists.empty_description'),
        action: (
          <Button
            onClick={() =>
              void navigate(generatePath(RootRoute.SettingsImportData))
            }
          >
            {t('settings:price_lists.import')}
          </Button>
        ),
      }}
      slots={{ PageHeader }}
      slotProps={{
        pageHeader: {
          title: t('shared:menu.price_lists'),
          isFlexible: true,
          rightColumn: (
            <Button data-testid="add-price-list" onClick={onAdd}>
              {t('settings:price_lists.add_price_list')}
            </Button>
          ),
        },
      }}
      getRowClassName={(params) => (params.row.active ? '' : 'disabled')}
    />
  )
}
