import {
  gridPageSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro'
import Pagination from '@mui/material/Pagination'
import { Box, Skeleton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isElementInViewport } from '../../utils/element'
import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { usePrevious, usePreviousDistinct } from 'react-use'
import {
  navbarHeightReactiveVar,
  pageHeaderHeightReactiveVar,
} from '../../cache'
import { useReactiveVar } from '@apollo/client'

export type CustomPaginationProps = {
  isLoading?: boolean
  hasPageHeader?: boolean
  rowCount?: number
  isDataLoaded?: boolean
  showMore?: boolean
}

export const CustomPagination = (props: CustomPaginationProps) => {
  const { t } = useTranslation('shared')

  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector)
  const previousRowCount = usePreviousDistinct(
    props.rowCount,
    () => props.rowCount !== 0,
  )
  const rowCount = props.rowCount || previousRowCount || 0
  const [forceLoading, setForceLoading] = useState(false)
  const prevLoading = usePrevious(props.isLoading)
  const isLoading = props.isLoading || forceLoading

  const isLastPage = (page || 1) * pageSize >= rowCount

  const navbarHeight = useReactiveVar(navbarHeightReactiveVar)

  const pageHeaderHeight = useReactiveVar(pageHeaderHeightReactiveVar)

  const onChange = (_event: unknown, value: number) => {
    apiRef.current.setPage(value - 1)
    const gridHeaderElement =
      apiRef.current.rootElementRef?.current?.querySelector(
        '.MuiDataGrid-columnHeadersInner',
      )

    if (gridHeaderElement && !isElementInViewport(gridHeaderElement)) {
      const headerOffset =
        (navbarHeight || 0) + (props.hasPageHeader ? pageHeaderHeight || 0 : 0)

      const gridHeaderElementPosition =
        gridHeaderElement.getBoundingClientRect().top

      const offsetPosition =
        gridHeaderElementPosition + window.scrollY - headerOffset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'auto',
      })
    }
  }

  const onShowMoreClick = () => {
    apiRef.current.setPage(page + 1)
  }

  const showSkeletonPagination = isLoading

  useEffect(() => {
    if (forceLoading && prevLoading !== props.isLoading) {
      setForceLoading(false)
    }
  }, [forceLoading, prevLoading, props.isLoading])

  const isDisabled = props.showMore ? isLastPage || isLoading : isLoading

  return (
    <Box
      sx={{
        display: isLastPage ? 'none' : 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        minHeight: 56,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,

        bgcolor: (theme) =>
          !props.showMore || isDisabled
            ? theme.palette.white
            : theme.palette.gray10,
      }}
    >
      {props.showMore ? (
        <Button
          loading={isLoading}
          disabled={isDisabled}
          onClick={onShowMoreClick}
          variant="outlined"
          fullWidth
          sx={{
            height: 56,
            bgcolor: (theme) =>
              isDisabled ? theme.palette.white : theme.palette.gray10,
            color: (theme) =>
              isDisabled ? theme.palette.gray50 : theme.palette.blue80,

            '&:hover': {
              bgcolor: (theme) =>
                isDisabled ? theme.palette.white : theme.palette.darkMode60,
              color: (theme) =>
                isDisabled ? theme.palette.gray50 : theme.palette.white,
            },
          }}
        >
          {isLastPage && !isLoading ? t('grid.no_more') : t('grid.show_more')}
        </Button>
      ) : (
        <>
          {showSkeletonPagination ? (
            <Skeleton
              variant="rectangular"
              sx={{
                width: 76,
                height: 28,
              }}
            />
          ) : (
            <Pagination
              color="standard"
              // count={pageCount || previousPageCount}
              page={page + 1}
              shape="rounded"
              onChange={onChange}
              // showFirstButton
              // showLastButton
              disabled={isDisabled}
              sx={{
                '.MuiButtonBase-root:hover': {
                  backgroundColor: (theme) =>
                    `${theme.palette.gray10} !important`,
                },
                '.Mui-selected': {
                  backgroundColor: (theme) =>
                    `${theme.palette.gray20} !important`,
                },
              }}
            />
          )}
          <Typography
            variant="caption"
            sx={{
              position: 'absolute',
              right: (theme) => theme.spacing(2),
            }}
          >
            {isLoading ? (
              <Skeleton
                variant="rectangular"
                sx={{
                  width: 50,
                  height: 15,
                }}
              />
            ) : (
              t('grid.pagination', {
                startCount: page * pageSize + 1,
                endCount:
                  page * pageSize + pageSize > rowCount
                    ? rowCount
                    : page * pageSize + pageSize,
                totalCount: rowCount,
              })
            )}
          </Typography>
        </>
      )}
    </Box>
  )
}
