import { Box, capitalize, Divider, Paper } from '@mui/material'
import { GridColDef, GridPinnedRowsProp } from '@mui/x-data-grid-pro'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../components/data-grid'
import {
  ReportJobsQuery,
  ReportResultColumnsInner,
  ReportResultItemValueDisplayType,
  ReportResults,
} from '../../generated/graphql'
import { formatDecimal } from '../../utils/format/number'
import { ArrayElement } from '../../utils/types'

type Props = {
  reportResults?: ReportResults
  reportJob?: ArrayElement<ReportJobsQuery['reportJobs']['items']>
  reportName?: string
}
type Row = Record<string, unknown>

export const TableSection = (props: Props) => {
  const { t } = useTranslation(['reports'])

  const formatTableValue = (
    type: ReportResultItemValueDisplayType,
    value: string | number,
  ) => {
    if (!value) return '-'

    switch (type) {
      case ReportResultItemValueDisplayType.TimestampYear:
        // TODO: Use formatDate
        return new Date(value).toLocaleDateString(undefined, {
          year: 'numeric',
        })
      case ReportResultItemValueDisplayType.TimestampYearMonth:
        // TODO: Use formatDate
        return new Date(value).toLocaleDateString(undefined, {
          year: 'numeric',
          month: '2-digit',
        })

      case ReportResultItemValueDisplayType.TimestampYearMonthDay:
        // TODO: Use formatDate
        return new Date(value).toLocaleDateString(undefined, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      case ReportResultItemValueDisplayType.TimestampYearMonthDayVerbose:
        //TODO: Implement verbose date
        return new Date(value).toLocaleDateString(undefined, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      case ReportResultItemValueDisplayType.Money:
      case ReportResultItemValueDisplayType.MoneyFixed:
        return new Intl.NumberFormat('sv-SE', {
          //TODO: replace hardcoded currency
          style: 'currency',
          currency: 'SEK',
        }).format(value as number)
      case ReportResultItemValueDisplayType.Decimalquantity:
        return Number(value).toFixed(0)

      case ReportResultItemValueDisplayType.DoublePercentage:
        return formatDecimal(value, { decimals: 6 })

      // TODO: implement all the types
      default:
        return value
    }
  }

  const columnMapper = useCallback(
    (column: ReportResultColumnsInner): GridColDef<Row> => {
      if (!column.key) return { field: '', flex: 1, headerName: '' }

      return {
        field: column.key,
        flex: 1,
        disableColumnMenu: true,
        headerName: t(`reports:report_keys.${capitalize(column.key)}`),
        valueGetter: (value) => {
          return formatTableValue(
            column.type as ReportResultItemValueDisplayType,
            value,
          )
        },
      }
    },
    [t],
  )

  const dataGridColumns: GridColDef<Row>[] = useMemo(() => {
    if (!props.reportResults?.columns) return []

    const columns =
      props.reportResults?.columns?.map((column) => columnMapper(column)) || []

    if (columns.length > 0) {
      columns.unshift({
        field: 'id',
        headerName: '#',
        disableColumnMenu: true,
        flex: 0.5,
        valueGetter: (value) => {
          if (value === -1) return ''
          return typeof value === 'number' ? value + 1 : value
        },
      })
    }

    return columns
  }, [columnMapper, props.reportResults?.columns])

  const noResultData = useMemo(
    () =>
      props.reportResults?.totals &&
      !Object.values(props.reportResults.totals || {}).some((value) => !!value),

    [props.reportResults?.totals],
  )

  const pinnedRows: GridPinnedRowsProp = useMemo(
    () => ({
      bottom: [{ ...props.reportResults?.totals, id: -1 }],
    }),
    [props.reportResults?.totals],
  )

  const rows = useMemo(() => {
    return (
      props.reportResults?.rows.map((row, index) => ({ ...row, id: index })) ||
      []
    )
  }, [props.reportResults?.rows])

  return (
    //TODO: Calculate table size dynamically
    <Box sx={{ width: '100%', height: '65vh' }}>
      {noResultData && (
        <Paper elevation={0} sx={{ p: 2 }}>
          <Divider sx={{ mb: 2, mx: -2 }} />
          {t('reports:no_report_data', { report: props.reportName })}
        </Paper>
      )}

      <DataGrid
        name="report-table"
        rows={rows}
        pinnedRows={pinnedRows}
        rowCount={props.reportResults?.rows.length || 0}
        columns={dataGridColumns}
        sx={{
          '.MuiDataGrid-row--lastVisible': {
            fontWeight: 'bold',
          },
          mb: 1,
          height: '100%',
        }}
        disableAllFilters
      />
    </Box>
  )
}
