import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'
import { ConfirmationDialog, FormFieldset } from '@sitoo/mui-components'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../../..'
import {
  DeleteSalesTaxGroupDocument,
  SalesTaxGroupsDocument,
} from '../../../../generated/graphql'
import { useAbsolutePath } from '../../../../hooks/absolute-path'
import { salesTaxGroupsViewPanelVar } from '../../sales-tax-groups'

type Props = {
  salesTaxGroupId: string | undefined
}

export const DeleteSalesTaxGroupField = (props: Props) => {
  const { salesTaxGroupId } = props
  const { t } = useTranslation(['settings'])
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const [deleteSalesTaxGroup, { loading }] = useMutation(
    DeleteSalesTaxGroupDocument,
    { refetchQueries: [SalesTaxGroupsDocument] },
  )

  const handleDeleteSalesTaxGroup = async () => {
    if (salesTaxGroupId) {
      try {
        await deleteSalesTaxGroup({
          variables: { deleteSalesTaxGroupId: Number(salesTaxGroupId) },
        })
        salesTaxGroupsViewPanelVar({
          isOpen: false,
          salesTaxGroupId: undefined,
        })
        void navigate(generatePath(RootRoute.SettingsSalesTaxGroups), {
          replace: true,
        })
        enqueueSnackbar(
          t('settings:sales_tax_group.sales_tax_group_message.success_delete'),
        )
      } catch {
        enqueueSnackbar(
          t('settings:sales_tax_group.sales_tax_group_message.failure_delete'),
        )
      }
    }
    setDeleteDialogOpen(false)
  }

  if (!salesTaxGroupId) {
    return null
  }

  return (
    <FormFieldset label={t('settings:sales_tax.delete_fieldset')}>
      <ConfirmationDialog
        confirmAction={handleDeleteSalesTaxGroup}
        title={t('settings:sales_tax_group.dialog_delete_label')}
        text={t('settings:sales_tax_group.dialog_delete_description')}
        variant="destructive"
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      />
      <Button
        data-testid="delete-sales-tax-group-button"
        fullWidth
        loading={loading}
        color="error"
        onClick={() => setDeleteDialogOpen(true)}
      >
        {t('settings:sales_tax_group.delete_sales_tax_group')}
      </Button>
    </FormFieldset>
  )
}
