import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../components/data-grid/context'
import { InventoryProductState } from '../generated/graphql'
import { useFormFilter } from '../hooks/form-filter'
import { SelectInput } from '../inputs/select-input'

export type WarehouseProductStateFilter = {
  productState?: InventoryProductState
}

export const WarehouseProductStateFilter = () => {
  const { t } = useTranslation('filter')

  const filterKey = 'productState'

  const { setFilter, subscribeOnResetFilter, registerFilter } =
    useContext<FilterContext<WarehouseProductStateFilter>>(FilterContext)

  const options = useMemo(
    () =>
      Object.values(InventoryProductState).map((value) => ({
        name: t(`warehouse_product_state.${value}`),
        value,
      })),
    [t],
  )

  const { queryParams, formContext, resetForm } =
    useFormFilter<WarehouseProductStateFilter>({
      formProps: {
        defaultValues: { productState: InventoryProductState.All },
      },
      parseOptions: {
        types: {
          productState: (value) =>
            (Object.values(InventoryProductState) as string[]).includes(value)
              ? value
              : undefined,
        },
      },
    })

  const { productState } = queryParams

  useEffect(() => {
    registerFilter({ key: filterKey })
  }, [registerFilter])

  useEffect(() => {
    if (productState?.length) {
      setFilter(filterKey, {
        label: t('warehouse_product_state.label'),
        value: productState,
        labelValues: [t(`warehouse_product_state.${productState}`)],
        isDefault: productState === InventoryProductState.All,
      })
    } else {
      setFilter(filterKey, {
        label: t('warehouse_product_state.label'),
        value: InventoryProductState.All,
        labelValues: [t(`warehouse_product_state.All`)],
        isDefault: true,
      })
    }
  }, [options, productState, setFilter, t])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        resetForm()
      }
    })

    return () => unsubscribe()
  }, [resetForm, subscribeOnResetFilter])

  return (
    <SelectInput
      name="productState"
      control={formContext.control}
      options={options}
      label={t('warehouse_product_state.label')}
    />
  )
}
