import {
  DeliveryIcon,
  GiftcardIcon,
  OrderIcon,
  ProductGridIcon,
  ReceiptIcon,
  SalesTaxIcon,
  WarehouseIcon,
} from '@sitoo/mui-components'
import { ReportType } from '../../../generated/graphql'
import { ElementType } from 'react'
import React from 'react'

type Props = {
  type: ReportType
}

const reportTypeIconMap = new Map<ReportType, ElementType>([
  [ReportType.Creditnote, ReceiptIcon],
  [ReportType.GiftCard, GiftcardIcon],
  [ReportType.OmniFulfillment, DeliveryIcon],
  [ReportType.Order, OrderIcon],
  [ReportType.Product, ProductGridIcon],
  [ReportType.SalesTax, SalesTaxIcon],
  [ReportType.Stock, WarehouseIcon],
  [ReportType.Warehouse, WarehouseIcon],
])

export const ReportTypeIcon = ({ type }: Props) => {
  const Icon = reportTypeIconMap.get(type) ?? React.Fragment

  return <Icon />
}
