import { Box, useMediaQuery } from '@mui/material'
import { ChevronLeftIcon, ChevronRightIcon } from '@sitoo/mui-components'
import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { usePrevious } from 'react-use'
import { sidebarOpenState } from '../../cache'
import { MenuItem } from '../sidebar-list-item'
import { SiteSelector } from '../site-selector'
import { MenuSection } from './menu-section'
import { ArrowButton, SidebarDrawer } from './styled'
import { useIsSettings } from '../../hooks/is-settings/index.ts'

export type MenuSection = {
  name?: string
  items: MenuItem[]
}

type Props = {
  footer?: React.ReactNode
  header?: React.ReactNode
  menuSections: MenuSection[]
}

export const SidebarList = (props: Props) => {
  const { menuSections, footer, header } = props
  const { pathname } = useLocation()
  const prevPathname = usePrevious(pathname)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isSettings = useIsSettings()

  const closeSidebar = () => {
    sidebarOpenState(false)
  }

  const toggleSidebar = () => {
    sidebarOpenState(!sidebarOpenState())
  }

  const filterAndTransformActiveItems = (menuItem: MenuItem): MenuItem => {
    const activeChildItems = menuItem.items?.filter(
      (childItem) => childItem.active ?? true,
    )

    // If there is only active item, display it instead of its parent
    if (
      !menuItem.keepSingleChildNested &&
      activeChildItems?.length === 1 &&
      activeChildItems[0]
    ) {
      return {
        ...menuItem,
        items: undefined,
        to: activeChildItems[0].to,
        name: activeChildItems[0].name,
        counter: activeChildItems[0].counter,
      }
    }

    return {
      ...menuItem,
      items: activeChildItems,
    }
  }

  useEffect(() => {
    if (sidebarOpenState() === undefined) {
      sidebarOpenState(!isMobile)
    }

    if (isMobile && prevPathname !== pathname) {
      closeSidebar()
    }
  }, [pathname, prevPathname, isMobile])

  const activeMenuSections = useMemo((): MenuSection[] => {
    const filteredMenuSections = menuSections.map((menuSection) => {
      return {
        ...menuSection,
        items: menuSection.items.map(filterAndTransformActiveItems),
      }
    })

    return filteredMenuSections
  }, [menuSections])

  return (
    <SidebarDrawer
      isSettings={isSettings}
      variant="permanent"
      open={sidebarOpenState()}
      slotProps={{ backdrop: { onClick: closeSidebar } }}
      sx={{ background: (theme) => theme.palette.gray80 }}
    >
      <Box
        sx={{
          position: 'fixed',
          width: 'inherit',
          pointerEvents: 'none',
          height: '100vh',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <ArrowButton
          className="arrow-button"
          onClick={toggleSidebar}
          open={sidebarOpenState()}
        >
          {sidebarOpenState() ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </ArrowButton>
      </Box>
      <Box
        sx={{
          mt: isSettings ? 5 : 7,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {header}

        <Box sx={{ display: ['block', 'none'] }}>
          <SiteSelector isSidebar />
        </Box>

        <Box
          sx={{
            mt: [0, isSettings ? 1.5 : 3.5],
          }}
        >
          {activeMenuSections.map((category, menuIndex) => {
            return (
              <MenuSection
                key={`${category.name}-${menuIndex}`}
                {...category}
              />
            )
          })}
        </Box>
        {footer}
      </Box>
    </SidebarDrawer>
  )
}
