import { useMutation, useQuery } from '@apollo/client'
import {
  StocktakingTransactionsDocument,
  UpdateBatchItemsRelativeDocument,
  WarehouseBatchItemInput,
} from '../../../../generated/graphql'
import { useCallback, useMemo } from 'react'

interface UseStocktakingTransactionsProps {
  warehouseId: number
  warehouseBatchId: number
}

type ValidStocktakingTransaction = {
  decimaltranschanges: string
  sku: string
}

export const useAdjustTransactions = ({
  warehouseId,
  warehouseBatchId,
}: UseStocktakingTransactionsProps) => {
  const { data, loading } = useQuery(StocktakingTransactionsDocument, {
    variables: {
      warehousebatchid: warehouseBatchId,
      warehouseid: warehouseId,
    },
  })

  const stocktakingTransactions = data?.stocktakingTransactions

  const itemsToAdjust = useMemo(
    () =>
      stocktakingTransactions
        ?.filter((item): item is ValidStocktakingTransaction =>
          Boolean(item.sku && item.decimaltranschanges),
        )
        .map((item) => {
          return {
            sku: item.sku,
            decimalquantity: item.decimaltranschanges,
          } satisfies WarehouseBatchItemInput
        }) || [],
    [stocktakingTransactions],
  )

  const [updateBatchItems, { loading: loadingUpdate }] = useMutation(
    UpdateBatchItemsRelativeDocument,
    {
      variables: {
        warehouseId,
        warehouseBatchId,
        warehouseBatchItems: itemsToAdjust,
      },
    },
  )

  const isAdjusting = loading || loadingUpdate

  const adjustTransactions = useCallback(async () => {
    const { data } = await updateBatchItems()

    return data?.updateBatchItemsRelative
  }, [updateBatchItems])

  return { isAdjusting, adjustTransactions }
}
