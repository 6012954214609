import { Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReportOrderState } from '../../generated/graphql'

type Props = {
  filter: string
  value?: string | number | boolean | ReportOrderState[] | number[] | null
}

export const FilterChip = (props: Props) => {
  const { filter, value } = props

  const { t } = useTranslation(['reports'])
  if (!value || filter === '__typename') return null

  // TODO: For actual reports implementation, make sure we format the value based on type of filter.
  return (
    <Chip
      key={filter}
      size="small"
      label={`${t(`reports:filter_keys.${filter}`)}: ${Array.isArray(value) ? value.join('/') : value}`}
      color="grayLight"
    />
  )
}
