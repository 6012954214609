import { List } from '@mui/material'
import { SidebarListItem } from '../sidebar-list-item'
import { Category } from './styled'
import { MenuSection as MenuSectionType } from '.'
import { Fragment, useMemo } from 'react'

type Props = Pick<MenuSectionType, 'name' | 'items'>

export const MenuSection = ({ name, items }: Props) => {
  const activeItems = useMemo(() => {
    return items.filter(({ active, items: childItems }) => {
      if (childItems) {
        return childItems.some(({ active }) => active)
      }

      return active ?? true
    })
  }, [items])

  if (!activeItems.length) {
    return null
  }

  return (
    <List sx={{ mb: 1.5 }}>
      <Fragment>
        {name && <Category variant="subheader">{name}</Category>}

        {activeItems.map((item) => (
          <SidebarListItem key={item.name} {...item} />
        ))}
      </Fragment>
    </List>
  )
}
