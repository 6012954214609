import { makeVar, useReactiveVar } from '@apollo/client'
import { Button, Grid2 as Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../..'
import { PageHeader } from '../../../components/page-header'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useTracking } from '../../../hooks/tracking'
import { PaymentOptionsList } from './payment-options-list'
import { PaymentOptionsViewPanel } from './payment-options-view-panel'
import { usePageTitle } from '../../../hooks/title'

type ViewPanelState = {
  id?: number
  isOpen: boolean
}

export const paymentOptionsViewPanelVar = makeVar<ViewPanelState>({
  id: undefined,
  isOpen: false,
})

export const SettingsPaymentOptionsPage = () => {
  const { t } = useTranslation(['payment_options', 'shared', 'settings'])
  const { trackButtonClick } = useTracking()
  const viewPanelState = useReactiveVar(paymentOptionsViewPanelVar)
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  usePageTitle(t('shared:menu.payment_options'))

  const closeViewPanel = () => {
    trackButtonClick({ name: 'payment-options-view-panel-close' })
    paymentOptionsViewPanelVar({ isOpen: false })
  }

  const openViewPanel = (id: number) => {
    trackButtonClick({ name: 'payment-options-view-panel-open' })
    paymentOptionsViewPanelVar({ isOpen: true, id })
  }

  const onAdd = () => {
    trackButtonClick({ name: 'payment-options-add' })
    void navigate(generatePath(RootRoute.SettingsPaymentOptionNew))
  }

  return (
    <Grid container flexWrap="nowrap">
      <Grid size="grow">
        <PageHeader
          title={t('payment_options:page_title')}
          isSticky={true}
          isFlexible={true}
          rightColumn={
            <Button data-testid="add-payment-option-button" onClick={onAdd}>
              {t('payment_options:add_payment_option')}
            </Button>
          }
        />
        <PaymentOptionsList onDetailPaymentOption={openViewPanel} />
      </Grid>

      <Grid>
        <PaymentOptionsViewPanel
          open={viewPanelState.isOpen}
          id={viewPanelState.id}
          onClose={closeViewPanel}
        />
      </Grid>
    </Grid>
  )
}
