import {
  PrimitiveCircleHollowSmallIcon,
  PrimitiveCircleSmallIcon,
} from '@sitoo/mui-components'
import { InventoryItemBatchItemState, Maybe } from '../../../generated/graphql'
import { formatDecimal } from '../../../utils/format/number'
import { SvgIconOwnProps } from '@mui/material'
import { t } from 'i18next'
import { CaptionRendererValue } from './caption-renderer'

type Row = {
  decimalnostockvalue?: Maybe<string>
  decimaltranschanges?: Maybe<string>
  warehousebatchitemstate?: Maybe<InventoryItemBatchItemState>
}

const getCaption = (row: Row): string | undefined => {
  const hasCaption = [
    InventoryItemBatchItemState.CountedModified,
    InventoryItemBatchItemState.CountedDiff,
  ].some((x) => x === row.warehousebatchitemstate)

  if (!hasCaption || !row.decimalnostockvalue) return

  const caption = t(`stocktaking:warehouse_item_caption`, {
    num: formatDecimal(row.decimalnostockvalue),
  })

  return caption
}

/**
 * Formats the warehouse item status with an icon.
 */
export const formatWarehouseItemStatus = (
  _value: unknown,
  row: Row,
  showCaption: boolean,
): CaptionRendererValue => {
  const caption = showCaption ? getCaption(row) : undefined

  const state =
    row.warehousebatchitemstate ?? InventoryItemBatchItemState.NotCounted

  const transactionChanges = row.decimaltranschanges

  const getColor = (
    state: InventoryItemBatchItemState,
  ): Exclude<SvgIconOwnProps['color'], undefined> => {
    switch (state) {
      case InventoryItemBatchItemState.CountedOk:
        return 'success'
      case InventoryItemBatchItemState.CountedDiff:
        return 'warning'
      case InventoryItemBatchItemState.CountedModified:
        return 'error'
      default:
        return 'disabled'
    }
  }

  const color = getColor(state)

  const Icon = transactionChanges
    ? PrimitiveCircleHollowSmallIcon
    : PrimitiveCircleSmallIcon

  const icon = (
    <Icon
      color={color}
      sx={{
        width: (theme) => theme.spacing(3),
        height: (theme) => theme.spacing(3),
      }}
    />
  )

  const suffix = transactionChanges
    ? ` (${Number(transactionChanges) > 0 ? '+' : ''}${formatDecimal(transactionChanges)})`
    : ''

  const title = t(`stocktaking:warehouse_item_status.${state}`) + suffix

  return { title, caption, icon }
}
