import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import {
  AdyenIcon,
  DashboardIcon,
  DocumentationIcon,
  ImportIcon,
  LockIcon,
  OrderIcon,
  PaymentCashIcon,
  ProductGridIcon,
  SalesTaxIcon,
  SettingsIcon,
  SitooPaymentsIcon,
  StarIcon,
  StoreIcon,
  UserDefaultIcon,
  WarehouseIcon,
  WarningIcon,
} from '@sitoo/mui-components'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthorization } from '../../hooks/authorization'
import { RootRoute } from '../../pages'
import { FeedbackDialog, feedbackDialogOpenVar } from '../feedback-dialog'
import { MenuSection, SidebarList } from '../sidebar-list'
import { SidebarListBackItem } from '../sidebar-list-item/sidebar-list-back-item'

export const SettingsSidebar = () => {
  const { t } = useTranslation(['shared'])
  const { modules } = useAuthorization()

  const menuSections = useMemo<MenuSection[]>(
    () => [
      {
        name: t('shared:menu.settings'),
        items: [
          {
            name: t('shared:menu.general'),
            icon: <SettingsIcon />,
            to: RootRoute.SettingsGeneral,
            active: modules.settingsGeneral,
          },
        ],
      },
      {
        items: [
          {
            name: t('shared:menu.dashboard_setup'),
            icon: <DashboardIcon />,
            items: [
              {
                name: t('shared:menu.dashboards'),
                to: RootRoute.SettingsPosDashboards,
                active: modules.settingsPosDashboards,
              },
            ],
          },
          {
            name: t('shared:menu.import'),
            icon: <ImportIcon />,
            items: [
              {
                name: t('shared:menu.import_data'),
                to: RootRoute.SettingsImportData,
                active:
                  modules.settingsImportPrices || modules.settingsImportUsers,
              },
            ],
          },
          {
            name: t('shared:menu.payment_options'),
            icon: <PaymentCashIcon />,
            to: RootRoute.SettingsPaymentOptions,
            active: modules.settingsPaymentOptions,
          },
          {
            name: t('shared:menu.product_setup'),
            icon: <ProductGridIcon />,
            items: [
              {
                name: t('shared:menu.custom_attributes'),
                to: RootRoute.SettingsCustomAttributes,
                active: modules.settingsCustomAttributes,
              },
              {
                name: t('shared:menu.brands'),
                to: RootRoute.SettingsProductsBrands,
                active: modules.settingsBrands,
              },
              /*               
              Disabled until we can refactor it properly
              {
                name: t('shared:menu.navigation'),
                to: RootRoute.SettingsProductsCategories,
                active: modules.settingsCategoriesNavigation,
              },
              */
              {
                name: t('shared:menu.product_groups'),
                to: RootRoute.SettingsProductsGroups,
                active: modules.settingsProductGroups,
              },
            ],
          },
          {
            name: t('shared:menu.price_lists'),
            icon: <OrderIcon />,
            to: RootRoute.SettingsPriceLists,
            active: modules.settingsPriceList,
          },
          {
            name: t('shared:menu.purchase_price_lists'),
            icon: <OrderIcon />,
            to: RootRoute.SettingsPurchasePriceLists,
            active: modules.settingsPurchasePriceList,
          },
          {
            name: t('shared:menu.reason_codes'),
            icon: <WarningIcon />,
            to: RootRoute.SettingsReasonCodes,
            active: modules.settingsReasonCodes,
          },
          {
            name: t('shared:menu.sales_tax'),
            icon: <SalesTaxIcon />,
            items: [
              {
                name: t('shared:menu.groups'),
                to: RootRoute.SettingsSalesTaxGroups,
                active: modules.settingsSalesTaxes,
              },
              {
                name: t('shared:menu.taxes'),
                to: RootRoute.SettingsSalesTaxes,
                active: modules.settingsSalesTaxes,
              },
            ],
          },
          {
            name: t('shared:menu.stores'),
            icon: <StoreIcon />,
            to: RootRoute.SettingsStores,
            active: modules.settingsStores,
          },
          {
            name: t('shared:menu.user_tags'),
            icon: <UserDefaultIcon />,
            to: RootRoute.SettingsUserTags,
            active: modules.users,
          },
          {
            name: t('shared:menu.warehouses'),
            icon: <WarehouseIcon />,
            to: RootRoute.SettingsWarehouses,
            active: modules.settingsWarehouses,
          },
        ],
      },
      {
        name: t('shared:menu.apps'),
        items: [
          {
            name: t('shared:menu.adyen_terminal_api'),
            active: modules.settingsAdyenTerminalApi,
            to: RootRoute.SettingsAdyenTerminalApi,
            icon: <AdyenIcon />,
          },
          {
            name: t('shared:menu.adyen_tap_to_pay'),
            active: modules.settingsAdyenTapToPay,
            to: RootRoute.SettingsAdyenTapToPay,
            icon: <AdyenIcon />,
          },
          {
            name: t('shared:menu.ctu_norway'),
            active: modules.settingsCtuNorway,
            to: RootRoute.SettingsCtuNorway,
            icon: <SitooPaymentsIcon />,
          },
          {
            name: t('shared:menu.rewards'),
            icon: <SitooPaymentsIcon />,
            to: RootRoute.SettingsCustomAPI,
            active: modules.settingsRewards,
          },
        ],
      },
      {
        name: t('shared:menu.security'),
        items: [
          {
            name: t('shared:menu.authentication'),
            active: modules.users,
            to: RootRoute.SettingsAuthentication,
            icon: <LockIcon />,
          },
          {
            name: t('shared:menu.log_events'),
            active: modules.users,
            to: RootRoute.SettingsAccessLogs,
            icon: <DocumentationIcon />,
          },
        ],
      },
    ],
    [
      t,
      modules.settingsGeneral,
      modules.settingsPosDashboards,
      modules.settingsImportPrices,
      modules.settingsImportUsers,
      modules.settingsPaymentOptions,
      modules.settingsCustomAttributes,
      modules.settingsBrands,
      modules.settingsProductGroups,
      modules.settingsPriceList,
      modules.settingsPurchasePriceList,
      modules.settingsReasonCodes,
      modules.settingsSalesTaxes,
      modules.settingsStores,
      modules.users,
      modules.settingsWarehouses,
      modules.settingsAdyenTerminalApi,
      modules.settingsAdyenTapToPay,
      modules.settingsCtuNorway,
      modules.settingsRewards,
    ],
  )

  return (
    <SidebarList
      menuSections={menuSections}
      header={<SidebarListBackItem />}
      footer={
        modules.directFeedback && (
          <List sx={{ mb: 2, mt: 'auto' }}>
            <FeedbackDialog />

            <ListItemButton
              onClick={() => feedbackDialogOpenVar(true)}
              sx={{ pl: [2, 3], bgcolor: 'transparent' }}
            >
              <ListItemIcon sx={{ color: 'inherit' }}>
                <StarIcon />
              </ListItemIcon>
              <ListItemText>
                {t('shared:feedback_dialog.leave_feedback')}
              </ListItemText>
            </ListItemButton>
          </List>
        )
      }
    />
  )
}
