import {
  Autocomplete,
  AutocompleteValue,
  ChipTypeMap,
  AutocompleteProps as MuiAutocompleteProps,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
} from '@mui/material'
import {
  ElementType,
  FocusEventHandler,
  SyntheticEvent,
  useCallback,
  useMemo,
} from 'react'
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../hooks/tracking'

type Option = {
  id: string | number
  label: string
}

/**
 * Example of MUI and react-hook-form integration:
 * https://github.com/dohomi/react-hook-form-mui/blob/master/packages/rhf-mui/src/AutocompleteElement.tsx#L41 GitHub
 */
type AutocompleteProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  TValue = Option | string | unknown,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = {
  name: TName
  multiple?: Multiple
  freeSolo?: FreeSolo
  options: TValue[]
  sx?: SxProps<Theme>
  required?: boolean
  isLoading?: boolean
  label?: TextFieldProps['label']

  autocompleteProps?: Omit<
    MuiAutocompleteProps<
      TValue,
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipComponent
    >,
    'name' | 'options' | 'loading' | 'renderInput'
  >

  textFieldProps?: Omit<TextFieldProps, 'name' | 'required' | 'label'>
  transform?: {
    output?: (
      value: AutocompleteValue<TValue, Multiple, DisableClearable, FreeSolo>,
    ) => AutocompleteValue<TValue, Multiple, DisableClearable, FreeSolo>
  }
}

export const AutocompleteInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  TValue = Option | string | unknown,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: AutocompleteProps<
    TFieldValues,
    TName,
    TValue,
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent
  > &
    UseControllerProps<TFieldValues>,
) => {
  const {
    name,
    control,
    sx,
    multiple,
    freeSolo,
    rules,
    required,
    label,
    autocompleteProps,
    textFieldProps,
    options,
    isLoading,
  } = props

  const { trackInputChange } = useTracking()

  const { t } = useTranslation(['shared'])

  const isRequired = rules?.required || required

  const validationRules: UseControllerProps<TFieldValues>['rules'] = {
    ...rules,
    required: isRequired
      ? t('shared:validation.field_required', { field: label ?? name })
      : undefined,
  }

  const { field, fieldState, formState } = useController({
    name,
    control,
    disabled: autocompleteProps?.disabled,
    rules: validationRules,
  })

  const { isSubmitting } = formState

  const formError = fieldState.error

  const value = useMemo(() => {
    const fieldValue = field.value as TValue | TValue[] | null

    if (freeSolo) {
      return fieldValue
    }

    if (Array.isArray(fieldValue)) {
      return options.filter((option) => fieldValue.includes(option))
    }

    if (fieldValue) {
      return options.find((option) => option === fieldValue) ?? null
    }

    return multiple ? [] : null
  }, [multiple, freeSolo, field.value, options])

  // This is a workaround to be able to create new tags without having to press 'enter'.
  // We could not simply use the 'autoSelect' prop since it leads to the last hovered item getting added on click-away.
  const handleOnBlur: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    if (!freeSolo) return

    const unregisteredValue = e.target.value.toUpperCase()
    if (unregisteredValue === '') return

    const valueAlreadyRegistered =
      Array.isArray(value) && value.includes(unregisteredValue as TValue)
    if (valueAlreadyRegistered) return

    field.onChange([...(Array.isArray(value) ? value : []), unregisteredValue])
  }

  const onChange = useCallback(
    (
      event: SyntheticEvent,
      value: AutocompleteValue<TValue, Multiple, DisableClearable, FreeSolo>,
    ) => {
      const transformedValue = props.transform?.output
        ? props.transform.output(value)
        : value

      field.onChange(transformedValue)
      trackInputChange({ name, value: String(value) })(event)
    },
    [field, props.transform, trackInputChange, name],
  )

  return (
    <Autocomplete
      sx={sx}
      multiple={multiple}
      freeSolo={freeSolo}
      fullWidth
      value={
        value as AutocompleteValue<TValue, Multiple, DisableClearable, FreeSolo>
      }
      options={options}
      disabled={isLoading ?? isSubmitting}
      onChange={onChange}
      data-testid={`${name}-autocomplete-container`}
      clearOnBlur
      renderInput={(params) => (
        <TextField
          type="text"
          {...textFieldProps}
          {...params}
          label={label}
          onBlur={handleOnBlur}
          error={!!formError}
          helperText={formError?.message ?? textFieldProps?.helperText}
          slotProps={{
            htmlInput: {
              ...textFieldProps?.slotProps?.htmlInput,
              ...params.inputProps,
              ['data-testid']: `autocomplete-input-${name}`,
            },
          }}
        />
      )}
      {...autocompleteProps}
    />
  )
}
