import { Button, Container } from '@mui/material'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../..'
import { FilterProvider } from '../../../components/data-grid/context'
import { PageHeader } from '../../../components/page-header'
import { WarehouseBatchTransaction } from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useAuthorization } from '../../../hooks/authorization'
import { usePageTitle } from '../../../hooks/title'
import { WarehouseBatchesList } from '../warehouse-batches-list'
import { NewStocktakingSessionDialog } from './new-stocktaking-session-dialog'

export const StocktakingsPage = () => {
  const { t } = useTranslation('stocktaking')
  usePageTitle(t('page_title_list'))
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const { writeStocktaking } = useAuthorization().modules

  const navigateToStocktaking = useCallback(
    (warehouseId: number, warehouseBatchId: number) => {
      void navigate(
        generatePath(RootRoute.Stocktaking, {
          warehouseId: String(warehouseId),
          warehouseBatchId: String(warehouseBatchId),
        }),
      )
    },
    [generatePath, navigate],
  )

  return (
    <>
      <PageHeader
        title={t('page_title_list')}
        isFlexible
        rightColumn={
          <>
            {writeStocktaking && (
              <>
                <Button
                  onClick={() => setOpen(true)}
                  data-testid="new-stocktaking"
                >
                  {t('start_action')}
                </Button>

                <NewStocktakingSessionDialog
                  open={open}
                  onClose={() => setOpen(false)}
                  onSuccess={navigateToStocktaking}
                />
              </>
            )}
          </>
        }
      />

      <Container maxWidth={false}>
        <FilterProvider>
          <WarehouseBatchesList
            type={WarehouseBatchTransaction.Stocktaking}
            onRowClick={navigateToStocktaking}
          />
        </FilterProvider>
      </Container>
    </>
  )
}
