import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { paymentOptionsViewPanelVar } from '.'
import { RootRoute } from '../..'
import { FieldHighlight } from '../../../components/field-highlight'
import { SettingsDetailDialog } from '../../../components/settings-detail-dialog'
import {
  AddPaymentTypeDocument,
  AllPaymentTypesDocument,
  AvailablePaymentMethodsDocument,
  DeletePaymentTypeDocument,
  GetPaymentTypeDocument,
  GetPaymentTypeQuery,
  PosPaymentMethod,
  UpdatePaymentTypeDocument,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useTracking } from '../../../hooks/tracking'
import { SelectInput } from '../../../inputs/select-input'
import { TextFieldInput } from '../../../inputs/text-field-input'

type PaymentType = GetPaymentTypeQuery['getPaymentType']

type PaymentOptionForm = Omit<
  PaymentType,
  'paymenttypeid' | 'paymentmethodid'
> & { paymentmethodid: PosPaymentMethod | '' }

export const PaymentOptionDialog = () => {
  const { t } = useTranslation(['shared', 'payment_options'])
  const { trackDialogOpen, trackDialogClose } = useTracking()
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const { id } = useParams()
  const paymentOptionId = Number(id)
  const isNewPaymentOption = !paymentOptionId

  const dialogName = 'payment-option'

  const { data, loading: fetchLoading } = useQuery(GetPaymentTypeDocument, {
    variables: { id: Number(paymentOptionId) },
    fetchPolicy: 'cache-and-network',
    skip: !paymentOptionId,
  })

  const { data: paymentMethodData, loading: isLoadingPaymentMethods } =
    useQuery(AvailablePaymentMethodsDocument)

  const [updatePaymentType, { loading: isUpdatingPaymentType }] = useMutation(
    UpdatePaymentTypeDocument,
    { refetchQueries: [AllPaymentTypesDocument] },
  )
  const [addPaymentType, { loading: isAddingPaymentType }] = useMutation(
    AddPaymentTypeDocument,
    { refetchQueries: [AllPaymentTypesDocument] },
  )

  const [deletePaymentType, { loading: isDeletingPaymentType }] = useMutation(
    DeletePaymentTypeDocument,
    {
      variables: {
        deletePaymentTypeId: paymentOptionId,
      },
      refetchQueries: [AllPaymentTypesDocument],
    },
  )

  const { formState, reset, control, handleSubmit, watch, getValues } =
    useForm<PaymentOptionForm>({
      defaultValues: { name: '', externalid: '', paymentmethodid: '' },
    })

  useEffect(() => {
    if (isNewPaymentOption) {
      reset(undefined, { keepDefaultValues: true })
    }
    if (data?.getPaymentType) {
      reset(data.getPaymentType)
    }
  }, [data, isNewPaymentOption, reset])

  useEffect(() => {
    trackDialogOpen({ name: dialogName })
  }, [trackDialogOpen, dialogName])

  const allPaymentMethods = useMemo(() => {
    return Object.keys(PosPaymentMethod).map((paymentMethodId) => {
      return {
        value: paymentMethodId,
        name: t(`payment_options:payment_method.${paymentMethodId}`),
      }
    })
  }, [t])

  const availablePaymentMethods =
    useMemo(() => {
      return paymentMethodData?.availablePaymentMethods
        ?.map((value) => {
          return { value, name: t(`payment_options:payment_method.${value}`) }
        })
        .sort((a, b) => a.name.localeCompare(b.name))
    }, [paymentMethodData?.availablePaymentMethods, t]) || []

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    void navigate(generatePath(RootRoute.SettingsPaymentOptions))
  }

  const onSubmit = async (paymentOption: PaymentOptionForm) => {
    const { name, paymentmethodid, externalid } = paymentOption
    const paymentMethod = paymentmethodid as PosPaymentMethod

    if (isNewPaymentOption) {
      await addPaymentType({
        variables: {
          paymentType: {
            name,
            externalid,
            paymentmethodid: paymentMethod,
          },
        },
      })
    } else {
      await updatePaymentType({
        variables: {
          paymentType: {
            name,
            externalid,
            paymentmethodid: paymentMethod,
            paymenttypeid: paymentOptionId,
          },
        },
      })
    }
  }

  const handleDeletePaymentType = async () => {
    await deletePaymentType()
    paymentOptionsViewPanelVar({ isOpen: false })
    void navigate(generatePath(RootRoute.SettingsPaymentOptions), {
      replace: true,
    })
  }

  const isLoading =
    fetchLoading ||
    isUpdatingPaymentType ||
    isAddingPaymentType ||
    isDeletingPaymentType ||
    isLoadingPaymentMethods
  return (
    <SettingsDetailDialog
      type={isNewPaymentOption ? 'add' : 'edit'}
      dialogName={dialogName}
      typeLabel={t('payment_options:item_type')}
      getItemName={() => getValues('name')}
      onSave={onSubmit}
      onDelete={handleDeletePaymentType}
      onClose={onClose}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      formState={formState}
    >
      <TextFieldInput
        name="name"
        control={control}
        required
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: {
            'data-testid': 'payment-option-input-name',
          },
        }}
        label={t('payment_options:name')}
      />

      <SelectInput
        name="paymentmethodid"
        control={control}
        options={
          isNewPaymentOption ? availablePaymentMethods : allPaymentMethods
        }
        label={t('payment_options:method')}
        helperText={t('payment_options:method_helper_text')}
        disabled={!isNewPaymentOption}
        required
      />

      <TextFieldInput
        name="externalid"
        control={control}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: {
            'data-testid': 'payment-option-input-externalid',
          },
        }}
        label={t('payment_options:external_id')}
      />

      {!isLoading && !watch('externalid') && (
        <FieldHighlight
          label={t('shared:validation.external_id_warning')}
          sx={{ mt: -1 }}
          dataTestId="no-external-id-highlight"
        />
      )}
    </SettingsDetailDialog>
  )
}
