import { PropsWithChildren } from 'react'
import { Grid2 as Grid } from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'

type Props = PropsWithChildren & {
  title: string
}

export const ReportCardGroup = ({ title, children }: Props) => {
  return (
    <>
      <SectionHeader variant="transparent" sx={{ p: 0, mb: 1 }}>
        {title}
      </SectionHeader>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        {children}
      </Grid>
    </>
  )
}
