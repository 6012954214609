import { useQuery } from '@apollo/client'
import { Button } from '@mui/material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { StocktakingTransactionsDocument } from '../../../../generated/graphql'
import { useAdjustTransactions } from './adjust-transactions'
import { enqueueSnackbar } from 'notistack'
import { useEffect } from 'react'
import { usePrevious } from 'react-use'
import { useTracking } from '../../../../hooks/tracking'

type Props = {
  open: boolean
  onClose: () => void
  warehouseId: number
  warehouseBatchId: number
}

export const AdjustTransactionsDialog = (props: Props) => {
  const { t } = useTranslation(['stocktaking'])
  const dialogName = 'adjust-transactions'
  const prevOpen = usePrevious(props.open)

  const { trackButtonClick, trackDialogOpen, trackDialogClose } = useTracking()

  const { data } = useQuery(StocktakingTransactionsDocument, {
    variables: {
      warehousebatchid: props.warehouseBatchId,
      warehouseid: props.warehouseId,
    },
    skip: !props.open,
    fetchPolicy: 'cache-and-network',
  })

  const { isAdjusting, adjustTransactions } = useAdjustTransactions({
    warehouseId: props.warehouseId,
    warehouseBatchId: props.warehouseBatchId,
  })

  const transactionCount = data?.stocktakingTransactions?.length || 0

  useEffect(() => {
    if (props.open === prevOpen) return

    if (props.open) {
      trackDialogOpen({ name: dialogName })
    } else {
      trackDialogClose({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen, trackDialogClose])

  const handleAdjustClick = async () => {
    try {
      if (transactionCount === 0) return

      trackButtonClick({ name: dialogName })

      await adjustTransactions()

      enqueueSnackbar(t('stocktaking:adjustTransactions.success'), {
        variant: 'success',
      })
    } catch (error) {
      console.error('Error adjusting transactions:', error)
      enqueueSnackbar(t('stocktaking:adjustTransactions.error'), {
        variant: 'error',
      })
    } finally {
      props.onClose()
    }
  }

  return (
    <Dialog open={props.open} maxWidth="xs" fullWidth onClose={props.onClose}>
      <DialogTitle type="extended">
        {t('stocktaking:adjustTransactions.title')}
      </DialogTitle>

      <DialogContent>
        <Stack sx={{ mb: 5 }}>
          {transactionCount === 0 && (
            <Typography>
              {t('stocktaking:adjustTransactions.noTransactions')}
            </Typography>
          )}

          {transactionCount > 0 && (
            <>
              <Typography sx={{ mb: 2 }}>
                {t('stocktaking:adjustTransactions.question')}
              </Typography>
              <Typography>
                {t('stocktaking:adjustTransactions.productsAffected', {
                  count: transactionCount,
                })}
              </Typography>
            </>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        {transactionCount > 0 && (
          <Button onClick={props.onClose} color="secondary">
            {t('shared:action.cancel')}
          </Button>
        )}

        <Button
          loading={isAdjusting}
          onClick={() => void handleAdjustClick()}
          color="primary"
        >
          {transactionCount > 0
            ? t('stocktaking:adjustTransactions.title')
            : t('shared:action.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
