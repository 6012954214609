import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { enqueueSnackbar } from 'notistack'
import {
  ExportProductWarehouseBatchItemsDocument,
  ExportProductWarehouseBatchItemsMutationVariables,
  FileType,
} from '../../../../generated/graphql'
import { FilterContext } from '../../../../components/data-grid/context'
import { ExportDialog } from '../../../../components/export-dialog'
import { TextFilter } from '../../../../components/data-grid/filters/text-filter'
import { CategoryIdFilter } from '../../../../filters/product-category-id-filter'
import { ProductGroupIdFilter } from '../../../../filters/product-group-id-filter'
import { ManufacturerIdFilter } from '../../../../filters/manufacturer-id-filter'
import { WarehouseProductStateFilter } from '../../../../filters/warehouse-product-state-filter'
import { WarehouseItemStateFilter } from '../../../../filters/warehouse-item-state-filter'
import { FranchiseSiteFilter } from '../../../../filters/franchise-site-filter'

interface Props {
  warehouseId: number
  warehouseBatchId: number
  open: boolean
  onClose: () => void
}

type ExportVars =
  Partial<ExportProductWarehouseBatchItemsMutationVariables> | null

type Filter = TextFilter &
  CategoryIdFilter &
  ProductGroupIdFilter &
  ManufacturerIdFilter &
  FranchiseSiteFilter &
  WarehouseProductStateFilter &
  WarehouseItemStateFilter

export const ExportStockDialog = (props: Props) => {
  const { warehouseId, warehouseBatchId, open, onClose } = props
  const { t } = useTranslation(['orders'])
  const { filter } = useContext<FilterContext<Filter>>(FilterContext)
  const [exportStockMutation, { loading: isExportingStock }] = useMutation(
    ExportProductWarehouseBatchItemsDocument,
  )

  const handleExport = async (fileType: FileType) => {
    const filterVariables: ExportVars = {
      searchText: filter.text?.value,
      categoryIds: filter.categoryIds?.value,
      productGroupIds: filter.productGroupIds?.value,
      manufacturerIds: filter.manufacturerIds?.value,
      productState: filter.productState?.value,
      inventoryState: filter.inventoryState?.value,
      siteId: filter.siteId?.value,
    }

    try {
      const exportStockResult = await exportStockMutation({
        variables: {
          ...filterVariables,
          fileType,
          warehouseBatchId,
          warehouseId,
        },
      })

      const fileLink = exportStockResult.data?.exportProductWarehouseBatchItems

      if (fileLink) {
        window.location.href = fileLink
      }

      enqueueSnackbar(t('orders:export_success'), { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(t('orders:export_error'), { variant: 'error' })
    } finally {
      onClose()
    }
  }

  return (
    <ExportDialog
      label={t('stocktaking:export_dialog_title')}
      open={open}
      onClose={onClose}
      onSuccess={handleExport}
      isLoading={isExportingStock}
    />
  )
}
