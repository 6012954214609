import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'
import { ConfirmationDialog, FormFieldset } from '@sitoo/mui-components'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../../..'
import {
  DeleteSalesTaxDocument,
  SalesTaxGroupsDocument,
} from '../../../../generated/graphql'
import { useAbsolutePath } from '../../../../hooks/absolute-path'
import { salesTaxViewPanelVar } from '../../sales-taxes'

type Props = {
  salesTaxId: string | undefined
}

export const DeleteSalesTaxField = (props: Props) => {
  const { salesTaxId } = props
  const { t } = useTranslation(['settings'])
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const [deleteSalesTax, { loading }] = useMutation(DeleteSalesTaxDocument, {
    refetchQueries: [SalesTaxGroupsDocument],
  })

  const handleDeleteSalesTax = async () => {
    if (salesTaxId) {
      try {
        await deleteSalesTax({
          variables: { deleteSalesTaxId: Number(salesTaxId) },
        })
        salesTaxViewPanelVar({
          isOpen: false,
          salesTaxId: undefined,
        })
        void navigate(generatePath(RootRoute.SettingsSalesTaxes), {
          replace: true,
        })
        enqueueSnackbar(
          t('settings:sales_tax.sales_tax_message.success_delete'),
        )
      } catch {
        enqueueSnackbar(
          t('settings:sales_tax.sales_tax_message.failure_delete'),
        )
      }
    }
    setDeleteDialogOpen(false)
  }

  if (!salesTaxId) {
    return null
  }

  return (
    <FormFieldset label={t('settings:sales_tax.delete_fieldset')}>
      <ConfirmationDialog
        confirmAction={handleDeleteSalesTax}
        title={t('settings:sales_tax.dialog_delete_label')}
        text={t('settings:sales_tax.dialog_delete_description')}
        variant="destructive"
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      />
      <Button
        data-testid="delete-sales-tax-button"
        fullWidth
        loading={loading}
        color="error"
        onClick={() => setDeleteDialogOpen(true)}
      >
        {t('settings:sales_tax.delete_sales_tax')}
      </Button>
    </FormFieldset>
  )
}
