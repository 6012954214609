import { useQuery } from '@apollo/client'
import { Button, Chip } from '@mui/material'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../..'
import { PageHeader } from '../../../components/page-header'
import { SettingsList } from '../../../components/settings-list'
import {
  GetCustomAttributesQuery,
  GetCustomAttributesDocument,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { usePageTitle } from '../../../hooks/title'
import { useTracking } from '../../../hooks/tracking'
import { ArrayElement } from '../../../utils/types'
import { OrderIcon } from '@sitoo/mui-components'
import { CursorPagination } from '../../../components/data-grid/cursor-pagination'

type Row = ArrayElement<GetCustomAttributesQuery['customAttributes']['items']>

const PAGE_SIZE = 100

export const SettingsCustomAttributesPage = () => {
  const name = 'custom-attribute-lists'
  const { t } = useTranslation(['shared', 'settings'])
  usePageTitle(t('shared:menu.custom_attributes'))
  const apiRef = useGridApiRef()
  const { trackButtonClick } = useTracking()

  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const {
    data,
    fetchMore,
    loading: fetchLoading,
  } = useQuery(GetCustomAttributesDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { start: 0, num: PAGE_SIZE },
  })

  const onEdit = (id: string) => {
    trackButtonClick({ name: `${name}-edit` })
    void navigate(generatePath(RootRoute.SettingsCustomAttribute, { id }))
  }

  const onAdd = () => {
    trackButtonClick({ name: `${name}-add` })
    void navigate(generatePath(RootRoute.SettingsCustomAttributeNew))
  }

  const isLoading = fetchLoading

  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'id',
        minWidth: 140,
        headerName: t('settings:custom_attributes.id'),
      },
      {
        field: 'type',
        minWidth: 140,
        headerName: t('settings:custom_attributes.type'),
        valueFormatter: (value: string) =>
          t(`settings:custom_attributes.type_options.${value.toLowerCase()}`),
      },
      {
        field: 'searchable',
        minWidth: 100,
        headerName: t('settings:custom_attributes.searchable'),
        renderCell: (params) =>
          params.formattedValue ? t('shared:label.yes') : t('shared:label.no'),
      },
      {
        field: 'title',
        minWidth: 240,
        headerName: t('settings:custom_attributes.title'),
      },
      {
        field: 'enums',
        minWidth: 180,
        headerName: t('settings:custom_attributes.allowed_values'),
        renderCell: (params) => {
          if (Array.isArray(params.formattedValue)) {
            return params.formattedValue.map((value) => (
              <Chip
                key={value}
                label={value}
                size="small"
                color="grayLight"
                sx={{ mr: 0.5 }}
              />
            ))
          }
        },
      },
    ],
    [t],
  )

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.customAttributes.totalcount) {
      return fetchMore({
        variables: {
          start: (data.customAttributes?.start || 0) + pageSize,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  return (
    <SettingsList
      name={name}
      apiRef={apiRef}
      rows={data?.customAttributes.items || []}
      rowCount={data?.customAttributes.totalcount || 0}
      showMore={
        Number(data?.customAttributes.items?.length) <
        Number(data?.customAttributes.totalcount)
      }
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      fetchMore={fetchMoreItems}
      hideTotalCount
      columns={columns}
      loading={isLoading}
      onRowClick={({ id }) => onEdit(String(id))}
      noRowsOverlay={{
        icon: <OrderIcon />,
        title: t('settings:custom_attributes.empty_title'),
        description: t('settings:custom_attributes.empty_description'),
        action: (
          <Button
            onClick={() =>
              void navigate(generatePath(RootRoute.SettingsCustomAttributeNew))
            }
          >
            {t('settings:custom_attributes.add')}
          </Button>
        ),
      }}
      slots={{ PageHeader, pagination: CursorPagination }}
      slotProps={{
        pageHeader: {
          title: t('shared:menu.custom_attributes'),
          isFlexible: true,
          rightColumn: (
            <Button data-testid="add-custom-attribute" onClick={onAdd}>
              {t('settings:custom_attributes.add')}
            </Button>
          ),
        },
      }}
    />
  )
}
