import { Box, Paper, Stack, Typography } from '@mui/material'

import { Button } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Report,
  ReportDefinitionFilterKey,
  ReportJobsQuery,
} from '../../generated/graphql'
import { ArrayElement } from '../../utils/types'
import { FilterChip } from './filter-chip'
import { ReportJobFilter } from './report-job-filter'
import { ReportsContext, ReportsContextType } from './reports-context'

type Props = {
  report?: Report
  reportJob?: ArrayElement<ReportJobsQuery['reportJobs']['items']>
  reportJobExists: boolean
}

export const NavigationSection = (props: Props) => {
  const { report, reportJob, reportJobExists } = props
  const { t } = useTranslation(['reports'])
  const { onAddReportJob, isAddingReportJob } = useContext(
    ReportsContext,
  ) as ReportsContextType

  const handleAddReportJob = async () => {
    if (report) {
      await onAddReportJob(report)
    }
  }

  const formatFilterValue = (filter: ReportDefinitionFilterKey, value: any) => {
    const filterKey = filter.toLowerCase()
    if (!value) return ''
    if (filterKey.includes('date')) {
      return new Date(value).toLocaleDateString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    }
    if (Array.isArray(value)) {
      return value.join('/')
    }
    return value
  }

  const filters = reportJob?.reportdefinition?.filters
  return (
    <Box component={Paper} elevation={0} sx={{ p: 2 }}>
      <Stack
        direction="row"
        columnGap={2}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 0,
          pb: 0,
        }}
      >
        <Stack>
          {!reportJobExists ? (
            <Typography variant="body02" fontSize="16pt" fontWeight={700}>
              {t('reports:no_report_generated')}
            </Typography>
          ) : (
            <ReportJobFilter />
          )}

          {filters && (
            <Stack direction="row" gap={1} sx={{ flexWrap: 'wrap' }}>
              {Object.entries(filters).map(([filter, value]) => {
                return (
                  <FilterChip
                    filter={filter}
                    value={formatFilterValue(
                      filter as ReportDefinitionFilterKey,
                      value,
                    )}
                    key={filter}
                  />
                )
              })}
            </Stack>
          )}
        </Stack>

        {reportJobExists && (
          <Button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleAddReportJob}
            disabled={isAddingReportJob}
            type="button"
            loading={isAddingReportJob}
          >
            {t('reports:refresh_data')}
          </Button>
        )}
      </Stack>
    </Box>
  )
}
