import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'
import { ConfirmationDialog, FormFieldset } from '@sitoo/mui-components'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../..'
import {
  DeleteUserDocument,
  UserPermissionsByIdQuery,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { userViewPanelVar } from '../../users'

type Props = {
  userId: string | undefined
  permissions: UserPermissionsByIdQuery['userPermissionsById'] | undefined
}

export const DeleteUserField = (props: Props) => {
  const { userId, permissions } = props
  const { t } = useTranslation(['users', 'shared'])
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const [deleteUser, { loading }] = useMutation(DeleteUserDocument)

  const handleDeleteUser = async () => {
    if (userId) {
      try {
        await deleteUser({ variables: { userId } })
        userViewPanelVar({
          isOpen: false,
          userId: undefined,
        })
        void navigate(generatePath(RootRoute.Users), { replace: true })
        enqueueSnackbar(t('users:user_message.success_delete'))
      } catch {
        enqueueSnackbar(t('users:user_message.failure_delete'))
      }
    }
    setDeleteDialogOpen(false)
  }

  if (!userId || !permissions?.deleteUser) {
    return null
  }

  return (
    <FormFieldset label={t('users:user_form.delete_user_fieldset')}>
      <ConfirmationDialog
        confirmAction={handleDeleteUser}
        title={t('users:user_form.dialog_delete_label')}
        text={t('users:user_form.dialog_delete_description')}
        variant="destructive"
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      />
      <Button
        data-testid="delete-user-button"
        fullWidth
        loading={loading}
        color="error"
        onClick={() => setDeleteDialogOpen(true)}
      >
        {t('users:user_form.delete_user_fieldset')}
      </Button>
    </FormFieldset>
  )
}
