import { useQuery } from '@apollo/client'
import { Container, Grid2 as Grid } from '@mui/material'
import { StarFullIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ReportsQuickDocument } from '../../../generated/graphql'
import { useFavoriteReports } from '../../report/favorites/use-favorite-reports'
import { ReportCard } from './report-card'
import { ReportCardGroup } from './report-card-group'
import { ReportTypeIcon } from './report-type-icon'

export const ReportsQuickPage = () => {
  const { t } = useTranslation(['reports'])
  const { favorites } = useFavoriteReports()
  const { data } = useQuery(ReportsQuickDocument, {
    variables: {
      reportIds: favorites,
    },
    fetchPolicy: 'cache-and-network',
  })

  return (
    <Container maxWidth={false}>
      <ReportCardGroup title={t('reports:favorites.favorite_label')}>
        {!data?.favoriteReports.items?.length && (
          <Grid
            container
            width="100%"
            justifyContent="center"
            alignItems="center"
            bgcolor="background.paper"
            sx={{
              p: 4,
              color: 'text.secondary',
            }}
          >
            {t('reports:no_favorites')}
          </Grid>
        )}
        {data?.favoriteReports.items?.map((report) => (
          <ReportCard
            key={report.reportid}
            report={report}
            icon={<StarFullIcon />}
          />
        ))}
      </ReportCardGroup>
      <ReportCardGroup title={t('reports:examples')}>
        {data?.systemReports.map((report) => (
          <ReportCard
            key={report.reportid}
            report={report}
            icon={<ReportTypeIcon type={report.reporttype} />}
          />
        ))}
      </ReportCardGroup>
    </Container>
  )
}
