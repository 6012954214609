import {
  Card,
  CardActionArea,
  Chip,
  Grid2 as Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material'
import { ChevronRightIcon } from '@sitoo/mui-components'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useLocation, useNavigate } from 'react-router'
import { RootRoute } from '../..'
import { ReportSummaryFragment } from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'

type Props = {
  report: ReportSummaryFragment
  icon: ReactNode
}

export const ReportCard = ({ report, icon }: Props) => {
  const { t } = useTranslation(['reports'])
  const navigate = useNavigate()
  const location = useLocation()
  const generatePath = useAbsolutePath()

  return (
    <Grid size={{ xs: 12, md: 4, lg: 3 }}>
      <Card
        elevation={0}
        onClick={() =>
          void navigate({
            pathname: generatePath(RootRoute.Report, { id: report.reportid }),
            search: createSearchParams({
              backTo: location.pathname,
            }).toString(),
          })
        }
        role="button"
      >
        <CardActionArea>
          <Stack
            sx={{
              display: 'grid',
              gridTemplateAreas: '"cardText cardIcon"',
              gridTemplateColumns: '1fr auto',
              alignItems: 'center',
              p: 2,
              columnGap: 1,
            }}
          >
            <List
              sx={{
                gridArea: 'cardText',
                overflow: 'hidden',
              }}
            >
              <ListItem sx={{ p: 0 }}>
                <Stack
                  gap={2}
                  direction="row"
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <ListItemIcon>{icon}</ListItemIcon>

                  <Stack sx={{ width: '100%', overflow: 'hidden' }}>
                    <ListItemText
                      primary={report.reportname}
                      sx={{
                        textWrap: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '95%',
                      }}
                      secondary={t(
                        `reports:report_keys:${report.reportdefinition.measures.at(0)}`,
                        { defaultValue: '' },
                      )}
                    />
                    <Chip
                      label={t(`reports:report_types.${report.reporttype}`)}
                      color="grayLight"
                      size="small"
                      sx={{ width: 'max-content', mt: 0.5 }}
                    />
                  </Stack>
                </Stack>
              </ListItem>
            </List>
            <ChevronRightIcon
              sx={{ justifySelf: 'flex-end', gridArea: 'cardIcon' }}
            />
          </Stack>
        </CardActionArea>
      </Card>
    </Grid>
  )
}
