import { Button, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActivateVariantsDialog } from './activate-variants-dialog'

type Props = {
  navigateToVariantsTab(): void
}

export const ActivateVariantsField = (props: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation(['products', 'shared'])

  return (
    <>
      <Button
        sx={{ width: '100%' }}
        onClick={() => setOpen(true)}
        data-testid="activate-product-variants-button"
      >
        {t('products:product_form.activate_variants')}
      </Button>
      <Typography variant="caption" color="gray60">
        {t('products:product_form.activate_variants_caption')}
      </Typography>

      <ActivateVariantsDialog
        open={open}
        onSuccess={() => {
          props.navigateToVariantsTab()
        }}
        onClose={() => {
          setOpen(false)
        }}
      />
    </>
  )
}
