import { Stack, Typography } from '@mui/material'
import { DoneIcon, Link } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '..'

export const PasswordSet = () => {
  const { t } = useTranslation(['login'])
  const navigate = useNavigate()

  return (
    <Stack direction="column" alignItems="center">
      <DoneIcon fontSize="large" sx={{ mb: 2 }} />

      <Typography variant="displayMedium" sx={{ mb: 1 }}>
        {t('login:new_password_set')}
      </Typography>

      <Typography>
        {`${t('login:new_password_set_info')} `}
        <Link
          onClick={() => {
            void navigate(RootRoute.Login)
          }}
          sx={{
            cursor: 'pointer',
          }}
        >
          {t('shared:label.sign_in')}
        </Link>
      </Typography>
    </Stack>
  )
}
