import { useFavoriteReports } from './use-favorite-reports'
import { Button } from '@mui/material'
import { StarIcon, StarFullIcon } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

type Props = {
  reportId: number
}

export const FavoriteReportToggleButton = ({ reportId }: Props) => {
  const { t } = useTranslation('reports')
  const { checkIfFavorite, removeFavorite, addFavorite } = useFavoriteReports()
  const { enqueueSnackbar } = useSnackbar()

  const isFavorite = checkIfFavorite(reportId)

  const toggleFavorite = () => {
    isFavorite ? removeFavorite(reportId) : addFavorite(reportId)
    enqueueSnackbar({
      message: isFavorite
        ? t('reports:favorites.remove_favorite_notification')
        : t('reports:favorites.add_favorite_notification'),
      variant: 'info',
    })
  }

  return (
    <Button
      startIcon={isFavorite ? <StarFullIcon /> : <StarIcon />}
      color="secondary"
      onClick={toggleFavorite}
    >
      {isFavorite
        ? t('favorites.remove_favorite')
        : t('favorites.add_favorite')}
    </Button>
  )
}
