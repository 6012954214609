/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { ShipmentContact } from '../../../generated/graphql'
import { useServerValidation } from '../../../hooks/server-validation'
import { useTracking } from '../../../hooks/tracking'
import { validateEmail } from '../../../utils/validate'

export type ContactDialogProps = {
  open: boolean
  action: 'add' | 'edit'
  contact?: ShipmentContact | null
  onClose(): void
  onSubmit?(contact: ShipmentContact): Promise<void> | void
  onSuccess?(): void
  onError?(error: unknown): void
  dataTestid?: string
  isNewShipment: boolean
}

type Form = ShipmentContact

export const SetContactDialog = (props: ContactDialogProps) => {
  const { t } = useTranslation(['shipments', 'shared'])
  const dialogName = 'set-contact'
  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)
  const [loading, setLoading] = useState(false)
  const { formState, reset, handleSubmit, register, setError } = useForm<Form>({
    mode: 'onChange',
  })
  const setFormError = useServerValidation<Form>('shipments', setError, {
    resolveFieldFromProperty: (property) =>
      property.split('.').pop() || property,
  })

  const onSubmit = async (formData: Form) => {
    try {
      setLoading(true)
      await props.onSubmit?.(formData)
      trackFormSuccess({ name: `${dialogName}-dialog` })
      props.onSuccess?.()
    } catch (error) {
      trackFormError({ name: `${dialogName}-dialog` })
      setFormError(error)
      props.onError?.(error)
    } finally {
      setLoading(false)
    }
  }

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
      reset({ ...props.contact })
    }
  }, [props.open, prevOpen, trackDialogOpen, props.contact, reset])

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      data-testid="set-contact-dialog"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          data-testid="contact-dialog-title"
          type="extended"
          sx={{
            paddingBottom: (theme) => theme.spacing(2),
          }}
        >
          {t(
            props.action === 'add'
              ? 'shipments:set_contact_dialog.add_title'
              : 'shipments:set_contact_dialog.edit_title',
          )}
        </DialogTitle>
        <DialogContent>
          <TextField
            error={!!formState?.errors?.title}
            fullWidth
            helperText={
              formState?.errors?.title?.message ||
              t('shipments:shipment_form.title_description')
            }
            label={t('shipments:shipment_form.title')}
            {...register('title')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.first_name}
            fullWidth
            helperText={formState?.errors?.first_name?.message}
            label={t('shipments:shipment_form.first_name')}
            {...register('first_name')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.last_name}
            fullWidth
            helperText={formState?.errors?.last_name?.message}
            label={t('shipments:shipment_form.last_name')}
            {...register('last_name')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.email}
            fullWidth
            helperText={formState?.errors?.email?.message}
            label={t('shipments:shipment_form.email')}
            {...register('email', {
              validate: (value) =>
                value && !validateEmail(value)
                  ? t('shipments:error.invalid_email')
                  : true,
            })}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.mobile}
            fullWidth
            helperText={
              formState?.errors?.mobile?.message ||
              t('shipments:shipment_form.mobile_description')
            }
            label={t('shipments:shipment_form.mobile')}
            {...register('mobile', {
              setValueAs: (value: string) => value?.replace(/ /g, ''),
            })}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.note}
            fullWidth
            helperText={formState?.errors?.note?.message}
            label={t('shipments:shipment_form.note')}
            {...register('note')}
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={trackButtonClickEvent(
              {
                name: `${dialogName}-dialog-cancel`,
              },
              onClose,
            )}
            color="secondary"
            size="small"
            type="button"
          >
            {t('shared:action.cancel')}
          </Button>
          <Button
            type="submit"
            size="small"
            onClick={trackButtonClickEvent({
              name: `${dialogName}-dialog-set`,
            })}
            disabled={!formState.isValid}
            data-testid="set-address"
            loading={loading}
          >
            {props.isNewShipment
              ? t('shared:action.ok')
              : t('shared:action.save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
