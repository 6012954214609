import { useContext, useMemo } from 'react'
import { ReportJobsQuery } from '../../generated/graphql'
import { useLocalizedDate } from '../../hooks/localized-date'
import { useSelectFirst } from '../../hooks/use-select-first'
import { SelectInput } from '../../inputs/select-input'
import { ArrayElement } from '../../utils/types'
import { ReportsContext, ReportsContextType } from './reports-context'

type ReportJobType = ArrayElement<ReportJobsQuery['reportJobs']['items']>

export const ReportJobFilter = () => {
  const { reportJobs, isLoadingReportJobs, reportJobFormContext } = useContext(
    ReportsContext,
  ) as ReportsContextType

  const { formatRelativeDate, formatDate } = useLocalizedDate()

  const sortByDateDescending = (a: ReportJobType, b: ReportJobType) =>
    new Date(b.datecreated).getTime() - new Date(a.datecreated).getTime()

  const options = useMemo(
    () =>
      [...(reportJobs || [])].sort(sortByDateDescending).map((reportJob) => {
        return {
          value: String(reportJob.jobid),
          name: `${formatRelativeDate(reportJob.datecreated, { showTime: false })} (${formatDate(reportJob.datecreated)})`,
        }
      }) || [],
    [reportJobs, formatDate, formatRelativeDate],
  )

  useSelectFirst({
    formContext: reportJobFormContext,
    isLoading: isLoadingReportJobs,
    options,
    name: 'reportJobId',
  })

  return (
    <SelectInput
      name="reportJobId"
      control={reportJobFormContext.control}
      options={options}
      disabled={isLoadingReportJobs}
      sx={{
        mb: 1,
        '.MuiInput-root': {
          background: 'none',
          fontSize: '1.2rem',
          fontWeight: 700,
        },
      }}
    />
  )
}
