import { useQuery } from '@apollo/client'
import { Box, Container } from '@mui/system'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate } from 'react-router'
import { reportListFilterPanelVar } from '..'
import { RootRoute } from '../..'
import { DataGrid } from '../../../components/data-grid'
import { FilterContext } from '../../../components/data-grid/context'
import { CursorPagination } from '../../../components/data-grid/cursor-pagination'
import { SortItem } from '../../../components/data-grid/filters/sort'
import { TextFilter } from '../../../components/data-grid/filters/text-filter'
import {
  GetReportListSort,
  ReportsAllDocument,
  ReportsAllQuery,
  ReportsAllQueryVariables,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useTracking } from '../../../hooks/tracking'
import { ArrayElement } from '../../../utils/types'
import { ReportTypeIcon } from '../quick/report-type-icon'
import { ReportDetailsRenderer } from './report-details-renderer'
import { ReportTypesFilter } from './report-types-filter'

type Row = ArrayElement<ReportsAllQuery['reports']['items']>
type Filter = ReportTypesFilter & TextFilter
const PAGE_SIZE = 100

export const ReportsAllPage = () => {
  const { t } = useTranslation(['shared', 'reports'])
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const apiRef = useGridApiRef()
  const { filter, isFilterReady } =
    useContext<FilterContext<Filter>>(FilterContext)
  const { trackButtonClick } = useTracking()

  const onShowFilter = () => {
    trackButtonClick({ name: 'reports-filter-panel-open' })
    reportListFilterPanelVar({ isOpen: !reportListFilterPanelVar().isOpen })
  }

  const sortItems = useMemo<SortItem<GetReportListSort>[]>(
    () => [
      {
        field: 'reportid',
        sort: 'asc',
        title: t('reports:columns.id'),
        type: 'text',
        value: GetReportListSort.ReportIdAsc,
        isDefault: true,
      },
      {
        field: 'reportid',
        sort: 'desc',
        title: t('reports:columns.id'),
        type: 'text',
        value: GetReportListSort.ReportIdDesc,
      },
      {
        field: 'reportname',
        sort: 'asc',
        title: t('reports:columns.name'),
        type: 'text',
        value: GetReportListSort.ReportNameAsc,
      },
      {
        field: 'reportname',
        sort: 'desc',
        title: t('reports:columns.name'),
        type: 'text',
        value: GetReportListSort.ReportNameDesc,
      },
    ],
    [t],
  )
  const sortModel = apiRef.current.state?.sorting.sortModel

  const queryVariables = useMemo(() => {
    const config = {
      pagination: {
        start: 0,
        pageSize: PAGE_SIZE,
      },
      filter,
      sorting: sortModel,
    }
    const variables: ReportsAllQueryVariables = {
      num: config.pagination?.pageSize,
      start: 0,
    }

    variables.searchtext = config.filter.text?.value
    variables.reporttype = config.filter.reportTypes?.value

    if (config.sorting) {
      const sortItem = config.sorting[0]

      if (sortItem) {
        variables.sort = sortItems.find(
          (s) => s.field === sortItem.field && s.sort === sortItem.sort,
        )?.value
      }
    }

    return variables
  }, [filter, sortItems, sortModel])

  const {
    data: reportsData,
    loading: isLoadingReports,
    fetchMore,
  } = useQuery(ReportsAllDocument, {
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !isFilterReady,
  })

  const dataGridColumns = useMemo<GridColDef<Row>[]>(() => {
    const columns: GridColDef<Row>[] = [
      {
        field: 'icon',
        headerName: '',
        resizable: false,
        renderCell: (params) => (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <ReportTypeIcon type={params.row.reporttype} />
          </Box>
        ),
        maxWidth: 40,
      },
      {
        field: 'reportid',
      },
      {
        field: 'reportname',
        headerName: t('reports:columns.name'),
        flex: 0.5,
      },
      {
        field: 'reporttype',
        headerName: t('reports:columns.type'),
        valueFormatter: (_value, row) =>
          t(`reports:report_types.${row.reporttype}`),

        flex: 0.5,
      },
      {
        field: 'reportdefinition',
        headerName: t('reports:columns.details'),
        renderCell: ReportDetailsRenderer,
        flex: 1,
      },
    ]

    return columns
  }, [t])

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel
    if (reportsData?.reports.totalcount) {
      return fetchMore({
        variables: {
          start: (reportsData?.reports?.start ?? 0) + pageSize,
          num: pageSize,
        },
      })
    }
  }, [apiRef, fetchMore, reportsData])

  const rows = useMemo(() => reportsData?.reports.items || [], [reportsData])
  const isLoading = isLoadingReports || !isFilterReady

  return (
    <Container maxWidth={false}>
      <DataGrid
        name="reports-list"
        apiRef={apiRef}
        loading={isLoading}
        rowHeight={51}
        columns={dataGridColumns}
        getRowId={(row) => row.reportid}
        rows={rows}
        rowCount={reportsData?.reports.totalcount || 0}
        fetchMore={fetchMoreItems}
        onRowClick={(row) =>
          void navigate({
            pathname: generatePath(RootRoute.Report, { id: row.id }),
            search: createSearchParams({
              backTo: location.pathname,
            }).toString(),
          })
        }
        slots={{ pagination: CursorPagination }}
        paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
        sorting={sortItems}
        onShowFilter={onShowFilter}
        hasPageHeader
        updateSearchParams
        hasTextFilter
        columnVisibilityModel={{
          reportid: false,
          name: true,
          type: true,
          details: true,
          icon: true,
        }}
        showMore={
          Number(reportsData?.reports.items?.length) <
          Number(reportsData?.reports.totalcount)
        }
      />
    </Container>
  )
}
