import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '../../..'
import { FormDeleteField } from '../../../../components/form-delete-field'
import { useAbsolutePath } from '../../../../hooks/absolute-path'
import { useAuthorization } from '../../../../hooks/authorization'
import { DeleteProductDialog } from '../../../products/delete-product-dialog'
import { reactiveProductViewPanelVar } from '../../../products'

export const DeleteField = () => {
  const { t } = useTranslation(['products'])
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const {
    modules: { writeProducts },
  } = useAuthorization()

  const { id } = useParams()

  const onClose = () => {
    setIsDialogOpen(false)
  }

  const onSuccess = (
    successCampaignIds: number[],
    failedCampaignIds: number[],
  ) => {
    if (successCampaignIds.length) {
      enqueueSnackbar(
        t('products:success_delete_product_snackbar', {
          count: successCampaignIds.length,
        }),
      )
    }

    if (failedCampaignIds.length) {
      enqueueSnackbar(
        t('products:failure_delete_product_snackbar', {
          count: failedCampaignIds.length,
        }),
        { variant: 'error' },
      )
    }
    void navigate(generatePath(RootRoute.Products), {
      state: { ignoreLeavingGuard: true },
    })
    onClose()
    reactiveProductViewPanelVar({ isOpen: false, productId: 0 })
  }

  if (!id) return null

  return (
    <>
      <FormDeleteField
        onClick={() => setIsDialogOpen(true)}
        label={t('products:delete_product_title')}
        testId="product-delete-button"
        disabled={!writeProducts}
      >
        {t('products:delete_product_title')}
      </FormDeleteField>

      <DeleteProductDialog
        open={isDialogOpen}
        onClose={onClose}
        productIds={[Number(id)]}
        onSuccess={onSuccess}
        dataTestid="product-delete-dialog"
      />
    </>
  )
}
