import { Button } from '@mui/material'
import { Box, IconButton, List, ListItem } from '@mui/material'
import { DeleteIcon, FormFieldset, PlusIcon } from '@sitoo/mui-components'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AllSalesTaxesDocument, SalesTax } from '../../../../generated/graphql'

import { useQuery } from '@apollo/client'
import { useCallback } from 'react'
import { SalesTaxSelector } from '../sales-tax-selector'
import {
  BaseSalesTaxGroupContext,
  EMPTY_SALES_TAX,
  EMPTY_SALES_TAX_ID,
} from '../shared'

export const SalesTaxesField = () => {
  const { t } = useTranslation(['settings'])

  const { control } = useFormContext<BaseSalesTaxGroupContext>()
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'salestaxes',
    rules: {
      validate: (v: SalesTax[]) => {
        return v.some((salesTax) => salesTax.id !== EMPTY_SALES_TAX_ID)
          ? true
          : t('shared:validation.field_required', {
              field: t('settings:sales_tax_group.error.at_least_one_sales_tax'),
            })
      },
    },
  })

  const { data: allSalesTaxesData, loading: isLoadingSalesTaxes } = useQuery(
    AllSalesTaxesDocument,
    { fetchPolicy: 'cache-and-network' },
  )

  const onDeleteRule = (index: number) => {
    if (fields.length === 1) {
      update(0, EMPTY_SALES_TAX)
      return
    }
    remove(index)
  }

  const onAddSalesTax = useCallback(() => {
    append(EMPTY_SALES_TAX)
  }, [append])

  const seletedSalesTaxes = fields.map((field) => field.name)

  return (
    <>
      <FormFieldset
        label={t('sales_tax_group.sales_tax_fieldset')}
        sx={{ '.MuiFormFieldset-Paper': { p: 0 } }}
      >
        <List>
          {fields.map((field, index) => (
            <ListItem
              divider
              key={field.id}
              data-testid={`sales-tax-row-${index}`}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                py: 1,
                px: 2,
              }}
            >
              <SalesTaxSelector
                salesTaxes={allSalesTaxesData?.allSalesTaxes || []}
                index={index}
                isLoading={isLoadingSalesTaxes}
                control={control}
                onUpdate={update}
                selectedSalesTaxes={seletedSalesTaxes}
              />
              <Box>
                <IconButton
                  onClick={() => onDeleteRule(index)}
                  data-testid={`tax-group-delete-row-button-${index}`}
                >
                  <DeleteIcon color="primary" />
                </IconButton>
              </Box>
            </ListItem>
          ))}
        </List>
        <ListItem sx={{ p: 0, minHeight: 'auto' }}>
          <Button
            fullWidth
            color="tertiary"
            onClick={onAddSalesTax}
            data-testid="sales-tax-group-add-sales-tax-button"
          >
            <PlusIcon sx={{ mr: 1 }} />
            {t('sales_tax_group.add_sales_tax')}
          </Button>
        </ListItem>
      </FormFieldset>
    </>
  )
}
