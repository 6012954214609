import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { useMemo } from 'react'
import { DataGridImageRenderer } from '../../../components/data-grid-image'
import { CaptionRenderer } from '../../../components/data-grid/utils/caption-renderer'
import { ColumnProps } from '../../../components/data-grid/utils/column-props'
import { RelativeDateRenderer } from '../../../components/data-grid/utils/relative-date-renderer'
import {
  StringRenderer,
  StringEditRenderer,
} from '../../../components/data-grid/utils/string-renderer'
import { formatDecimal } from '../../../utils/format/number'
import { Row } from './list'
import { useAuthorization } from '../../../hooks/authorization'
import { useTracking } from '../../../hooks/tracking'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { useTranslation } from 'react-i18next'
import { formatProductTitle } from '../../../components/data-grid/utils/format-product-title'
import { formatWarehouseItemStatus } from '../../../components/data-grid/utils/format-warehouse-item-status'

type Props = {
  shouldShowCustomFields: boolean
  stockValueWarning: boolean
}

export const useStocktakingColumns = (props: Props) => {
  const { t } = useTranslation('stocktaking')
  const { shouldShowCustomFields, stockValueWarning } = props
  const { trackInputFocus, trackInputBlur } = useTracking()

  const { formatRelativeDate, formatDate } = useLocalizedDate()

  const {
    modules: { writeStocktaking },
  } = useAuthorization()

  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'images',
        ...ColumnProps.image,
        headerName: '',
        valueGetter: (_value, row): string => row.images?.[0] || '',
        cellClassName: 'image-column',
        headerClassName: 'image-column',
        renderCell: DataGridImageRenderer,
      },
      {
        field: 'productid',
        headerName: t('columns.productid'),
      },
      {
        field: 'sku',
        headerName: t('columns.sku'),
      },
      {
        field: 'title',
        headerName: t('columns.title'),
        valueGetter: formatProductTitle,
        renderCell: CaptionRenderer,
      },

      {
        field: 'binlocation',
        headerName: t('columns.binlocation'),
      },

      {
        ...ColumnProps.price,
        minWidth: undefined,
        field: 'decimaltotal',
        headerName: t('columns.decimaltotal'),

        valueGetter: (value: string) =>
          value ? formatDecimal(value) : undefined,
      },
      {
        field: 'decimalquantity',
        ...ColumnProps.price,
        minWidth: undefined,
        headerName: t('columns.decimalquantity'),
        editable: writeStocktaking,

        valueGetter: (value: string) =>
          value ? formatDecimal(value) : undefined,

        valueSetter: (value: string | undefined, row) => {
          const numValue = Number(value)
          const quantity = numValue < 0 ? String(0) : value

          return {
            ...row,
            decimalquantity: quantity ? formatDecimal(quantity) : undefined,
          }
        },

        renderCell: (params: GridRenderCellParams<Row, string>) =>
          StringRenderer(params, false),

        renderEditCell: (props) =>
          StringEditRenderer(
            props,
            trackInputFocus,
            trackInputBlur,
            'stocktaking-quantity',
          ),
      },
      {
        field: 'comment',
        headerName: t('columns.comment'),
        editable: writeStocktaking,
        valueGetter: (_value, row) => row.comment,
        renderCell: (params) => StringRenderer(params, false),
        renderEditCell: (props) =>
          StringEditRenderer(
            props,
            trackInputFocus,
            trackInputBlur,
            'stocktaking-note',
          ),
      },

      {
        field: 'warehousebatchitemstate',
        headerName: t('columns.warehousebatchitemstate'),

        valueGetter: (value, row) =>
          formatWarehouseItemStatus(value, row, stockValueWarning),
        renderCell: CaptionRenderer,
      },

      {
        field: 'decimalcounteddiff',
        headerName: t('columns.decimalcounteddiff'),

        valueGetter: (value: string | null) => {
          if (value === null) {
            return
          }
          const decimalValue = formatDecimal(value)

          return Number(value) > 0 ? `+${decimalValue}` : decimalValue
        },
      },

      {
        field: 'datemodified',
        headerName: t('columns.datemodified'),
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },

      {
        field: 'emailowner',
        headerName: t('columns.emailowner'),
      },

      ...(shouldShowCustomFields
        ? [
            {
              field: 'custom1',
              headerName: t('columns.custom1'),
            },
            {
              field: 'custom2',
              headerName: t('columns.custom2'),
            },
          ]
        : []),
    ],
    [
      formatDate,
      formatRelativeDate,
      shouldShowCustomFields,
      stockValueWarning,
      t,
      trackInputBlur,
      trackInputFocus,
      writeStocktaking,
    ],
  )

  return { columns }
}
