import { Category, Maybe } from '../../../generated/graphql'

/**
 * It concatenates all the parent category names to a string like:
 * Root Category / Parent Category / Target Category
 * @param categoryId - target category id
 * @param categories - a list of all the categories
 * @param fullPath - build a full category path e.e "Parent / Child"
 */
export const formatCategoryName = (
  categoryId: Maybe<number> | undefined,
  categories: Maybe<Category[]> | undefined,
  fullPath?: boolean,
): string | undefined => {
  if (!categories?.length) {
    return
  }

  const rowCategories: string[] = []
  let parentId: number | null | undefined = categoryId

  do {
    const category = categories?.find(({ id }) => id === parentId)

    if (!category) break

    rowCategories.unshift(category.title)

    if (!fullPath) break

    parentId = category.categoryparentid
  } while (parentId)

  if (rowCategories.length) {
    return rowCategories.join(' / ')
  }
}

/**
 * Returns a sorted list of categories by parent id
 */
function getByParentId(categories: Category[], parentId: number | null) {
  return categories
    .filter(({ categoryparentid }) => categoryparentid === parentId)
    .sort((a, b) => Number(a.sortorder) - Number(b.sortorder))
}

/**
 * Recursively builds a list of categories with all the children
 */
const getChildCategories = (
  category: Category,
  categories: Category[],
  fullPath: boolean,
): Category[] => {
  const childCategories = getByParentId(categories, category.id)

  const childChildCategories = childCategories
    .map((child) => ({
      ...child,
      title: fullPath ? [category.title, child.title].join(' / ') : child.title,
    }))
    .flatMap((child) => getChildCategories(child, categories, fullPath))

  return [category, ...childChildCategories]
}

/**
 * Returns a sorted list of categories with all the children.
 * If fullPath is true, it will concatenate all the parent category names to
 * a string like: `Jeans / Black / Skinny`
 */
export function buildCategoriesList(
  categories: Category[],
  fullPath = true,
): Category[] {
  const rootCategories = getByParentId(categories, null)

  return rootCategories.flatMap((rootCategory) =>
    getChildCategories(rootCategory, categories, fullPath),
  )
}
